import React, { useState, useEffect } from "react"
import { Checkbox } from "antd"

export const CheckBox = (props) => {
  const { field, value, id, handleChange, disabled } = props
  const {recsUpdated, setRecsUpdated} = props

  const [state, setState] = useState(value)

  useEffect(() => {
    setState(value)
  }, [value])

  useEffect(() => {
    if (recsUpdated) {
      setState(false)
      setRecsUpdated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recsUpdated])

  return (
    <Checkbox
      data-cy="Checkbox"
      disabled={disabled}
      checked={state}
      defaultChecked={value}
      onChange={e => {
        setState(e.target.checked)
        handleChange({...props, field, value: e.target.checked, id })
      }}
    />
  )
}

export default CheckBox
