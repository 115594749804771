import React, { useEffect } from "react"
import { log, Login, Logout } from "../store/psrs"
import { Card, Layout, Button, Row, Col } from "antd"
import 'antd/dist/antd.css'

const { Content } = Layout

export const Home = props => {
  log.g('Home')

  useEffect(() => {
    if (props.location.pathname === "/logout") Logout()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logoUrl = process.env.REACT_APP_LOGO_URL || 'psrsLogin.png'

  const getProductTitle = () => {

    /* const poweredBy = (
      <>Powered By \n<strong>Enterprise Technologies SchedulerPro<sup>&copy;</sup></strong></>
    )
  
    const productTitle = (
      <>
        <strong>T</strong>ime  <strong>P</strong>ay &<strong>S</strong>ystem
      </> 
    ) */
  
    return (
      <div style={{textAlign: 'center'}}>
        <strong>Oceanside<br />T</strong>ime & <strong>P</strong>ay <strong>S</strong>ystem<br /><small>Powered By<br /><strong>Enterprise Technologies SchedulerPro<sup>&copy;</sup></strong></small>
      </div> 
    )

  }
  


  return (
    <>
      <Content>
        <Row > 
            <Col xs={24} sm={24} md={8} lg={8} xl={8} />
            <Col xs={24} sm={24} md={8} lg={8} xl={8} style={{ padding: "4rem" }}>
              {!window.location.hash.startsWith("#/id_token") && (
                <Card
                 //title={<><strong>T</strong>ime  <strong>P</strong>ay & <strong>S</strong>ystem\n Powered By<strong>Enterprise Technologies SchedulerPro<sup>&copy;</sup></strong></>}
                 title={getProductTitle()}
                 actions={[
                    <Button data-cy="login-button" shape="round" size="large" onClick={Login}>
                      Log In
                    </Button>
                  ]}
                >
                  <img width='100%' height='100%' src={logoUrl} alt=''/>
                </Card>
              )}
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} />
        </Row>
      </Content>
      
    </>
  )
}

export default Home
