import React, { useState, useEffect, useMemo } from "react"

export const TriToggle = props => {
  const { field, value, handleChange, labels } = props
  const {clearFilters, setClearFilters} = props
  const {recsUpdated, setRecsUpdated} = props
  const dataType = props.dataType || "boolean"
  //const [state, setState] = useState(value || null)

  let _value = null
  if (value === true || value === false) {
    _value = value
  } 

  if (value === 'sync' || value === 'synced') _value = value

  

  const [state, setState] = useState(_value )

  const initialLabel = state ? labels[0] : state === false ? labels[1] : `${labels[0]} ?`
  const [label, setLabel] = useState(initialLabel)
  const [mounted, setMounted] = useState(false)
  const initialClassName = state === false ? "exclude" : state === true ? "include" : "off"
  const [className, setClassName] = useState(`track ${initialClassName}`)

  if (clearFilters) {
      setClearFilters(false)
  }
  
  const toggleValue = (clearFilter) => {
    
    const _clearFilter = typeof clearFilter === 'boolean' ? true : false

    //console.log("toggleValue state", state)
    if (state === null && !_clearFilter) {
      setClassName("track include")
      setState(true)
      setLabel(labels[0])
    }
    if (state === true && !_clearFilter) {
      setClassName("track exclude")
      setState(false)
      setLabel(labels[1])
    }
    if (state === false && !_clearFilter) {
      setClassName("track off")
      setState(null)
      setLabel(`${labels[0]} ?`)
    }
    if (_clearFilter ) {
      setClassName("track off")
      setState(null)
      setLabel(`${labels[0]} ?`)
    }
  }

  const sql = useMemo(() => {
    if (state === null) return null
    else if (field === "unedited") return `"updatedAt" ${state === true ? "=" : "!="} "createdAt"`
    else if (dataType === "number" && !!state) return `"${field}" IS NOT NULL AND "${field}" > 0`
    else if (dataType === "number" && !state) return `"${field}" IS NULL OR "${field}" = 0`
    else if (dataType === "boolean" && !!state) return `"${field}" IS TRUE`
    else if (dataType === "boolean" && !state) return `"${field}" IS FALSE`
    else return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    if (!mounted) setMounted(true)
    else {
      handleChange({ ...props, field, value: state, sql })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  useEffect(() => {
    if (mounted && recsUpdated) {
      setState(false)
      toggleValue(false)
      setRecsUpdated(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recsUpdated])

  useEffect(() => {
    if (mounted && clearFilters) {
      setState(false)
      toggleValue(false)
      setClearFilters(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilters])

  return (
    <>
      <label className="TriToggleLabel">{label}</label>
      <div data-cy="tri-toggle" className="TriToggle" onClick={toggleValue}>
        <div className={className}>
          <span className="knob"></span>
        </div>
        {/* <span className="label">{display}</span> */}
      </div>
    </>
  )
}

export default TriToggle
