import React, { useState, useEffect } from "react"
import { reqOptions, REQ, store } from "../store/psrs"
import useSWR, { mutate } from "swr"
import { useParams, useHistory } from "react-router-dom"
import { TeamMembers } from "./TeamMembers"
import { SupervisorSelect } from "./Forms/SupervisorSelect"
import InlineEdit from "./Forms/InlineEdit"
import { TagSelect } from "./Forms/TagSelect"
import { message, Spin, Button, Tag, Breadcrumb, Icon, Tooltip, Popconfirm } from "antd"
import { Link } from "react-router-dom"
import _ from "lodash"

export const Team = props => {
  const { teamId } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [team, setTeam] = useState(false)

  const fetcher = url => {
    // setLoading(true)
    const options = { ...reqOptions }
    return fetch(url, options).then(res => {
      setLoading(false)
      return res.json()
    })
  }

  const key = `${store.get("api")}/api/teams/${teamId}`

  const { data } = useSWR(key, fetcher)

  const PageHeader = ({ title }) => {
    return (
      <>
        <div className="view_title">
          <h1 data-cy="page-title">{title}</h1>
        </div>
        <div className="Breadcrumb">
          <Breadcrumb>
            <Breadcrumb.Item key="home">
              <span onClick={() => history.push(`/nav`)}>
                <Icon type="home" />
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="teams">
              <span data-cy="back" onClick={() => history.push(`/teams/mine`)}>
                <Icon type="team" />
                <span> My Teams</span>
              </span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </>
    )
  }

  const updateTeam = async ({ field, value }) => {
    setLoading(true)
    let update = !field ? _.merge(team, value) : { ...team }
    if (field) update[field] = value
    const updatedTeam = await REQ(`/api/teams/${teamId}`, "PATCH", update)
    if (updatedTeam.errors) {
      //console.log(`updatedTeam.errors ... team.name = ${team.name}`, team)
      setTeam({ ...team, yo: Date.now() })
      const { errors } = updatedTeam
      errors.forEach(e => {
        message.error(e.message)
      })
      // mutate(`${store.get("api")}/api/teams/${teamId}`, team)
    } else {
      setTeam(updatedTeam)
      // mutate(`${store.get("api")}/api/teams/${teamId}`, updatedTeam)
    }
    setLoading(false)
  }

  const createSchedule = async () => {
    setLoading(true)
    const tagIds = team.tags.map(t => t.id)
    const createdSchedule = await REQ(`/api/teams/${team.id}/create_schedule`, "POST", { tagIds, isOvertime: false })
    setLoading(false)
    history.push(`/team/${teamId}/schedule/${createdSchedule.id}`)
    // mutate(`${store.get('api')}/api/teams/${teamId}`, updatedTeam)
  }

  const createOtSchedule = async () => {
    setLoading(true)
    const tagIds = team.tags.map(t => t.id)
    const createdSchedule = await REQ(`/api/teams/${team.id}/create_schedule`, "POST", { tagIds, isOvertime: true })
    setLoading(false)
    history.push(`/team/${teamId}/schedule/${createdSchedule.id}`)
    // mutate(`${store.get('api')}/api/teams/${teamId}`, updatedTeam)
  }

  const deleteSchedule = async templateId => {
    setLoading(true)

    let canRemove = true
    let updatedTeam = {}
    message.loading({content: "Analyzing Shifts and Time Entries for this Schedule", duration: 5})

    const Resource = await REQ(`/api/resources/template/${templateId}`)
    if (Resource.shifts && Resource.shifts.length > 0) {
      for (let s of Resource.shifts) {
        if (s.approved || s.submit || s.rejected || s.process || s.locked) {
          canRemove = false
        }
      }
    } 
    
    if (canRemove) {
      updatedTeam = await REQ(`/api/templates/${templateId}`, "DELETE")
    } else {
      message.warning("This schedule has time entriy activity and can't be deleted.")
    }

    setLoading(false)
    mutate(`${store.get("api")}/api/teams/${teamId}`, updatedTeam)
  }

  const Schedule = () => {
    const Schedules = team.templates || []
    return Schedules.map((t, i) => {
      return (
        <li key={`s${i}`}>
          <span className="link">
            <Link data-cy={`TeamScheduleLink`} id={`link-${t.id}`} to={`/team/${team.id}/schedule/${t.id}`}>
              {t.name || `Schedule id: ${t.id}`}
            </Link>
          </span>
            <span data-cy={`TeamScheduleDelete`} id={`delete-${t.id}`} className="icon link" >
            <Tooltip title={`Delete Schedule`}>
              <Popconfirm
                title='Delete Schedule - This can not be undone' 
                okText='Delete Schedule?'
                onConfirm={()=> deleteSchedule(t.id)}
                cancelText="Cancel"
              >
                  <Icon type="delete" />
              </Popconfirm>
            </Tooltip>
            </span>
        </li>
      )
    })
  }

  const Super1 = team ? team.supervisor1 : false
  const Super2 = team ? team.supervisor2 : false
  const Super3 = team ? team.supervisor3 : false
  const Subordinates = Super1 ? Super1.subordinates : []
  const disabled = false
  const selOpts = { pk: "id", storeKey: "teams", record: team, handleChange: updateTeam }

  useEffect(() => {
    //console.log("useEffect Team data changed", data)
    if (_.isObject(data)) {
      setTeam({ ...data, yo: Date.now() })
    }
  }, [data])

  useEffect(() => {
    // console.log(`Team Name Changed to ${team.name}`)
  }, [team])

  return (
    <>
      {!team && (
        <div className="Team" data-resource-id={teamId}>
          <PageHeader title="Loading" />
        </div>
      )}
      {!!team && (
        <div className="Team" data-resource-id={teamId}>
          <PageHeader title={team.name} />

          <div className="view_content">
            <div className="grid_container">
              <section className="one grid_section">
                <header>Name / Groups</header>
                <Spin spinning={loading}>
                 <div>
                    <InlineEdit data-cy="TeamNameInput" field="name" value={team.name} handleChange={updateTeam} yo={team.yo} />
                  </div>
                  <div>
                    <TagSelect data-cy="TeamTagSelect" field="tagIds" value={team.tags} {...selOpts} />
                  </div>
                </Spin>
                <header>Command</header>
                <Spin spinning={loading}>
                  <div className="field item">
                    <SupervisorSelect
                      data-cy="TeamSupervisorSelect"
                      field="superNumber1"
                      supervisor={team.supervisor1}
                      {...selOpts}
                    />
                  </div>
                  {Super2 && (
                    <div className="item">
                      <Tag data-cy="TeamSuper2Title">{Super2.jobTitle}</Tag>
                      <span data-cy="TeamSuper2Name">{Super2.fullName}</span>
                    </div>
                  )}
                  {Super3 && (
                    <div className="item">
                      <Tag data-cy="TeamSuper3Title">{Super3.jobTitle}</Tag>
                      <span data-cy="TeamSuper3Name">{Super3.fullName}</span>
                    </div>
                  )}
                </Spin>
              </section>

              <section className="two grid_section">
                <TeamMembers subordinates={Subordinates} members={team.members} team={team} mutate={mutate} url={key} />
              </section>

              <section className="three grid_section">
                <header>Schedules</header>
                <Spin spinning={loading}>
                  <ul>
                    <Schedule records={team.templates} />
                    <li>
                      <span>Create Schedule</span>
                      <Button.Group>
                        <Button
                          data-cy="TeamCreateScheduleButton"
                          size="small"
                          theme="filled"
                          disabled={disabled}
                          onClick={createSchedule}
                        >
                          Regular
                        </Button>
                        <Button
                          data-cy="TeamCreateOtScheduleButton"
                          size="small"
                          theme="filled"
                          disabled={disabled}
                          onClick={createOtSchedule}
                        >
                          Overtime
                        </Button>
                      </Button.Group>
                    </li>
                  </ul>
                </Spin>
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Team
