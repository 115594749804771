import React from "react"

export class PageLayout extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="view_title">
          <h1 data-cy="page-title">{this.props.title}</h1>
          <div className="actions">{this.props.actions}</div>
        </div>
        <div className="view_content">{this.props.children}</div>
      </React.Fragment>
    )
  }
}

export default PageLayout
