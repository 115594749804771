import React, { useState, useEffect } from "react"
import { Modal, message } from "antd"
import { fetch, store } from "../../../store/psrs"

export const RestoreTimeEntryModal = props => {
  //const today = moment()
  const { restoreTimeEntryOpen, setRestoreTimeEntryOpen, setParanoid, records, excluded, queryRecords, filters, setFilters, query, setQuery } = props
  const included = records.filter(r => !excluded.has(r.id))

  const [loading, setLoading] = useState(true)
  const [restoreShiftCount, setRestoreShiftCount] = useState(0)

  const handleRestore = async () => {
    //console.log("handleRestore");

    var ids = included.map(s => s.id)
    //const body = included.map(s => ({ id: s.id, deletedAt: s.deletedAt }))

    message.loading({ content: "Restoring Records...", key: "restoring" })
    const url = `${store.get("api")}/api/time_entries/restore`
    //const restored = 
    await fetch(url, { method: "POST", body: JSON.stringify({ entryIds: ids }) })
    setFilters({ ...filters, deletedAt: null })
    setQuery({...query, deletedAt: null})
    setParanoid(true)
    await queryRecords()
    setParanoid(true)
    setRestoreTimeEntryOpen(false)
   
    message.success({ content: "Successfully Restored Records...", key: "restoring" })
  }

  const fetchShifts = async () => {
    setLoading(true)

    for (let entry of included ) {
      const url = `${store.get("api")}/api/shifts/restore/${entry.shiftId}`
      const _shift = await fetch(url, { method: "GET" })
      if (_shift && _shift.length > 0 ) {
        setRestoreShiftCount(_shift.length)
      }
      
    }

    setLoading(false)
  }


  useEffect(() => {
    if (restoreTimeEntryOpen) fetchShifts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoreTimeEntryOpen])

  return (
    <Modal
      title={`Restore ${included.length} Time Entries ?`}
      visible={restoreTimeEntryOpen}
      onOk={handleRestore}
      okText='RESTORE'
      okButtonProps={{type: 'default'}}
      onCancel={() => setRestoreTimeEntryOpen(false)}
      cancelText='CANCEL'
    >
      {loading && <h3>Loading Details ...</h3>}

      {!loading && included.length && (

        <div className="bulkDeleteBreakdown">
          <ul>
            <li>
              <b>{included.length}</b> Time Entries will be bulk restored
            </li>
            <li>
              Restoring these <b>{included.length}</b> Time Entries  will result in restoring <b>{restoreShiftCount}</b>  Shifts
            </li>
          </ul>
        </div>
      )}
    </Modal>
  )
}
