import React from "react"

export const JobPill = ({jobTitle}) => {
  // officer
  // secretary
  // beach
  // supervisor
  // csofficer
  // assistant
  // specialist
  // tech
  // cadet
  // captain
  // chief
  // lieutenant
  // recruit
  // mgr
  // sergeant
  // calltaker
  // dispatcher
  // analyst

  let t = typeof jobTitle === "string" ? jobTitle.toLowerCase() : "none"

  // t = t.replace("police officer ", "")
  // t = t.replace("police ", "")
  // t = t.replace("public saftey ", "")
  // t = t.replace("public safety ", "")
  // t = t.replace("police records ", "")
  // if (t.includes("community svcs officer")) t = "csofficer"
  // if (t.includes("admin secretary")) t = "secretary"
  // if (t.includes("call taker")) t = "calltaker"
  // if (t.includes("seargent")) t = "sgt"
  // if (t.includes("beach")) t = "beach"
  // if (t.includes("spec")) t = "specialist"
  // if (t.includes("analyst")) t = "analyst"
  // if (t.includes("superv")) t = "supervisor"
  // if (t.includes("supv")) t = "supervisor"
  // if (t.includes("mgr")) t = "mgr"
  // if (t.includes("tech")) t = "tech"
  // if (t.includes("assistant")) t = "assistant"
  return (
    t && (
      <span data-cy="job-pill" className={`pill job ${t}`}>
        {t}
      </span>
    )
  )
}

export default JobPill

// let title = jobTitle.toLowerCase()
// let titles = [
//   "Police Officer",
//   "Admin Secretary",
//   "Beach Safety Officer",
//   "Communications Superv",
//   "Community Svcs Officer",
//   "Consulting Assistant",
//   "Crime Prev Spec",
//   "Crime and Intel Analysis Supv",
//   "Evid & Prop Superv",
//   "Evid & Prop Tech II",
//   "Field Evidence Tech",
//   "Office Assistant",
//   "Police Cadet",
//   "Police Captain",
//   "Police Chief",
//   "Police Lieutenant",
//   "Police Officer",
//   "Police Officer Recruit",
//   "Police Records Mgr",
//   "Police Records Superv",
//   "Police Records Tech",
//   "Police Sergeant",
//   "Professional Assistant",
//   "Program Spec",
//   "Public Saftey Call Taker",
//   "Public Saftey Com Mgr",
//   "Public Saftey Dispatcher",
//   "Sr Crime & Intel Analyst",
//   "Sr Field Evidence Tech",
//   "Sr Mgmt Analyst",
//   "Sr Office Spec",
//   "Sr Police Records Tech"
// ]
// titles = titles.map((t) => t.toLowerCase())
// titles = titles.map((t) => {
//   t = t.replace("police officer ", "")
//   t = t.replace("police ", "")
//   t = t.replace("public saftey ", "")
//   t = t.replace("public safety ", "")
//   t = t.replace("police records ", "")
//   if (t.includes("community svcs officer")) t = "csofficer"
//   if (t.includes("admin secretary")) t = "secretary"
//   if (t.includes("call taker")) t = "calltaker"
//   if (t.includes("seargent")) t = "sgt"
//   if (t.includes("beach")) t = "beach"
//   if (t.includes("spec")) t = "specialist"
//   if (t.includes("analyst")) t = "analyst"
//   if (t.includes("superv")) t = "supervisor"
//   if (t.includes("supv")) t = "supervisor"
//   if (t.includes("mgr")) t = "mgr"
//   if (t.includes("tech")) t = "tech"
//   if (t.includes("assistant")) t = "assistant"
//   return t
// })
