import React, { useState } from "react"
import { store } from "../../store/psrs"
//import Select from "react-select/creatable"
import Select from "react-select"
// import {TagSelectOptions} from "./_SelectOptions"
//import { REQ } from "../../store/psrs"
import _ from "lodash"

export const TagSelect = props => {
  const { id, disabled, /*filter*/ } = props

  //let values = props.value && Array.isArray(props.value) ? props.value.map(r => r.id) : []
  let values = props.value && Array.isArray(props.value) ?
      props.value.map(r => {
        if (r.id) return r.id
        else return r
    }) : []
  


  //-//console.log("TagSelect values", values)

  // const Tags = store.get("Tags") || []

  const TagSelectOptions = () => {
    let tagSelectOptions = store.get("Tags").map(r => ({ value: r.id, label: r.name }))
    tagSelectOptions = _.sortBy(tagSelectOptions, ['label']) 
    return tagSelectOptions
  }

  const options = TagSelectOptions()

  const selected = _.filter(options, g => {
    return values.includes(g.value)
  })

  const [state, setState] = useState({
    options,
    selected,
    sql: null,
    loading: false,
  })

  let _selected = state.selected
  if (state.selected !== selected) _selected = selected
  // console.log('state.selected', state);

  const selectProps = {
    className: "psrs-select",
    classNamePrefix: "psrs",
    placeholder: "Select Groups",
    isMulti: props.multi || true,
    options: TagSelectOptions(),
    value: _selected, //state.selected,
    isDisabled: disabled || state.loading,
    isLoading: state.loading,
    onChange: (select, action) => {
      //console.log(select)
      const val = select ? select.map(v => v.value) : []
      const labels = select ? select.map(v => v.label) : []
      setState({ ...state, selected: select })
			//console.log(labels);
			var sql = "";
			if( labels && labels[0] ) {
        // eslint-disable-next-line
				var i = 0, terms = "";
				labels.forEach(function (text) {
					//console.log("text: " + text);
					if( text && text.indexOf(" ") !== -1 ) {
      			var sp = text.split(" ");
      			while( sp.indexOf("") !== -1 ) {
        			sp.splice(sp.indexOf(""), 1);
      			}
      			text = sp.join(" <-> ");
    			}
					terms += (terms.length ? " | " : "") + text + ":*";
					i++;
				});
				//console.log("terms: " + terms);
      	sql = `_search @@ to_tsquery('english', '${terms}')`
			}
      props.handleChange({ ...props, value: val, field: "tagIds", id, sql })
    },
    onCreateOption: async name => {
      return null
    }
    /* Removing inline add for now.
    onCreateOption: async name => {
      setState({ ...state, loading: true })
      const created = await REQ("/api/resources/tag", "POST", { name: name.toLowerCase() })
      const newOption = { value: created.id, label: created.name }
      const newOptions = [...state.options, newOption]
      const newSelected = [...state.selected, newOption]
      setState({ ...state, loading: false, options: newOptions, selected: newSelected, sql: "" })
      // props.handleChange({ id, field, value: update, sql })
    },
    */
  }

  return <Select {...selectProps} />
}

export default TagSelect
