import React, { useState, useEffect } from "react"
import { reqOptions, store, Can /* idb WIP, idb*/ } from "../store/psrs"
import useSWR, { mutate } from "swr"
import ReactTable from "react-table"
import { Button, Tooltip } from "antd"
import { Search, InPut, CheckBox } from "./Admin/fields"
import { deleteResource, createResource, updateResource, sortStringAsInt } from "./Admin/fields"
import { PayTypeSelect } from "./Forms/PayTypeSelect"
import _ from "lodash"
// import {REQ} from "../store/psrs"

export const Assignments = props => {
  const pageTitle = "Assignments"
  const createTitle = "Create New Assignment"


  // new indexedDB --- WIP
 //let initialRecords
 // const initRecs = async c => {
 //   let r = await idb.collection(c).get()
 //   initialRecords = r
 // }

//  initRecs('Assignments')

// end indexedDB -- WIP
  const [state, setState] = useState({
    pk: "id",
    model: "assignment",
    ids: [],
    //InexedDB wip -- initialRecords: initialRecords  || undefined,
    initialRecords: store.get("_Assignments") || undefined,
  })

  const [records, setRecords] = useState(state.initialRecords || [])

  const fetcher = async url => {
    const options = { ...reqOptions }
    const data = await fetch(url, options).then(res => res.json())
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    return data
  }
  const key = `${store.get("api")}/api/resources/assignment`

  const { data } = useSWR(key, fetcher, { initialData: state.initialRecords })

  const _props = { state, setState, mutate, url: key }

  const columns = [
    {
      Header: "Updated",
      accessor: "updatedAt",
      show: false,
    },
    {
      Header: "ID",
      accessor: "id",
      className: "id_column",
      show: false,
    },
    {
      Header: "",
      accessor: "i",
      sortable: false,
      show: true,
      width: 50,
      style: {
        color: 'white',
      },
    },
    {
      Header: "Enabled",
      accessor: "forever",
      sortable: false,
      className: "column_align_center",
      headerClassName: "column_align_center",
      Cell: cell => {
        const id = cell.original.id
        return <CheckBox field="forever" id={id} toValue={true} unValue={false} value={cell.value} {..._props} />
      },

    },
    {
      Header: "Name",
      accessor: "name",
      sortable: false,
      Cell: row => {
        return <InPut field="name" value={row.value} id={row.original.id} {..._props} />
      },
    },
    {
      Header: "Pay Type (optional)",
      accessor: "payTypeCode",
      sortable: false,
      Cell: row => {
        const record = row.original
        const opts = {
          id: record.id,
          record,
          value: row.value,
          optional: true,
          handleChange: ({ value }) => {
            updateResource({ ..._props, value, field: "payTypeCode", id: record.id })
          },
        }
        return <PayTypeSelect data-cy="PayTypeSelect" {...opts} />
      },
      sortMethod: sortStringAsInt,
    },
    {
      Header: "Type",
      accessor: "type",
      sortable: false, 
      Cell: row => {
        return <InPut field="type" value={row.value} id={row.original.id} {..._props} />
      },
    },
    {
      Header: "Job Code",
      accessor: "jobCode",
      sortable: false, 
      Cell: row => {
        return <InPut field="jobCode" value={row.value} id={row.original.id} {..._props} />
      },
    },
    {
      Header: "Job Step Code",
      accessor: "jobStepCode",
      sortable: false, 
      Cell: row => {
        return <InPut field="jobStepCode" value={row.value} id={row.original.id} {..._props} />
      },
    },
    {
      Header: "Timecard Override",
      accessor: "jobStepTimecardOverride",
      sortable: false, 
      Cell: row => {
        return <InPut field="jobStepTimecardOverride" value={row.value} id={row.original.id} {..._props} />
      },
    },
    { Header: "updated", accessor: "updatedAt", show: false },
    {
      Header: "Actions",
      sortable: false, 
      width: 100,
      Cell: row => (
        <div className="row_actions">
          <Can I="create:Assignments">
            <Tooltip title="Delete">
              <Button icon="delete" shape="circle" type="danger" onClick={e => deleteResource({ ..._props, id: row.original.id })} />
            </Tooltip>
          </Can>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (data && data.length) store.set("_Assignments", data)
    if (data && data.length) store.set("Assignments", data)

    return() => { 
      store.remove("_Assignments")
    }

  }, [data])

  useEffect(() => {
    //console.log("DATA CHANGE", data)
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ids])

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{pageTitle}</h1>
        <div className="actions">
          <Search {..._props} />
          <Can I="create:Assignments">
            <Button data-cy="CreateResourceButton" type="dashed" icon="plus-square" onClick={() => createResource(_props)}>
              {createTitle}
            </Button>
          </Can>
        </div>
      </div>
      <div data-cy="RecordsTable" className="view_content">
        <ReactTable
          columns={columns}
          loading={state.loading}
          data={records}
          defaultPageSize={100}
          filterable={true}
          multiSort={false}
          className="-striped -highlight flex-1"
          defaultSorted={[{ id: "i", desc: true }]}
          onSortedChange={() => mutate(key)}
        />
      </div>
    </React.Fragment>
  )
}

export default Assignments
