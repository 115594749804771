import React from "react"
import { MyContext, AbilityContext } from "./context"
import { ability } from "./ability"
import produce from "immer"
import { REQ, Emitter, seedData, getRecord, getRecords, store, getPayTypeRecord } from "./psrs"
// import {seedData, , , getCurrentPayPeriod} from "./psrs"

// DB.events.on('loaded', () => console.log('AHOY'))
class StoreProvider extends React.Component {
  _isMounted = false
  state = {
    confirmed: false,
    loading: false,
    dhtmlxAttachedEventIds: new Set(),
    newShiftDrawer: 0,
    shiftDrawerId: store.get("shiftDrawerId") || 0,
    templateDrawerId: store.get("templateDrawerId") || 0,
    updatedAt: Date.now(),
    getRecords: CollectionName => getRecords(CollectionName),
    getRecord: (CollectionName, value, key) => getRecord(CollectionName, value, key),
    getPayTypeRecord: (CollectionName, value, key) => getPayTypeRecord(CollectionName, value, key),
    setPayPeriod: payPeriod => {
      store.set("PayPeriod", payPeriod)
      this.setState({ ...this.state, payPeriod })
    },
    //setOriginalShift: shift => {
    //  store.set("theOriginalShift", shift)
    //  this.setState({...this.state, shift })
    //},

    setPayPeriodById: async payPeriodId => {
      const pp = await REQ(`/api/pay_periods/${payPeriodId}`)
      if (this._isMounted) this.setState({ ...this.state, payPeriod: pp })
    },
    dhtmlxSetAttachedEventIds: ids => {
      this.produceState({ dhtmlxattAchedEventIds: ids }, "dhtmlxSetAttachedEventIds")
    },
    setNewlyCreatedShift: async shift => {
      const pp = await REQ(`/api/pay_periods/${shift.payPeriodId}`)
      if (this._isMounted) this.setState({ ...this.state, newShiftDrawer: 0, shiftDrawerId: shift.id, payPeriod: pp })
    },
    setNewShiftDrawerPayTypeCode: payTypeCode => {
      if (this._isMounted) this.setState({ ...this.state, newShiftDrawer: payTypeCode })
    },
    setNewShiftTemplate: shiftJson => {
      if (this._isMounted) this.setState({...this.state, shiftJson: shiftJson})
    },
    setShiftDrawerId: id => {
      //console.log("setShiftDrawerId", id)
      store.set("shiftDrawerId", id)
      Emitter.emit(id !== 0 ? "ShiftDrawerOpen" : "ShiftDrawerClose")
      if (this._isMounted) this.setState({ ...this.state, shiftDrawerId: id })
    },
    setTemplateDrawerId: id => {
      //console.log("setTemplateDrawerId", id)
      store.set("templateDrawerId", id)
      Emitter.emit(id !== 0 ? "templateDrawerOpen" : "templateDrawerClose")
      if (this._isMounted) this.setState({ ...this.state, templateDrawerId: id })
    },
    updateStore: params => {
      let update = { updatedAt: Date.now() }
      if (params) update = { ...update, ...params }
      if (this._isMounted) this.setState({ ...this.state, update })
    },
    rawQuery: async (query, model) => {
      const results = await REQ("/api/raw_query", "POST", { query })
      if (model) {
        const update = {}
        update[model] = results[1].rows
        this.produceState(update, "rawQuery")
      }
      return results[1].rows
    },
    logout: () => {
      localStorage.clear()
      this.props.zero.logout()
    },
    callMethodAsync: async method => {
      if (this[method] && this[method].constructor.name === "AsyncFunction") {
        return await this[method]()
      } else return false
    },
    callMethod: method => {
      if (this[method] && !this[method].constructor.name !== "AsyncFunction") {
        return this[method]()
      } else return false
    },
  }

  handleError(err) {}

  produceState({ verb, data, storeKey, pks, records }) {
    const newState = produce(this.state, draft => {
      draft.loading = false
      if (draft.payPeriod) localStorage.setItem("payPeriodId", draft.payPeriod.id)
      draft.storeHasRecords = true
      return draft
    })
    if (this._isMounted) this.setState(newState)  //<-- causing unmounted memleak...  
  }

  componentDidMount() {
    this._isMounted = true
  /*
    // eslint-disable-next-line
    let accessGood
    document.cookie.split('; ').forEach(cookie => {
      const key = cookie.split('=')[0];
      if (key.includes('com.auth0.auth.') || (key.includes('psrs'))) {
        accessGood = true
        //document.cookie = key +'=; Max-Age=-99999999;';  
      }
    });

*/
    if (store.session.get("token")) seedData()
    if (store.session.get("loginConfirmed")) seedData()

    
    // blw 03-25-2021... 
    ////this.interval = setInterval(() => {
      //if (store.session.get('loginConfirmed') && store.session.get('CurrentUser').auth0User) {
   ////   if (store.session.get('loginConfirmed') && store.session.get('CurrentUser').number) {
   ///     this.setState(seedData())
   ///   }
   /// }, 15000);
    
  }

 //// componentWillUnmount() {
 ///   console.log('*** blw - componentWillUnmound....')
 ////   clearInterval(this.interval);
 //// }
  // componentDidUpdate(prevProps, prevState) { }
// end blw 03-25-2021
  setSelectData(data) {
    const newState = produce(this.state, draft => {
      return { ...draft, ...data, selectData: true, loading: false }
    })
    if (this._isMounted) this.setState(newState)
  }

  constructor(props) {
    super(props)
    this.setSelectData = this.setSelectData.bind(this)
    this.produceState = this.produceState.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  render() {
    const user = store.session.get("CurrentUser")
    // console.log('const user = store.session.get(currentUser)', store.session.get('CurrentUser'));

    return (
      <MyContext.Provider value={this.state}>
        <AbilityContext.Provider value={ability(user)}>{this.props.children}</AbilityContext.Provider>
      </MyContext.Provider>
    )
  }
}

export default StoreProvider
