import React from "react"
import {store} from "../../store/psrs"
import {Button} from "antd"

const ShiftSubNavButtons = ({history, page}) => {
  const role = store.session.get('role')
  const nav = (e) => {
     return e.target && e.target.value ? history.push(e.target.value, {basePath: "/shifts"}) : false
  }
  const supervised = page === "SupervisedShifts"
  const direct = page === "DirectSupervisedShifts"
  const mine = page === "MyShifts"
  const approvals = page === "AvailableShifts"
  const bidWorkbench = page === 'AvailableShiftsWB'

  return (
    <Button.Group className="mx-2">
      { role !== "employee" && <Button type={bidWorkbench ? "primary" : "default"} onClick={nav} value="/shifts/bidderworkbench">Bidders Workbench</Button> }
      { <Button type={approvals ? "primary" : "default"} onClick={nav} value="/shifts/available">Available</Button> }
      { role !== "employee" && <Button type={supervised ? "primary" : "default"} onClick={nav} value="/shifts/supervised">All Supervised</Button> }
      { role !== "employee" && <Button type={direct ? "primary" : "default"} onClick={nav} value="/shifts/direct">Direct Supervised</Button> }
      { <Button type={mine ? "primary" : "default"} onClick={nav} value="/shifts/mine">Mine</Button> }
    </Button.Group>
  )
}

export default ShiftSubNavButtons


