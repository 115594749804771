import store from "store2"

window.store = store


const BusinessUnits = store.get('BusinessUnits') || []
export const BusinessUnitSelectOptions = BusinessUnits.map(r => ({ value: r.code, label: r.name }))

const Employees = store.get('Employees') || []
export const EmployeeSelectOptions = Employees.map(r => ({ value: r.number, label: r.fullName }))

const Supervisors = store.get('Supervisors') || []
export const SupervisorSelectOptions = Supervisors.map(r => ({ value: r.number, label: r.fullName }))

const Gizmos = store.get('Gizmos') || []
export const EquipmentSelectOptions = Gizmos.map(r => ({ value: r.id, label: r.name }))

const PayTypes = store.get('PayTypes') || []
export const PayTypeSelectOptions = PayTypes.map(r => ({ value: r.code, label: r.description }))

const Specialties = store.get('Specialties') || []
export const SpecialtySelectOptions = Specialties.map(r => ({ value: r.id, label: r.name }))

const Reasons = store.get('Reasons') || []
export const ReasonSelectOptions = Reasons.map(r => ({ value: r.id, label: r.description }))

const SubLedgers = store.get('SubLedgers') || []
export const SubLedgerSelectOptions = SubLedgers.map(r => ({ value: r.code, label: r.description }))

const Tags = store.get('Tags') || []
export const TagSelectOptions = () => Tags.map(r => ({ value: r.id, label: r.name }))

const Assignments = store.get("Assignments") || []
export const AssignmentSelectOptions = Assignments.map(r => ({value: r.id, label: r.name}))

export default {
  AssignmentSelect: AssignmentSelectOptions,
  BusinessUnitSelect: BusinessUnitSelectOptions,
  EmployeeSelect: EmployeeSelectOptions,
  SupervisorSelect: SupervisorSelectOptions,
  EquipmentSelect: EquipmentSelectOptions,
  PayTypeSelect: PayTypeSelectOptions,
  SpecialtySelect: SpecialtySelectOptions,
  ReasonSelect: ReasonSelectOptions,
  SubLedgerSelect: SubLedgerSelectOptions,
  TagSelect: TagSelectOptions,
}
