import {useContext} from "react"
import { MyContext, AbilityContext} from "./context"
// import REQ from "../store/REQ"

/*
const proxyState = (state) => {
  return new Proxy(state, {
    get: (target, prop) => {
      const stores = ["Assignments", "BusinessUnits", "Employees", "Gizmos", "PayPeriods", "PayTypes", "Reasons", "Specialties", "SubLedgers", "Supervisors", "Tags"]
      if (stores.includes(prop)) {
        return target.
      } else {
        return target[prop]
      }
    }
  })
}
*/

export const useAbilityContext = () => {
  const context = useContext(AbilityContext)
  return context
}

export const useMyContext = () => {
  const context = useContext(MyContext)
  // return proxyState(context)
  return context
}

export default useMyContext
