const color = {
  dark: "#001427",
  light: "#0094C6",
  red: "#FB3640",
  yellow: "#FAA916",
  green: "#4D8B31",
  blue: "#0094C6",
  grey: "#666666",
  headerBG: { light: "#A8A391", dark: "#FFFFFF" },
}

export const theme = {
  name: "psrs",
  rounding: 2,
  spacing: 24,
  defaultMode: "light",
  global: {
    colors: {
      ...color,
      brand: { dark: color.dark, light: color.light },
      background: { dark: "#111111", light: "#FFFFFF" },
      backgroundBack: { dark: "#111111", light: "#EEEEEE" },
      backgroundFront: { dark: "#222222", light: "#FFFFFF" },
      backgroundContrast: { dark: "#FFFFFF11", light: "#11111111" },
      text: { dark: "#EEEEEE", light: "#333333" },
      textStrong: { dark: "#FFFFFF", light: "#000000" },
      textWeak: { dark: "#CCCCCC", light: "#444444" },
      textXweak: { dark: "#999999", light: "#666666" },
      border: { dark: "#444444", light: "#CCCCCC" },
      control: "brand",
      activeBackground: "background-contrast",
      activeText: "text-strong",
      selectedBackground: "brand",
      selectedText: "text-strong",
      statusCritical: "#FB3640",
      statusWarning: "#FAA916",
      statusOk: "#4D8B31",
      statusUnknown: "#CCCCCC",
      statusDisabled: "#CCCCCC",
      graph0: { light: "brand", dark: "#001427" },
      graph1: color.yellow,
    },
    font: {
      family: "Roboto, Helvetica Neue, Arial, sans-serif",
      face:
        "/* vietnamese */\n@font-face {\n  font-family: 'Muli';\n  font-style: normal;\n  font-weight: 400;\n  src: url(https://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFnOkEk40e6fwniDtzNAAw.woff) format('woff');\n  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;\n}\n/* latin-ext */\n@font-face {\n  font-family: 'Muli';\n  font-style: normal;\n  font-weight: 400;\n  src: url(https://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFnOkEk50e6fwniDtzNAAw.woff) format('woff');\n  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;\n}\n/* latin */\n@font-face {\n  font-family: 'Muli';\n  font-style: normal;\n  font-weight: 400;\n  src: url(https://fonts.gstatic.com/s/muli/v20/7Aulp_0qiz-aVz7u3PJLcUMYOFnOkEk30e6fwniDtzM.woff) format('woff');\n  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;\n}\n",
      size: "16px",
      height: "24px",
      maxWidth: "432px",
    },
    active: { background: "active-background", color: "active-text" },
    hover: { background: "active-background", color: "active-text" },
    selected: { background: "selected-background", color: "selected-text" },
    borderSize: { xsmall: "1px", small: "2px", medium: "4px", large: "12px", xlarge: "24px" },
    breakpoints: {
      small: {
        value: 768,
        borderSize: { xsmall: "1px", small: "2px", medium: "4px", large: "6px", xlarge: "12px" },
        edgeSize: {
          none: "0px",
          hair: "1px",
          xxsmall: "2px",
          xsmall: "3px",
          small: "6px",
          medium: "12px",
          large: "24px",
          xlarge: "48px",
        },
        size: {
          xxsmall: "24px",
          xsmall: "48px",
          small: "96px",
          medium: "192px",
          large: "384px",
          xlarge: "768px",
          full: "100%",
        },
      },
      medium: { value: 1536 },
      large: {},
    },
    edgeSize: {
      none: "0px",
      hair: "1px",
      xxsmall: "3px",
      xsmall: "6px",
      small: "12px",
      medium: "24px",
      large: "48px",
      xlarge: "96px",
      responsiveBreakpoint: "small",
    },
    input: { padding: "12px", weight: 600 },
    spacing: "24px",
    size: {
      xxsmall: "48px",
      xsmall: "96px",
      small: "192px",
      medium: "384px",
      large: "768px",
      xlarge: "1152px",
      xxlarge: "1536px",
      full: "100%",
    },
    control: { border: { radius: "2px" } },
    drop: { border: { radius: "2px" } },
  },
  scale: 0.2,
  button: {
    border: { width: "2px", radius: "2px" },
    padding: { vertical: "4px", horizontal: "22px" },
  },
  calendar: {
    small: { fontSize: "17.2px", lineHeight: 1.375, daySize: "27.43px" },
    medium: { fontSize: "18px", lineHeight: 1.45, daySize: "54.86px" },
    large: { fontSize: "20.4px", lineHeight: 1.11, daySize: "109.71px" },
  },
  checkBox: {
    size: "24px",
    toggle: { radius: "2px", size: "48px" },
    check: { radius: "2px" },
  },
  clock: {
    analog: {
      hour: { width: "8px", size: "24px" },
      minute: { width: "4px", size: "12px" },
      second: { width: "3px", size: "9px" },
      size: { small: "72px", medium: "96px", large: "144px", xlarge: "216px", huge: "288px" },
    },
    digital: {
      text: {
        xsmall: { size: "16.4px", height: 1.5 },
        small: { size: "17.2px", height: 1.43 },
        medium: { size: "18px", height: 1.375 },
        large: { size: "18.8px", height: 1.167 },
        xlarge: { size: "19.6px", height: 1.1875 },
        xxlarge: { size: "21.2px", height: 1.125 },
      },
    },
  },
  heading: {
    level: {
      1: {
        small: { size: "21px", height: "27px", maxWidth: "509px" },
        medium: { size: "24px", height: "30px", maxWidth: "586px" },
        large: { size: "31px", height: "37px", maxWidth: "739px" },
        xlarge: { size: "37px", height: "43px", maxWidth: "893px" },
      },
      2: {
        small: { size: "20px", height: "26px", maxWidth: "490px" },
        medium: { size: "23px", height: "29px", maxWidth: "547px" },
        large: { size: "25px", height: "31px", maxWidth: "605px" },
        xlarge: { size: "28px", height: "34px", maxWidth: "662px" },
      },
      3: {
        small: { size: "20px", height: "26px", maxWidth: "470px" },
        medium: { size: "21px", height: "27px", maxWidth: "509px" },
        large: { size: "23px", height: "29px", maxWidth: "547px" },
        xlarge: { size: "24px", height: "30px", maxWidth: "586px" },
      },
      4: {
        small: { size: "19px", height: "25px", maxWidth: "451px" },
        medium: { size: "20px", height: "26px", maxWidth: "470px" },
        large: { size: "20px", height: "26px", maxWidth: "490px" },
        xlarge: { size: "21px", height: "27px", maxWidth: "509px" },
      },
      5: {
        small: { size: "18px", height: "24px", maxWidth: "422px" },
        medium: { size: "18px", height: "24px", maxWidth: "422px" },
        large: { size: "18px", height: "24px", maxWidth: "422px" },
        xlarge: { size: "18px", height: "24px", maxWidth: "422px" },
      },
      6: {
        small: { size: "17px", height: "23px", maxWidth: "413px" },
        medium: { size: "17px", height: "23px", maxWidth: "413px" },
        large: { size: "17px", height: "23px", maxWidth: "413px" },
        xlarge: { size: "17px", height: "23px", maxWidth: "413px" },
      },
    },
  },
  paragraph: {
    small: { size: "18px", height: "24px", maxWidth: "422px" },
    medium: { size: "18px", height: "24px", maxWidth: "432px" },
    large: { size: "19px", height: "25px", maxWidth: "451px" },
    xlarge: { size: "20px", height: "26px", maxWidth: "470px" },
    xxlarge: { size: "21px", height: "27px", maxWidth: "509px" },
  },
  radioButton: { size: "24px", check: { radius: "2px" } },
  text: {
    xsmall: { size: "17px", height: "23px", maxWidth: "413px" },
    small: { size: "18px", height: "24px", maxWidth: "422px" },
    medium: { size: "18px", height: "24px", maxWidth: "432px" },
    large: { size: "19px", height: "25px", maxWidth: "451px" },
    xlarge: { size: "20px", height: "26px", maxWidth: "470px" },
    xxlarge: { size: "21px", height: "27px", maxWidth: "509px" },
  },
  formField: {
    border: {
      color: "border",
      error: { color: { dark: "white", light: color.red } },
      position: "inner",
      side: "bottom",
    },
    content: { pad: "small" },
    disabled: { background: { color: "status-disabled", opacity: "medium" } },
    error: { color: color.red, margin: { vertical: "xsmall", horizontal: "small" } },
    help: { color: "dark-3", margin: { start: "small" } },
    info: { color: "text-xweak", margin: { vertical: "xsmall", horizontal: "small" } },
    label: { margin: { vertical: "xsmall", horizontal: "small" } },
    margin: { bottom: "small" },
    round: "2px",
  },
}
