import React from "react"
import { TimePicker, Button } from "antd"
import moment from "moment-timezone"

import "../../styles/Components/TimeButton.scss"

export const StartTimeFieldT = props => {
  // console.log("StartTimeField disabled", props.disabled)

  const { field, /*value,*/ handleChange, id } = props

  const [timeOpen, setTimeOpen] = React.useState({ open: false })

  
	var dvalue = null;
 	if( props.value ) {
		//-//console.log("props.value.start: " + props.value.start);
    //var td = new Date(props.value.start);
    //dvalue = moment(td.getTime()); // moment adjusts it already .subtract(td.getTimezoneOffset(), 'm');
		dvalue = props.value.start ? moment(props.value.start) : moment(props.value);
		//-//console.log("DVALUE: " + dvalue.format("HH:mm"));
  }

  const handleOpenChange = open => {
    setTimeOpen({open: open})
  } 

  const options = {
    isDisabled: props.disabled,
    disabled: props.disabled,
    minuteStep: 6,
    showNow: true,
    defaultOpenValue: moment('00:00','HH:mm'),
    open: timeOpen.open,
    onOpenChange: handleOpenChange,
    //onChange: onChange,
    //onChange: val => {
			/*
			val = new Date(Number(val));
      var h = val.getHours();
      var m = val.getMinutes();
      val.setUTCHours(h);
      val.setUTCMinutes(m);
			if( value && value.start ) { // Shift Drawer Edit
				var sz = moment(value.start).toISOString().substring(0,11);
				var ez = val.toISOString().substring(11);
				//console.log(sz);
				//console.log(ez);
				val = new Date(sz + ez);
			}
      */
			//console.log("VAL: " + val.toISOString());
			
      //handleChange({ ...props, field, value:val, id })
    //},
    addon: (val) => (
      <Button 
        className="pulse"
        size="small" 
        type="primary" 
        block={true}
        //onClick={handleClose}
        onClick={() => {
          setTimeOpen({open: false})
          handleChange({ ...props, field, value:val.props.value, id })
        }}
      >
        Ok
      </Button>
    ),
    defaultValue: dvalue,
    format: "HH:mm",
    className: "StartTimeField",
    allowClear: props.allowClear ? props.allowClear: false,
  }
  return <TimePicker data-cy="start-time-picker" {...options} />
}

export default StartTimeFieldT
