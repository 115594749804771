import React, { useState, useEffect } from "react"
import useMyContext from "../store/useMyContext"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { Spin, Button, Table } from "antd"
import { CheckBox, EmployeeSelect, InlineEdit } from "./Forms"
import kindOf from "kind-of"
import uniqid from "uniqid"
import Help from "./Help"
import helpText from "./Help/text"
import uid from "uniqid"
// import API from "../../store/psrs"
import { REQ } from "../store/psrs"
// const { REQ } = API
const text = helpText.views.team

export const TeamMembers = ({ subordinates, team, members, mutate, url }) => {
  const context = useMyContext()

  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const disabled = false

  const Team = team

  // const teamMemberIds = Team.members.map((s) => s.id)
  // const Members = _.orderBy(context.get("members", "teamId", Team.id, "allow empty"), ["id"], ["desc"])

  const Members = _.orderBy(members, "i", "desc")

  const [state, setState] = useState({
    mounted: false,
    members,
  })

  const updateMember = async ({ field, value, id }) => {
    let update = kindOf(value) === "object" ? value : {}

    if (field === 'paidLunch') value = !!value ? false : true

    if (field) update[field] = value ? value : null
    const updatedMember = await REQ(`/api/resources/member/${id}`, "PATCH", { ...update })
    console.log("updatedMember", updatedMember)
    mutate(url)
  }

  const addSubordinates = async () => {
    setLoading(true)
    const _Team = await REQ(`/api/teams/${Team.id}/add_subordinates`, "POST")
    setLoading(false)
    mutate(url, _Team)
  }

  const addMember = async () => {
    setLoading(true)
    const _Team = await REQ(`/api/teams/${Team.id}/add_member`, "POST")
    setLoading(false)
    mutate(url, _Team)
  }

  const removeMember = async member => {
    setLoading(true)
    const _Team = await REQ(`/api/teams/${Team.id}/delete_member/${member.id}`, "DELETE")
    setLoading(false)
    mutate(url, _Team)
  }

  const clearMembers = async () => {
    setLoading(true)
    const _Team = await REQ(`/api/teams/${Team.id}/clear_members`, "DELETE")
    setLoading(false)
    mutate(url, _Team)
  }

  const createSchedule = async ({ name, isOvertime, employeeNumber }) => {
    setLoading(true)
    const tagIds = team.tags.map(t => t.id)
    const _Team = await REQ(`/api/teams/${Team.id}/create_schedule`, "POST", { tagIds, name, isOvertime, employeeNumber })
    setLoading(false)
    history.push(`/team/${Team.id}/schedule/${_Team.id}`)
    mutate(url)
  }

  const buttonProps = {
    size: "small",
    ghost: true,
    style: { marginLeft: "10px" },
    theme: "filled",
  }

  const showRemoveButton = members && members.length > 0

  const hasSubordinates = Team.subordinateNumbers && Team.subordinateNumbers.length > 0

  const columns = [
    {
      title: "Name / Employee",
      dataIndex: "employee",
      key: uniqid(),
      width: 240,
      render: (val, record) => {
        return (
          <div>
            {/*
            <EmployeeSelect
              data-cy={`EmployeeSelect`}
              disabled={Slot.scheduled}
              employee={Slot.employee}
              allowClear={true}
              field="employeeNumber"
              id={Slot.id}
              handleChange={updateSlot}
            />
            */}
            <EmployeeSelect
              data-cy={`EmployeeSelect-${record.id}`}
              disabled={disabled}
              employee={record.employee ? record.employee : null}
              allowClear={true}
              field="employeeNumber"
              id={record.id}
              handleChange={updateMember}
            />
          </div>
        )
      },
    },
    {
      title: "Assignment",
      dataIndex: "assignmentId",
      key: uniqid(),
      width: 230,
      render: (val, record) => (
        <React.Fragment>
          <div>
            <div style={{ position: "relative" }}>
              <InlineEdit
                data-cy={`PositionName-${record.id}`}
                disabled={disabled}
                value={record.operationalAssignment}
                field="operationalAssignment"
                id={record.id}
                handleChange={updateMember}
                placeholder="Operational Assignment"
              />
              <div style={{ display: "inline-block", position: "absolute", right: "10px", top: "5px" }}>
                <Help text="This will be passed forward to created Schedule templates" />
              </div>
            </div>
            {/* <AssignmentSelect
              data-cy={`AssignmentSelect-${record.id}`}
              disabled={disabled}
              value={record.assignmentId}
              field="assignmentId"
              id={record.id}
              handleChange={updateMember}
            /> */}
          </div>
          <div>
            <CheckBox
              data-cy={`PaidLunchCheckbox-${record.id}`}
              disabled={disabled}
              field="paidLunch"
              value={!record.paidLunch}
              id={record.id}
              handleChange={updateMember}
            />
            <span>Unpaid Lunch</span>
          </div>
        </React.Fragment>
      ),
    },
    {
      title: "",
      dataIndex: "employee",
      key: uniqid(),
      width: 10,
      render: (val, record) => {
        const defProps = { style: { opacity: disabled ? ".2" : "1" }, theme: "filled", disabled }
        const delProps = {
          ...defProps,
          disabled,
          size: "small",
          icon: "close-circle",
          onClick: () => (!disabled ? removeMember(record) : false),
        }
        const employee = record.employee
        const employeeNumber = employee ? record.employee.number : false
        const name = employee ? employee.fullName : "Blank Employee"
        return (
          <div className="slotActions">
            <span style={{ marginRight: "4px" }}>Create Schedule</span>
            <Button.Group>
              <Button size="small" onClick={() => createSchedule({ name, isOvertime: false, employeeNumber })}>
                REG
              </Button>
              <Button size="small" onClick={() => createSchedule({ name, isOvertime: true, employeeNumber })}>
                OT
              </Button>
              <Button {...delProps}></Button>
            </Button.Group>
          </div>
        )
      },
    },
  ]

  const tableProps = {
    loading: false,
    dataSource: Members,
    showHeader: false,
    columns: columns,
    pagination: false,
    onRow: (record, index) => {
      return { id: `member_${record.id}` }
    },
    rowKey: () => uid(),
  }

  useEffect(() => {
    setState({ ...state, mounted: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.mounted) {
      const members = context.Members.filter(m => m.teamId === Team.id)
      setState({ ...state, members: _.orderBy(members, ["employeeNumber"]) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.members])

  useEffect(() => {
    state.mounted && console.log("STATE CHANGED", state)
  }, [state])

  return (
    <section className="members grid_section">
      <header>
        Team Members
        <Help text={text.roster} />
        <div style={{ float: "right" }}>
          {showRemoveButton && (
            <Button
              data-cy="RemoveAllMembersButton"
              {...buttonProps}
              disabled={loading || disabled}
              onClick={clearMembers}
              icon="usergroup-delete"
            >
              Remove All
            </Button>
          )}
          {hasSubordinates && (
            <Button
              data-cy="AddSubordinatesButton"
              {...buttonProps}
              disabled={loading || disabled}
              onClick={addSubordinates}
              icon="usergroup-add"
            >
              Add Subordinates
            </Button>
          )}
          <Button data-cy="AddMemberButton" {...buttonProps} disabled={loading || disabled} onClick={addMember} icon="user-add">
            Add Member
          </Button>
        </div>
      </header>
      <Spin spinning={loading}>
        <Table {...tableProps} />
      </Spin>
    </section>
  )
}

export default TeamMembers
