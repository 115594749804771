import React from "react"
//import React, { useState /*, useEffect*/  } from "react"
import { Logout, session, ValidAppState, getRole} from "../store/psrs"
import { Grommet } from "grommet"
import { routes } from "../routes"
import StoreProvider from "../store/provider"
import { Layout, Modal } from "antd"
import { Switch, Route, useHistory, Redirect } from "react-router-dom"
import { HashRouter as Router } from "react-router-dom"
import * as comp from "."
import _ from "lodash"
import { useStateIfMounted } from "use-state-if-mounted"
import { SideNavigation } from "."
import ErrorBoundary from "./ErrorBoundary"
import { Blank } from "./Blank"
import { theme } from "../styles/theme"
// import Home from "./Home"
import SessionTimeout from './SessionTimeout'
import Footer from './Footer'  
import useScript from '../hooks/useScript'
  
const { Content } = Layout

export const AppLayout = props => {
  // console.log('AppLayout')

  const { confirmed, setConfirmed, setRenderConfirm } = props
  const { title, page, path, url, query, adminQuery, component, exact } = props
  const history = useHistory()
  const validAppState = ValidAppState()

/* will try this again, if we can make it ignore F5 or CMD+R refreshes
  const handleTabClosing =  () => {
    if (session.get("loginConfirmed")) {
      console.log('handleTabClosing..... ')
      // logout or delete user session?
      Logout()
    }
  }
  const alertUser = (event) => {
    if (session.get("loginConfirmed")) {
      event.preventDefault()
      event.returnValue = ''
    }
  }
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser)
    window.addEventListener('unload', handleTabClosing)
    return () => {
        window.removeEventListener('beforeunload', alertUser)
        window.removeEventListener('unload', handleTabClosing)
    }
  })
  */
  return (
    <Route
      exact={exact}
      path={path}
      render={matchProps => {
        const { location } = matchProps
        const { pathname } = location

        const isHomePath = pathname === "/" || pathname === "/login" || pathname === "/logout"

        if (pathname === "/logout") {
          //console.log('pathname === "/logout"', renderConfirm)
          setRenderConfirm(false)
          Logout()
          return <Redirect to="/login" />
        }

        if (!isHomePath && !validAppState) {
          return <Redirect to="/logout" />
        }

        // console.log('Route', matchProps, path, ValidSession())
        if (isHomePath && validAppState) {
          setRenderConfirm(false)
          const role = session.get("role") || false
          //const to = { supervisor: "/shifts/direct", admin: "/records", employee: "/shifts/mine" }
          const to = { supervisor: "/shifts/direct", admin: "/payperiodstats", employee: "/shifts/mine" }
          return <Redirect to={to[role]} />
        }

        if (!validAppState && !session.has("loggedOut")) {
          Logout()
          return <Redirect to="/login" />
        }

        const Component = comp[component]
        let data = null
        const routeProps = { page, path, history, title, data, url, query, adminQuery }
        const navProps = { history, page: props.page }

        return (
          <StoreProvider {...props} {...routeProps}>
            <Grommet full theme={theme}>
              <Layout className={`Page ${props.page}Page`}>
                {validAppState && <SideNavigation {...matchProps} {...navProps} />}
                <Layout>
                  <Content className={`AppContent ${props.page}Page`}>
                    <ErrorBoundary>
                      <Component {...matchProps} {...routeProps} />
                    </ErrorBoundary>
                    <Footer/>
                  </Content>
                </Layout>
              </Layout>
              {!isHomePath && (
                <Modal
                  title="Confirm Login"
                  visible={!confirmed}
                  closable={false}
                  maskClosable={false}
                  destroyOnClose={true}
                  onOk={() => {
                    session.set("loginConfirmed", true)
                    setConfirmed(true)
                  }}
                  onCancel={() => {
                    session.set("loginConfirmed", false)
                    setConfirmed(false)
                    Logout()
                  }}
                  okText="Agree"
                  cancelText="Logout"
                >
                  <p>
                    By logging into the SchedulerPro (PSRS), I hereby expressly acknowledge and agree that use
                    of the PSRS while off-duty is voluntary and that such time is not compensable, consistent with both its
                    voluntary and its de minimis nature.
                  </p>
                </Modal>
              )}
            </Grommet>
          </StoreProvider>
        )
      }}
    />
  )
}

const Routes = props => {
  const _routes = routes()

  
  const _myRole = getRole()

  let _roleRoutes = _routes
  const allRoutes = _.filter(_routes, r=> r.roles ? r.roles.includes('all') : '')
  const superRoutes = _.filter(_routes, r=> r.roles ? r.roles.includes('supervisor') : '')
  const adminRoutes = _.filter(_routes, r=> r.roles ? r.roles.includes('admin') : '')

  if (_myRole === 'employee') {
    _roleRoutes = [...allRoutes]
  } else if (_myRole === 'supervisor') {
    _roleRoutes = [...allRoutes, ...superRoutes]
  } else if (_myRole === 'admin') {
    _roleRoutes = [...allRoutes, ...adminRoutes]
  }

  //return _routes.map(route => <AppLayout key={route.path} {...route} {...props} />)
  return _roleRoutes.map(route => <AppLayout key={route.path} {...route} {...props} />)

}

export const App = () => {
  console.log("App")
  const [confirmed, setConfirmed] = useStateIfMounted(session.get("loginConfirmed"))
  const [renderConfirm, setRenderConfirm] = useStateIfMounted(true)
  const confirmProps = { confirmed, setConfirmed, renderConfirm, setRenderConfirm }
  useScript('https://cdn.jsdelivr.net/gh/samuelstroschein/bromb/packages/web/dist/widget.js',true,true,false,'dark');


  const handleClick = () => {
    //-delete// setAuth(!isAuthenticated)
    Logout()
  }

  return (
    <>    
      {<SessionTimeout isAuthenticated={confirmed} logOut={handleClick} timeout={session.get('sessionTimeOut')} />}

        <Router keyLength={12} >
          <Switch>
            <Routes {...confirmProps} />
          </Switch>
          <Route
            render={matchProps => {
              return <Blank {...matchProps} />
            }}
          />
        </Router>
    </>
  )
}

export default App
