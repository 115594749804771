import React, { useState, useEffect, useMemo } from "react"
import Select from "react-select"
import useMyContext from "../../store/useMyContext"
import {store} from "../../store/psrs"
import moment from "moment"
import _ from "lodash"

export const PayPeriodEndSelector = props => {
  const context = useMyContext()

  const PayPeriods = useMemo(() => {

    const payPeriods = store.get('PayPeriods')

    // selector will have this year and next year.
    const year = moment().format('YYYY')
    const yearNext = moment().add(1,'years').format('YYYY')
    return payPeriods.filter(pp => {
      return moment(pp.end).format("YYYY") === yearNext || moment(pp.start).format("YYYY") === year
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.payPeriods])

  const StartDates = useMemo(() => {
    const unsorted = PayPeriods.map(pp => ({ i: pp.i, id: pp.id, value: pp.id, label: moment(pp.start).format("MMM Do YYYY") }))
    return _.orderBy(unsorted, "i", "asc")
  }, [PayPeriods])

  const EndDates = useMemo(() => {
    //const unsorted = PayPeriods.map(pp => ({ i: pp.i, id: pp.id, value: pp.id, label: moment(pp.end).format("MMM Do YYYY") }))

    const currentPP = store.get('PayPeriodToday')
    const onlyFuture = _.filter(PayPeriods, pp => pp.i > currentPP.i)
    const unsorted = onlyFuture.map(pp => ({ i: pp.i, id: pp.id, value: pp.id, label: moment(pp.end).format("MMM Do YYYY") }))


    //return _.orderBy(unsorted, "i", "asc")
    return _.orderBy(unsorted, "i", "asc")
  }, [PayPeriods])

  const payPeriodStartId = props.value ? props.value.payPeriodStartId : null
  const payPeriodEndId = props.value ? props.value.payPeriodEndId : null

  const [state, setState] = useState({
    startValue: _.find(StartDates, { value: payPeriodStartId || null }),
    endValue: _.find(StartDates, { value: payPeriodEndId || null }),
    menuIsOpen: false,
  })

  /*
  const selectOptionsStart = {
    id: "PayPeriodStart",
    className: "ResourceSelect PayPeriodRangeSelect psrs-select",
    classNamePrefix: "psrs",
    dropdownClassName: "PayPeriodStartDropdown",
    isMulti: false,
    options: StartDates,
    defaultValue: props.value.payPeriodStartId ? _.find(StartDates, {value: props.value.payPeriodStartId}) : null,
    menuIsOpen: state.menuIsOpen,
    onMenuOpen: () => setState({ ...state, menuIsOpen: true }),
    onMenuClose: () => setState({ ...state, menuIsOpen: false }),
    onChange: (select, action) => {
      props.handleChange({ ...props, field: "payPeriodStartId", value: select.value })
    },
  }
  */
 
  const selectOptionsEnd = {
    id: "PayPeriodEnd",
    className: "ResourceSelect PayPeriodRangeSelect psrs-select",
    classNamePrefix: "psrs",
    dropdownClassName: "PayPeriodEndDropdown",
    isMulti: false,
    isDisabled: props.disabled,
    isClearable: true,
    isSearchable: true,
    options: EndDates,
    defaultValue: props.value.payPeriodEndId ? _.find(EndDates, {value: props.value.payPeriodEndId}) : null,
    menuIsOpen: state.menuIsOpen,
    onMenuOpen: () => setState({ ...state, menuIsOpen: true }),
    onMenuClose: () => setState({ ...state, menuIsOpen: false }),
    onChange: (select, action) => {
      // setState({ ...state, value: select })
      props.handleChange({ ...props, field: "payPeriodEndId", value: select ? select.value : null })
    },
  }

  useEffect(() => {
    setState({
      ...state,
      startValue: _.find(StartDates, { value: payPeriodStartId }),
      endValue: _.find(StartDates, { value: payPeriodEndId }),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <React.Fragment>
      <Select {...selectOptionsEnd} />
    </React.Fragment>
  )
}

export default PayPeriodEndSelector
