import React from "react"
import {store} from "../../store/psrs"
import {Button} from "antd"

const ApprovalSubNavButtonsV2 = ({history, page}) => {
  const nav = (e) => history.push(e.target.value, {basePath: "/approvals"})
  const role = store.session.get('role')
  const shifts = page === "ShiftApprovals"
  const timeEntries = page === "TimeEntryApprovals"
  return (
    <>
      <Button.Group className="mx-2">
        { <Button type={shifts ? "primary" : "default"} onClick={nav} value="/approvals/shifts">Shifts</Button> }
        { <Button type={timeEntries ? "primary" : "default"} onClick={nav} value="/approvals/timeEntries">TimeEntries</Button> }
      </Button.Group>
      { role !=='employee' && <Button type="default" onClick={nav} value="/approvalsV2/shifts">Approval Workbench</Button> }
     </>
  )


}

export default ApprovalSubNavButtonsV2