import React, { useState } from "react"
import { store, session } from "../../store/psrs"
import Select, { createFilter } from "react-select"
// import {PayTypeSelectOptions} from "./_SelectOptions"
import _ from "lodash"

export const PayTypeSelect = props => {
  const field = props.field || "payTypeCode"
  const { record, optional, disabled } = props
  const id = record ? record.id : props.id
  const value = props.value ? props.value : record ? record[field] : null

// add pill for code to description 
const PadPayTypeCode = (pad, str, padLeft) => {
  if (typeof str === 'undefined') 
    return pad;
  if (padLeft) {
    return (pad + str).slice(-pad.length);
  } else {
    return (str + pad).substring(0, pad.length);
  }
}


const OptionLabel = ({ payType }) => {
  const { code, description, rename } = payType
  const padding = Array(4).join(' ') // make a string of 4 spaces
  //const formattedCode = PadPayTypeCode( padding,code,false );

  // 
  return (
    <div className="PayTypeSelectOption">
      <span className="payTypeCode">{ PadPayTypeCode( padding,code,true ) }</span>
      <span className="payTypeLabel">{ rename? rename : description}</span>
    </div>
  )
}
// end pill for code


  const PayTypes = store.get("PayTypes") || []
  //let PayTypeSelectOptions = _.sortBy(PayTypes.map(r => ({ value: r.code, sort: parseInt(r.code), label: r.rename? PadPayTypeCode(padding,r.code,false).concat(' - ').concat(r.rename) : r.code.concat(' - ').concat(r.description) })),['sort'])
  let PayTypeSelectOptions = _.sortBy(PayTypes.map(pt => ({ value: pt.code, sort: parseInt(pt.code), labelRename: pt.rename? pt.rename: pt.description, label: <OptionLabel payType={pt} /> })),['sort'])

  const role = session.get('role')
  //const emp = session.get('CurrentUser') || []
  const conditions = []

  if (props.selectType && props.selectType === 'sick') {
    const sickCodes = ['20','21','24','25']
    PayTypeSelectOptions = _.filter(PayTypeSelectOptions, pt => sickCodes.includes(pt.value))
    //PayTypeSelectOptions = PayTypeSelectOptions.map(pt => ({ value: pt.code, label: <OptionLabel leaveType={pt} /> }))
   // <span className="pill swat"> swat </span>}
  }

  if (props.selectType && props.selectType === 'pto') {
    const ptoCodes = ['22','30','37','41','51','70','81','82','89']
    PayTypeSelectOptions = _.filter(PayTypeSelectOptions, p => ptoCodes.includes(p.code))
  }


  // blw -- need to review this?  why were we filtering this out or tyring to?
    /* remove on 20201-07-08

  if(!emp.isFieldTraining && role !== 'supervisor' && role !=='admin' ) {
    conditions.push('fto')
  }
  if(!emp.isDive && role !== 'supervisor' && role !=='admin') {
    conditions.push('dive')
  }

  */
  if(conditions.length) {
    PayTypeSelectOptions= _.filter(PayTypeSelectOptions, function(currentObject) {
        const label = currentObject.label.props.payType.rename ? currentObject.label.props.payType.rename : currentObject.label.props.payType.description
        return !conditions.some(el => label.toLowerCase().includes(el));
      });
  }  

  // disable if evidence 2hr or 4hr
  let evidenceDisable = false
  //relax this security ?
  if ('value' in props) {
    if (field === 'payTypeCode') {
      evidenceDisable = props.value === "250" || props.value === "251" ? true : false
      if (role === 'supervisor' || role === 'admin') {
        evidenceDisable = false
      }
    }    
  }

  const [state, setState] = useState({
    options: PayTypeSelectOptions,
    value: value ? _.find(PayTypeSelectOptions, { value: value }) : null,
    sql: null,
  })

  const selected = _.filter(PayTypeSelectOptions, p => {
    return value ? value === p.value : null
  })

  let _selected = state.value
  if (state.value !== selected) _selected = selected


  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any',
    stringify: option => `${option.label.props.payType.name} ${option.label.props.payType.description} ${option.label.props.payType.rename} ${option.value}`
  };

  const selectProps = {
    id: "PayTypeSelect",
    className: "psrs-select",
    classNamePrefix: "psrs",
    placeholder: optional ? "Select an Optional Pay Type" : "Select a Pay Type",
    isClearable: true,
    isSearchable: true,
    isMulti: false,
    options: PayTypeSelectOptions,
    defaultValue: _selected, //state.value,
    value: _selected, 
    isDisabled: evidenceDisable || disabled,
    filterOption: createFilter(filterConfig),
    onChange: select => {
      const val = !select ? null : select.value
      const sql = !val ? null : `"${field}" IS NOT NULL AND "${field}" = '${val}'`
      setState({ ...state, sql })
      props.handleChange({ ...props, value: val, field, id, sql })
      return
    },
  }

  // console.log("PayTypeSelect value", value, field)

  return <Select {...selectProps} />
}

export default PayTypeSelect
