import React from "react"
// import { Can } from "../../store/psrs"
import { Button, Tag, Tooltip,Popconfirm, Icon} from "antd"
import uid from "uniqid"
import moment from "moment"
import { CheckBox } from "grommet"
import {Can, a} from "../../store/psrs"
import { ApprovalFinePrint, FinePrint } from "../FinePrint"
import { CheckRequiredfields, ImbalancedMessage, RequiredFieldMessage } from "../ShiftDrawer/CheckRequiredFields"

const _columns = props => {
  //const {context} = props
  const {ability, context, updateTimeEntry, getTimeEntryShiftNotes, setIsAuditModalVisible /*, refresh*/} = props

  const checkShiftNotes = async (shiftId) => {
    return await getTimeEntryShiftNotes(shiftId).notes
   
  }

  /*
  const checkShiftFields = async (shiftId) => {
    return await getTimeEntryShiftNotes(shiftId)
   
  }
  */

  const validateFields = async (shift) => {
    let valid = true

    const { checkFields, okSubmit } = await CheckRequiredfields(shift)

    if (!okSubmit) {
      valid = false 
      const message = `Required Fields Missing. Please open the Shift Drawer and update the required fields for Shift`
      RequiredFieldMessage(shift,checkFields,message)
    }

    if (shift.imbalanced ) {
      valid = false
      ImbalancedMessage(shift)
    } 

    return valid
  }

  const renderStatus = timeEntry => {
    const { approved, rejected, submit, assignmentId, assignment, locked, paidLunch } = timeEntry

    // Gricel and team requested move to 1 hour on 07/28/2021
    //const newCanSubmit = moment(timeEntry.end).subtract(30,"minutes").toISOString() <= moment().toISOString() ? true:false
    const newCanSubmit = moment(timeEntry.end).subtract(1,"hours").toISOString() <= moment().toISOString() ? true:false

    const canApprove = ability.can("approve", a("timeEntry", timeEntry))

    const notesExist = checkShiftNotes(timeEntry.shiftId) === '' || checkShiftNotes(timeEntry.shiftId) === null ? false : true
        
    let payTypeCategory = ""
    if (timeEntry && timeEntry.payType) payTypeCategory = timeEntry.payType.category 

    const isOT = payTypeCategory === 'OVERTIME' ?  true : false
    const isPTO = payTypeCategory === 'LEAVE' ?  true : false
    const isSick = payTypeCategory === 'SICK' ?  true : false
    //const isWrongTime = false

    const ot = { className: `stat ${isOT ? "on has overtime" : ""}`, type: "clock-circle" }
    const pto = { className: `stat ${isPTO ? "on pto" : ""}`, type: "message" }
    const sick = { className: `stat ${isSick ? "on sick" : ""}`, type: "medicine-box" }
    const submitted = { className: `stat ${submit ? "on submitted" : notesExist ? '' : "on submitted-notes"}`, type: "check-circle" }
    const good = { className: `stat ${approved ? "on approved" : ""}`, type: "like" }
    const bad = { className: `stat ${rejected ? "on rejected" : ""}`, type: "dislike" }
    //const wrongTime = { className: `stat ${isWrongTime ? "on imbalanced" : ""}`, type: "stop" }
    const acting = { className: `stat ${assignmentId ? "on acting" : ""}`, type: "safety-certificate" }
    const isLocked = { className: `stat ${locked ? "on locked": "on unlocked" }`, type: locked ? "lock" :"unlock" }
    const isPaidLunch = paidLunch
    const paidlunch = { className: `stat ${paidLunch ? "on paidLunch": "" }`, type: "rest" }

    return (
      <div className="StatusIcons">
        <Can I="see:row:icon:available" not this={timeEntry} >
          {/* paidLunch */}
          <Tooltip mouseEnterDelay={paidlunch ? 0 : 99} title={ isPaidLunch ? "Paid Lunch" : "Un Paid Lunch" }>
              <span className="statusIcon">
                <Icon {...paidlunch} />
              </span>
          </Tooltip>

          {/* SICK */}
          <Tooltip mouseEnterDelay={isSick ? 0 : 99} title="Sick">
            <span className="statusIcon">
              <Icon {...sick} />
            </span>
          </Tooltip>

          {/* VACATION */}
          <Tooltip mouseEnterDelay={isPTO ? 0 : 99} title="Time Off">
            <span className="statusIcon">
              <Icon {...pto} />
            </span>
          </Tooltip>

          {/* SUBMIT */}
          <Tooltip mouseEnterDelay={newCanSubmit ? 0 : 99} title={timeEntry.submit ? 'Un Submit': 'Submit'} >
            <Popconfirm
              disabled = {newCanSubmit ? false : true }
              title={timeEntry.submit ? "Unsubmit timeEntry" : <FinePrint />}
              onConfirm={async () => {

                //need to check the notes on the shift
                const shiftFields = await getTimeEntryShiftNotes(timeEntry.shiftId)
                const valid = await (validateFields(shiftFields))
                if (newCanSubmit && valid) updateTimeEntry({ field: "submit", value: !timeEntry.submit, shiftId: timeEntry.shiftId })
              }}
              okText={timeEntry.submit ? "Un Submit" : "Submit"}
              cancelText="Cancel"
            >
              <span className={`statusIcon ${newCanSubmit && "hoverPointer"}`}>
                <Icon {...submitted} />
              </span>
            </Popconfirm>
          </Tooltip>

          {/* OVERTIME */}
          <Tooltip mouseEnterDelay={isOT ? 0 : 99} title="Has Overtime">
            <span className="statusIcon">
              <Icon {...ot} />
            </span>
          </Tooltip>

          
          {/* IMBALANCED */}
          {/*
          <Tooltip mouseEnterDelay={isWrongTime ? 0 : 99} title="Wrong Time Entry Hours">
            <span className="statusIcon">
              <Icon {...wrongTime} />
            </span>
          </Tooltip>
          */}
          
          {/* ACTING ROLE */}
          <Tooltip mouseEnterDelay={assignmentId ? 0 : 99} title={assignment ? assignment.name : 'Acting Role'}>
            <span className="statusIcon">
              <Icon {...acting} />
            </span>
          </Tooltip>

           {/* REJECTED */}
           <Tooltip mouseEnterDelay={canApprove ? 0 : 99} title={`${timeEntry.rejected ? "Un Reject" : "Reject"}`}>
            <Popconfirm
              disabled={canApprove && !timeEntry.approved ? false : true }
              title={timeEntry.approved ? "Un Reject timeEntry" : <ApprovalFinePrint/>}
              onConfirm={() => {
                //if (canApprove) approveEntry()
                if (canApprove && !timeEntry.approved) updateTimeEntry({ field: "rejected", value: !timeEntry.rejected, shiftId: timeEntry.shiftId })
                //--//if (canApprove && !timeEntry.rejected) approveEntry('shift', timeEntry.shiftId, !timeEntry.rejected)

              }}
              okText={timeEntry.rejected ? "Un Reject" : "Reject"}
              cancelText="Cancel"
            >
              <span className={`statusIcon ${canApprove && "hoverPointer"}`}>
                <Icon {...bad} />
              </span>
            </Popconfirm>
          </Tooltip>

           {/* APPROVED */}
           <Tooltip mouseEnterDelay={canApprove ? 0 : 99} title={`${timeEntry.approved ? "Un Approve" : "Approve"}`}>
            <Popconfirm
              disabled={canApprove && !timeEntry.rejected ? false : true }
              title={timeEntry.approved ? "Un Approve timeEntry" : <ApprovalFinePrint/>}
              onConfirm={async () => {
                //if (canApprove) approveEntry()
                const shiftFields = await getTimeEntryShiftNotes(timeEntry.shiftId)
                const valid = await (validateFields(shiftFields))  
                if (canApprove && !timeEntry.rejected && valid) updateTimeEntry({ field: "approved", value: !timeEntry.approved, shiftId: timeEntry.shiftId })
                //--//if (canApprove && !timeEntry.rejected) approveEntry('shift', timeEntry.shiftId, !timeEntry.approved)

              }}
              okText={timeEntry.approved ? "Un Approve" : "Approved"}
              cancelText="Cancel"
            >
              <span className={`statusIcon ${canApprove && "hoverPointer"}`}>
                <Icon {...good} />
              </span>
            </Popconfirm>
          </Tooltip>
          {/* LOCKED */}
          <Tooltip mouseEnterDelay={isLocked ? 0 : 99} title={ locked? "Locked" : "Not Locked" }>
            <span className="statusIcon">
              <Icon {...isLocked} />
            </span>
          </Tooltip>
        </Can>
      </div>



    )
  }

  return [
    {
      Header: "Employee",
      //width: 200,
      width: "auto",
      accessor: 'employee.fullName',
      show: props.page === "MyTimeEntries" ? false : true,
      className: "",
      sortable: true,
      Cell: cell => cell.value ? cell.value : "Unassigned",
    },
    {
      Header: "Date",
      accessor: 'start',
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => {
        const date = moment(cell.value).format("MMM Do")
        const day = moment(cell.value).format("ddd")
        return <> <Tag>{day}</Tag> <span>{date}</span> </>
      },
    },
    {
      Header: "Hours",
      accessor: "duration",
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => parseFloat(cell.value).toFixed(2),
    },
    {
      Header: "Unpaid Lunch",
      accessor: "paidLunch",
      className: "justify-around",
      show: false,
      sortable: true,
      Cell: cell => <> <CheckBox disabled={true} checked={!cell.value}></CheckBox></>,
    },
    {
      Header: "Supervisor 1",
      accessor: "supervisor1.lastName",
      show: true,
      sortable: true,
      Cell: cell => {
        return cell.value ? cell.value : ""
      },
    },
    // {
    //   Header: "Business Unit",
    //   accessor: "businessUnit",
    //   show: true,
    //   className: "justify-around",
    //   Cell: cell => {
    //     if (!cell.value) { return "" }
    //     else { return <Tooltip title={cell.value.name || "none"}> <Tag>{cell.value.code || "none"}</Tag> </Tooltip>  }
    //   },
    // },
    // {
    //   Header: "Cost Center Chargeout",
    //   accessor: "costCenterChargeOut",
    //   show: true,
    //   className: "justify-around",
    //   Cell: cell => {
    //     if (!cell.value) { return "" }
    //     else { return <Tooltip title={cell.value.name || "none"}> <Tag>{cell.value.code || "none"}</Tag> </Tooltip> }
    //   },
    // },
    {
      Header: 'SubLedger',
      accessor: "subLedger.code",
      show: true,
      className: "justify-around",
      sortable: true,
      Cell: cell => {
        if (!cell.value) { return "" }
        else { return <Tooltip title={cell.value.name || "none"}> <Tag style={{fontSize: 'x-small'}}>{cell.value.code || "none"}</Tag> </Tooltip> }
      }
    },
    {
      Header: "Acting Role",
      accessor: 'assignment.name',
      show: true,
      className: "justify-around",
      sortable: false,
      Cell: cell => cell.value ? <><Tag style={{fontSize: 'x-small'}}>{cell.value}</Tag> </> : ''
    },
    {
      Header: "Groups",
      accessor: 'tags',
      show: true,
      className: "justify-around",
      sortable: false,
      Cell: cell => {
        return cell.value ? cell.value.map(tag => <Tag style={{fontSize: 'x-small'}} key={uid()}>{tag.name}</Tag>) : <span />
      },
    },
    {
      Header: "PayType",
      accessor: 'payType',
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => {
        return cell.value ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },
    {
      Header: "",
      show: true,
      className: "justify-around",
      //width: 190,
      width: "auto",
      sortable: false,
      Cell: cell => renderStatus(cell.original)
    },
    {
      Header: "Actions",
      accessor: "id",
      show: true,
      className: "justify-around",
      //width: 120,
      width: "auto",
      sortable: false,
      /* Cell: cell => {
        return <Button value={cell.value} icon="eye" onClick={e => {
          //console.log(cell.original.shiftId)
          context.setShiftDrawerId(cell.original.shiftId)
        }}>View</Button>
      } */
      Cell: cell => (
        <>
          <Tooltip title={"view"}>
            <Button
              value={cell.value}
              size="small"
              icon="eye"
              onClick={e => {
                context.setShiftDrawerId(cell.original.shiftId)
              }}
            >
            </Button>
          </Tooltip>
          <Tooltip title={"Audit Trail"}>
            <Button
            value={cell.value}
            size="small"
            icon="history"
            onClick={e => {
              //context.setShiftDrawerId(cell.value)
              const { start, end, employeeNumber} = cell.original
              setIsAuditModalVisible({
                id: cell.value,
                type: "audittable",
                visible: true, 
                shiftStart: start, 
                shiftEnd: end,
                employeeNumber: employeeNumber,
              })
              
            }}
          >
          </Button>
         </Tooltip>
         <Tooltip title={"Shift Timeline"}>
            <Button
            value={cell.value}
            size="small"
            icon="clock-circle"
            onClick={e => {
              //context.setShiftDrawerId(cell.value)
              const { start, end, employeeNumber} = cell.original
              setIsAuditModalVisible({
                id: cell.value,
                type: "timeline",
                visible: true, 
                shiftStart: start, 
                shiftEnd: end,
                employeeNumber: employeeNumber,
              })
              
            }}
          >
          </Button>
         </Tooltip>
      </>
      ),
    },
  ]
}

export default _columns
