import React, { useState, useEffect } from "react"
import Select from "react-select"
import { fetch, store } from "../store/psrs"
import { Input } from "antd"
import uniqid from "uniqid"
import JSON from "react-json-pretty"

export const SearchView = props => {
  const pageTitle = "SearchView"

  const initialState = store.get("_Search") || {
    query: "",
    model: "shift",
    records: [],
  }

  const [state, setState] = useState(initialState)

  const Records = () => state.records.map(r => <JSON key={uniqid()} data={r} className="json"></JSON>)

  useEffect(() => {
    store.set("_Search", state)
  }, [state])

  const searchProps = {
    allowClear: true,
    defaultValue: state.query,
    onChange: e => {},
    onSearch: async value => {
      // setState({ ...state, search: value })
      const { model } = state
      const records = await fetch(`${store.get("api")}/api/search?model=${model}&query=${value}&returning=json`)
      //console.log("RECORDS", records)
      setState({ ...state, query: value, records })
    },
  }

  const selectProps = {
    isSearchable: false,
    className: "psrs-select",
    classNamePrefix: "psrs",
    options: [
      { value: "shift", label: "shift" },
      { value: "timeEntry", label: "timeEntry" },
      { value: "tag", label: "tag" },
      { value: "team", label: "team" },
      { value: "employee", label: "employee" },
      { value: "gizmo", label: "gizmo" },
      { value: "businessUnit", label: "businessUnit" },
      { value: "payType", label: "payType" },
      { value: "specialty", label: "specialty" },
      { value: "subLedger", label: "subLedger" },
    ],
    defaultInputValue: state.model,
    onChange: e => {
      setState({ ...state, model: e.value })
    },
  }

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">
          {pageTitle} {state.records.length} Records
        </h1>
        <div className="center">
          <Input.Search {...searchProps} style={{ width: "300px" }} />
        </div>
        <div className="actions" style={{ width: "300px" }}>
          <Select {...selectProps} />
        </div>
      </div>
      <div className="view_content">
        <div className="SearchView">
          <Records />
        </div>
      </div>
    </React.Fragment>
  )
}

export default SearchView
