import React, { useState, useEffect } from "react"
import { Modal, message } from "antd"
import { fetch, store } from "../../../store/psrs"

export const RestoreModal = props => {
  //const today = moment()
  const { restoreOpen, setRestoreOpen, setParanoid, records, excluded, queryRecords, filters, setFilters, query, setQuery } = props
  const included = records.filter(r => !excluded.has(r.id))
    
  const includedIds = included.map(r => r.id)

  const [loading, setLoading] = useState(true)
  const [restoreTimeEntryCount, setRestoreTimeEntryCount] = useState(0)

  const handleRestore = async () => {    
    //var ids = shifts.map(s => s.id)
    const body = included.map(s => ({ id: s.id, deletedAt: s.deletedAt }))

    message.loading({ content: "Restoring Records...", key: "restoring" })
    const url = `${store.get("api")}/api/shifts/restore`
    //const restored = 
    await fetch(url, { method: "POST", body: JSON.stringify(body) })
    setFilters({ ...filters, deletedAt: null })
    setQuery({...query, deletedAt: null})
    setParanoid(true)
    await queryRecords()
    setParanoid(true)
    setRestoreOpen(false)
    message.success({ content: "Successfully Restored Records...", key: "restoring" })
  }

  const fetchShifts = async () => {
    setLoading(true)

    const url = `${store.get("api")}/api/deletedTimeEntriesByShiftIds`
    const _timeEntries = await fetch(url, { method: "POST", body: JSON.stringify({ ids: includedIds }) })

    if (_timeEntries && _timeEntries.length) {
      setRestoreTimeEntryCount(_timeEntries.length)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (restoreOpen) fetchShifts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restoreOpen])

	
  return (
    <Modal
      title={`Restore ${included.length} Shifts ?`}
      visible={restoreOpen}
      onOk={handleRestore}
      okText='RESTORE'
      okButtonProps={{type: 'default'}}
      onCancel={() => setRestoreOpen(false)}
      cancelText='CANCEL'
    >
      {loading && <h3>Loading Details ...</h3>}

      {!loading && included.length && (

          <div className="bulkDeleteBreakdown">
          <ul>
            <li>
              <b>{included.length}</b> Shift(s) will be bulk restored
            </li>
            <li>
              Restoring these <b>{included.length}</b> Shifts will result in restoring <b>{restoreTimeEntryCount}</b>  Time Entries
            </li>
          </ul>
          </div>
      )}
    </Modal>
  )
}
