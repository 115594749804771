import React, { useState, useEffect } from "react"
import { reqOptions, store, fetch, getRole } from "../store/psrs"
import ReactTable from "react-table"
import { Search, sortStringAsInt } from "./Admin/fields"
import { Checkbox } from "antd"
import useSWR, { mutate } from "swr"
import _ from "lodash"

export const BusinessUnits = props => {
  const pageTitle = "Business Units"

  const [state, setState] = useState({
    pk: "code",
    model: "businessUnit",
    ids: [],
    initialRecords: store.get("_BusinessUnits") || undefined,
  })

  const [records, setRecords] = useState([])

  const fetcher = async url => {
    const options = { ...reqOptions }
    const data = await fetch(url, options)
    //console.log("FETCHER", data)
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    return data
  }
  const key = `${store.get("api")}/api/resources/businessUnit`

  const { data } = useSWR(key, fetcher)

  const _props = { state, setState }

  const updateRecord = async (e, id) => {
    const body = JSON.stringify({ included: e.target.checked })
    await fetch(`${store.get("api")}/api/resources/businessUnit/${id}`, { method: "PATCH", body })
    mutate(key)
  }

  const columns = [
    {
      Header: "Updated",
      accessor: "updatedAt",
      show: false,
    },
    {
      Header: "Include",
      accessor: "included",
      className: "column_align_center",
      headerClassName: "column_align_center",
      Cell: cell => <Checkbox field="included" defaultChecked={cell.value} onChange={e => updateRecord(e, cell.original.id)} />,
    },
    {
      Header: "Code",
      accessor: "code",
      className: "code_column justify-around",
      sortMethod: sortStringAsInt,
    },
    {
      Header: "Name",
      accessor: "name",
    },
  ]

  useEffect(() => {
    store.set("_BusinessUnits", data)
    store.set("BusinessUnits", data)

    return() => { 
      store.remove("_BusinessUnits")
    }

  }, [data])

  useEffect(() => {
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
  }, [state.ids, data])

  const allowAccess = getRole() === 'admin' ? true : false

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{pageTitle}</h1>
        <div className="actions">
          <Search {..._props} />
        </div>
      </div>
      <div data-cy="RecordsTable" className="view_content">
        { allowAccess && <ReactTable
          columns={columns}
          loading={false}
          data={records}
          defaultPageSize={20}
          className="-striped -highlight flex-1"
          defaultSorted={[{ id: "included", desc: true }]}
          onSortedChange={() => mutate(key)}
        /> }
      </div>
    </React.Fragment>
  )
}

export default BusinessUnits
