import React from "react"
import {Button, Modal} from "antd"
import {SwatchesPicker} from "react-color"

import uuid from "uuid"

import { REQ } from "../../store/psrs"

export const CategoryColorPicker = (props) => {
 
  const {
      showColorPicker, 
      color, 
      shiftJson, 
      data,
      handleClose, 
      mutate, 
      //setShowColorPicker,
      setColor, 
      //categories,
      url
    } = props
  
  console.log('cat color picker modal')
  const handleClick = async () => {


      if (shiftJson) {  

        // we need to update all categories when one is changed....
        const updateCategories  = data ? data.filter(element => element.category === shiftJson.category) : []
        
        shiftJson["categoryColor"] = color
        const field = 'categoryColor'
        const model = "shiftTemplate"

        const value = color.hex
        //const id = shiftJson.id


        if (updateCategories.length > 0 ){
            updateCategories.forEach( async t => {
                const id = t.id
                const update = {}
                update[field] = value                
                await REQ(`/api/resources/${model}/${id}`, "PATCH", update)
            })
        }

        mutate(url)
          
      }
      
      handleClose()
  }
  
  return (
    <Modal title="Cateogy Color" 
       visible={showColorPicker} 
       onOk={handleClose} 
       onCancel={handleClick} 
       footer={[
          <span key={uuid()}>
            <Button key="ok" type="primary" onClick={handleClick}>
              OK
            </Button> 
          </span>

      ]}
    >
        <SwatchesPicker color={color} onChange={setColor} />
    </Modal>

  )
}

export default CategoryColorPicker

