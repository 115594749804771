import React, {useState} from "react"
import {Icon, Popover, Modal, Button} from "antd"
import {CopyToClipboard} from 'react-copy-to-clipboard'
import uuid from "uuid"

export const Help = (props) => {
  // const context = useMyContext()
  // const title = props.title || "Field Name"
  const content = props.text || "Insert helpful text here for this item"
  const trigger = props.trigger || "click"
  const placement = props.placement || "top"

  const [visible, setVisible] = useState(false)
  
  const show = props.type === 'shiftHelp' ? true : false

  let helpProps = {
    placement,
    // title,
    content,
    trigger,
  }

  if (show) {
    helpProps = {
      placement,
      content: 'Shift Info',
      trigger,
      onClick: () => setVisible(true)
    }
    
  } 

    

  const iconProps = {
    className: `Help ${props.position ? props.position : "right"}`,
    // theme: "filled",
    type: "info-circle"
  }

  if (props.type === "sync") {
    iconProps.type = "redo"
  }

  if (props.handleClick) {
    iconProps.onClick = props.handleClick
  }

  const [copySuccess, setCopySuccess] = useState('');

  const handleClose = () => {
    setVisible(false)
  }

  //const showInfo = () => {
  //  setVisible(true)
 // }
 
 

 return (
   <>
    <Popover {...helpProps}>
      <Icon {...iconProps} />
    </Popover>
     { show && <Modal
      title="Shift Info"
      visible={visible}
      onOk={handleClose}
      onCancel={handleClose}
      footer={[
        <span key={uuid()}>
          <span key={uuid()}>
          <a style={{ fontSize: '16px'}} href="https://submission.bromb.co/bwright/PSRS/bug">Send bug</a>
          &nbsp;&nbsp;&nbsp;
          </span>
          <span key={uuid()}>
            <Button key="cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button key="ok" type="primary" onClick={handleClose}>
              OK
            </Button> 
          </span>
           

        </span>
          
      ]}
    > 
      <p key={uuid()}><strong>Shift id: </strong> {content.id}</p>
      <p key={uuid()}><strong>Shift Name: </strong> {content.name}</p>
      <p key={uuid()}><strong>Shift Pay Period id: </strong> {content.payPeriodId}</p>
      <p key={uuid()}><strong>Shift Available: </strong> {content.available ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift UTC Start: </strong> {content.utcStart}</p>
      <p key={uuid()}><strong>Shift UTC End: </strong> {content.utcEnd}</p>
      <p key={uuid()}><strong>Shift Employee Number: </strong> {content.employeeNumber}</p>
      <p key={uuid()}><strong>Shift Supervisor Number: </strong> {content.supervisorNumber}</p>
      <p key={uuid()}><strong>Shift Duration: </strong> {content.duration}</p>
      <p key={uuid()}><strong>Shift Duration Orginal: </strong> {content.durationOg}</p>
      <p key={uuid()}><strong>Shift Duration OT: </strong> {content.durationOt}</p>
      <p key={uuid()}><strong>Shift Sick: </strong> {content.isSick ? 'true' : 'false' }</p>
      <p key={uuid()}><strong>Shift OT: </strong> {content.isOT ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift OT Scheduled: </strong>{content.isScheduledOT ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift Leave: </strong> {content.isLeave ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift processed: </strong> {content.isProcessed ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift submitted: </strong> {content.isSubmitted ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift approved: </strong> {content.isApproved ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift rejected: </strong> {content.isRejected ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift imbalanced: </strong> {content.isImbalanced ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift Created Date: </strong> {content.createdAt }</p>
      <p key={uuid()}><strong>Shift Updated Date: </strong> {content.updatedAt }</p>
      <p key={uuid()}><strong>Shift User Id: </strong> {content.userId }</p>
      <p key={uuid()}><strong>Shift Locked: </strong> {content.isLocked ? 'true' : 'false'}</p>
      <p key={uuid()}><strong>Shift Template Id: </strong> {content.shiftTemplateId }</p>
      <p key={uuid()}><strong>Shift Notifications: </strong> {content.Notifications ? 'true' : 'false' }</p>

      {React.version}
      <br />
      <span>
          <CopyToClipboard key="copytoclipboard" text={JSON.stringify(content, null, 2)}>
              <Button key="copy" onClick={()=> setCopySuccess('Copied to clipboard')}>Copy to Clipboard</Button>
          </CopyToClipboard>
          {copySuccess}
      </span>

  </Modal> }
  </ >
 )
  //return (
  //  <Popover {...helpProps}>
  //    <Icon {...iconProps} />
  //  </Popover>
  //)
}

export default Help
