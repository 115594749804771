import React, { useState, useEffect } from "react"
import { reqOptions, store, getRole } from "../store/psrs"
import useSWR, { mutate } from "swr"
import ReactTable from "react-table"
import Select from "react-select"
import { Search } from "./Admin/fields"
import { InPut, CheckBox } from "./Admin/fields"
import { updateResource, sortStringAsInt } from "./Admin/fields"
// import {Emitter } from "../store/psrs"
import _ from "lodash"

export const PayTypes = props => {
  const pageTitle = "Pay Types"

  const [state, setState] = useState({
    pk: "code",
    model: "payType",
    ids: [],
    initialRecords: store.get("_PayTypes") || undefined,
    sortColumns: []
    //onSortedChange: sortChanged,
  })

  const [records, setRecords] = useState(state.initialRecords || [])

  const [forceRefresh, setForceRefresh] = useState(false)
  

  const sortChanged = (newSorted, column, shiftKey) => {
    console.log(newSorted, ' : ', column, ' : ', shiftKey )
    setForceRefresh(true)
  }

  const pageChanged = (pageIndex) => {
   console.log('page index-> ', pageIndex)
   setForceRefresh(true)
  }

  const fetcher = async url => {
    const options = { ...reqOptions }
    const data = await fetch(url, options).then(res => res.json())
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    return data
  }
  const key = `${store.get("api")}/api/resources/payType`
  const { data } = useSWR(key, fetcher, { initialData: state.initialRecords })

  const _props = { state, setState, mutate, url: key }

  const categoryOptions = [
    { value: "SICK", label: "SICK" },
    { value: "OVERTIME", label: "OVERTIME" },
    { value: "LEAVE", label: "LEAVE" },
    { value: "REGULAR", label: "REGULAR" },
    { value: "SPECIAL", label: "SPECIAL" },
    { value: "OTHER", label: "OTHER" },
    //{ value: "HOLIDAY", label: "HOLIDAY"},
    //{ value: "WCR", label: "WCR"},
    //{value: "VACATION", label: "VACATION"},
    //{value: "SPECIALTY", label: "SPECIALTY"},
  ]

  const columns = [
    { Header: "Updated", accessor: "updatedAt", show: false },
    {
      Header: "Include",
      accessor: "included",
      className: "column_align_center",
      headerClassName: "column_align_center",
      Cell: cell => {
        const id = cell.original.id
        return <CheckBox field="included" id={id} toValue={true} unValue={false} value={cell.value} {..._props} />
      },
    },
    {
      Header: "id",
      accessor: "id",
      show: false,
    },
    {
      Header: "Code",
      accessor: "code",
      className: "justify-around",
      width: 80,
      sortMethod: sortStringAsInt,
    },
    {
      Header: "Rename",
      accessor: "rename",
      show: false,
    },
    {
      Header: "Generic Name",
      accessor: "rename",
      Cell: row => {
        return <InPut value={row.value} field="rename" forceRefresh={forceRefresh} setForceRefresh={setForceRefresh} id={row.original.id} {..._props} />
      },
    },
    { Header: "Description", accessor: "description" },
    { 
      Header: "Category Raw",
      accessor: "category",
      show: false
    },
    {
      Header: "Category",
      accessor: "category",
      Cell: cell => {
        const id = cell.original.id
        const selectOptions = {
          className: "psrs-select",
          classNamePrefix: "psrs",
          defaultValue: cell.value ? _.find(categoryOptions, { value: cell.value.toUpperCase() }) : null,
          isClearable: true,
          options: categoryOptions,
          placeholder: `Select an Optional Category`,
          id: id,
          onChange: select => {
            const val = !select ? null : select.value.toUpperCase()
            updateResource({ ..._props, value: val, field: "category", id: cell.original.id })
          },
        }
        return <Select {...selectOptions} />
      },
    },
  ]

  useEffect(() => {
    if (data && data.length) store.set("_PayTypes", data)
    if (data && data.length) store.set("PayTypes", data)

    return() => { 
      store.remove("_PayTypes")
    }

  }, [data])

  useEffect(() => {
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ids])

  const allowAccess = getRole() === 'admin' ? true : false


  return ( 
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{pageTitle}</h1>
        <div className="actions">
          <Search {..._props} />
        </div>
      </div>
      <div data-cy="RecordsTable" className="view_content">
       {allowAccess &&  <ReactTable
          columns={columns}
          loading={false}
          data={records}
          defaultPageSize={100}
          className="-striped -highlight flex-1"
          //defaultSorted={[{ id: "i", desc: true }]}
          defaultSorted={[{id: "included", desc: true},{ id: "code", desc: false }]}
          //defaultSorted={[{ id: "code", desc: false }]}
          autoResetPage={true} 
          //sorted={[{ id: "i", desc: true }]}
          onSortedChange={sortChanged}
          onPageChange={pageChanged}
          
            
        />}
      </div>
    </React.Fragment>
  )
}

export default PayTypes
