// THIS SHOULD GET FILLED OUT AND ABSTRACTED INTO A CMS FEATURE

const JDE = "data sourced from JDE"
const NOTJDE = "data is not stored in JDE"

export const fields = {
  assignment: "",
  groups: "",
  equipment: "",
  payType: ""
}

export const views = {
  businessUnits: {
    page: "This page is strictly for reference. None of it is editable",
    code: JDE,
    name: JDE
  },
  equipment: {
    page: ""
  },
  reasons: {
    page: "",
    category: "Field used in displaying appropriate reasons to choose from",
    description: "Text user sees when choosing a reason"
  },
  assignments: {
    page: NOTJDE,
    name: "",
    payType: "Optional. Allows for some assignments to set a shifts pay type"
  },
  specialties: {
    page: "",
    name: ""
  },
  payTypes: {
    page: "",
    include: "",
    name: "",
    description: JDE,
    code: JDE,
    category: ""
  },
  team: {
    page: "",
    shift: "Values in this section are used as the defaults for positions added to the roster",
    weekDays: "Selections here will only populate new positions added to the roster",
    businessUnit: "Optional: Used to override the businessUnit of all generated shifts",
    subLedger: "Optional: Used to provide roster positions with a default sub ledger",
    groups: "Used for grouping Teams in the schedule. Changes here will update all Positions and Shifts",
    repeating: "If deselected, individual dates must be selected",
    start: "",
    duration: "",
    roster: "",
    slot: {
      name: "Optional. Use to give the position a meaningful name. May be used for call signs?",
      assignment: "",
      equipment: "",
      shortDay: "",
      start: "",
      duration: "",
      groups: "",
      disabled: "",
      schedule: "",
      duplicate: ""
    }
  }
}

const help = {fields, views}

export default help
