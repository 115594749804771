import React from "react"
import { TimePicker } from "antd"
import moment from "moment"

export const StartTimeField = props => {
  // console.log("StartTimeField disabled", props.disabled)

  const { field, /*value,*/ handleChange, id } = props

	var dvalue = null;
 	if( props.value ) {
		//-//console.log("props.value.start: " + props.value.start);
    //var td = new Date(props.value.start);
    //dvalue = moment(td.getTime()); // moment adjusts it already .subtract(td.getTimezoneOffset(), 'm');
		dvalue = moment(props.value.start);
		//-//console.log("DVALUE: " + dvalue.format("HH:mm"));
  }

  const options = {
    isDisabled: props.disabled,
    disabled: props.disabled,
    minuteStep: 6,
    onChange: val => {
			/*
			val = new Date(Number(val));
      var h = val.getHours();
      var m = val.getMinutes();
      val.setUTCHours(h);
      val.setUTCMinutes(m);
			if( value && value.start ) { // Shift Drawer Edit
				var sz = moment(value.start).toISOString().substring(0,11);
				var ez = val.toISOString().substring(11);
				//console.log(sz);
				//console.log(ez);
				val = new Date(sz + ez);
			}
			console.log("VAL: " + val.toISOString());
			*/
      handleChange({ ...props, field, value:val, id })
    },
    defaultValue: dvalue,
    format: "HH:mm",
    className: "StartTimeField",
    allowClear: false,
  }
  return <TimePicker data-cy="start-time-picker" {...options} />
}

export default StartTimeField
