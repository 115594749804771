import React, { useState, useEffect } from "react"
import moment from "moment"
import { Checkbox, Modal, message } from "antd"
import { fetch, store } from "../../../store/psrs"

export const DeleteModal = props => {
  const today = moment()
  const { deleteOpen, setDeleteOpen, records, excluded, queryRecords } = props
  const included = records.filter(r => !excluded.has(r.id))
  const future = included.filter(r => !moment(r.start).isBefore(today))
  const past = included.filter(r => moment(r.start).isBefore(today))
  const pto = future.filter(r => r.requestedOff)
  const ot = future.filter(r => r.scheduledOT)
  var shifts = future.filter(r => !r.requestedOff && !r.scheduledOT)

  const excludedCount = records.length - included.length
  var cantCount = pto.length + ot.length
  var deleteCount = shifts.length
  //const shiftIds = shifts.map(s => s.id)
  const recordIds = shifts.map(r => r.id)

  // const [deleting, setDeleting] = useState(false)
  const [loading, setLoading] = useState(true)
  const [incPast, setIncPast] = useState(false)
  const [types, setTypes] = useState({})
  // const [shifts, setShifts] = useState(_shifts)
  // const [pto, setPto] = useState(_pto)
  // const [past, setPast] = useState(_past)
  // const [ot, setOt] = useState(_ot)
  // const [cantCount, setCantCount] = useState(0)

  const handleDelete = async () => {
		//console.log("handleDelete: " + incPast);
    
    if (window.confirm('Are you sure you want to delete the selected records?')) {
        var ids = shifts.map(s => s.id)
        //console.log(ids)
        message.loading({ content: "Deleting Records...", key: "deleting" })
        const url = `${store.get("api")}/api/shifts`
        const deleted = await fetch(url, { method: "DELETE", body: JSON.stringify({ ids: ids }) })
        console.log("handleDelete", deleted)
        await queryRecords()
        setDeleteOpen(false)
        message.success({ content: deleted === -1 ? "Records Can Not Be Deleted" : "Successfully Deleted Records...", duration: 5, key: "deleted" })
    } else {
      setDeleteOpen(false)
    }
  }

  const timeEntryPayTypes = {}

  const fetchShifts = async () => {
    setLoading(true)

    const url = `${store.get("api")}/api/timeEntriesByShiftIds`
    const _timeEntries = await fetch(url, { method: "POST", body: JSON.stringify({ ids: recordIds }) })
    const timeEntries = _timeEntries.map(te => ({ code: te.payType.code, description: te.payType.description }))

    for (let entry of timeEntries) {
      if (!timeEntryPayTypes[entry.code]) timeEntryPayTypes[entry.code] = []
      timeEntryPayTypes[entry.code].push([entry.code, entry.description])
    }

    //console.log(`Past Shifts Count: ${past.length}`)
    //console.log(`Future PTO Count: ${pto.length}`)
    //console.log(`Future Unscheduled OT Count: ${ot.length}`)

    //console.log("TimeEntryPayTypes", timeEntryPayTypes, Object.keys(timeEntryPayTypes))
    setTypes(timeEntryPayTypes)

    setLoading(false)
  }

  const PayTypeRows = () => {
    const payTypeKeys = Object.keys(types)
    //console.log("PayTypeRows", payTypeKeys)
    return payTypeKeys.map(key => (
      <tr key={key}>
        <td>{types[key].length}</td>
        <td>{types[key][0][1]}</td>
        <td>{key}</td>
      </tr>
    ))
  }

  useEffect(() => {
    if (deleteOpen) fetchShifts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOpen])

	if( incPast ) {
		shifts = shifts.concat(past)
  	deleteCount = shifts.length
	}
	else {
		cantCount += past.length
	}

  return (
    <Modal
      title={`Delete ${shifts.length} Shifts ?`}
      visible={deleteOpen}
      onOk={handleDelete}
      okText='DELETE'
      okButtonProps={{type: 'danger'}}
      onCancel={() => setDeleteOpen(false)}
      cancelText='CANCEL'
    >
      {loading && <h3>Loading Details ...</h3>}

      {!loading && shifts.length && (

        <div className="bulkDeleteBreakdown">
          <h2 style={{color: 'red'}}>This will Deleted the following Shifts and Time Entries </h2>
          <ul>
            <li>
              Of <b>{records.length}</b> filtered Shifts, <b>{excludedCount}</b> Shifts were manually excluded.
            </li>
            
            {cantCount > 0 && (
              <>
                <li>
                  Of <b>{included.length}</b> selected, <b>{cantCount}</b> Shifts <b>CAN'T</b> be bulk deleted as ...
                  <ul>
                    {!!past.length && !incPast && (
                      <li>
                        <b>{past.length}</b> Are Past Shifts.
                      </li>
                    )}
                    {!!pto.length && (
                      <li>
                        <b>{pto.length}</b> Are Scheduled for Time Off.
                      </li>
                    )}
                    {!!ot.length && (
                      <li>
                        <b>{ot.length}</b> Are Un-Scheduled Overtime Shifts.
                      </li>
                    )}
                  </ul>
                </li>
              </>
            )}

            <li>
              Of <b>{included.length}</b> Shifts, <b>{deleteCount}</b> CAN be bulk deleted
            </li>

            <li>
              Deleting these <b>{deleteCount}</b> Shifts will result in removal of following Time Entries ...
            </li>
          </ul>

          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>Qty</th>
                <th>PayType</th>
                <th>Code</th>
              </tr>
            </thead>
            <tbody>
              <PayTypeRows />
            </tbody>
          </table>
        </div>
      )}
			<section>
				<br/>
				<Checkbox
           checked={incPast}
           onChange={e => setIncPast(!incPast)}
        >
        Include past shifts
				</Checkbox>
			</section>
    </Modal>
  )
}
