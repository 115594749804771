import React from "react"

import {Button, Modal } from "antd"
import uuid from "uuid"
import moment from "moment-timezone"

import ShiftJsonTable from "./ShiftJsonTable"
import ShiftTimeLine from "./ShiftTimeLine"


const ShiftAuditModal = props => {
    
    const {
        id, 
        isAuditModalVisible,
        setIsAuditModalVisible,
        type,
        data,
        modelName
    } = props

    let auditTitle = ''
    let auditEmpName = ''
    let auditEmpNum = ''
    //let shiftId = 0
    let AuditRec = {}
        
    if (data && data.length > 0 ) {
        if (id !== 0) {
            AuditRec = modelName === 'Shift' || modelName === 'shift' ? data.find(s => s.id === id) : data.find(t => t.id === id)
            auditTitle = AuditRec && AuditRec.start ? moment(AuditRec.start ).format("ddd MMM Do") : ''
            auditEmpNum = AuditRec && AuditRec.employeeNumber ? AuditRec.employeeNumber : ''
            auditEmpName = AuditRec && AuditRec.employee && AuditRec.employee.fullName ? AuditRec.employee.fullName : ''

            //shiftId = modelName === 'Shift' || modelName === 'shift' ? AuditRec.id : AuditRec.shiftId
            
        }
       
    }
      
    const handleAuditOk = () => {
        setIsAuditModalVisible({
            id: 0, 
            visible: false
        })
    }

    const handleAuditCancel = () => {
        setIsAuditModalVisible({
            id: 0, 
            visible: false
        })
    }  

/*     const ShiftAuiditTable = () => {
        return generateTable(GetData(id))
    } 

    function generateTable(jsonArray) {

        const tableContainer = document.getElementById('table-container');
        tableContainer.innerHTML=''; // Clear existing table data
      
        const table = document.createElement('table');
        const thead = document.createElement('thead');
        const tbody = document.createElement('tbody');
      
        // Create table header
        const headerRow = document.createElement('tr');
        const headers = ['ID', 'Employee Number','Full Name', 'Date Created', 'Date Updated', 'Action','Field', 'Value Before', 'Value After', 'Changed By','Created At','Updated At'];
        headers.forEach(headerText => {
            const th = document.createElement('th');
            th.textContent = headerText;
            headerRow.appendChild(th);
        });
        thead.appendChild(headerRow);
        
        // Create table rows from JSON data
        jsonArray.forEach(item => {
            const commonData = [
                item.id,
                //item.name,
                //item.action,
                //item.userId,
                item.employeeNumber,
                item.employeeName,
                item.date_created,
                item.date_updated,
                
            ];
      
            Object.keys(item.changes).forEach(changeKey => {
                const row = document.createElement('tr');
                
                // Add common data fields to the row
                commonData.forEach(data => {
                    const commonCell = document.createElement('td');
                    commonCell.textContent = data;
                    row.appendChild(commonCell);
                });
                
                // Add specific change fields to the row
                const actionCell = document.createElement('td');
                actionCell.textContent = item.changes[changeKey].action !== null ? item.changes[changeKey].action : '';
                row.appendChild(actionCell);
      
                const fieldNameCell = document.createElement('td');
                fieldNameCell.textContent = changeKey;
                row.appendChild(fieldNameCell);
                
                const beforeCell = document.createElement('td');
                beforeCell.textContent = item.changes[changeKey].before !== null ? item.changes[changeKey].before : '';
                row.appendChild(beforeCell);
                
                const afterCell = document.createElement('td');
                afterCell.textContent = item.changes[changeKey].after !== null ? item.changes[changeKey].after : '';
                row.appendChild(afterCell);
      
                
                const userIdCell = document.createElement('td');
                userIdCell.textContent = item.changes[changeKey].userId !== null ? item.changes[changeKey].userId : '';
                row.appendChild(userIdCell);
      
                const dateCreatedCell = document.createElement('td');
                dateCreatedCell.textContent = item.changes[changeKey].dateCreated !== null ? item.changes[changeKey].dateCreated : '';
                row.appendChild(dateCreatedCell);
      
                const dateUpdatedCell = document.createElement('td');
                dateUpdatedCell.textContent = item.changes[changeKey].dateUpdated !== null ? item.changes[changeKey].dateUpdated : '';
                row.appendChild(dateUpdatedCell);
      
                
                tbody.appendChild(row);
            });
        });
      
        table.appendChild(thead);
        table.appendChild(tbody);
      
        
        tableContainer.appendChild(table);
        
        return table;
    }
*/
    const tableToCSV = () => {
 
        // Variable to store the final csv data
        let csv_data = [];
     
        // Get each row data
        let rows = document.getElementsByTagName('tr');
        for (let i = 0; i < rows.length; i++) {
     
            // Get each column data
            let cols = rows[i].querySelectorAll('td,th');
     
            // Stores each csv row data
            let csvrow = [];
            for (let j = 0; j < cols.length; j++) {
     
                // Get the text data of each cell of
                // a row and push it to csvrow
                csvrow.push(cols[j].innerHTML);
            }
     
            // Combine each column value with comma
            csv_data.push(csvrow.join(","));
        }
        // Combine each row data with new line character
        csv_data = csv_data.join('\n');
        //console.log('csv_data --> ', csv_data)
     
        /* We will use this function later to download
        the data in a csv file downloadCSVFile(csv_data);
        */
    
        // Call this function to download csv file  
        downloadCSVFile(csv_data);
     
      }
    
    const downloadCSVFile = (csv_data) => {
    
        // Create CSV file object and feed our
        // csv_data into it
        const CSVFile = new Blob([csv_data], { type: "text/csv" });
        
        // Create to temporary link to initiate
        // download process
        let temp_link = document.createElement('a');
        
        // Download csv file
        temp_link.download = AuditRec ? `TPS-${AuditRec.modelName}_${id}_${moment().format('YYYYMMDDHHMMSS')}` : "TPS-Export.csv";
        let url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;
        
        // This link should not be displayed
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);
        
        // Automatically click the link to trigger download 
        temp_link.click();
        document.body.removeChild(temp_link);
    }
    
    return (
      <>
        <div>
            <div id="loadingIndicator">
                <br />
                <div className="loader"></div>
                <br />
            </div>
            <div id="table-container" style={{overflowX: "auto"}}>
                <Modal
                    key={uuid()}
                    title={`Shift Audit: ${auditTitle} | ${auditEmpNum} - ${auditEmpName}`}
                    style={{ top: 20 }}
                    visible={isAuditModalVisible} 
                    onOk={handleAuditOk} 
                    onCancel={handleAuditCancel}
                    width={type === 'timeline' ? '40%' : '100%'}
                    bodyStyle={{overflowY: 'scroll', overflowX: 'scroll', height: '80vh' , overflow: 'auto'}}
                    footer={type === 'audittable' ? [
                        <div key={uuid()}>
                            <Button icon="download" type="dashed" key={uuid()} onClick={tableToCSV} >Download</Button>
                            <Button type="primary" key={uuid()} onClick={handleAuditOk}>
                                OK
                            </Button>
                        </div>
                    ] 
                    : <div key={uuid()}>
                    <Button  key={uuid()} onClick={handleAuditCancel} >Cancel</Button>
                    <Button type="primary" key={uuid()} onClick={handleAuditOk}>
                        OK
                    </Button>
                </div>} 
                >
                    {type === 'audittable' && <ShiftJsonTable id={id} modelName={modelName} />}
                    {type === 'timeline' && <ShiftTimeLine id={id} modelName={modelName} />}
                </Modal>
            </div>
        </div>
        
      </>
    )
}

export default ShiftAuditModal