import React from "react"
import { REQ, session } from "../../store/psrs"
import { LeaveBalanceSelect } from "../Forms"
// import { PayTypeSelect } from "../Forms"
//import { PayPeriodEndSelector } from "../Forms"
import { DurationField } from "../Forms/DurationField"
import { Button, Icon, Table, Tooltip, Input } from "antd"
import uuid from "uuid"
import moment from "moment"
//import _ from "lodash"

import  { EmployeeLeaveTable }  from "./EmployeeLeaveTable"

// import toggleArray from "toggle-in-array"

const LeaveEntriesTable = props => {
  const { mutate, url, shiftLocked, setShiftLocked } = props
  const Shift = props.shift
  const Employee = Shift.employee
  const timeEntries = Shift.timeEntries
  //-// show this for consistencey -- const timeEntries = _.filter(Shift.timeEntries, t => t.name !== "Unpaid Lunch")
  // const Leave = context.get("leaveBalances", "id", Employee.leaveBalanceId)
  // const payTypeCodes = [28, 29, 39, 49, 59, 79, 484, 206]
  // const PayTypes = context.get("payTypes", "code", payTypeCodes)

  const addEntry = async () => {
    const updatedShift = await REQ(`/api/time_entries/create`, "POST", Shift)
    session.set("changeDetected", true)
    setShiftLocked(false)
    mutate(url, updatedShift)
  }

  const removeEntry = async id => {
    const updatedShift = await REQ(`/api/time_entries/${id}`, "DELETE")
    session.set("changeDetected", true)
    setShiftLocked(true)
    mutate(url, updatedShift)
  }

  const updateEntry = async ({ field, value, id }) => {
    const update = field ? {} : value
    if (field) update[field] = value
    const updatedShift = await REQ(`/api/time_entries/${id}`, "PATCH", update)
    //console.log("updated timeEntry", updatedShift)
    session.set("changeDetected", true)
    mutate(url, updatedShift)
  }

  const renderTotal = (text, shift, index) => {
    const { timeEntries } = shift

    // look at why no time entries.  
    if (timeEntries) {
      if (timeEntries.length) {
        // const shiftDuration = shift.duration
        // const durations = timeEntries.map(e => e.duration)
        // const subTotal = durations.reduce((total, val) => total + val)
        const balanced = shift.imbalanced ? "error" : "equal"
        return <span className={balanced}>{shift.timeTotal}</span>
      } else {
        return <span>0</span>
      }
  }
  }

  const renderBlank = (text, record, index) => ""

  const subTotalOptions = {
    rowClassName: "timeEntryTotals",
    dataSource: [Shift],
    pagination: false,
    showHeader: false,
    bordered: false,
    rowKey: "id",
    columns: [
      { width: 90, key: uuid(), title: "", render: renderTotal, align: "center" },
      {
        key: uuid(),
        title: "",
        align: "left",
        render: () => `Entry Total Hours ${Shift.imbalanced ? "do NOT match the shift duration! Please fix." : ""}`,
      },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
    ],
  }

  const columns = [
    {
      width: 80,
      align: "center",
      title: "Date",
      dataIndex: "start",
      render: text => moment(text).format("MM-D"),
    },
    {
      width: 100,
      align: "center",
      title: "Hours*",
      dataIndex: "duration",
      render: (text, record, index) => (<DurationField disabled={record.locked} field="duration" value={text} id={record.id} handleChange={updateEntry} noLabel={true} />),
    },
    // {
    //   align: "center",
    //   title: "Pay Type",
    //   dataIndex: "payTypeCode",
    //   render: (text, record, index) => (
    //     <PayTypeSelect
    //       handleChange={updateEntry}
    //       leaveBalances={Employee.leave}
    //       field="payTypeCode"
    //       value={record.payTypeCode}
    //       id={record.id}
    //       size="small"
    //     />
    //   ),
    //   style: { paddingRight: 0 },
    // },
    {
      // width: 240,
      align: "left",
      title: "Leave*",
      dataIndex: "payTypeCode",
      width: 200,
      render: (text, record, index) => (
        <LeaveBalanceSelect
          handleChange={updateEntry}
          leaveBalances={Employee.leave}
          field="payTypeCode"
          value={record.payTypeCode}
          id={record.id}
          size="small"
          selectType='pto'
          disabled={record.locked}
        />
      ),
      style: { paddingRight: 0 },
    },
    {
      title: "Notes",
      dataIndex: "notes",
      width: 300,
      render: (text, record, index) => {
        return (
          <Input
            defaultValue={text}
            placeholder="optional"
            onBlur={e => updateEntry({ field: "notes", value: e.target.value, id: record.id })}
            disabled={record.locked}
          />
        )
      },
    },
    {/*
    {
      title: "Pay Period End Override",
      dataIndex: "payPeriodEndOverride",
      width: 300,
      render: (text, record, index) => {
        return (
          <PayPeriodEndSelector
            data-cy="templatePayPeriodRangeSelector"
            defaultValue={text}
            placeholder="optional"
            disabled={record.locked}
            value={{ payPeriodStartId: record.payPeriodEndOverride, payPeriodEndId: record.payPeriodEndOverride }}
            record={timeEntries}
            handleChange={e => {
              console.log('over ride the pay period end')
              updateEntry({ field: "payPeriodEndOverride", value: e.value, id: record.id })}
            }
          />
        )
      },
    }, 
  */},
    {
      align: "center",
      dataIndex: "id",
      render: (text, record, index) => {
        if (index > 0) {
          return (
            <Tooltip title="Remove Entry" placement="left">
              <span onClick={() => removeEntry( record.id)}>
                <Icon className="removeIcon roundIconAction" theme="filled" type="delete" />
              </span>
            </Tooltip>
          )
        }
      },
    },
  ]

  const timeEntryOptions = {
    dataSource: timeEntries,
    columns: columns,
    pagination: false,
    showHeader: true,
    sortOrder: "ascend",
    rowKey: () => uuid(),
  }

  //const [active, setActive] = useState([0, 1, 2, 3])
  //const TH = { scope: "col", border: "bottom", align: "center", size: "small" }
  //const TD = { scope: "row",  align: "center", size: "small", border: "bottom" }

  return (
    <React.Fragment>
      <section>
        <h2> Leave Distribution </h2>
      </section>
      <section>
        <Button type="primary" size="small" icon="file-add" ghost={true} disabled={shiftLocked} onClick={addEntry}>
            Add Leave Entry
        </Button>
      </section>
      <EmployeeLeaveTable leave={Employee && Employee.leave ? Employee.leave : {}} />
      <Table {...timeEntryOptions} />
      <Table key="timeEntryTotal" {...subTotalOptions} />
    </React.Fragment>
  )
}

export default LeaveEntriesTable
