import React, { useState, useEffect } from "react"
import { reqOptions, store, Can } from "../store/psrs"
import useSWR, { mutate } from "swr"
import ReactTable from "react-table"
import { Button, Tooltip, Popconfirm } from "antd"
import { InPut } from "./Admin/fields"
import { Search } from "./Admin/fields"
import { deleteResource, createResource } from "./Admin/fields"
import _ from "lodash"

export const Gizmos = props => {
  const pageTitle = "Equipment"
  const createTitle = "Create New Item"

  const [state, setState] = useState({
    pk: "id",
    model: "gizmo",
    ids: [],
    initialRecords: store.get("_Gizmos") || undefined,
  })

  const [records, setRecords] = useState(state.initialRecords || [])

  const fetcher = async url => {
    const options = { ...reqOptions }
    const data = await fetch(url, options).then(res => res.json())
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    return data
  }
  const key = `${store.get("api")}/api/resources/gizmo`
  const { data } = useSWR(key, fetcher, { initialData: state.initialRecords })

  const _props = { state, setState, mutate, url: key }

  const columns = [
    {
      Header: "Updated",
      accessor: "updatedAt",
      show: false,
    },
    {
      Header: "",
      accessor: "i",
      sortable: false,
      show: true,
      width: 50,
      style: {
        color: 'white',
      },
    },
    { Header: "ID", accessor: "id", className: "id_column", show: false },
    {
      Header: "Name",
      accessor: "name",
      sortable: false,
      Cell: cell => <InPut field="name" value={cell.value} id={cell.original.id} {..._props} />,
    },
    { Header: "updated", accessor: "updatedAt", show: false },
    {
      Header: "Actions",
      sortable: false,
      Cell: row => (
        <div className="row_actions">
          <Can I="create:Equipment">
            <Popconfirm
              title={`Are you sure you want to DELETE this Entry?`}
              onConfirm={() => deleteResource({ ..._props, id: row.original.id })}
            >
              <Tooltip title="Delete">
                <Button icon="delete" shape="circle" type="danger" />
              </Tooltip>
            </Popconfirm>
          </Can>
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (data && data.length) store.set("_Gizmos", data)
    if (data && data.length) store.set("Gizmos", data)

    return() => { 
      store.remove("_Gizmos")
    }

  }, [data])

  useEffect(() => {
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ids])

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{pageTitle}</h1>
        <div className="actions">
          <Search {..._props} />
          <Can I="create:Equipment">
            <Button data-cy="CreateResourceButton" type="dashed" icon="plus-square" onClick={() => createResource(_props)}>
              {createTitle}
            </Button>
          </Can>
        </div>
      </div>
      <div data-cy="RecordsTable" className="view_content">
        <ReactTable
          columns={columns}
          loading={false}
          data={records}
          defaultPageSize={100}
          filterable={true}
          multiSort={false}
          className="-striped -highlight flex-1"
          defaultSorted={[{ id: "i", desc: true }]}
        />
      </div>
    </React.Fragment>
  )
}

export default Gizmos
