import React, { useEffect, useState } from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import useSWR from "swr"
import _ from "lodash"
import {Layout} from "antd"

import { reqOptions, store, Emitter, REQ } from "../store/psrs"
import { useMyContext, useAbilityContext } from "../store/useMyContext"
import ReactTable from "react-table"
import columns from "./TimeEntries/_columns"
import ShiftAuditModal from "./Audit/ShiftAuditModal"


const {Content} = Layout

export const TimeEntries = props => {
  const context = useMyContext()
  const ability = useAbilityContext()
  const payPeriod = store.get("PayPeriod")
  const payPeriodId = payPeriod.id
  const query = props.query(payPeriodId)
  const [loading, setLoading] = useState(false)
  // const initial = session.get(page)

  const [isAuditModalVisible,setIsAuditModalVisible] = useStateIfMounted({id: 0, visible: false, type: 'audittable'})

  const showAuditModal = () => {
    setIsAuditModalVisible({...isAuditModalVisible, visible: true})  
  } 

  const fetcher = key => {
    const options = {
      ...reqOptions,
      method: "POST",
      body: JSON.stringify({ query }),
    }
    setLoading(true)
    return fetch(props.url, options)
      .then(async res => {
        setLoading(false)
        const records = await res.json()
        //const filteredRecs = _.filter(records, t => t.name !== "Unpaid Lunch")
        const filteredRecs = _.filter(records, t => t.payTypeCode !== "380")


        if (records) Emitter.emit("ChangedLockStatus", {locked: records[0].locked} )

        return filteredRecs 
        //return records
      })
      .catch(err => Emitter.emit("TimeEntries/index.js", { fn: "fetcher", error: err }))
  }

  const getTimeEntryShiftNotes = async (shiftId) => {
    const shift = await REQ(`/api/shifts/${shiftId}`,"GET")
    return({ 
      id: shift.id, 
      notes: shift.notes, 
      reason: shift.reason,
      reasonId: shift.reasonId, 
      submit: shift.submit, 
      imbalanced: shift.imbalanced,
      diveHours: shift.diveHours,
      diveHoursIncluded: shift.diveHoursIncluded,
      ftoHours: shift.ftoHours,
      ftoHoursIncluded: shift.ftoHoursIncluded,
      isOT: shift.isOT,
      isSick: shift.isSick,
      requestedOff: shift.requestedOff,
      name: shift.name,

    })
  }

  const updateTimeEntry = async ({ field, value, shiftId }) => {
    //console.log("TimeEntries.js updateTimeEntry", field, value, shiftId)
    const body = { id: shiftId }
    body[field] = value
    const updated = await REQ(`/api/shifts/${shiftId}`, "PATCH", body)
    mutate()
    console.log("updated timeentry", updated)
  }

  // const swrOpts = {revalidateOnFocus: false, initialData: initial && initial.length ? initial : null }
  const swrOpts = { revalidateOnFocus: false }
  const { data, mutate } = useSWR(query, fetcher, swrOpts)

  useEffect(() => {
    Emitter.on("ShiftDrawerClose", () => {
      mutate()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refresh = () => {
    //const pageUrl = `${store.get("api")}${props.path}`
    mutate() // mutate(props.url)
  }


  const tableOptions = {
    columns: columns({ ...props, context, ability, updateTimeEntry, refresh, getTimeEntryShiftNotes, setIsAuditModalVisible}),
    filterable: true,
    defaultPageSize: 100,
    className : "-striped -highlight",
    data: data ? data : [],
    loading,
    noDataText: "",
    multiSort: true,
    defaultSorted: [
      { id: "employee.fullName", asc: true },
      { id: "start", asc: true },
    ],
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  }

    // added layout/content for scrolling on small screens
  return (
    <>
      <Layout className="RecordsViewer" >
        <Content className="Content">
          <ReactTable {...tableOptions} />
          <ShiftAuditModal 
            centered = {true}
            confirmLoading = {true}
            destroyOnClose={true}
            //zIndex = {-1}
            shiftId = { isAuditModalVisible.id }
            id= { isAuditModalVisible.id }
            isAuditModalVisible = { isAuditModalVisible.visible }
            setIsAuditModalVisible = { setIsAuditModalVisible } 
            showAuditModal = { showAuditModal }
            context = {context}
            shift = {data ? data : []}
            data= {data ? data : []}
            modelName="TimeEntry"
            type = {isAuditModalVisible.type}
          />
        </Content>
      </Layout>
    </>
  )
}

export default TimeEntries
