import React, { useState, useEffect } from "react"
import { store } from "../../store/psrs"
import uniqid from "uniqid"
import { Select } from "antd"
import { renderSelectDropdown, filterOption } from "./_selectDefaults"
import _ from "lodash"

const Option = Select.Option

export const GroupSelector = props => {
  const { tagIds, handleChange } = props
  //console.log("GroupSelector tagIds", tagIds)
  const [state, setState] = useState({
    selected: [],
    tagIds: [],
    groups: [],
  })

  const getTagIds = async () => {
    let groups = store.get("Tags")
   // groups = _.sortBy(groups, ['name'])
    groups = _.orderBy(groups, [group => group.name.toLowerCase()], ['asc']);


    let ids = groups.map(g => g.id)
    //ids = _.sortBy(ids, ['label']) 
    // const ids = await REQ("/api/tags_with_shifts", "POST")
    setState({ ...state, tagIds: ids, groups, selected: tagIds ? tagIds : [] })
  }

  const options = () =>
    state.groups.map(group => (
      <Option data-cy={`option-${group.id}`} key={uniqid()} value={group.id} label={group.name}>
        {group.name}
      </Option>
    ))

  const selectOptions = {
    isDisabled: props.disabled,
    disabled: props.disabled,
    className: "GroupsSelector",
    dropdownClassName: "GroupsSelectorDropdown",
    allowClear: true,
    showSearch: true,
    mode: "multiple",
    onChange: handleChange,
    value: tagIds,
    filterOption,
    dropdownRender: menu => renderSelectDropdown(menu, props["data-cy"]),
  }

  useEffect(() => {
    getTagIds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Select data-cy="Select" {...selectOptions}>
      {options()}
    </Select>
  )
}

export default GroupSelector
