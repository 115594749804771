import React, { useEffect, useState } from "react"
import Select from "react-select"
import useMyContext from "../../store/useMyContext"

export const TeamSelector = props => {
  const context = useMyContext()
  const options = context.Teams.map(g => ({ value: g.id, label: g.name }))
  const { pk, storeKey, record, field } = props

  const [state, setState] = useState({
    value: undefined,
    inputValue: "",
    menuIsOpen: false,
  })

  useEffect(() => {
    const Record = context[storeKey].get(record[pk])
    const v = Record[field]
    const selected = v ? context.teams.get(v) : undefined
    const value = v ? { value: selected.id, label: selected.name } : undefined
    setState({ ...state, value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const selectProps = {
    id: "TeamSelect",
    className: "TeamSelect",
    dropdownClassName: "TeamSelectDropdown",
    placeholder: "Select Team",
    isMulti: false,
    options: options,
    value: state.value,
    inputValue: state.inputValue,
    onInputChange: inputValue => setState({ ...state, inputValue }),
    menuIsOpen: state.menuIsOpen,
    onMenuOpen: () => setState({ ...state, menuIsOpen: true }),
    onMenuClose: () => setState({ ...state, menuIsOpen: false }),
    onChange: (select, action) => {
      // setState({ ...state, value: select })
      props.handleChange({...props, field: props.field, value: select.value, id: props.record.id })
    },
  }

  return <Select {...selectProps} />
}

export default TeamSelector
