import React from "react"
import {store} from "../../store/psrs"
import {Button} from "antd"

const TimeEntrySubNavButtons = ({history, page}) => {
  const nav = (e) => history.push(e.target.value, {basePath: "/timeentries"})
  const role = store.session.get('role')
  const supervised = page === "SupervisedTimeEntries"
  const direct = page === "DirectSupervisedTimeEntries"
  const mine = page === "MyTimeEntries"
  return (
    <Button.Group className="mx-2">
      { role !== "employee" && <Button type={supervised ? "primary" : "default"} onClick={nav} value="/timeentries/supervised">All Supervised</Button> }
      { role !== "employee" && <Button type={direct ? "primary" : "default"} onClick={nav} value="/timeentries/direct">Direct Supervised</Button> }
      { <Button type={mine ? "primary" : "default"} onClick={nav} value="/timeentries/mine">Mine</Button> }
    </Button.Group>
  )


}

export default TimeEntrySubNavButtons
