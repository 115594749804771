import React, { useState, useMemo, useEffect } from "react"
import { useMyContext, useAbilityContext } from "../../store/useMyContext"
import useSWR, { mutate } from "swr"
import uuid from "uuid"
import _ from "lodash"

import { reqOptions, a, Can, REQ, store, session, Emitter } from "../../store/psrs"
import { Keyboard, Box } from "grommet"
import { Button, Input, Tooltip, Popconfirm, notification, Icon, Switch} from "antd"
import TimeEntriesTable from "./TimeEntriesTable"
import LeaveEntriesTable from "../ShiftDrawer/LeaveEntriesTable"
import SickEntriesTable from "../ShiftDrawer/SickEntriesTable"
import { TagSelect, BusinessUnitSelect, DurationField, ReasonSelect } from "../Forms"
import {  EquipmentSelect, AssignmentSelect, SubLedgerSelect } from "../Forms"
import { PayTypeSelect } from "../Forms"
import { Drawer } from "../Drawer"

//import  moment from "moment-timezone"


//const jsonShift = require('../../__test__/shiftTemplates/shift.json')
//const jsonEntry = require('../../__test__/shiftTemplates/timeEntry.json')


///--do we even need this?  let shiftUpdates = []
//let jsonShiftUpdates = {} //require('../../__test__/shiftTemplates/shift.json')
//let jsonEntryUpdates = require('../../__test__/shiftTemplates/timeEntry.json')

//jsonShiftUpdates['timeEntries'] = [] //jsonEntryUpdates

const ButtonGroup = Button.Group

export const TemplateDrawer = props => {

  //console.log('TemplateDrawer')

  const { listUrl, shiftJson, setNewTemplate, dataRec } = props
  //fix up time entires
  //jsonShiftUpdates.timeEntries.forEach(function(e){ e.shiftId = jsonShiftUpdates.id })

  // pull data into json

  const categoryColor = dataRec ? dataRec.categoryColor : 'aabbcc'
  
  if (shiftJson && shiftJson.shiftJson) { 
    shiftJson.shiftJson.name = shiftJson.name
    shiftJson.shiftJson.description = shiftJson.description
    shiftJson.shiftJson.isActive = shiftJson.isActive
    shiftJson.shiftJson.canEdit = shiftJson.canEdit
    shiftJson.shiftJson.isOT = shiftJson.isOT
  }
  
  //var originalShift= null
  let requestApproval = false

  const context = useMyContext()
  const ability = useAbilityContext()
  const { templateDrawerId } = context
  const id = context.templateDrawerId

  const empNum = session.get("CurrentUser").number
  const url = `${store.get("api")}/api/shiftTemplates/${templateDrawerId}`

  const [state, setState] = useState({ loading: true, currentUserNumber: empNum, buttonsLocked: false })

  const [priorPayPeriod, setPriorPayPeriod] = useState(false)

  // eslint-disable-next-line
  const [notesRequired, setNotesRequired] = useState(false)
  //const [jsonShiftUpdates, setJsonShiftUpdates] = useState( shiftJson.shiftJson ) 
  const [jsonShiftUpdates, setJsonShiftUpdates] = useState( shiftJson && shiftJson.shiftJson ? shiftJson.shiftJson : null ) 

  const fetcher = url => {
    const options = { ...reqOptions }
    return fetch(url, options)
      .then(res => res.json())
      .catch(err => Emitter.emit("TemplateDrawer", { fn: "fetcher", error: err }))
  }

  const { data, error } = useSWR(url, fetcher, { refreshInterval: 0 })

  //const data = jsonShift
  //const error = undefined
  
  const templateData = data && data.data && data.data.data ? data.data.data : null

  const drawerProps = useMemo(() => {
    const opts = {
      visible: "visible",
      open: true,
      className: "ShiftDrawer",
      closable: true,
      mask: true,
      maskClosable: true,
      title: "Template Shift Drawer",
      width: 800,
      height: 400,
      zIndex: 4,
      placement: "right",
      destroyOnClose: false,
      onClose: e => {
        context.setTemplateDrawerId(0)
        if (setNewTemplate) setNewTemplate(null)
      },
    }
    return opts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const autoMutate = () => {
    mutate(window.location.href, { ...data })
  }

  /* 02/18/22 useEffect(() => {
    //console.log("DATA CHANGED", data)
    if (!!templateData) {
      //setJsonShiftUpdates(templateData.shiftJson)

      // Emitter.emit('ShiftDrawerUpdate')
      const Reasons = context.getRecords("Reasons")
      const { superNumber1, superNumber2, superNumber3 } = data
      const approvers = [superNumber1, superNumber2, superNumber3]
 
    
      // make notes required based on different criteria
      setNotesRequired(false)
      //if (data.isOT || data.isSick || data.requestedOff) setNotesRequired(true)
      if (templateData.isOT || templateData.isSick ) setNotesRequired(true)
      //if (data.ftoHours > 0 && !data.ftoHoursIncluded) setNotesRequired(true)
      //if (data.diveHours > 0 && !data.diveHours) setNotesRequired(true)
      if (templateData.name === 'NEW SHIFT BUTTON') setNotesRequired(true)
      if (templateData && templateData.timeEntries) {

        templateData.timeEntries.forEach((entry) => {

          // if time entries are OT, Leave, Sick, etc... 
          const _catFilters = ['OVERTIME','LEAVE', 'SICK','VACATION' ]
          const _payTypes = store.get("PayTypes")
          const _payType= _payTypes.find(el => el.code === entry.payTypeCode)  
          let _category = 'NONE'
          if (_payType) _category = _payType.category  
          if (_catFilters.includes(_category) && !data.requestedOff) {
              setNotesRequired(true)
          }
        })
      }


      const _state = {
        loading: false,
        approvers,
        canApprove: store.session.get("role") !== "employee",
        employee: false,
        reasons: Reasons,
        rejectionReasons: Reasons.filter(reason => reason.category && reason.category.toLowerCase() === "rejected"),
        updated: Date.now(),
      }

     
      setState({ ...state, ..._state })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateData]) */

  useEffect(() => {
    if (!templateDrawerId) mutate(url, null)
    if (!!templateDrawerId && data) mutate(url, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateDrawerId, context.templateDrawerId])
  
  
  if (error) return <Drawer {...drawerProps} title="error" />
  if (!templateData) return <Drawer {...drawerProps} title="loading" />

  if (!jsonShiftUpdates) {
    // close the drawer if open.  if user refreshes, may not have template data
    context.setTemplateDrawerId(0)
    return <Drawer {...drawerProps} title="loading" />
  }

  //if (templateData) {
   if (jsonShiftUpdates) {
    const Shift = jsonShiftUpdates // data

    const title = templateData ? `Shift Template: ${templateData.name}` : "Loading"
    //const employeeNumber = session.get("CurrentUser").number
  
    const updateShift = async ({ field, value }) => {
		

      const update = field ? {} : value
      if (field) {
        update[field] = value
      }
      //---do we need this?  shiftUpdates.push(update)

      //jsonShiftUpdates = {...jsonShiftUpdates, ...update}

      //handle the unpaid lunch stuff.....
      if (field === 'paidLunch' && value === false ) addEntry(field)
      if (field === 'paidLunch' && value === true ) removeEntry(field)

      // if click ot add default ot time entry
      if (field === 'isOT' && value === true) {
        jsonShiftUpdates.timeEntries.push({
          id: uuid(),
          shiftId: jsonShiftUpdates.id,
          duration: 0,
          payTypeCode: '200',
          businessUnitCode: jsonShiftUpdates.businessUnitCode,
          subLedgerCode: jsonShiftUpdates.subLedgerCode,
          assignmentId: jsonShiftUpdates.assignmentId,
          })
      } 
      if (field === 'isOT' && value === false) {
         // reset isOT isSick or requestedOff shift flags
         for (let e of jsonShiftUpdates.timeEntries) {
          const payType = store.get('PayTypes').find(p => p.code === e.payTypeCode)
          if (payType.category === 'OVERTIME') {
            jsonShiftUpdates.timeEntries = _.reject(jsonShiftUpdates.timeEntries, function(el) { return el.id === e.id })
          } 
        }
      } 

      //const updated = await REQ(`/api/shifts/${Shift.id}`, "PATCH", update)
     
      //if (updated.isOT || updated.isSick || update.requestedOff) {
     //   requestApproval = true
     // } else {
        requestApproval = false

        setJsonShiftUpdates({...jsonShiftUpdates, ...update})

        //update fields in Time Entries as well
        jsonShiftUpdates.timeEntries.forEach(function(e){if (e.shiftId === jsonShiftUpdates.id) e[field] = value })



        setState({...state})

     // }
     // mutate(url, updated)

    }

    const addEntry = async (field = null) => {
      // const updatedShift = await REQ(`/api/time_entries/create`, "POST", Shift).catch(err =>
      //   Emitter.emit("TimeEntriesTable.js", { fn: "addEntry", error: err }),
      // )
     
       // json needs to be an array.... 
       const timeEntries = jsonShiftUpdates.timeEntries
       timeEntries.push({
          id: uuid(),
          shiftId: jsonShiftUpdates.id,
          duration: field==='paidLunch'?  0.5 : 0,
          payTypeCode: field === 'paidLunch' ? '380' : '1',
          businessUnitCode: jsonShiftUpdates.businessUnitCode,
          subLedgerCode: jsonShiftUpdates.subLedgerCode,
          assignmentId: jsonShiftUpdates.assignmentId,
          })
        
        jsonShiftUpdates.timeEntries=timeEntries
        setState({...state})

        
       //mutate(url)
     }
   
    const updateEntry = async ({ field, value, id, payTypeCategory }) => {
      const update = field ? {} : value
      if (field) update[field] = value
      //console.log("updated timeEntry", update)
     // const updatedShift = await REQ(`/api/time_entries/${id}`, "PATCH", update).catch(err =>
     //   Emitter.emit("TimeEntriesTable.js", { fn: "updateEntry", error: err }),
     // )
  
      //timeEntryJSON = {...timeEntryJSON, ...update}
        if (field === 'payTypeCode') {
          const payType = store.get('PayTypes').find(p => p.code === value)

          if (payType && payType.category) {
            jsonShiftUpdates.isOT = payType.category.toUpperCase() === 'OVERTIME' ? true : false
            jsonShiftUpdates.isSick = payType.category.toUpperCase() === 'SICK' ? true : false
            jsonShiftUpdates.requestedOff = payType.category.toUpperCase() === 'LEAVE' ? true : false

            jsonShiftUpdates.isDive = payType.rename === '' ? payType.name.toUpperCase().includes('DIVE') ? true 
               : payType.rename.toUpperCase().includes('DIVE') ? true : false 
               : payType.rename.toUpperCase().includes('DIVE') ? true : false 

               jsonShiftUpdates.isFieldTraining = payType.rename === '' ? payType.name.toUpperCase().includes('FTO') ? true 
               : payType.rename.toUpperCase().includes('FTO') ? true : false 
               : payType.rename.toUpperCase().includes('FTO') ? true : false 
          }
          
        } 

        if (field === 'payTypeCode' && payTypeCategory !== 'alternate') {
          jsonShiftUpdates.payTypeCode = value
        }
        if (field === 'payTypeCode' && payTypeCategory === 'alternate') {
          jsonShiftUpdates.payTypeCodeAlt = value
        }

        if (field === 'duration') {
          jsonShiftUpdates.duration = value
          jsonShiftUpdates.durationOg = value
          jsonShiftUpdates.durationOt = value
        }
        

        //const entry = jsonShiftUpdates.timeEntries.find(a => a.id === id)
        //const _entry = {...entry, ...update}
  
        jsonShiftUpdates.timeEntries.forEach(function(e){if (e.id === id) e[field] = value })
  

        setState({...state})
    
      //console.log("updated timeEntry", updatedShift)
      //mutate(url, updatedShift)
    }

    const removeEntry = async (id ) => {
      //  const updatedShift = await REQ(`/api/time_entries/${id}`, "DELETE").catch(err =>
      //    Emitter.emit("TimeEntriesTable.js", { fn: "removeEntry", error: err }),
      //  )
    
        //jsonShiftUpdates.timeEntries.forEach(function(e){if (e.id === id) e[field] = value })

        if (id === 'paidLunch') {
          jsonShiftUpdates.timeEntries = _.reject(jsonShiftUpdates.timeEntries, function(el) { return el.payTypeCode === '380' })

        } else {
          jsonShiftUpdates.timeEntries = _.reject(jsonShiftUpdates.timeEntries, function(el) { return el.id === id })

        }


        // reset isOT isSick or requestedOff shift flags
        for (let e of jsonShiftUpdates.timeEntries) {
          const payType = store.get('PayTypes').find(p => p.code === e.payTypeCode)

          jsonShiftUpdates.isOT = payType.category.toUpperCase() === 'OVERTIME' ? true : false
          jsonShiftUpdates.isSick = payType.category.toUpperCase() === 'SICK' ? true : false
          jsonShiftUpdates.requestedOff = payType.category.toUpperCase() === 'LEAVE' ? true : false

        }

        setState({...state})
      // remvoe from array of entries.... 
    
        //setShiftLocked(true)
        //if (shiftLocked) setShowMore(false)
    
       // mutate(url, updatedShift)
      }

    const confirmChangesClose = async () => {
      await confirmChanges()
      context.setTemplateDrawerId(0)
    }
    const confirmChanges = async () => {
      /* let params = {}
      for (let update of shiftUpdates ) {
        Object.assign(params, update);
      } */
      requestApproval = false
      
      //const updateNotification = await REQ(`/api/notifications/${Shift.id}`, "POST")
      //const updateNotification = await REQ(`/api/notifications`, "POST", sendNotification)
      //console.log('updateNotification -> ', updateNotification)

    
      let updatedTemplate = shiftJson
      

      if (!updatedTemplate) {

        console.log(`shiftJson undefined: ${Shift.name} - ${context.templateDrawerId} Error`)
        // try to re-fetch and get template shiftJson
        const templateShift = await REQ(`/api/shiftTemplates/${context.templateDrawerId}`, "GET")
        if (templateShift) {
          updatedTemplate = templateShift.data.data.shiftJson
        }
      }

      if (jsonShiftUpdates.category === undefined || 
          jsonShiftUpdates.category === null || 
          jsonShiftUpdates.category === '' ) {
        jsonShiftUpdates.category = '_'
      }

      if (updatedTemplate) {

        if (updatedTemplate.shiftJson.category === undefined || 
            updatedTemplate.shiftJson.category === null || 
            updatedTemplate.shiftJson.category === '' ) {
          updatedTemplate.shiftJson.category = '_'
        }

        updatedTemplate.name = jsonShiftUpdates.name
        updatedTemplate.description = jsonShiftUpdates.description
        updatedTemplate.isOT = jsonShiftUpdates.isOT
        updatedTemplate.isActive = jsonShiftUpdates.isActive
        updatedTemplate.canEdit = jsonShiftUpdates.canEdit

        updatedTemplate.businessUnitCode = jsonShiftUpdates.businessUnitCode
        
        updatedTemplate.shiftJson = jsonShiftUpdates

        updatedTemplate.payTypeCode = jsonShiftUpdates.timeEntries.find(p => p.payTypeCode !== '380' && p.payTypeCategory === 'primary').payTypeCode
        updatedTemplate.shiftJson.payTypeCode = jsonShiftUpdates.timeEntries.find(p => p.payTypeCode !== '380' && p.payTypeCategory === 'primary').payTypeCode
        updatedTemplate.category = updatedTemplate.shiftJson.category

        try {
          updatedTemplate.payTypeCodeAlt = jsonShiftUpdates.timeEntries.find(p => p.payTypeCodeAlt !== '380' && p.payTypeCategory === 'alternate').payTypeCode
          updatedTemplate.shiftJson.payTypeCodeAlt = jsonShiftUpdates.timeEntries.find(p => p.payTypeCodeAlt !== '380' && p.payTypeCategory === 'alternate').payTypeCode

        } catch (e) {
          updatedTemplate.payTypeCodeAlt = null
          updatedTemplate.shiftJson.payTypeCodeAlt = null

        }

        const updateTemplateShift = await REQ(`/api/shifttemplate/update/${context.templateDrawerId}`, "PUT", updatedTemplate)
        if (listUrl) mutate(listUrl)

        const statusCode = updateTemplateShift.statusCode

        notification.open({
          message: statusCode === 200 ? "Changes Saved" : "Error Saving",
          description: statusCode === 200 ?  `Template Shift: ${Shift.name} Saved` : `Template Shift: ${updateTemplateShift.data.status} Error` ,
          placement: "topRight",
          duration: 3,
        })
      } else {
        notification.open({
          message: "Error Saving Shift Template",
          description: `Template Shift: ${Shift.name} - ${context.templateDrawerId} Error NOT Saved` ,
          placement: "topRight",
          duration: 3,
        })
      }
    }
    
    const confirmDelete = async x => {
      const DeletedShift = await REQ(`/api/shifttemplate/softDelete/${templateDrawerId}`, "PUT")
      console.log("DeletedShift", DeletedShift)
      context.setTemplateDrawerId(0)
    }
    const cancelDelete = x => {
      console.log("cancelDelete", x)
    }
    
    const selectProps = {
      pk: "id",
      record: Shift,
      storeKey: "shifts",
      handleChange: updateShift,
      id: Shift ? Shift.id : uuid(),
    }

    const boxProps = {
      // align: 'center',
      // alignContent: 'stretch',
      // basis: "auto",
      className: "Box",
      direction: "row",
      // fill: false,
      // flex: 'grow',
      gap: "1.5rem",
      // justify: 'stretch',
      pad: "small",
    }

    // const canEditShift = ability.can("edit", a("Shift", Shift))
    const cantEditShift = ability.cannot("edit", a("Shift", Shift))
    //const cantEditEmployee = !ability.can("edit:shift:Employee")
    //const cantEditSupervisor = ability.cannot("edit:shift:Supervisor")
    const cantEditGroups = ability.cannot("edit:shift:Groups")
    const cantEditAssignment = ability.cannot("edit:shift:Assignment")
    const cantEditBusinessUnit = ability.cannot("edit:shift:BusinessUnit")
    const cantEditSubLedger = ability.cannot("edit:shift:SubLedger")
    //const cantEditLunch = ability.cannot("edit:shift:paidLunch")

    /*
    let evidenceShift = Shift.payTypeCode === '250' || Shift.payTypeCode === '251' ? true : false
    if ('timeEntries' in Shift) {
      for (let t of Shift.timeEntries) {
        evidenceShift = t.payTypeCode === '250' || t.payTypeCode === '251' ? true : false
      }
    }
    */

  
    return (
      <Drawer {...drawerProps} title={title} >
        <Keyboard
          target="document"
          onEsc={() => {
            //console.log("ESCAPE")
            context.setTemplateDrawerId(0)
          }}
          onClose={() => {
            //console.log("CLOSE")
            context.setTemplateDrawerId(0)
          }}
        >
          {templateData && (
            <div className="ShiftDrawerForm">
              <Box {...boxProps}>
              <div className="flex1">
                  <h4>Shift Template Name</h4>
                  <Input
                    defaultValue={Shift.name}
                    placeholder="required"
                    onBlur={e => updateShift({ field: "name", value: e.target.value })}
                    disabled={false}
                  />
                </div>
                <div className="flex1">
                  <h4>Shift Template Category</h4>
                    <div>
                      <Input
                      style={{width: '90%'}}
                      defaultValue={Shift.category}
                      placeholder="required"
                      onBlur={e => updateShift({ field: "category", value: e.target.value })}
                      disabled={false}
                    />
                    <div className="box-shift-drawer" style={{backgroundColor: categoryColor, overflowX: 'scroll !important'}} />
                  </div>
                </div>
                <div>
                  <h4>Active</h4>
                  <Switch
                    disabled={false}
                    checked={Shift.isActive}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "isActive", value: e })
                    }}
                  />
                </div>
                <div>
                  <h4>Allow User Edits</h4>
                  <Switch
                    disabled={false}
                    checked={Shift.canEdit}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "canEdit", value: e })
                    }}
                  />
                </div>
                {/*
                <div className="employeeSelect flex1">
                  <h4>Employee</h4>
                  <EmployeeSelect employee={Shift.employee} field="employeeNumber" {...selectProps} disabled={cantEditEmployee} />
                </div>
                <div className="supervisorSelect flex1">
                  <h4>Supervisor</h4>
                  <SupervisorSelect
                    supervisor={Shift.supervisor1}
                    field="superNumber1"
                    {...selectProps}
                    disabled={cantEditSupervisor}
                  />
                </div>
                */}
                <div className="flex1">
                  <h4>Operational Assignment</h4>
                  <Input
                    defaultValue={Shift.operationalAssignment}
                    placeholder="optional"
                    onBlur={e => updateShift({ field: "operationalAssignment", value: e.target.value })}
                    //disabled={false}
                    disabled={false}
                  />
                </div>
              </Box>
              <Box {...boxProps}>
                <div className="flex1">
                  <h4>Primary Pay Type Code</h4>
                  <PayTypeSelect disabled={true} value={Shift.payTypeCode} {...selectProps} id={Shift.id} />
                </div>
                <div className="flex1">
                  <h4>Alternate Pay Type Code</h4>
                  <PayTypeSelect disabled={true} value={Shift.payTypeCodeAlt} field='payTypeCodeAlt' {...selectProps} id={Shift.id} />
                </div>

              </Box>
              <Box {...boxProps}>
                <div className="tagsSelect flex1">
                  <h4>Groups</h4>
                  <TagSelect {...selectProps} value={jsonShiftUpdates.tagIds} disabled={cantEditGroups } />
                </div>
                <div className="equipmentSelect flex1">
                  <h4>Equipment</h4>
                  <EquipmentSelect {...selectProps} value={jsonShiftUpdates.gizmoIds} disabled={cantEditShift  } />
                </div>
                <div className="assignmentSelect flex1">
                  <h4>Acting Role</h4>
                  <AssignmentSelect field="assignmentId" {...selectProps} disabled={ cantEditAssignment } />
                </div>
              </Box>
              <Box {...boxProps}>
                <div className="durationField flex1">
                  <h4>duration</h4>
                  <DurationField
                    field="duration"
                    value={Shift.duration ? Shift.duration : undefined}
                    handleChange={updateShift}
                    disabled={false}
                  />
                </div>
                <div className="lunchField flex1">
                  <h4>Unpaid Lunch</h4>
                  <Switch
                    disabled={false }
                    checked={!Shift.paidLunch}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "paidLunch", value: !e })
                    }}
                  />
                </div>
                <div className="lunchField flex1">
                  <h4>FTO</h4>
                  <Switch
                    disabled={false }
                    checked={Shift.isFieldTraining}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "isFieldTraining", value: e })
                    }}
                  />
                </div>
                <div className="lunchField flex1">
                  <h4>Dive</h4>
                  <Switch
                    disabled={false }
                    checked={Shift.isDive}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "isDive", value: e })
                    }}
                  />
                </div>
                <div className="lunchField flex1">
                  <h4>Overtime Shift</h4>
                  <Switch
                    //style={{background: Shift.isOT?'green':''}}
                    disabled={false}
                    checked={Shift.isOT}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "isOT", value: e })
                    }}
                  />
                </div>
              </Box>
              <Box {...boxProps}>
                {/* REASONS */}
                {/* <Can I="see:shift:reasons" this={Shift}> */}
                <div className="flex1">
                  <h4>{Shift.isOT && !Shift.scheduledOT ? <b>Reason*</b> : 'Reason'}</h4>
                  <ReasonSelect  value={Shift.reasonId} field="reasonId" {...selectProps} disabled={false} />
                </div>
                {/* </Can> */}

                {/* BUSINESS UNIT */}
                <div className="businessUnitSelect flex1">
                  <h4>Business Unit Override</h4>
                  <BusinessUnitSelect field="costCenterChargeOutCode" {...selectProps} disabled={cantEditBusinessUnit } />
                </div>

                {/* SUBLEDGER */}
                <div className="subLedgerSelect flex1">
                  <h4>Sub Ledger</h4>
                  <SubLedgerSelect field="subLedgerCode" {...selectProps} disabled={cantEditSubLedger } />
                </div>
              </Box>
              <Box {...boxProps}>
                {/* NOTES */}
                <div className="flex1">
                  <h4>{notesRequired ? <b>Notes*</b> : 'Notes'}</h4>
                  <Input
                    defaultValue={Shift.notes}
                    placeholder={notesRequired ? "Required notes" : "Optional notes"}
                    onBlur={e => updateShift({ field: "notes", value: e.target.value })}
                    disabled={cantEditShift }
                  />
                </div>
              </Box>
              <section className="timeEntries">

                {/* TIME ENTRIES TABLE */}
                <Can I="see:shift:timeEntries" this={Shift}>
                    <TimeEntriesTable 
                      timeEntries={Shift.timeEntries ||  []} 
                      shift={Shift} mutate={autoMutate} 
                      url={url} 
                      changeDetected={undefined} 
                      requestApproval={requestApproval}
                      shiftLocked={undefined}
                      setShiftLocked={undefined}
                      //setButtonsLocked = {setButtonsLocked}
                      changeDetected2={undefined}
                      setChangeDetected2={undefined}
                      priorPayPeriod={priorPayPeriod}
                      setPriorPayPeriod={setPriorPayPeriod}
                      timeEntryLocked={undefined}
                      setTimeEntryLocked={undefined}
                      updateEntry={updateEntry}
                      addEntry={addEntry}
                      removeEntry={removeEntry}

                    /> 
                </Can>

                {/* SICK ENTRIES TABLE */}
                <Can I="see:shift:sickEntries" this={Shift}>
                  <SickEntriesTable 
                     shift={Shift} 
                     mutate={autoMutate} 
                     url={url}
                     shiftLocked={undefined}
                     setShiftLocked={undefined}
                     //setButtonsLocked = {setButtonsLocked}
                  />
                </Can>

                {/* LEAVE ENTRIES TABLE */}
                <Can I="see:shift:leaveEntries" this={Shift}>
                  <LeaveEntriesTable 
                     shift={Shift} 
                     mutate={autoMutate} 
                     url={url} 
                     shiftLocked={undefined}
                     setShiftLocked={undefined}
                     //setButtonsLocked = {setButtonsLocked}
                  />
                </Can>

              </section>
              <section className="shiftActions">
                {!Shift.available && (
                  <ButtonGroup>
                    <div className="FlexBlock" >
                    {/*SAVE AND CLOSE */}
                    <Tooltip title={'Save Shift Template and Close'}>
                        <Popconfirm
                          disabled={false}
                          title='Save Shift Template and Close'
                          onConfirm={confirmChangesClose}
                          okText={'Save Shift Template and Close'}
                          cancelText="Cancel"
                        >
                          <Button
                            disabled={false}
                            icon='close'
                            size="large"
                            //style={state.changeDetected  ? { background: "", borderColor: "orange" } :{ background: "", borderColor: "" } }
                          />
                        </Popconfirm>
                      </Tooltip>
                    {/*SAVE*/}
                    <Tooltip title={'Save Shift Template'}>
                        <Popconfirm
                          disabled={false}
                          title='Save Shift Template'
                          onConfirm={confirmChanges}
                          okText={'Save Shift Template'}
                          cancelText="Cancel"
                        >
                          <Button
                            disabled={false}
                            icon='save'
                            size="large"
                            //style={state.changeDetected  ? { background: "", borderColor: "orange" } :{ background: "", borderColor: "" } }
                          />
                        </Popconfirm>
                      </Tooltip>
                      {/*DELETE*/}
                      <Tooltip title="Delete Shift Template">
                        <Popconfirm
                          title="Are you sure you want to delete this Shift Template?"
                          onConfirm={confirmDelete}
                          onCancel={cancelDelete}
                          okText="Yes"
                          cancelText="No"
                          disabled={false}
                        >
                          <Button 
                          type="default" size="large" icon="delete" disabled={false}/>
                        </Popconfirm>
                      </Tooltip>
                      </div>
                  </ButtonGroup>
                )}
                {!!Shift.originalShiftId && (
                  <Button
                    disabled={false}
                    className="link"
                    type="primary"
                    ghost={true}
                    onClick={() => context.setTemplateDrawerId(Shift.originalShiftId)}
                  >
                    Original Shift
                  </Button>
                )}
                {!!Shift.sickShiftId && (
                  <Button
                    disabled={false}
                    className="link"
                    type="primary"
                    ghost={true}
                    onClick={() => {
                      context.setTemplateDrawerId(Shift.sickShiftId)
                    }}
                  >
                    Sick Shift
                  </Button>
                )}
              </section>
            </div>
            
          )}
        </Keyboard>
      </Drawer>
    )
  }
}

export default TemplateDrawer
