import React from "react"
import useMyContext from "../../store/useMyContext"
import { Button, List } from "antd"

export const BidsTable = props => {
  const { shift, handleUpdate } = props
  const { biddersInCollision } = shift
  const context = useMyContext()
  const employeeNumbers = shift.bids || []
  const employees = employeeNumbers.map(e => {
    const employee = context.getRecord("Employees", e, "number")
    return employee
  })

  const bids = employees.map(employee => {
    const name = employee ? employee.fullName : "NAME"
    const collision = biddersInCollision.includes(employee.number)
    const label = collision ? `${name} (collision)` : name
    const buttonProps = {
      type: collision ? "danger" : "default",
      style: { margin: "0 1rem" },
      onClick: () => handleUpdate({ field: "employeeNumber", value: employee.number }),
    }
    return (
      <List.Item key={employee.number} className={collision ? "collision" : ""}>
        <Button {...buttonProps}> {label} </Button>
      </List.Item>
    )
  })
  return <List header={<h3 style={{padding: '10px'}}>  Bids for this Shift</h3>}>{bids}</List>
}

export default BidsTable
