import React, { useEffect, useState, useRef, useMemo } from "react"
import { REQ, reqOptions, Emitter, store, a, Can  } from "../store/psrs" 
//import { useHistory } from "react-router-dom"
import { useMyContext, useAbilityContext } from "../store/useMyContext"
import useSWR from "swr"
import ReactTable from "react-table"

import checkboxHOC from "react-table/lib/hoc/selectTable"

import { useStateIfMounted } from "use-state-if-mounted"

import { shiftColumns } from "./ApprovalsV2/shiftColumns"
import { timeEntryColumns } from "./ApprovalsV2/timeEntryColumns"
import { ApprovalFinePrint } from "./FinePrint"
//import { Button } from "antd"
import {Layout, Button, Popconfirm, Tooltip, Badge } from "antd"
//import { ShiftDrawer } from "./ShiftDrawer"
import { NewShiftDrawer } from "./NewShiftDrawer"
import _ from "lodash"
import moment from "moment-timezone"
//import { TimeEntryFields } from "./RecordsViewer/Model"
import { Search } from "./Admin/fields"
import { FilterToggle } from "./Forms"

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';

import "react-table/react-table.css";

const { Content, Sider } = Layout

const CheckboxTable = checkboxHOC(ReactTable);

export const ApprovalsV2 = props => {
  // console.log("Approvals", props)
  const { /*url,*/ page } = props
  //const history = useHistory()
  const context = useMyContext()
  const ability = useAbilityContext()

  const payPeriod = store.get("PayPeriod")
  const payPeriodId = payPeriod.id
  const me = store.session.get("CurrentUser")
  
  const query = props.query(payPeriodId)

  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState({})
  const [expandedAll, setExpandedAll] = useState(false)
  const [allDisabled, setAllDisabled] = useState(true)
  const [siderCollapsed, setSiderCollapsed] = useState(false)

  const { newShiftDrawer } = context

  const [state, setState] = useState({
    selection: [],
    timeEntrySelected: [],
    shiftSelected: [],
    selectAll: false, 
    pk: "id",
    model: "shift",
    ids: [],
    initialRecords: undefined,
  })

  const [records, setRecords] = useState(state.initialRecords || [])
  //const [showApproveReject, setShowApproveReject] = useState({show: false})
  const [showApprove, setShowApprove] = useState({show: false})
  const [showReject, setShowReject] = useState({show: false})
  const [filterSubmit, setFilterSubmit] = useState({show: true})
  const [filterNeedsApproved, setFilterNeedsApproved] = useState({show: true})
  const [filterRegular, setFilterRegular] = useState({show: true})
  const [filterOT, setFilterOT] = useState({show: true})
  const [filterSick, setFilterSick] = useState({show: true})
  const [filterLeave, setFilterLeave] = useState({show: true})
  const [filterS1, setFilterS1] = useState({on: true, sql: `  "superNumber1" = '${me.number}'`})
  const [filterS2, setFilterS2] = useState({on: false, sql: ` "superNumber2" = '${me.number}'`})
  const [filterS3, setFilterS3] = useState({on: false, sql: ` "superNumber3" = '${me.number}'`})
  const [showFuture, setShowFuture] = useState({ show: false })

  // eslint-disable-next-line
  const [selectedSupervisor, setSelectedSupervisor] = useStateIfMounted({number: me.number})

  //const [filterSQL, setFilterSQL] = useState(`${baseSQL} AND (${filterS1.sql})`)

  //const baseSQL = `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${payPeriodId}' AND ("needsApproved" = true OR "approved" is true OR "rejected" is true) AND "employeeNumber" is not null `;
  //const baseSQL = `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${payPeriodId}' AND "employeeNumber" is not null `;

  //let shiftIds = []
  //let timeEntryData = []

  const buildSupervisorSQL = async (props) => {

    const { filterS1, filterS2, filterS3, filterSubmit, filterNeedsApproved, showApprove, showReject, showFuture} = props

    const payPeriod = store.get("PayPeriod")
    const payPeriodId = payPeriod.id
    //const userId = session.get("CurrentUser").number
    const ppTodayStart = moment(store.get("PayPeriodToday").start).format("YYYY-MM-DD")
    let baseSQL = `SELECT id FROM "shifts" WHERE "deletedAt" is null AND "payPeriodId"='${payPeriodId}' AND "employeeNumber" is not null `;
    let sqlFilters = '';


    if (showFuture) {
      baseSQL = `SELECT s.id FROM "shifts" s WHERE s."deletedAt" IS NULL AND s."locked" IS NOT true AND s."approved" IS ${showApprove} AND (s."employeeNumber" IS NOT NULL AND s."employeeNumber" != '${me.number}') AND s."start"::DATE >= '${ppTodayStart}'`;
    }

    if (filterS1.on && !filterS2.on && !filterS3.on) {
      sqlFilters += `AND (${filterS1.sql}`;
    }

    if (filterS1.on && filterS2.on && !filterS3.on) {
      sqlFilters += ` AND (${filterS1.sql} OR ${filterS2.sql}`;
    }

    if (filterS1.on && !filterS2.on && filterS3.on) {
      sqlFilters += ` AND (${filterS1.sql} OR ${filterS3.sql}`;
    }

    if (filterS1.on && filterS2.on && filterS3.on) {
      sqlFilters += ` AND (${filterS1.sql} OR ${filterS2.sql} OR ${filterS3.sql}`;
    }

    if (!filterS1.on && !filterS3.on && filterS2.on) {
      sqlFilters += `AND (${filterS2.sql}`;
    }

    if (!filterS1.on && !filterS2.on && filterS3.on) {
      sqlFilters += `AND (${filterS3.sql}`;
    }

    sqlFilters = sqlFilters !== '' ? sqlFilters + ')' : '';

    // filter on submit
    if (filterSubmit.show) {
      //sqlFilters += ` AND ( "submit" = true OR "needsApproved" = true )`
      sqlFilters += ` AND ( "submit" = true )`
    }

    if (filterNeedsApproved.show) {
      //sqlFilters += ` AND ( "submit" = true OR "needsApproved" = true )`
      sqlFilters += ` AND ( "needsApproved" = true )`
    }

  //  if (!showApproveReject) {
  //     sqlFilters += ` AND ( "approved" = false AND "rejected" = false )`
  //      //--goog--filteredRecs = !showAll ? _.filter(filteredRecs, t_1 => (!t_1.approved && !t_1.rejected)) : filteredRecs
  //  }

  if (showApprove && !showReject) {
    sqlFilters += ` AND ( "approved" = true )`
  }

 if  (showReject && !showApprove ) {
   sqlFilters += ` AND ( "rejected" = true )`
  }

 if ( showReject && showApprove ) {
  sqlFilters += ` AND ( "approved" = true OR "rejected" = true )`
 }

 if ( !showReject && !showApprove) {
  sqlFilters += ` AND ( "approved" = false AND "rejected" = false )`
 }

//  if (selectedSupervisor) {
//   sqlFilters = ` AND ("superNumber1" = '${selectedSupervisor.number}')`
//  }

    return `${baseSQL} ${sqlFilters}`

  }

  const buildPayCategoryFilters = async (props) => {

    const {filterRegular, filterOT, filterSick, filterLeave, filteredRecs} = props
    let updateFilters = filteredRecs

    let regularRecs = []
    let otRecs = []
    let leaveRecs = []
    let sickRecs = []

    if (filterRegular.show) {
      regularRecs = _.filter(updateFilters, fr => (!fr.isSick && !fr.requestedOff && !fr.isOT))
    }

    if (filterOT.show) {
      otRecs = _.filter(updateFilters, fr => (!fr.isSick && !fr.requestedOff && fr.isOT))
    }

    if (filterSick.show) {
      sickRecs = _.filter(updateFilters, fr => (fr.isSick && !fr.requestedOff && !fr.isOT))
    }

    if (filterLeave.show) {
      leaveRecs = _.filter(updateFilters, fr => (!fr.isSick && fr.requestedOff && !fr.isOT))
    }

    updateFilters = [...regularRecs, ...otRecs, ...sickRecs, ...leaveRecs]

    //if (updateFilters.length > 0 ) {
      return updateFilters
    //} else {
    //  return filteredRecs
    //}
    
    // all filters on
    //if (filterRegular.show && filterLeave.show && filterOT.show && filterSick.show) {
    //  return  filteredRecs
   // }

    //return updateFilters

/*
    // just regular
    if (filterRegular.show && !filterLeave.show && !filterOT.show && !filterSick.show) {
      return  _.filter(updateFilters, fr => (!fr.isSick && !fr.requestedOff && !fr.isOT))
    }
    
    // just OT
    if (!filterRegular.show && !filterLeave.show && filterOT.show && !filterSick.show) {
      return  _.filter(updateFilters, fo => (!fo.isSick && !fo.requestedOff && fo.isOT))
    }

    // just leave
    if (!filterRegular && filterLeave.show && !filterOT.show && !filterSick.show) {
      return  _.filter(updateFilters, fl => (!fl.isSick && fl.requestedOff && !fl.isOT))
    }

    // just sick
    if (!filterRegular.show && !filterLeave.show && !filterOT.show && filterSick.show) {
      return  _.filter(updateFilters, fs => (fs.isSick && !fs.requestedOff && !fs.isOT))
    }

*/
/*
    // regular
    updateFilters = filterRegular.show ? _.filter(updateFilters, fr => (!fr.isSick && !fr.requestedOff && !fr.isOT)) : updateFilters
      
    // OT
    updateFilters = filterOT.show ? _.filter(updateFilters, fo => fo.isOT) : updateFilters

    // Sick
    updateFilters = filterSick.show? _.filter(updateFilters, fs => fs.isSick) : updateFilters

    // Leave
    updateFilters = filterLeave.show ? _.filter(updateFilters, fl => fl.requestedOff) : updateFilters
*/
    //return updateFilters
  }
  const  fetcher = async (key, opts) => {

    //const {url, showApproveReject } = props

    const props = JSON.parse(opts)
    
    //const {url, filterS1, filterS2, filterS3, showApproveReject  } = props
    //const {filterRegular, filterSick, filterOT, filterLeave} = props

    //const { showApproveReject } = props

    const newSQL = await buildSupervisorSQL(props)

    const options = {
      ...reqOptions,
      method: "POST",
      //body: JSON.stringify({ query }),
      body:  JSON.stringify({ query: newSQL}),
    }

    //let showAll = showApproveReject
    //let showSubmit = filterSubmit
    //let showAll = key === 'true' ? true : false
    //let showAll = showApproveReject === 'true' ? true : false

    setLoading(true)
    try {
      const res = await fetch(props.url, options)
      setLoading(false)
      const records = await res.json()
      let filteredRecs = _.filter(records, t => t.name !== "Unpaid Lunch")
      
      //--goog--filteredRecs = !showAll ? _.filter(filteredRecs, t_1 => (!t_1.approved && !t_1.rejected)) : filteredRecs
      //filteredRecs = !showApproveReject.show ? _.filter(filteredRecs, t => !t.rejected ) : filteredRecs

      //filteredRecs = !showSubmit ? _.filter(filteredRecs, s => s.submit ) : filteredRecs

      filteredRecs = await buildPayCategoryFilters({...props, filteredRecs: filteredRecs})
/*
      // regular
      filteredRecs = filterRegular.show ? _.filter(filteredRecs, fr => (!fr.isSick && !fr.requestedOff && !fr.isOT)) : filteredRecs
      
      // OT
      filteredRecs = filterOT.show ? _.filter(filteredRecs, fo => fo.isOT) : filteredRecs

      // Sick
      filteredRecs = filterSick.show? _.filter(filteredRecs, fs => fs.isOT) : filteredRecs

      // Leave
      filteredRecs = filterLeave.show ? _.filter(filteredRecs, fl => fl.requestedOff) : filteredRecs


      //rec.isSick ? "SICK" : rec.requestedOff ? "LEAVE" : rec.isOT ? "OVERTIME" : "REGULAR"
*/

      setRecords(state.ids.length ? _.filter(filteredRecs, r => state.ids.includes(r.id)) : filteredRecs)
      return await filteredRecs
    } catch (err) {
      return Emitter.emit("ApprovalsV2/index.js", { fn: "fetcher", error: err })
    }
  }

  const swrOpts = { revalidateOnFocus: false, revalidate: false }
  const swrProps = {
    url: props.url,
    //showApproveReject: showApproveReject.show,
    showApprove: showApprove.show,
    showReject: showReject.show,
    filterS1: filterS1,
    filterS2: filterS2,
    filterS3: filterS3, 
    filterOT: filterOT, 
    filterLeave: filterLeave,
    filterSick: filterSick,
    filterRegular: filterRegular,
    filterSubmit: filterSubmit,
    filterNeedsApproved: filterNeedsApproved,
    showFuture: showFuture.show,
    query: query
  }

  //const { data } = useSWR(props.url, fetcher, swrOpts)
  //const { data, mutate } = useSWR([props.url, JSON.stringify(swrProps)], fetcher, swrOpts)
  const { data: swrData, mutate} = useSWR([`${props.url}V2`, JSON.stringify(swrProps)], fetcher, swrOpts)

  // per react-table, data must be memoized
  const skipPageResetRef = useRef();
  /*
  const dataChecker = () => {
    skipPageResetRef.current = true;
    console.log('useMemo: data changed!', swrData);
    return swrData ? swrData : [];
  }
  */
  //const data = useMemo(() => dataChecker(), [swrData]);

  const data = useMemo(() => {
    skipPageResetRef.current = true;
    console.log('useMemo: data changed!', swrData);
    return swrData ? swrData : [];
  }, [swrData])

  //const { data, mutate } = useSWR(JSON.stringify(swrProps), fetcher, swrOpts)


  //const { data, mutate } = useSWR([query,swrProps], fetcher, swrOpts)

  //const canApproveAll = true //ability.cannot("approve", a("All", me))

  const _props = { state, setState, mutate, url: props.url, width: 300}

  //const canApproveShift = ability.cannot("approve", a("Shift", me))
  //const canApproveTime = ability.cannot("approve", a("TimeEntry", me))

  useEffect(() => {
    Emitter.on("ShiftDrawerUpdate", () => {
      //const pageUrl = `${store.get("api")}${props.path}`
      //mutate(url)
      mutate()
    })
    Emitter.on("ShiftDrawerClose", () => {
      //const pageUrl = `${store.get("api")}${props.path}`
      //mutate(url)
      mutate()
    })
    Emitter.on("ChangedPayPeriod", async () => {
      //window.location.reload();
      //const pageUrl = `${store.get("api")}${props.path}`
      //mutate(url)
      //mutate()
      
     /*  const updateData = await fetcher(`${props.url}V2`, JSON.stringify(swrProps))
      if (updateData && updateData.length > 0) {
        //mutate(props.url, {...swrData, ...updateData})
        refresh()
      } */
     
     
 
      

      /* mutate(props.url, async updates => {

        const query = props.query(payPeriodId)

        const options = {
          ...reqOptions,
          method: "POST",
          body: JSON.stringify({ query }),
          //body:  JSON.stringify({ query: newSQL}),
        }

        const updatedShifts = await fetch(props.url, options)

        //const records = await res.json()
        let filteredRecs = updates.filter(r => r.name !== 'Unpaid Lunch')
        //let filteredRecs = _.filter(updates, t => t.name !== "Unpaid Lunch")
        return [...filteredRecs, ...updatedShifts]
      }, { revalidate: false }) */
      
    }) 

   

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
   
/*   useEffect(() => {
    console.log('state change')
   // mutate()

  },[state, mutate])   */

  useEffect(() => {
    if (state.ids) {
      //const matching = _.filter(data, r => state.ids.includes(r.id))
      setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    } else {
       //mutate()
    }
    
   
  }, [state.ids, data])


  /* useEffect(() => {

    if (showApproveReject) {
      mutate()
    } else {
      mutate()
    }

  }, [showApproveReject]) */

  /* useEffect(() => {

    if (filterS2.on) {
      mutate()
    } else {
      mutate()
    }

  }, [filterS2]) */

  const refresh = () => {
    //const pageUrl = `${store.get("api")}${props.path}`
    mutate() // mutate(props.url)
  }

  const columnProps = { ...props, context, ability, refresh }

  const tableOptions = {
    noDataText: "",
    filterable: true,
    loading: loading, //state.loading,
    data: records, //data,
    defaultPageSize: 100,
    //columns: page === "ShiftApprovalsV2" || page === "MyShiftApprovalsV2" ? shiftColumns(columnProps) : timeEntryColumns(columnProps),
    columns: shiftColumns(columnProps),
    multiSort: true,
    defaultSorted: [
      { id: "supervisor1.fullName", asc: true },
      { id: "employee.fullName", asc: true },
      { id: "start", asc: true },
    ],
  }

  
  const isSelected = key => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    //return state.selection.find(e => e.key === key)
    return state.selection.includes(`select-${key}`) ;
  };


  const toggleSelection = (key, shift, row) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    // start off with the existing state
    
    let selection = [...state.selection];
    let timeEntrySelected = [...state.timeEntrySelected]
    let shiftSelected = [...state.shiftSelected]

    const keyIndex = selection.indexOf(key);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      if (state.selectAll) {
        if (row.employeeNumber !== me.number) {
          selection.push(key)
        }
      } else {
        //const shiftData = records.find( r => r.id === key.slice(7))
        selection.push(key)
        /*
        selection.push({
          key: key,
          approved: shiftData.approved,
          rejected: shiftData.rejected,
          needsApproved: shiftData.needsApproved
        }) 
        */
      }
      
      const disabled = ability.cannot("approve", a("Shift", row))
      setAllDisabled(disabled)

    }

    if (row.modelName === 'Shift') {

      const shiftKeyIndex = shiftSelected.indexOf(key);

      if (shiftKeyIndex >= 0) {
        // it does exist so we will remove it using destructing
        shiftSelected = [
          ...shiftSelected.slice(0, shiftKeyIndex),
          ...shiftSelected.slice(shiftKeyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        shiftSelected.push(row.id);
      }

    } else if (row.modelName === 'TimeEntry') {
      const timeEntryKeyIndex = timeEntrySelected.indexOf(key);
      if (timeEntryKeyIndex >= 0) {
        // it does exist so we will remove it using destructing
        timeEntrySelected = [
          ...timeEntrySelected.slice(0, timeEntryKeyIndex),
          ...timeEntrySelected.slice(timeEntryKeyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        timeEntrySelected.push(row.id);
      }

      const shiftKeyIndex = shiftSelected.indexOf(key);

      if (shiftKeyIndex >= 0) {
        // it does exist so we will remove it using destructing
        shiftSelected = [
          ...shiftSelected.slice(0, shiftKeyIndex),
          ...shiftSelected.slice(shiftKeyIndex + 1)
        ];
      } else {
        // it does not exist so add it
        shiftSelected.push(row.shiftId);
      }
  }
    // update the state
    setState({...state, selection, timeEntrySelected, shiftSelected });
  };

  const toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?
      
      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).
      
      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).
      
      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'. 
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAll = state.selectAll ? false : true;
    const selection = [];
    const timeEntrySelected = []
    const shiftSelected = []

    if (selectAll) {
      // we need to get at the internals of ReactTable
      //-const wrappedInstance = ReactTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      //-const currentRecords = wrappedInstance.getResolvedState().sortedData;
      const currentRecords = data
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (item.employeeNumber !== me.number) {
          selection.push(`select-${item.id}`);
          timeEntrySelected.push(item.id)
          shiftSelected.push(item.id)
          const disabled = ability.cannot("approve", a("Shift", item))
          setAllDisabled(disabled)
        }
      });
    }
    setState({ ...state, selectAll, selection, timeEntrySelected, shiftSelected });
  };

  const checkboxProps = {
    selectAll: state.selectAll,
    isSelected,
    toggleSelection,
    toggleAll,
    selectType: "checkbox",
    getTrProps: (s, r) => {
      // someone asked for an example of a background color change
      // here it is...
      const selected = isSelected(r && r.original ? r.original.id : false);
      //const timeEntrySelected = isSelected(r && r.original ? r.original.id : false);
      //const shiftSelected = isSelected(r && r.original ? r.original.shiftId : false);

      let exp = false
      if (r) {
        if (r.nestingPath[0] in expanded) {
          if (expanded[r.nestingPath[0]] === true) {
            //if (Object.values(expanded).indexOf(true) > -1) {
              exp = true
          }
        }
      }
      
      return {
        'data-qnt':  page === "ShiftApprovalsV2" || page === "MyShiftApprovalsV2"  ? 1 : 0,
          style: {
            //backgroundColor: selected || exp ? "#A7C7E7"  : "inherit",
            backgroundColor: selected || exp ? "AliceBlue" : "inherit"

             //color: selected || exp ? 'white' : 'inherit',  "#1990FF" "#89CFF0"
          }
      }
    } 
  };

  /* const logSelection = () => {
    console.log("selection:", state.selection);
  }; */

  const approveAllSelected = async (props ) => {

    const {model, timeEntrySelected, shiftSelected, approved} = props;

    const shiftIds = shiftSelected
    const timeEntryIds = timeEntrySelected

    //const shiftData = records.find( r => r.id === key.slice(7))

    if (approved.length > 0 ) {
      //un-approve records
      approved.forEach(async r => await REQ(`/api/resources/shift`, "PATCH", { ids: shiftIds, update: { approved: false, rejected: false, isCollision: false, needsApproved: true }}))
    } else {
      if (model === 'shift') {
        // update shifts
        await REQ(`/api/resources/shift`, "PATCH", { ids: shiftIds, update: { approved: true, rejected: false, isCollision: false, needsApproved: false}})

      } else if (model === 'timeEntry') {
        // update both the shift and the time entries
        // update shifts
        await REQ(`/api/resources/shift`, "PATCH", { ids: shiftIds, update: { approved: true, rejected: false  }})

        // update time entriesToDelete
        await REQ(`/api/resources/timeEntry`, "PATCH", { ids:  timeEntryIds, update: { approved: true, rejected: false }})
      }
    }

    setState({...state, selection: [], timeEntrySelected: [], shiftSelected: [], selectAll: false, })
    //refresh()
    mutate(props.url, data)
  }

  const rejectAllSelected = async (props ) => {

    const {model, timeEntrySelected, shiftSelected, rejected } = props;

    const shiftIds = shiftSelected
    const timeEntryIds = timeEntrySelected

    if (rejected.length > 0 ) {
      //un-rejecting records
      rejected.forEach(async r => await REQ(`/api/resources/shift`, "PATCH", { ids: shiftIds, update: { approved: false, rejected: false, isCollision: false, needsApproved: true }}))
    } else {
      if (model === 'shift') {
        // update shifts
        await REQ(`/api/resources/shift`, "PATCH", { ids: shiftIds, update: { approved: false, rejected: true, isCollision: false, needsApproved: false }})

      } else if (model === 'timeEntry') {
        // update both the shift and the time entries
        // update shifts
        await REQ(`/api/resources/shift`, "PATCH", { ids: shiftIds, update: { approved: false, rejected: true  }})

        // update time entriesToDelete
        await REQ(`/api/resources/timeEntry`, "PATCH", { ids:  timeEntryIds, update: { approved: false, rejected: true }})
      }
    }
    refresh()
  }

 /*  const handleShowApproveReject = async (props) => {
    setShowApproveReject((prevState) => ({show: !prevState.show}))
    //mutate()
  } */

  const handleShowApprove = async (props) => {
    setShowApprove((prevState) => ({show: !prevState.show}))
    //mutate()
  }

  const handleShowReject = async (props) => {
    setShowReject((prevState) => ({show: !prevState.show}))
    //mutate()
  }

  const handleFilterSubmit = async (props) => {
    setFilterSubmit((prevState) => ({show: !prevState.show}))
    //mutate()
  }

  const handleFilterNeedsApproved = async (props) => {
    setFilterNeedsApproved((prevState) => ({show: !prevState.show}))
    //mutate()
  }

  const handleFilterRegular = async (props) => {
    setFilterRegular((prevState) => ({show: !prevState.show}))

  }

  const handleFilterOT = async (props) => {
    setFilterOT((prevState) => ({show: !prevState.show}))
  }

  const handleFilterSick = async (props) => {
    setFilterSick((prevState) => ({show: !prevState.show}))
  }

  const handleFilterLeave = async (props) => {
    setFilterLeave((prevState) => ({show: !prevState.show}))
  }
  
  const handleFilterS1 = async (props) => {
    setFilterS1((prevState) => ({...filterS1, on: !prevState.on}))
    //refresh()
  }

  const handleFilterS2 = async (props) => {
    setFilterS2((prevState) => ({...filterS2, on: !prevState.on}))
    //refresh()
  }

  const handleFilterS3 = async (props) => {
    setFilterS3((prevState) => ({...filterS3, on: !prevState.on}))
    //refresh()
  }

  const handleFilterFuture = async (value) => {
    setShowFuture((prevState) => ({show: !prevState.show}))
    setFilterRegular((prevState) => ({show: value ? false : true }))
    setFilterSick((prevState) => ({show: value ? true : false}))
    setFilterOT((prevState) => ({show: value ? true : false }))
    setFilterLeave((prevState) => ({show: value ? true : false}))
    setShowApprove((prevState) => ({show: false }))
    setFilterSubmit((prevState) => ({show: true }))
    setSelectedSupervisor(null)

    //mutate()
  }

  const onCollapse = collapsed => {
    console.log(collapsed);
    setSiderCollapsed(collapsed)
  }
  
  // added layout/content for scrolling on small screens
  return (
    <React.Fragment>
      {/*
      <div className="view_title">
        <h1 data-cy="page-title">{props.title}</h1>
        <div className="actions">
          <Button.Group>
            <Button type={page === "ShiftApprovals" ? "primary" : "default"} onClick={() => history.push("/approvals/shifts")}>
              Shifts
            </Button>
            <Button
              type={page === "TimeEntryApprovals" ? "primary" : "default"}
              onClick={() => history.push("/approvals/timeEntries")}
            >
              Time Entries
            </Button>
          </Button.Group>
        </div>
      </div>
      <div className="view_content"> 
      */}
      <Can I="visit:ApprovalsV2">
        <Layout className="RecordsViewer">
          <div style={{ height: '20vh' }}>
            <Sider 
              style={{backgroundColor:"white"}} 
              width={"100%"}
              trigger={null} 
              collapsible={true} 
              collapsed={siderCollapsed}
              breakpoint="lg"
              collapsedWidth="0"
              onBreakpoint={broken => {
                console.log(broken);
              }}
              onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
                onCollapse(collapsed)
              }}
              //onCollapse={onCollapse}
            >
              <section className="recordsFilter">
                <ul className="unstyledList">
                  <div style={{padding: '15px'}} id="my-tab-content" className="tab-content">
                    <li style={{borderBottom: '1px solid black', width: '100%',display: 'block', textAlign: "center"}}>
                      <div className="inputs">
                        <div className="input">
                          <label style={{fontSize: 22}}><strong>Filters</strong></label>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="inputs">
                        <div className="input">
                          <br />
                          <label style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}><strong>Future Pay Periods</strong></label>
                          <FilterToggle 
                            onChange={handleFilterFuture}
                            checked={showFuture.show}
                            checkOn="#48D1CC"
                            iconType="project"
                            label="Future Shifts"
                          />
                        </div>
                      </div>
                      <br />
                    </li>
                    <li>
                      <div className="inputs">
                        <div className="input">
                          <label style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}><strong>Status</strong></label>
                          <FilterToggle 
                            onChange={handleFilterSubmit}
                            checked={filterSubmit.show}
                            checkOn="#4281A4"
                            iconType="check-circle"
                            label="Submitted"
                          />
                          <br />
                          <FilterToggle 
                            onChange={handleFilterNeedsApproved}
                            checked={filterNeedsApproved.show}
                            checkOn="#B8860B"
                            iconType="alert"
                            label="Needs Approved"
                          />
                          <br />
                          {/* <Switch 
                            size="small" 
                            checked={filterSubmit.show} 
                            onChange={handleFilterSubmit} 
                          /> <Icon 
                                type="check-circle" 
                                style={{color: `${filterSubmit.show ? "#4281A4":""}` }}
                                theme={filterSubmit.show ? "filled" : "outlined"} 
                            />   Submitted  */}
                        </div>
                        <div className="input">
                          <FilterToggle 
                            onChange={handleShowApprove}
                            checked={showApprove.show}
                            checkOn="#4dacff"
                            iconType={"like"}
                            label="Approved"
                          />
                          <br />
                          <FilterToggle 
                            onChange={handleShowReject}
                            checked={showReject.show}
                            checkOn="red"
                            iconType={"dislike"}
                            label="Rejected"
                          />
                          <br />
                        {/*   <Switch
                            size="small" 
                            checked={showApproveReject.show} 
                            onChange={handleShowApproveReject} 
                          />  <Icon 
                                type="like" 
                                style={{color: `${showApproveReject.show ? "#4dacff":""}` }}
                                theme={showApproveReject.show ? "filled" : "outlined"} 
                              /> 
                              <Icon 
                                type="dislike"
                                style={{color: `${showApproveReject.show ? "red":""}` }}
                                theme={showApproveReject.show ? "filled" : "outlined"}  
                              /> Show Approved/Rejected */}
                        </div>
                      </div>
                      <br />
                    </li>
                    <li>
                      <div className="inputs">
                        <label style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}><strong>Supervisors</strong></label>
                            <FilterToggle 
                              onChange={handleFilterS1}
                              checked={filterS1.on}
                              checkOn="#32CD32"
                              iconType="user"
                              label="Supervisor 1"
                            />
                            
                            {/* <Switch 
                              size="small" 
                              checked={filterS1.on} 
                              onChange={handleFilterS1} 
                            />  <Icon 
                                  type="user" 
                                  style={{color: `${filterS1.on ? "#32CD32":"black"}`}}
                                /> Supervisor 1 */}
                          <br />
                            <FilterToggle 
                              onChange={handleFilterS2}
                              checked={filterS2.on}
                              checkOn="#DAA520"
                              iconType="team"
                              label="Supervisor 2"
                            />
                          {/*  <Switch 
                              size="small" 
                              checked={filterS2.on} 
                              onChange={handleFilterS2} 
                            /> <Icon 
                                  fill="blue"
                                  type="team" 
                                  style={{color: `${filterS2.on ? "#DAA520":"black"}` }}
                                /> Supervisor 2 */}
                          <br />
                            <FilterToggle 
                              onChange={handleFilterS3}
                              checked={filterS3.on}
                              checkOn="#00BFFF"
                              iconType="team"
                              iconStyle={{color: `${filterS3.on ? "#00BFFF":"black"}` }}
                              label="Supervisor 3"
                            />
                            {/* <Switch 
                              size="small" 
                              checked={filterS3.on} 
                              onChange={handleFilterS3} 
                            />  <Icon 
                                  type="team" 
                                  style={{color: `${filterS3.on ? "#00BFFF":"black"}` }}
                                /> Supervisor 3 */}
                      </div>  
                      <br />
                    </li>
                    <li>
                      <div className="inputs">
                          <label style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}><strong>Pay Type Categories</strong></label>
                          <FilterToggle 
                              onChange={handleFilterRegular}
                              checked={filterRegular.show}
                              checkOn="grey"
                              iconType="schedule"
                              label="Regular"
                          />
                        {/*   <Switch 
                            size="small" 
                            checked={filterRegular.show} 
                            onChange={handleFilterRegular} 
                          />  <Icon 
                                style={{color: `${filterRegular.show ? "grey":""}` }}
                                theme={filterRegular.show ? "filled" : "outlined"} 
                                type="schedule" 
                              /> Regular */}
                          <br />
                          <FilterToggle 
                              onChange={handleFilterOT}
                              checked={filterOT.show}
                              checkOn=""
                              iconType="clock-circle"
                              label="Overtime"
                          />
                          {/* <Switch 
                            size="small" 
                            checked={filterOT.show} 
                            onChange={handleFilterOT} 
                          />  <Icon 
                                theme={filterOT.show ? "filled" : "outlined"} 
                                type="clock-circle" 
                              /> Overtime */}
                          <br />
                          <FilterToggle 
                              onChange={handleFilterSick}
                              checked={filterSick.show}
                              checkOn="brown"
                              iconType="medicine-box"
                              label="Sick"
                          />
                          {/* <Switch 
                            size="small" 
                            checked={filterSick.show} 
                            onChange={handleFilterSick} 
                          /> <Icon
                                style={{color: `${filterSick.show ? "brown":""}` }}
                                theme={filterSick.show ? "filled" : "outlined"} 
                                type="medicine-box" 
                            /> Sick */}
                          <br />
                          <FilterToggle 
                              onChange={handleFilterLeave}
                              checked={filterLeave.show}
                              checkOn="#f36d33"
                              iconType="message"
                              label="Leave"
                          />
                          {/* <Switch 
                            size="small" 
                            checked={filterLeave.show} 
                            onChange={handleFilterLeave} 
                          />  <Icon 
                                style={{color: `${filterLeave.show ? "#f36d33":""}` }}
                                theme={filterLeave.show ? "filled" : "outlined"} 
                                type="message" 
                              /> Leave */}
                        </div>
                        <br />
                    </li>
                    {/* <li>
                      <div className="inputs">
                        <label style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}><strong>Supervisor Override</strong></label>
                        <br />
                          <SupervisorSelect
                            supervisor={selSupOverride}
                            value={selSupOverride && selSupOverride.number ? selSupOverride.number : null}
                            field="number"
                            {...selectPropsOverride}
                            disabled={false}

                          />
                      </div>
                    </li> */}
                  </div>
                </ul>
              </section>
            </Sider>
          </div>
          <Layout className="RecordsViewer">
            <Content className="ContentV2">
              {'   '}
              <Tooltip title="Filters"><Button style={{background: "#1990FF"}} type="default" onClick={()=> setSiderCollapsed(!siderCollapsed)}>{siderCollapsed ? <MenuUnfoldOutlined style={{color: "white"}} /> : <MenuFoldOutlined style={{color: "white"}} />}</Button></Tooltip>
              {'   '}
              <Tooltip title='Approve / Un-Approve All Selected'>
                <Popconfirm
                  title={<ApprovalFinePrint />}
                  //onConfirm={logSelection}
    /*               onConfirm={ e => {
                    console.log(e)
                    //const shiftData = records.find( r => r.id === key.slice(7))
                    approveAllSelected({ model:"shift", selection: state.selection, timeEntrySelected: state.timeEntrySelected, shiftSelected: state.shiftSelected, approved: e.approved})}}
    */              
                    onConfirm= { e => {
                      const shiftData = state.shiftSelected.map( el => records.find( r => el === r.id && r.approved && !r.needsApproved)).filter(notUndefined => notUndefined !== undefined)
                      approveAllSelected({ model:"shift", selection: state.selection.find(e => e === shiftData.id), timeEntrySelected: state.timeEntrySelected, shiftSelected: state.shiftSelected, approved: shiftData  })
                    }}
                  okText={"Approve / Un-Approve All Selected"}
                  cancelText="Cancel"
                >
                  <Button
                    icon="like"
                    disabled={state.selection.length === 0 || allDisabled}
                    type="primary"
                    size="small"
                  >
                    Approve All Selected
                  </Button>
                </Popconfirm>
              </Tooltip>
              <Tooltip title='Reject / Un-Reject All Selected'>
                <Popconfirm
                  title={<ApprovalFinePrint />}
                  //onConfirm={logSelection}
                  onConfirm= { e => {
                    const shiftData = state.shiftSelected.map( el => records.find( r => el === r.id && r.rejected && !r.needsApproved)).filter(notUndefined => notUndefined !== undefined)
                    rejectAllSelected({ model:"shift", selection: state.selection.find(e => e === shiftData.id), timeEntrySelected: state.timeEntrySelected, shiftSelected: state.shiftSelected, rejected: shiftData  })
                  }}
                  //onConfirm={ e => rejectAllSelected({ model:"shift", selection: state.selection, timeEntrySelected: state.timeEntrySelected, shiftSelected: state.shiftSelected, rejected: e.rejected })}
                  okText={"Reject / Un-Reject All Selected"}
                  cancelText="Cancel"
                >
                  <Button
                    icon="dislike"
                    disabled={state.selection.length === 0 || allDisabled}
                    type="danger"
                    size="small"
                  >
                    Reject All Selected
                  </Button>
                </Popconfirm>
              </Tooltip>
              {'   '}
              <Button 
                disabled={!(page === "ShiftApprovalsV2" || page === "MyShiftApprovalsV2")}
                size="small"
                onClick={async ()=> {
                  const expandAll = data.map(s => s.id)
                  let exp = expanded
                  for (const [i, e] of expandAll.entries()) {    
                    console.log('e -> ', e)
                    const path = i;
                    if (exp) {
                      const diff = { [path]: exp[path] ? false : true }
                      exp = 
                        {
                          ...exp,
                          ...diff
                        }
                    }
                  }
                  setExpanded(exp)
                  setExpandedAll(!expandedAll)
              }}>{expandedAll ? 'Collapse All' : 'Expand All'}</Button>
              {'   '}
            <Search {..._props} />
              <Tooltip title='Total Records'>
                {'  Total Records '} <Badge status={tableOptions.data.length > 0 ? "processing" : "default" }  text={tableOptions.data.length} title={tableOptions.data.length} count={tableOptions.data.length} />  {'  |  '}
              </Tooltip>
              <Tooltip title="Refresh">
                <Button icon="sync" type="primary" size="small" onClick={refresh}></Button>
              </Tooltip>
              
              <CheckboxTable 
                /* filtered={[{ // the current filters model
                  id: 'supervisor1.fullName',
                  value: 'Armijo, Manuel A.'
                }]} */
                style={{height: '94%'}}
                keyField="id"
                //ref={r => (CheckboxTable = r)}
                {...tableOptions}
                {...checkboxProps}
                expanded={expanded}
                /* getTrProps={(state, rowInfo, column) => {
                  if (rowInfo === undefined) {
                      return {}
                  }
                  return {
                      'data-qnt':  page === "ShiftApprovals" || page === "MyShiftApprovals"  ? 1 : 0 
                      //rowInfo.original.SOME_VALUE_WITH_QUANTITY_OF_CHILDREN_ROWS
                  }
                }}  */
                getTdProps={(state, rowInfo, column, instance) => { 
                  if (page === "ShiftApprovalsV2" || page === "MyShiftApprovalsV2" ) {
                    return {
                      onClick: (e) => {
                        if (rowInfo) {
                          const path = rowInfo.nestingPath[0]
                          
                          //let style = {}
                          if (e.target.nodeName !== 'BUTTON') {
                            if (expanded) {
                              const diff = { [path]: expanded[path] ? false : true }
                    
                              //let selection = []
                            
                              const autoSelect = (Object.values(diff).indexOf(true) > -1) 
                              if (autoSelect === true) {
                                //selection.push(`select-${rowInfo.original.id}`)
                                //instance.props.className = "data-qnt:1" 
                                
                                
                              } else {
                                //selection = selection.filter(item => item !=`select-${rowInfo.original.id}`)
                                //instance.props.className = ""
                              }
                              //setState({selection})
                              setExpanded(
                                {
                                  ...expanded,
                                  ...diff
                                }
                              )
                            }
                          }
                          
                        }
                      }
                    }
                  } else {
                    return {}
                  }
                }}
                SubComponent={ row => {
                  if (row.original.modelName === 'Shift') {
                    let timeEntries = row.original.timeEntries
                    const timeEntryData = []
                    for (let t of timeEntries) {
                      t.employee = row.original.employee
                      t.supervisor1 = row.original.supervisor1
                      t.supervisor2 = row.original.supervisor2
                      t.supervisor3 = row.original.supervisor3
                      t.shiftStart = row.original.start
                      //t.payType = row.original.payType
                      t.payType.description = t.payType.rename !== null || t.payType.rename !== "" ? t.payType.rename : t.payType.name

                      timeEntryData.push(t)
                    }

                    return (
                      <div style={{ height: "250px", margin: "0px", width: "100%", padding: "30px" }}>
                        <h2>Time Entries - {row.original.employee.fullName}</h2>
                        <ReactTable
                          style={
                            { 
                              height: "100%",
                              //backgroundColor: expanded ? "AliceBlue" : "inherit"
                            }
                          } 

                          keyField="id"
                          data={timeEntryData}
                          columns={timeEntryColumns(columnProps)}
                          defaultPageSize={timeEntryData.length+1}
                          showPagination={false}
                          multiSort = {true}
                          defaultSorted = {[
                            { id: "employeeNumber", asc: true },
                            { id: "start", asc: true },
                          ]}
                        /> 
                      </div>
                    )
                  }
                }} 
              />
              {/*</div> */}
              {/*{!!shiftDrawerId && <ShiftDrawer />}*/}
              {!!newShiftDrawer && <NewShiftDrawer payTypeCode={newShiftDrawer} />}
            </Content>
          </Layout>
        </Layout>
      </Can>
   </React.Fragment>
  )
}

export default ApprovalsV2
