
import React from "react"
import moment from 'moment-timezone'
import {Tooltip,Button, Icon} from "antd"
import { Emitter, REQ } from "../../store/psrs"

export const PayPeriodLockTable = (props) => {

    const {payPeriodLock, setPayPeriodLock, shiftLock, setShiftLock, timeEntryLock, setTimeEntryLock, payPeriodId, filters, superNumber1} = props

    const updateLockStatus = async (lockType, locked) => {

        //console.log('updateLockStatus -> ', lockType, ' : ', locked)

        const update = {
            payPeriodId: payPeriodId,
            type: lockType,
            locked: locked 
        }

        const updated = await REQ(`/api/pay_period/locked/${payPeriodId}`, "POST", update).catch(err =>
            Emitter.emit("PayPeriodStats.js", { fn: "updateLockStatus", error: err }),
        )
        
        console.log('udpated -> ', updated)
        Emitter.emit("ChangedLockStatus", {...updated, ...update} )

        //mutate(url, updated)
    }
    
    const payPeriodStatus = { className: `stat ${payPeriodLock ? "on payPeriodLock" : "on payPeriodUnlock" }`, type: "circle" }
    const shiftStatus = { className: `stat ${shiftLock ? "on shiftLock" : "on shiftUnlock" }`, type: "circle" }
    const timeEntryStaus = { className: `stat ${timeEntryLock ? "on timeEntryLock" : "on timeEntryUnlock" }`, type: "circle" }

    const startDate = moment(filters[0])
    const endDate = moment(filters[1])

    const canLock = props.role === 'admin' ? true : false

    const body = { 
        start: startDate.utc().format('YYYY-MM-DDTHH:mm:ss'),
        end: endDate.utc().format('YYYY-MM-DDTHH:mm:ss'), 
        payPeriodId: payPeriodId,
    }

    if (superNumber1) {
        body['supervisor1'] = superNumber1
    }

    return (
        <table className='lockTable' >
            <thead>
            <tr>
                <th>Level</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Pay Period</td>
                <td><div className="StatusIcons"><span className="statusIcon"><Icon {...payPeriodStatus} /></span>&nbsp;&nbsp;{payPeriodLock ? 'Locked' : 'Unlocked'}</div></td>
                <td>
                <div className="row_actions">                
                    <Tooltip title="Lock">
                    <Button 
                        disabled={payPeriodLock || !canLock} 
                        icon="lock" 
                        ghost
                        shape="circle" 
                        type="primary"
                        onClick={() => {
                        setPayPeriodLock(true)
                        setShiftLock(true)
                        setTimeEntryLock(true)
                        updateLockStatus('P',true)
                        }}
                    />
                    </Tooltip>&nbsp;&nbsp;
                    <Tooltip title="Unlock">
                    <Button 
                        disabled={!payPeriodLock || !canLock}
                        icon="unlock" 
                        ghost
                        shape="circle" 
                        type="primary"
                        onClick={() => {
                        setPayPeriodLock(false)
                        setShiftLock(false)
                        setTimeEntryLock(false)
                        updateLockStatus('P',false)
                        }}
                    />
                    </Tooltip>
                </div>
                </td>                        
            </tr>
            <tr>
                <td>Shift</td>
                <td><div className="StatusIcons"><span className="statusIcon"><Icon {...shiftStatus} /></span>&nbsp;&nbsp;{shiftLock ? 'Locked' : 'Unlocked'}</div></td>
                <td>
                <div className="row_actions">                
                    <Tooltip title="Lock">
                    <Button 
                        disabled={shiftLock || !canLock} 
                        icon="lock" 
                        ghost 
                        shape="circle" 
                        type="primary"
                        onClick={() => { 
                        setPayPeriodLock(true)
                        setShiftLock(true)
                        setTimeEntryLock(true)
                        updateLockStatus('S',true)
                        }}
                    />
                    </Tooltip>&nbsp;&nbsp;
                    <Tooltip title="Unlock">
                    <Button 
                        disabled={!shiftLock || !canLock} 
                        icon="unlock" 
                        ghost 
                        shape="circle" 
                        type="primary"
                        onClick={() => {
                        //setPayPeriodLock(true)
                        setShiftLock(false)
                        setTimeEntryLock(false)
                        updateLockStatus('S',false)
                        }}
                    />
                    </Tooltip>
                </div>
                </td>
            </tr>
            <tr>
                <td>Time Entry</td>
                <td><div className="StatusIcons"><span className="statusIcon"><Icon {...timeEntryStaus} /></span>&nbsp;&nbsp;{timeEntryLock ? 'Locked' : 'Unlocked'}</div></td>
                <td>
                <div className="row_actions">                
                    <Tooltip title="Lock">
                    <Button 
                        disabled={timeEntryLock || !canLock} 
                        icon="lock" 
                        ghost 
                        shape="circle" 
                        type="primary" 
                        onClick={() => {
                        setPayPeriodLock(true)
                        setShiftLock(true)
                        setTimeEntryLock(true)
                        updateLockStatus('T',true)
                        }}
                    />
                    </Tooltip> &nbsp;&nbsp;
                    <Tooltip title="Unlock">
                    <Button 
                        disabled={!timeEntryLock || !canLock} 
                        icon="unlock"  
                        ghost 
                        shape="circle" 
                        type="primary"  
                        onClick={() => {
                        //setPayPeriodLock(false)
                        //setShiftLock(false)
                        setTimeEntryLock(false)
                        updateLockStatus('T',false)

                        }}
                    />
                    </Tooltip>
                </div>
                </td>
            </tr>
            </tbody>  
        </table>
    )
}

export default PayPeriodLockTable