import React, { useState, useEffect } from "react"
import { reqOptions, store, Can, REQ } from "../store/psrs"
import ReactTable from "react-table"
import useSWR, { mutate } from "swr"
import { Button,/*Popconfirm,*/Tooltip, Checkbox } from "antd"
import  moment from "moment-timezone"


export const Sessions = props => {
  const pageTitle = "Active User Sessions"

  const [state, setState] = useState({
    pk: "id",
    model: "sessions",
    ids: [],
    sessions: [],
    initialRecords: null,
    queryLast24: true,
    mounted: false,
    loading: false,
  })

  const [records, setRecords] = useState(state.initialRecords || [])


  const fetcher = async url => {

    const body = JSON.stringify({queryLast24: state.queryLast24 })
    const options = {...reqOptions, method: 'POST', body: body }

    const data = await fetch(url, options)
    .then(res => res.json())
    .catch(err => console.log('error -> ', err))
  
    setRecords(data)
    return data
  }

  const key = `${store.get("api")}/api/active_sessions`
  const { data } = useSWR(key, fetcher, { initialData: state.initialRecords, filters: state.queryLast24 })
  
  const _props = { state, setState, mutate, url: key }

  const cleanUserSessions = async (props) => {
    //console.log('remove that session now....')

    const body = {

    }

    await REQ(`/api/clean_sessions`, "POST", body)
    .then( resp => {
      //console.log('data -> ', resp)
      if (resp.cleanUserSessions !== 'Success') {
          console.log('Clean User Sessions Error -> ', resp.error)
      }

    })
    .catch( err => {
        console.log('Clean User Sessions Error catch -> ', err)
    })

    //mutate(key)
  }

  const deleteUserSession = async (props) => {
      //console.log('remove that session now....')

      const body = {
          partitionKey: props.partitionKey,
          rowKey: props.rowKey
      }

      await REQ(`/api/remove_session`, "POST", body)
      .then( resp => {
        //console.log('data -> ', resp)
        if (resp.deleteSession !== 'Success') {
            console.log('Delete Session Error -> ', resp.error)
        }

      })
      .catch( err => {
          console.log('Delete Session Error catch -> ', err)
      })

      mutate(key)
  }

  const secondsToDhms = (seconds) => {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600*24));
    var h = Math.floor(seconds % (3600*24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 60);
    
    var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
    var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
    }
  
  const columns = [
    {
        Header: "Login Time",
        accessor: "loginTime",
        sortable: true,
        className: "justify-around",
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
        Header: "Last Session Activity",
        accessor: "lastSessionTime",
        sortable: true,
        className: "justify-around",
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
    },
    {
      Header: "Idle Time",
      sortable: true,
      className: "justify-around",
      Cell: row => { 
        //const lTime = moment(row.original.loginTime)
        const sTime = moment(row.original.lastSessionTime, 'MM-DD-YYYY HH:mm')
        const nTime = moment()
        
        const seconds = moment.duration(nTime.diff(sTime, 'seconds'))

       return  <div style={{ textAlign: "center" }}>{secondsToDhms(seconds)}</div> 
        //const LoginDate = moment(row.value)
       
    
      }

    },
    {
        Header: "Name",
        accessor: "fullName",
        sortable: true,
        className: "justify-start",
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
       
      },
      {
        Header: "Role",
        accessor: "role",
        sortable: true,
        className: "code_column justify-around",
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: "Actions",
        sortable: false,
        Cell: row => (
          <div className="row_actions">
            <Can I="delete:Sessions">
              {/*<Popconfirm
                title={`Are you sure you want to DELETE this Entry?`}
                onConfirm={() => deleteUserSession({ ..._props, partitionKey: row.original.partitionKey, rowKey: row.original.rowKey })}
              >*/}
                <Tooltip title="Delete">
                  <Button 
                     icon="delete" 
                     shape="circle" 
                     type="danger" 
                     onClick={() => deleteUserSession({ ..._props, partitionKey: row.original.partitionKey, rowKey: row.original.rowKey })}
                  />
                </Tooltip>
             {/* </Popconfirm>*/}
            </Can>
          </div>
        ),
      },
  ]

  useEffect(() => {
  //  if (data && data.length) store.set("_sessions", data)
   //  mutate()
  }, [data])

  useEffect(() => {
    //setRecords(state.sessionTime.length ? _.filter(data, r => state.sessionTime.includes(r.id)) : data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    mutate(key)
  }, [state.queryLast24, key])

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{pageTitle}</h1>
        <div>
            <h3>Total Sessions: {records.sessionCount}</h3>
        </div>
        <div className="actions">
         {/*} <Search {..._props} />*/}
         <Checkbox
            disabled={false}
            checked={state.queryLast24}
            onChange={ e => {
              state.queryLast24 = e.target.checked
              mutate(key)
              //handleUpdate(e.target.checked)
            }}
        > Last 24 hours </Checkbox>
          <Tooltip title="Remove disconnected Sessions">
            <Button 
              icon="delete" 
              //shape="circle" 
              type="danger" 
              onClick={() => cleanUserSessions({ ..._props})}
          >
            Remove All
          </Button>
          </Tooltip>
        </div>
      </div>
      <div data-cy="RecordsTable" className="view_content">
        <ReactTable
          columns={columns}
          loading={false}
          data={records.sessions}
          filterable={true}
          multiSort={true}
          defaultPageSize={50}
          defaultSorted={[{ id: "lastSessionTime", desc: true }]}

          className="-striped -highlight flex-1"
        />
      </div>
    </React.Fragment>
  )
}

export default Sessions
