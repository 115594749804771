import React, { useState } from "react"
import { FlexBlock } from "../UI"
import { Icon, Input, Button, Table, Popconfirm} from "antd"
import { DurationField, BusinessUnitSelect, SubLedgerSelect, AssignmentSelect, /*PayPeriodEndSelector*/ } from "../Forms"
import { PayTypeSelect } from "./PayTypeSelect"
import uuid from "uuid"
import _ from "lodash"
import Select from 'react-select';

// import moment from "moment"

const TimeEntriesTable = props => {
  const { 
    timeEntries, 
    //mutate, 
    //url, 
    //changeDetected2,
    //setChangeDetected2,
    //shiftLocked,
    //setShiftLocked,
    //priorPayPeriod,
    //setPriorPayPeriod,
    updateEntry,
    addEntry,
    removeEntry,
    //jsonShiftUpdates,
    //jsonEntryUpdates,

  } = props
  
  //let timeEntryJSON = timeEntries

  const Shift = props.shift
  Shift.paidLunch = Shift.paidLunch ? true : false

  const [showMore, setShowMore] = useState(false)

  
  const showMoreFields = async () => {
    setShowMore(!showMore)

  }

  // disable entry if evidence 2hr or 4hr pay type code
  //let evidenceDisable = false
  //for (let timeEntry of timeEntries) {
  //  evidenceDisable = timeEntry.payTypeCode === '250' || timeEntry.payTypeCode === '251' ? true : false
  //}

  const selectProps = { 
    //disabled: evidenceDisable, 
    handleChange: updateEntry 
  }


  const renderTotal = (text, shift, index) => {
    const { timeEntries } = shift
		//console.log(timeEntries);
    if (timeEntries && timeEntries.length > 0 ) {
      // const shiftDuration = shift.duration
      // const durations = timeEntries.map(e => e.duration)
      // const subTotal = durations.reduce((total, val) => total + val)
      const balanced = shift.imbalanced ? "error" : "equal"
      return <span className={balanced}>{shift.timeTotal}</span>
    } else {
      return <span>0</span>
    }
  }

  const renderBlank = (text, record, index) => ""

  const subTotalOptions = {
    rowClassName: "timeEntryTotals",
    dataSource: [Shift],
    pagination: false,
    showHeader: false,
    bordered: false,
    rowKey: "id",
    columns: [
      { width: 90, key: uuid(), title: "", render: renderTotal, align: "center" },
      {
        key: uuid(),
        title: "",
        align: "left",
        render: () => `Entry Total Hours ${Shift.imbalanced ? "do NOT match the shift duration! Please fix." : ""}`,
      },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
    ],
  }

  //const payPeriodStartId = store.get("PayPeriodToday").id
  //const payPeriodEndId = store.get("PayPeriodToday").id

  const options = [
   { value: 'primary', label: 'PRIMARY' },
   { value: 'alternate', label: 'ALTERNATE' },
   { value:'lunch', label: 'LUNCH'}

  ]   

  const columns = [
    {
      align: "center",
      title: "Type",
      dataIndex: "payTypeCategory",
      width: 130,
      render: (text, record, index) => (
          <Select
            className="psrs-select"
            classNamePrefix="psrs"
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isSearchable={false}
            options={options}
            defaultValue={{value: record.payTypeCategory ? record.payTypeCategory : null, 
               label: record.payTypeCategory ? record.payTypeCategory.toUpperCase(): null }}
            onChange={e => {
              //console.log(e.value)
              updateEntry({ field: "payTypeCategory", value: e.value, id: record.id })
            }}

            
          />

       
      ),
    },
    {
      align: "center",
      title: "Hours*",
      dataIndex: "duration",
      width: 80,
      render: (text, record, index) => (
        <DurationField
          noLabel={true}
          disabled={false}
          field="duration"
          value={parseFloat(text).toFixed(2)}
          id={record.id}
          handleChange={updateEntry}
        />
      ),
    },
    {
      align: "center",
      title: "Pay Type*",
      dataIndex: "payTypeCode",
      //width: 220,
      render: (text, record, index) => {
        return <PayTypeSelect disabled={false} payTypeCategory = {record.payTypeCategory} value={record.payTypeCode} {...selectProps} id={record.id} />
      },
      style: { paddingRight: 0 },
    },
    {
      title: "Business Unit",
      dataIndex: "Business Unit Override",
      //width: 210,
      render: (text, record, index) => (
        <BusinessUnitSelect
          {...selectProps}
          disabled={false}
          field="costCenterChargeOutCode"
          value={record.costCenterChargeOutCode || record.businessUnitCode}
          record={record}
        />
      ),
    },
    {
      title: "Sub Ledger",
      dataIndex: "subLedger.description",
      //width: 210,
      render: (text, record, index) => (
        <SubLedgerSelect {...selectProps} record={record} disabled={false} field="subLedgerCode" value={record.subLedgerCode} />
      ),
    },
    
    {
      title: "Acting Role",
      dataIndex: "assignment.description",
      //width: 210,
      render: (text, record, index) => (
         <AssignmentSelect {...selectProps} record={record} disabled={false} field="assignmentId" value={record.assignmentId} />
      ),
    },
    {
      title: "Case",
      dataIndex: "caseNumber",
      // width: 100,
      render: (text, record, index) => {
        return (
          <Input
            defaultValue={text}
            placeholder="optional"
            onBlur={e => updateEntry({ field: "caseNumber", value: e.target.value, id: record.id })}
            disabled={false}
          />
        )
      },
    },
    {
      align: "center",
      title: "",
      //width: 40,
      key: uuid(),
      del: true, 
      render: (text, record, index) => (
        <Popconfirm
          title="Are you sure you want to delete this Time Entry?"
          onConfirm={() => removeEntry(record.id)}
          okText="Yes"
          cancelText="No"
          disabled={false}
        >
          <Icon type="delete" theme="filled" className="ShiftDrawerTimeEntryDelete" />
        </Popconfirm>
      ),
    },
  ]

  //blw want paid lunch in grid
  const dataSource = _.orderBy(timeEntries, ["i"], ["asc"])
  // const dataSource = _.filter(timeEntries, t => t.name !== "Unpaid Lunch")

  const filters = [
     { type: "dataIndex", name: "caseNumber" }, 
     { type: "dataIndex", name: "payPeriodEndOverride" },
  ];

  const extraFilters = [
    { type: "dataIndex", name: "caseNumber" }, 
    { type: "dataIndex", name: "payPeriodEndOverride" },
    { type: "dataIndex", name: "Business Unit Override"},
    { type: "dataIndex", name: "duration" },
    { type: "dataIndex", name: "payTypeCode" },
    { type: "del", name: true},
  ]
  
  const standardFields = columns.filter(el => filters.every(filterEl => el[filterEl.type] !== filterEl.name))
  const extraFields = columns.filter(el => extraFilters.some(filterEl => el[filterEl.type] === filterEl.name))

  const timeEntryOptions = {
    dataSource,
   // columns: columns,
    //columns: showMore ? columns : columns.filter(ea => ea.dataIndex !== 'caseNumber'),
    columns: showMore ? extraFields: standardFields,
    pagination: false,
    showHeader: true,
    bordered: true,
    rowKey: () => uuid(),
  }
  

  return (
    <React.Fragment>
      <FlexBlock>
        <section>
          <h2>Time Entries</h2>
        </section>
        <section>
          <Button.Group>
            <Button  type="primary" size="small" icon="more" ghost={true} onClick={showMoreFields}>
              {!showMore ? 'Show More ' : 'Show Less '}     
            </Button> <Button disabled={false} type="primary" size="small" icon="file-add" ghost={true} onClick={addEntry}>
              Add a Time Entry
            </Button>
          </Button.Group>
        </section>
      </FlexBlock>
      <Table key="timeEntriesTable" {...timeEntryOptions} />
      <Table key="timeEntryTotal" {...subTotalOptions} />
    </React.Fragment>
  )
}

export default TimeEntriesTable
