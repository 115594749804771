import React from "react"
//import moment from "moment"
import moment from 'moment-timezone'
import {DatePicker} from "antd"

export const DateTimePicker = (props) => {
  const {field, value, id, handleChange} = props

  function onOk(val) {
    handleChange({...props, field: field, value: val.format("YYYY-MM-DD HH:mm"), id})
  }

  const options = {
    onOk,
    format: "YYYY-MM-DD HH:mm",
    defaultValue: value ? moment(value) : null,
    showTime: {
      defaultValue: moment(value).tz('utc'),
      format: "HH:mm",
      minuteStep: 1
    }
  }

  return (
    <div>
      <DatePicker data-cy="date-time-picker" {...options} />
    </div>
  )
}

export default DateTimePicker
