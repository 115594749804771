import React, { useState, useEffect } from "react"
import { Checkbox, Input, Layout, Radio } from "antd"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/dist/style.css"
const { Content } = Layout

export const Settings = props => {
  const [phone, setPhone] = useState({ valid: false, number: null })
  const [state, setState] = useState({})

  const notificationMethodProps = {
    buttonStyle: "solid",
    onChange: e => setState({ ...state, preferredNotificationChannel: e.target.value }),
  }

  const handleUpdate = async v => {
    setPhone({ ...phone, number: v, valid: v.length === 10 })
    phone.number = v
    phone.valid = v.length === 10
  }

  const phoneProps = {
    value: state.notificationsPhoneNumber,
    disableCountryCode: true,
    disableDropdown: true,
    defaultCountry: "us",
    onlyCountries: ["us"],
    isValid: v => {
      handleUpdate(v)
      return v.length === 10
    },
    onBlur: e => {
      if (phone.valid) setState({ ...state, notificationsPhoneNumber: phone.number })
    },
  }

  useEffect(() => {
    // console.log("useEffect", state)
    // if (!mounted) {
    //   API.REQ(`/api/settings/${employeeNumber}`).then(res => {
    //     setMounted(true)
    //     const phoneNumber = res.notificationsPhoneNumber || null
    //     setPhone({ number: phoneNumber, valid: phoneNumber.length === 10 })
    //     setState({ ...state, ...res })
    //   })
    // } else {
    //   API.REQ(`/api/settings/${employeeNumber}`, "PATCH", state).then(res => {
    //     console.log("updateSettings", res)
    //   })
    // }
  }, [state])

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">My Settings</h1>
      </div>
      <div className="view_content">
        <Content className="UserSettings">
          <section>
            <label className="labelLeft">Notification Phone</label>
            <div className="input">
              <PhoneInput {...phoneProps} />
            </div>
          </section>
          <section>
            <label className="labelLeft">Notifications Email</label>
            <div className="input">
              {state.id && (
                <Input
                  onBlur={e => e.target.value && setState({ ...state, notificationsEmail: e.target.value })}
                  defaultValue={state.notificationsEmail}
                />
              )}
            </div>
          </section>
          <section>
            <label className="labelLeft">Preferred Notification Method</label>
            <div className="input">
              <br />
              <Radio.Group {...notificationMethodProps} value={state.preferredNotificationChannel}>
                <Radio.Button value="text" checked={state.preferredNotificationChannel === "text" ? true : false}>
                  Text Message
                </Radio.Button>
                <Radio.Button value="email" checked={state.preferredNotificationChannel === "email" ? true : false}>
                  Email
                </Radio.Button>
              </Radio.Group>
            </div>
          </section>
          <section>
            <Checkbox
              checked={state.turnOffNotifications}
              onChange={e => setState({ ...state, turnOffNotifications: e.target.checked })}
            >
              Turn Off Notifications
            </Checkbox>
          </section>
        </Content>
      </div>
    </React.Fragment>
  )
}

export default Settings
