import React, { useState, useEffect, useMemo } from "react"
//import { store, REQ, fetch } from "../store/psrs"
import { store, REQ } from "../store/psrs"
import "./Reports/Reports2.sass"
// import Split from "react-split"
//import { Input, Tooltip, Icon, Button, Radio, Tabs, Tab, Tag, DatePicker, Row, Col, Layout, Transfer } from "antd"
import { Input, Button, Radio, Tabs, DatePicker, Transfer, Card, Tooltip, Icon, Checkbox, Layout, Collapse} from "antd"
//import json from "react-json-pretty"
import jsreport from 'jsreport-browser-client-dist'
import {GroupSelector} from "./Forms/GroupSelector"
import {SupervisorSelect} from "./Forms/SupervisorSelect"
//import InlineEdit from "./Forms/InlineEdit
//import useMyContext from "../store/useMyContext"
//import { Table, TableHeader, TableBody, TableRow, TableCell } from "grommet"
import { Table, TableBody, TableRow, TableCell } from "grommet"
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';

import moment from "moment-timezone"
import _ from "lodash"

import uniqid from "uniqid"
import InlineEdit from "./Forms/InlineEdit"

const { RangePicker } = DatePicker
const { TabPane } = Tabs
const { Sider } = Layout
const { Panel } = Collapse

export const Reports = props => {
  const storedState = store.get("ReportsView" + (new Date()).getTime() )

  if (storedState) {
    const { range } = storedState
    storedState.range = [moment(range[0]), moment(range[1])]
  }

	//const context = useMyContext()
	//const Employee = async number => context.getRecord("Employees", number, 'number')

  const CurrentPP = store.get("PayPeriod")

  const [tab, setTab] = useState("general");
  const [collapsed, setCollapsed] = useState(false)

  const initialState = {
    mounted: false,
    field: "submit",
    value: false,
    range: [moment(CurrentPP.start), moment(CurrentPP.end)],
    includeNoTeams: true,
    payPeriod: "current",
    allSupervisors: false,
		tagIds:[],
    supervisorNumbers: [],
    employeeNumbers: [],
		reportType:"sick",
		date:new Date(),
    displayDate: new Date(),
    utcStartDate: new Date(),
    utcEndDate: new Date(),
		watchTitle:"WATCH CMDR",
		watchCommander:"",
    cmdLabel: "WATCH CMDR",
		superLabel: "Supervisor",
		westTitle:"WEST",
		eastTitle:"EAST",
		westSupervisor:"",
		eastSupervisor:"",
    businessUnitNumbers: [],
    overrideBUNumbers: [],
    supervisors: [{title: 'WEST', employeeNumber: false, number: false},{title: 'EAST', employeeNumber: false, number: false}],
    watchCmdrs: [{title: 'WATCH CMDR', employeeNumber: false, number: false}],
  }

  const [state, setState] = useState(storedState || initialState)
  const [mounted, setMounted] = useState(false)


  //const [data, setData] = useState(null)

  const toggle = () => {
    setCollapsed( !collapsed );
    //console.log('collapsed -> ', collapsed)
  }

  //watch commander(s)
  const addWatchCmdr = () => {
    state.watchCmdrs.push({ title: "Blank", employeeNumber: false, number: false })
    setState({ ...state })
  }

  const updateWatchCmdr = args => {
    const { id, field, value } = args
    state.watchCmdrs[id][field] = value
    setState({ ...state })
  }

  const removeWatchCmdr = i => {
    state.watchCmdrs.splice(i, 1)
    setState({ ...state })
  }

  // supervisor(s)
  const updateSupervisor = args => {
    const { id, field, value } = args
    state.supervisors[id][field] = value
    setState({ ...state })
  }

  const addSupervisor = () => {
    state.supervisors.push({ title: "Blank", employeeNumber: false })
    setState({ ...state })
  }

  const removeSupervisor = i => {
    state.supervisors.splice(i, 1)
    setState({ ...state })
  }

  const renderWatchCmdr = () => {

    return state.watchCmdrs.map((supervisor, i) => {
      return (
        <Card size="small" className="supervisorCard" key={uniqid()}>
          <div className="field">
            <Tooltip title="Remove Watch Commander">
              <Icon className="removeSupervisor" type="minus-circle" theme="filled" onClick={() => removeWatchCmdr(i)} />
            </Tooltip>
            <InlineEdit allowClear={true} value={supervisor.title} field="title" id={i} handleChange={updateWatchCmdr} />
          </div>
          <div className="field">
            <SupervisorSelect value={supervisor.number} supervisor={supervisor} field="number" id={i} handleChange={updateWatchCmdr} />
          </div>
        </Card>
      )
    })
  }

  const renderSupervisor = () => {
    return state.supervisors.map((supervisor, i) => {
      return (
        <Card size="small" className="supervisorCard" key={uniqid()}>
          <div className="field">
            <Tooltip title="Remove Supervisor">
              <Icon className="removeSupervisor" type="minus-circle" theme="filled" onClick={() => removeSupervisor(i)} />
            </Tooltip>
            <InlineEdit allowClear={true} value={supervisor.title} field="title" id={i} handleChange={updateSupervisor} />
          </div>
          <div className="field">
            <SupervisorSelect value={supervisor.number} supervisor={supervisor} field="number" id={i} handleChange={updateSupervisor} />
          </div>
        </Card>
      )
    })
  }

  // finsish adds


  const reports = {
		generateReport: function(type, name, recipe, filename) {

        //console.log('****[ generateReport ]****');
        //console.log("   type -> ", type);
        //console.log("   reportName -> ", name);
        //console.log("   recipe -> ", recipe);
        //console.log("   filename -> ", filename);

        //console.log(" --****  RADIO -> ", $("input[type='radio'].active").attr("id"));
        /*
        console.log("enable spinner....")
        var el = document.getElementById("showspinner");
				el.className += " spinner";
        */     

        document.getElementById("noDataMessage").style.display = "none";

        window.setTimeout(function () { // allow UI to update
				reports.generateReport2(type, name, recipe, filename);
			}, 10);
		},

		generateReport2: function(type, name, recipe, filename) {
        var supervisor = "";
       // var payperiod = "";
       // var weekday = "";
        var startDate = "";
        var endDate = "";
        var service="";

        //console.log('new selection ->',$("#supervisor_ot :selected"));

        //var newSelected = $("#supervisor_ot :selected");
        var supervisorNumbers=''

        //$.each(newSelected, function (key, entry) { supervisorNumbers += entry.value+","; });
        
        //remove extra , from end 
        supervisorNumbers = supervisorNumbers.substring(0, supervisorNumbers.length-1);
        //var superNumberList = supervisorNumbers.substring(0, supervisorNumbers.length-1) + ")";

        console.log('supervisorNumbers ->', supervisorNumbers);
        //console.log('superNumberList: ', superNumberList);

        startDate = new Date(state.range[0]).toISOString().substring(0,10);
        endDate = new Date(state.range[1]).toISOString().substring(0,10);

        //startDate = new Date(state.range[0]).toISOString()
        //endDate = new Date(state.range[1]).toISOString()
        // setFilters({ ...filters, date: null })
        //const startQ = state.range[0].format("YYYY-MM-DDT00:00:00")
        //let endQ = state.range[1].format("YYYY-MM-DDT23:59:59")
        //const utcStartQ = moment(startQ).utc().format('YYYY-MM-DD');
        //const utcEndQ = moment(endQ).utc().format('YYYY-MM-DD');

				//console.log("name: " + name);
        if (name === "daily") {
            //weekday = $("#dailyboard_day :selected").val();
            //supervisor = $("#supervisor_dd :selected").val();
            service = '/daily'
        } else if (name === "not_submitted") {
            //supervisor = $("#supervisor_ns :selected").val();
            //payperiod = $("#payPeriod_ns :selected").val();
            service = '/notsubmitted'
        } else if (name === "timeOff") {
            //supervisor = $("#supervisor_pto :selected").val();
            //startDate = $("#ptoStartDate").val();
            //endDate = $("#ptoEndDate").val();
            service = '/pto'
        } else if (name === "sick") {
            //supervisor = $("#supervisor_sick :selected").val();
            //startDate = $("#sickStartDate").val();
            //endDate = $("#sickEndDate").val();
            service = '/sick/'+ startDate+'/'+ endDate + '/' + supervisor
            service = '/sick'
        } else if (name === "overtime") {
            //supervisor = $("#supervisor_ot :selected").val();
            //startDate = $("#otStartDate").val();
            //endDate = $("#otEndDate").val();
            service = '/overtime'
        }

        //console.log("supervisorSelected: ", supervisor);
        //console.log("payperiodSelected: ", payperiod);
        //console.log("daySelected: ", weekday);
        //console.log("startDate: ", startDate);
        //console.log("endDate: ", endDate);
        //console.log("businessUnitSelected: ");
    
        reports.sendJSReportRequest(service,name,recipe,name,type)
    },

    sendJSReportRequest: async function(service,name,recipe,filename,type) {

        //console.log('sendJSReportRequest')
        //console.log('service-> ', service)
        //console.log('name-> ', name)
        //console.log('recipe-> ', recipe)
        //console.log('filename-> ', filename)
        //console.log('PSRS URL-> ', reports.psrsUrl)
    
				var report = name === "daily" ? "DailyDashBoardV4" : "main-ot-dates_v4a"
    

        //console.log("enable spinner....")
        var el = document.getElementById("showspinner");
				el.className += "spinner";

        // Get data from psrs.  
        // adjust date range for UTC

        //var range
        //var current
        //var YYYYMMDD
        //var utcStartQ
        //var utcEndQ
        if (state.range) {
          //const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()

          //range = state.range
          //current = range.map(d => moment(d).utc())
          //YYYYMMDD = current.map(c => c.tz(userTimeZone).format("YYYY-MM-DD"))

          // new utc
          //utcStartQ = moment(YYYYMMDD[0] + ' 00:00:00').utc().format()
          //utcEndQ = moment(YYYYMMDD[1] + ' 24:00:00').utc().format()

          //state.range[0] = moment(utcStartQ)
          //state.range[1] = moment(utcEndQ)

        }


				var data = await REQ("/api/reports" + service, "POST", state).catch(function (err) {
      		//Emitter.emit("RecordsViewer.js", { fn: "handleUpdate", error: err }),
        })

        let reportData
        if ( name === 'daily') {
          if (!data) {
            //console.log('disable the spinner')
            el = document.getElementById("showspinner");
            el.className = el.className.replace(/spinner/,"");
            return document.getElementById("noDataMessage").style.display = "table"; 
          }
          reportData = data
        } else {
          if( !data[0].jsreport ) {
            //console.log('disable the spinner')
            el = document.getElementById("showspinner");
            el.className = el.className.replace(/spinner/,"");
            return document.getElementById("noDataMessage").style.display = "table";
          }
          reportData = data[0].jsreport
        }

        var jsonData = { 
					reportType: name,
    			startDate: moment(new Date(state.range[0])).format("MM-DD-YYYY"),
    			endDate: moment(new Date(state.range[1])).format("MM-DD-YYYY"),
					reportData
        };
        
        jsreport.serverUrl = "https://lts.jsreportonline.net";

          // call jsreport server/service 
          var request = {}
          if (report === 'DailyDashBoardV4') {
              request = {
                template: {
                  name: report,
                  engine: 'handlebars',
                  recipe: recipe,
                },
                data: JSON.stringify(jsonData.reportData),
                options:{
                  reports:{
                      save: true,
                      preview: true,
                  }
                }
            };
          } else {
              request = {
                  template: {
                      name: report,
                      engine: 'handlebars',
                      recipe: recipe,
                  },
                  data: JSON.stringify(jsonData),
                  options:{
                      reports:{
                          save: true,
                          preview: true,
                      }
                  }
              };
          }
    
          //console.log('request-> ',JSON.stringify(request));
    
    
          //add custom headers to ajax calls (renderAsync)
          //console.log('auth: ', "Basic " + btoa("ltsreportuser:62bMmvkhx356QLR"))
            
          jsreport.headers['Authorization'] = "Basic " + btoa("ltsreportuser:62bMmvkhx356QLR")
          //jsreport.headers['Content-Type'] = "application/json"
    
          //console.log('jsreport -> ', jsreport)
    
          //jsreport.render($("#placeholder"), request);     
          // here ”placeholder” is div tag name in html page    
    
          // new async way so we can hide the spinner
          jsreport.renderAsync(request)
          .then((res) => {

                //console.log('recipe: ', recipe);
                //if (recipe === 'chrome-pdf') {
                if (type === 'pdfdownload') {
                    //console.log('*** saving pdf ***');
                    //console.log('filename: ', reports.formatFileName(filename));
                    //open download dialog
                    res.download(reports.formatFileName(filename))
                }

                var placeholder = document.getElementById('reportPlaceholder')
                var iframe = document.createElement('iframe')
    
                iframe.src = res.toDataURI()
                iframe.style.width = '100%'
                iframe.style.height = '100%'
    
                while (placeholder.childNodes.length > 2 ) {
                  placeholder.removeChild(placeholder.lastChild);
                }
    
                placeholder.appendChild(iframe);

                console.log('disable the spinner')
                var el = document.getElementById("showspinner");
								el.className = el.className.replace(/spinner/,"");
                
                //Loading.hide()
            })
            .catch(e => {
              console.log('jsreport error: ', e)
              var el = document.getElementById("showspinner");
              el.className = el.className.replace(/spinner/,"");
              document.getElementById("noDataMessage").style.display = "table"; 
            })
      //end 
    },

		formatFileName: function(filename){

        const d = new Date();
        const dateTimeFormat = new Intl.DateTimeFormat('en-US', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit', 
            hour12: false 
        }); 

        const [{value: month },,
               {value: day },,
               {value: year },,
               {value: hour},,
               {value: minute},,
               {value: second}] = dateTimeFormat.formatToParts(d) 

        return `${year}${month}${day}_${hour}${minute}${second}-${filename}`

    }
  }

  const dailyGeneratePDF = () => {
    var placeholder = document.getElementById('reportPlaceholder')
    while (placeholder.childNodes.length > 2 ) {
      placeholder.removeChild(placeholder.lastChild);
    }
    reports.generateReport("pdf", "daily", "html", "ot_shifts.pdf")
  }

  const dailyDownloadPDF = () => {
    var placeholder = document.getElementById('reportPlaceholder')
    while (placeholder.childNodes.length > 2 ) {
      placeholder.removeChild(placeholder.lastChild);
    }
    reports.generateReport("pdfdownload", "daily", "chrome-pdf", "ot_shifts.pdf")
  }

  const generatePDF = () => {
    var placeholder = document.getElementById('reportPlaceholder')
    while (placeholder.childNodes.length > 2 ) {
      placeholder.removeChild(placeholder.lastChild);
    }
    reports.generateReport("pdf", state.reportType, "chrome-pdf", "ot_shifts.pdf")
  }

  const downloadPDF = () => {
    var placeholder = document.getElementById('reportPlaceholder')
    while (placeholder.childNodes.length > 2 ) {
      placeholder.removeChild(placeholder.lastChild);
    }
    reports.generateReport("pdfdownload", state.reportType, "chrome-pdf", "ot_shifts.pdf")
  }

  const btnProps = {
    size: "large",
    block: true,
    type: "primary",
    shape: "round",
    ghost: true,
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const supervisors = useMemo(() => {
    return store.get("Supervisors").map(r => {     
        const employeeName = `${r.firstName} ${r.lastName}`
        return {
          //key: r.role, !== 'disabled' ? r.number : null,
          //title: r.role !== 'disabled' ? employeeName : null,
          //description: r.role !== 'disabled' ? employeeName.toLowerCase() : null,
          //label: r.role !== 'disabled' ? r.fullName : null, 
          key: r.number,
          title: employeeName,
          description: employeeName.toLowerCase(),
          label: r.fullName,
          role: r.role 
        }
    })
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const employees = useMemo(() => { 
    return store.get("Employees").map(r => {      
        const employeeName = `${r.firstName} ${r.lastName}`
        return {
          key: r.number,
          title: employeeName,
          description: employeeName.toLowerCase(),
          label: r.fullName,
          role: r.role
        }
    })
    
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const businessUnits = useMemo(() => { 
    return store.get("BusinessUnits").map(r => {      
        return {
          key: r.code,
          title: r.name,
          description: r.name.toLowerCase(),
          label: r.included,
          type: r.type
        }
    })
    
  })

  const filterOption = (inputValue, option) => option.description.indexOf(inputValue.toLowerCase()) > -1

  const handleDateChange = sel => {
    const YYYYMMDD = moment(sel).format('YYYY-MM-DD')
    const utcStartDate = moment(YYYYMMDD + ' 00:00:00').utc().format();
    const utcEndDate = moment(YYYYMMDD + ' 24:00:00').utc().format();
    const sDate = moment(YYYYMMDD + ' 24:00:00').utc().format('YYYY-MM-DD');
   // const utcEndQ = !!dateString[1] ? moment(dateString[1] + ' 24:00:00').utc().format() : moment(dateString[0] + ' 24:00:00').utc().format();
    //setState({ ...state, date: sel.toDate() })
    setState({ ...state, date: sDate, displayDate: sel.format(), utcStartDate: utcStartDate, utcEndDate: utcEndDate })
    //dailyGeneratePDF()
  }
  const handleIncludeChange = props => {
    state.includeNoTeams = props.value
    setState({ ...state, includeNoTeams: props.value})
    //dailyGeneratePDF()
  }


  /*
	const setWatchTitle = sel => {
    setState({ ...state, watchTitle: sel.value })
  }

	const setWatchCommander = sel => {
    setState({ ...state, watchCommander: sel.value })
  }

	const setWestSupervisor = sel => {
    setState({ ...state, westSupervisor: sel.value })
  }

	const setEastSupervisor = sel => {
    setState({ ...state, eastSupervisor: sel.value })
  }
  */
  const setCmdLabel = inp => {
    setState({ ...state, cmdLabel: inp.currentTarget.value })
  }

	const setSuperLabel = inp => {
    setState({ ...state, superLabel: inp.currentTarget.value })
  }

  /*
	const setWestTitle = inp => {
    setState({ ...state, westTitle: inp.value })
  }

	const setEastTitle = inp => {
    setState({ ...state, eastTitle: inp.value })
  }
  */

  const handleEmployeeChange = employeeNumbers => {
    setState({ ...state, employeeNumbers, supervisorNumbers: [] })
  }

  const handleSupervisorChange = supervisorNumbers => {
		//console.log("S:" + JSON.stringify(supervisorNumbers, null, 2));
    setState({ ...state, employeeNumbers:[], supervisorNumbers:supervisorNumbers  })
  }

  const handleBusinessUnitChange = businessUnitNumbers => {
    setState({ ...state, businessUnitNumbers})
  }

  const handleOverrideBusinessUnitChange = overrideBUNumbers => {
    setState({ ...state, overrideBUNumbers})
  }


  /*
  const fetchData = async () => {
    console.log("FETCH DATA")
    const endpoint = "https://localhost:5001/api/reports"
    const start = state.range[0].format("MM-DD-YYYY")
    const end = state.range[1].format("MM-DD-YYYY")
    const { field, value, employeeNumbers, supervisorNumbers } = state
    const url = `${endpoint}/${field}/${value}/${start}/${end}`
    const body = JSON.stringify({ employeeNumbers, supervisorNumbers })
    const res = await fetch(url, { method: "POST", body })
    console.log("FETCHED DATA", res)
  }

  const JobFullName = ({ employeeNumber }) => {
    const employee = employeeNumber ? Employee(employeeNumber) : false
    const fullName = employee ? employee.fullName : "No Name"
    const jobTitle = employee ? employee.jobTitle : "None"
    return (
      <span>
        <Tag>{jobTitle || "jobTitle"}</Tag> {fullName || "fullName"}
      </span>
    )
  }
  */

  useEffect(() => {
    if (!mounted) setMounted(true)
    else {
     //if (tab === 'daily') dailyGeneratePDF()
    }
    // eslint-disable-next-line
  }, [tab])

  useEffect(() => {
    if (!mounted) setMounted(true)
    else {
      store.set("ReportsView", state)
    }
    //if (tab === 'daily') dailyGeneratePDF()
    // fetchData()
    // eslint-disable-next-line
  }, [state])

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">Reports</h1>
        <div className="actions"></div>
      </div>
      <div className="view_content ReportsView">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
        <Sider className="Sider reportTab" width="680" theme="dark" trigger={null} collapsible collapsed={collapsed} collapsedWidth="0">
          <div className="leftColumn">
              <Tabs 
                defaultActiveKey="general" 
                activeKey={tab} 
                onChange={key => {
                  var placeholder = document.getElementById('reportPlaceholder')
                  while (placeholder.childNodes.length > 2 ) {
                    placeholder.removeChild(placeholder.lastChild);
                  }
                  document.getElementById("noDataMessage").style.display = "table";
                  setTab(key)
                }}
              >
                <TabPane tab="General" key="general" forceRender={true}>
                  <div className="leftColumn" style={{ width: "300px" }}>
                    <section className="reportType">
                      <h3>Report Type</h3>
                      <section>
                        <Radio.Group
                          defaultValue={state.reportType}
                          buttonStyle="solid"
                          onChange={e => {
                            //const field = "reportType" //e.target.value
                            //const value = e.target.value
                            //console.log(`${field} ${value}`)
                            //setState({ ...state, field, value })

                            //clear the right side panel
                            var placeholder = document.getElementById('reportPlaceholder')
                            while (placeholder.childNodes.length > 2 ) {
                              placeholder.removeChild(placeholder.lastChild);
                            }
                            document.getElementById("noDataMessage").style.display = "table";

                            state.reportType = e.target.value;
                            //console.log(state.reportType);
                          }}
                        >
                          <Radio.Button val={true} value="sick">
                            Sick
                          </Radio.Button>
                          <Radio.Button val={false} value="timeOff">
                            Time Off
                          </Radio.Button>
                          <Radio.Button val={false} value="overtime">
                            Overtime
                          </Radio.Button>
                          <Radio.Button val={false} value="not_submitted">
                            Not Submitted
                          </Radio.Button>
                        </Radio.Group>
                      </section>
                    </section>

                    <section className="dates">
                      <h3>Dates</h3>
                      <section>
                        <RangePicker value={state.range} onChange={ e => {
                          //console.log('e is -> ', e)
                          setState({ ...state, range: e })
                          }
                        } />
                      </section>
                    </section>

                    <section className="payPeriod">
                      <h3> Pay Period </h3>
                      <section>
                        <Radio.Group
                          defaultValue={state.payPeriod}
                          buttonStyle="solid"
                          onChange={e => {
                            //console.log('** e -> ',e)
                            const value = e.target.value
                            const PayPeriod = value === "current" ? store.get("PayPeriodToday") : value === "previous" ? store.get("PayPeriodPrev") : store.get("PayPeriodNext")
                            const range = [moment(PayPeriod.start), moment(PayPeriod.end)]
                            //console.log("RANGE", range)
                            setState({ ...state, payPeriod: e.target.value, range })
                          }}
                        >
                          <Radio.Button value="previous">Previous</Radio.Button>
                          <Radio.Button value="current">Current</Radio.Button>
                          <Radio.Button value="next">Next</Radio.Button>
                        </Radio.Group>
                      </section>
                    </section>

                    <Collapse defaultActiveKey={['1']}>
                      <Panel header={<h3>Override Business Units</h3>} key="1">

                        <section className="supervisors">
                          <h3>Override Business Units</h3>   
                          <section>
                            <Transfer
                              titles={["Excluded", "Included"]}
                              dataSource={_.sortBy(_.filter(businessUnits, e => e.label === true),['title'])}
                              showSearch
                              filterOption={filterOption}
                              targetKeys={state.overrideBUNumbers}
                              onChange={handleOverrideBusinessUnitChange}
                              render={item => item.title}
                              locale={{ itemUnit: "", itemsUnit: "" }}
                            />
                          </section>
                          </section>
                      

                      </Panel>
                    </Collapse>

                    <Collapse defaultActiveKey={['1']}>
                      <Panel header={<h3>Business Units</h3>} key="1">

                        <section className="supervisors">
                          <h3>Business Units</h3>
                          <section>
                            <Transfer
                              titles={["Excluded", "Included"]}
                              dataSource={_.sortBy(_.filter(businessUnits, e => e.label === true),['title'])}
                              showSearch
                              filterOption={filterOption}
                              targetKeys={state.businessUnitNumbers}
                              onChange={handleBusinessUnitChange}
                              render={item => item.title}
                              locale={{ itemUnit: "", itemsUnit: "" }}
                            />
                          </section>
                        </section>

                      </Panel>
                    </Collapse>

                    <Collapse defaultActiveKey={['1']}>
                      <Panel header={<h3>Employees</h3>} key="1">      
                        <section className="supervisors">
                          <h3>Employees</h3>
                          <section>
                            <Transfer
                              titles={["Excluded", "Included"]}
                              dataSource={_.sortBy(_.filter(employees, e => e.role !== "disabled"),['label'])}
                              showSearch
                              filterOption={filterOption}
                              targetKeys={state.employeeNumbers}
                              onChange={handleEmployeeChange}
                              render={item => item.title}
                              locale={{ itemUnit: "", itemsUnit: "" }}
                            />
                          </section>
                        </section>
                      </Panel>
                    </Collapse>    

                    <Collapse defaultActiveKey={['1']}>
                      <Panel header={<h3>Supervisors</h3>} key="1">          
                        <section className="supervisors">
                          <h3>Supervisors</h3>
                          <section>
                            <Transfer
                              titles={["Excluded", "Included"]}
                              dataSource={_.sortBy(_.filter(supervisors, e => e.role !== "disabled"),['label'])}
                              showSearch
                              filterOption={filterOption}
                              targetKeys={state.supervisorNumbers}
                              onChange={handleSupervisorChange}
                              render={item => item.title}
                              locale={{ itemUnit: "", itemsUnit: "" }}
                            />
                          </section>
                        </section>
                        </Panel>
                    </Collapse> 

                    <section className="submit">
                      <Button {...btnProps} icon="fileProtect" onClick={generatePDF}>
                        View
                      </Button>
                      <Button {...btnProps} icon="download" onClick={downloadPDF}>
                        Download
                      </Button>
                    </section>
                  </div>
                </TabPane>
                <TabPane tab="Daily" key="daily" forceRender={true}>
                  <div className="leftColumn" style={{ width: "300px" }}>
                    {/*<Sider className="toolbar noPrint">*/}
                      <section className="reportType">
                        <h3>Date</h3>
                        <Table style={{ width:"100%"}}>
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <DatePicker allowClear={false} value={moment(state.displayDate)} onChange={handleDateChange} />
                              </TableCell>
                            {/*
                              <TableCell>
                                <Checkbox 
                                    defaultChecked={true}
                                    onChange={e => handleIncludeChange({ field: "includeNoTeams", value: e.target.checked })}
                                >
                                  Include Employees<br> not in a Team</br>
                                </Checkbox>
                              </TableCell>
                            */}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </section>
                      <section className="reportType">
                        
                        <h3>Groups</h3>
                        <div style={{float: "left", width: "50%"}}>
                        <GroupSelector
                          tagIds={state.tagIds}
                          handleChange={sel => {
                            setState({ ...state, tagIds: sel })
                          }}
                        />
                        </div>
                        <div style={{float: "left", width: "50%", paddingLeft: "10px" }}>
                        <Checkbox 
                          defaultChecked={true}
                          onChange={e => handleIncludeChange({ field: "includeNoTeams", value: e.target.checked })}
                        >
                          Include Employees not in a Team
                        </Checkbox>
                        </div>
                      </section>
                      {/*
                      <section>
                        <Checkbox 
                           defaultChecked={true}
                           onChange={e => handleIncludeChange({ field: "includeNoTeams", value: e.target.checked })}
                        >
                         Include Employees not in a Team
                        </Checkbox>
                      </section>
                       */}
                      <section className="reportType">
                        <Table style={{ width:"100%"}}><TableBody>
                          <TableRow>
                            <TableCell>
                              <h3>Watch Commander<> </>
                                <Tooltip title="Add a Watch Commander">
                                  <Icon className="addSupervisor" type="plus-circle" theme="filled" onClick={() => addWatchCmdr()} />
                                </Tooltip>
                              </h3>
                              <strong>
                                Command Label
                                  <Input
                                    allowClear={true}
                                    defaultValue={state.cmdLabel}
                                    placeholder=""
                                    onBlur={setCmdLabel}
                                  />
                              </strong>
                              <div className="supervisors">{renderWatchCmdr()}</div>
                            </TableCell>
                          </TableRow>
                          {/*
                          <TableRow>
                            <TableCell>
                              <Input
                                defaultValue={state.watchTitle}
                                placeholder=""
                                handleChange={setWatchTitle}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <SupervisorSelect value={state.watchCommander} handleChange={setWatchCommander} />
                            </TableCell>
                          </TableRow>
                          */} 
                        </TableBody></Table>
                      </section>
                      <section className="reportType">
                        <Table style={{ width:"100%"}}><TableBody>
                          <TableRow>
                            <TableCell>
                              <h3>Supervisors<> </>
                                <Tooltip title="Add a Supervisor">
                                  <Icon className="addSupervisor" type="plus-circle" theme="filled" onClick={() => addSupervisor()} />
                                </Tooltip>
                              </h3>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>
                                <strong>
                                  Supervisor Label
                                  <Input
                                    allowClear={true}
                                    size={'large'}
                                    defaultValue={state.superLabel}
                                    placeholder=""
                                    onBlur={setSuperLabel}
                                  />
                                </strong>
                              <div className="supervisors">{renderSupervisor()}</div>
                            </TableCell>
                          </TableRow>
                          {/*
                          <TableRow>
                            <TableCell>
                              <Input
                                defaultValue={state.superLabel}
                                placeholder=""
                                handleChange={setSuperLabel}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Input
                                defaultValue={state.westTitle}
                                placeholder=""
                                handleChange={setWestTitle}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <SupervisorSelect value={state.westSupervisor} handleChange={setWestSupervisor} />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <Input
                                defaultValue={state.eastTitle}
                                placeholder=""
                                handleChange={setEastTitle}
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              <SupervisorSelect value={state.eastSupervisor} handleChange={setEastSupervisor} />
                            </TableCell>
                          </TableRow>
                          */}
                        </TableBody></Table>
                      </section>
                      <section className="submit">
                        <Button {...btnProps} icon="fileProtect" onClick={dailyGeneratePDF}>
                          View
                        </Button>
                        <Button {...btnProps} icon="download" onClick={dailyDownloadPDF}>
                          Download
                        </Button>
                      </section>
                  </div>
                </TabPane>
              </Tabs>
          </div>
        </Sider>
        <div className="rightColumn" id="reportPlaceholder">
          <div id="showspinner"></div>
					<div id="noDataMessage" style={{ display:"table", width:"100%", height:"100%" }}>
						<div style={{ display:"table-cell", verticalAlign:"middle" }}>
        			<div style={{ position:"relative", width:"100%", textAlign:"center" }}>
              <span><h1>No Data Found</h1></span>
        			</div>
        		</div>
        	</div>
        </div>
      </div>
    </React.Fragment>
  )
}
//	<div id="noDataMessage" style={{ display:"table", width:"100%", height:"100%", display:"none" }}>
export default Reports
