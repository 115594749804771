import React, { useMemo, useState, useEffect } from "react"
import { REQ, reqOptions, store, Can, getRole } from "../store/psrs"
import useSWR from "swr"
import DataTable from "react-data-table-component"
import { Tag, Button, Tooltip, Switch } from "antd"
import { Search } from "./Admin/fields"
import { Link } from "react-router-dom"
import uniqid from "uniqid"
import _ from "lodash"
import stc from "string-to-color"
import "react-base-table/styles.css"
import uuid from "uuid"

export const Employees = props => {
  const [state, setState] = useState({
    pk: "number",
    model: "employee",
    ids: [],
    //initialRecords: store.get("_Employees") || undefined,
    initialRecords: undefined,
  })

 
  const key = `${store.get("api")}/api/employees`
  const fetcher = async url => {
    const options = { ...reqOptions }
    const data = await fetch(url, options).then(res => res.json())

    // filter out disabled by default
    let filterData = data.filter(e => e.role !== 'disabled')

   // setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    setRecords(state.ids.length ? _.filter(filterData, r => state.ids.includes(r.id)) : filterData)
    return data
  }
  const { data, mutate } = useSWR(key, fetcher) //, { initialData: state.initialRecords })

  /*
  const SilentInvite = ({ row }) => {
    const btnOpts = {
      disabled: !!row.email ? false : true,
      onClick: async () => {
        console.log(`SILENT INVITE: ${row}`)
        const RES = await REQ(`/api/auth0/silent_invite/${row.number}/${row.email}`)
        console.log("SILENT INVITE RES", RES)
      },
    }
    return <Button  style={row.auth0User ? { background: "green", borderColor: "black" } :{ background: "yellow", borderColor: "black" } } {...btnOpts}>Silent Invite</Button>
  }
  */

  const [records, setRecords] = useState(state.initialRecords || [])
  const [showDisabled, setShowDisabled] = useState(false)

  const DisableToggle = () => {
    //{..._props} 
    return <Switch 
      size="small" 
      checked={showDisabled} 
      //defaulChecked={showDisabled} 
      onClick={ (value) =>{
       console.log('ShowDisabled -> ', value)
       setShowDisabled(value)

      }} /> 
   }

  useEffect(() => {
    //if (data && data.length) store.set("_Employees", data)

    if (data && data.length) {
      if (_.isArray(data) && data.length) {
          const employees = data.map(e => {
            return _.pick(e, ["fullName", "number", "jobTitle", "firstName", "lastName", "role","businessUnitCode"])
          }) 
          store.set("Employees", employees)
      }
    }
    if (data && Array.isArray(data) && data.length > 0) {
       // filter out disabled by default
       let filterData
       if (showDisabled) {
        filterData = data && data.filter ? data.filter(e => e.role === 'disabled') : data

       } else {
          filterData = data && data.filter ? data.filter(e => e.role !== 'disabled') : data

       }
       
      //setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
      setRecords(state.ids.length ? _.filter(filterData, r => state.ids.includes(r.id)) : filterData)

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    console.log('x')
    //setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    
    if (data && Array.isArray(data) && data.length > 0) {
      // filter out disabled by default
      let filterData
      if (showDisabled) {
       filterData = data.filter(e => e.role === 'disabled')

      } else {
         filterData = data.filter(e => e.role !== 'disabled')

      }

      if (state.ids.length === data.length) {
        setRecords(state.ids.length ? _.filter(filterData, r => state.ids.includes(r.id)) : filterData)
      } else {
        setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : filterData)

      } 

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ids])

  useEffect(()=>{
    console.log('showDisabled -> ',showDisabled)
    let filterData
    if (_.isArray(data) && data.length) {
      if (data) {
        if (showDisabled) {
          filterData = data.filter(e => e.role === 'disabled')
        } else {
          filterData = data.filter(e => e.role !== 'disabled')
        }
      }
    }

    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : filterData)

    mutate(key)
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showDisabled])

  const columns = useMemo(
    () => [
      {
        name: "Role",
        sortable: true,
        //selector: row => <Tag>{row.role}</Tag>,
        selector: "role"
      },
      {
        name: "Number",
        sortable: true,
        selector: row => parseInt(row.number),
        cell: row => <Link to={`/admin/employees/${row.number}`}>{row.number}</Link>,
      },
      {
        name: "First",
        sortable: true,
        selector: "firstName",
      },
      {
        name: "Last",
        sortable: true,
        selector: "lastName",
      },
      {
        name: "Job Title",
        sortable: true,
        selector: "jobTitle",
      },
      {
        name: "Email",
        sortable: true,
        selector: "email",
      },
      /*
      {
        name: "Groups",
        selector: row => {
          const tags = row.tags.map(tag => <Tag key={`${row.id + tag.id}${tag.id}`}>{tag.name}</Tag>)
          return <div>{tags}</div>
        },
      },
      */
      {
        name: "Supervisor",
        sortable: true,
        minWidth: "20ch",
        selector: row => (
          <div>
            {!!row.supervisor && (
              <div>
                <span className="pill"> {row.supervisor.jobTitle} </span> {row.supervisor.lastName}
              </div>
            )}
          </div>
        ),
      },
      {
        name: "Special",
        minWidth: "20ch",
        wrap: true,
        allowOverflow: true,
        selector: row => {
          const { specialties } = row
          return (
            <div className="specialty_pills">
              {specialties.map(s => (
                <span key={uniqid()} className="pill" style={{ backgroundColor: stc(`${s.name}a2`), fontWeight: 600 }}>
                  {s.abbr}
                </span>
              ))}
            </div>
          )
        },
      },
      {
        name: "Business Unit",
        sortable: true,
        selector: "businessUnit.name",
      },
      {
        name: "Code",
        selector: row => <Tag>{row.businessUnit.code}</Tag>,
      },
      {
        name: "Actions",
        selector: row => { 
          const btnOpts = {
            disabled: row.email || row.auth0User ? false : true,
            onClick: async () => {
              //console.log(`SILENT INVITE: ${row.number}`)
              const RES = await REQ(`/api/auth0/silent_invite/${row.number}/email`)
              console.log("SILENT INVITE RES", RES)
              mutate()
            },
          }
          return (
            <Can I="see:SilentInvite">
              <div className="row_actions">
                <Tooltip title="Silent Invite and Activate User">
                  <Button 
                    icon="user-add"
                    shape="circle" 
                    type={row.auth0User? "solid": "dashed"}
                    style={row.auth0User ? { background: "lightgreen", borderColor: "black" } :{ background: "lemonchiffon", borderColor: "black" } } {...btnOpts}
                  />
                </Tooltip>
              </div>
            </Can> 
          )
        }       
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  )

  const searchProps = { key: "search", state, setState, mutate, url: key }
  const actions = [<div  key={uuid()}><small>Show Disabled{' '}</small><DisableToggle  key={uuid()} /></div>,<Search {...searchProps} /> ]

  const tableProps = {
    data: records,
    actions,
    columns,
    noDataComponent: "Loading",
    theme: "material",
    keyField: "number",
    title: "Employees",
    defaultSortField: "lastName",
    className: "EmployeesTable",
    fixedHeader: true,
    expandableRows: false,
    highlightOnHover: true,
    overflowYOffset: "0px",
    //selectableRows: true,
    onSort: e => {
      console.log("onSort", e)
    },
  }
  //const className = "view_content"
  const allowAccess = getRole() === 'admin' || getRole() === 'supervisor' ? true : false

  return (
     allowAccess &&
    <div data-cy="RecordsTable">
      <DataTable {...tableProps} /> 
    </div>
    
  )
}

export default Employees
