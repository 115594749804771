import React, {useState, useEffect} from "react"
import * as F from "../../Forms"
import { session, Can  } from "../../../store/psrs"
import { Row, Col, Input } from "antd"
import { CheckBox } from "../../Forms"

import clean from "clean-deep"
// import _ from "lodash"

const { TriToggle, BusinessUnitSelect, SubLedgerSelect } = F
const { AssignmentSelect, DurationSelect } = F
const { TagSelect, TextInput } = F
const { EmployeeSelect, SupervisorSelect } = F

const clearFields = (type) => {
  
  var  clearableFields =  {
    'clearOpAssignment': true,
    'clearAssignmentId': true, 
    'clearBusinessUnitCode': true,
    'clearChargeOutCode': true,
    'clearPayTypeCode': true,
    'clearSubLedger': true,
    'clearGroups': true,
    'clearNotes': true, 
  }

  return clearableFields[type] || false
}

const getClearFields = (type) => {
  
  var  clearableFields =  {  
    'clearOpAssignment' : 'operationalAssignment',
    'clearAssignmentId': 'assignmentId', 
    'clearBusinessUnitCode': 'businessUnitCode',
    'clearChargeOutCode': 'costCenterChargeOutCode',
    'clearPayTypeCode': 'payTypeCode',
    'clearSubLedger': 'subLedgerCode',
    'clearGroups': 'tagIds',
    'clearNotes': 'notes', 
  }

  return clearableFields[type] || false
}


const Fields = props => {
  const { update, setUpdate } = props
  const {recsUpdated, setRecsUpdated} = props

  //const modUpdate = update ? update : {}
  let modUpdate = update ? update : {}
  const [ clearCheckBoxes, setClearCheckBoxes] = useState(false) 
  const [mounted, setMounted] = useState(false)
  const [state, setState] = useState({
    clearOpAssigment: false,
    clearAssigmentId: false,
    clearBusinessUnitCode: false,
    clearChargeOutCode: false,
    clearPayTypeCode: false,
    clearSubLedger: false,
    clearGroups: false,
    clearNotes: false, 
  })

  const handleFieldChange = args => {
    // console.log("Records/Shifts/Fields > handleFieldChange", args)
    const { field, value } = args
    const _update = {}

    if ( value === null || value === '') {
      _update[field] = null
    } else {
      _update[field] = value
    }

    if (field === 'approved' && value !== null) {

      if (value) {
        _update.rejected = false
        _update.approved = true
       // _update.submit = true
        _update.approvedByNumber = session.get('CurrentUser').number
        _update.isCollision = false
      } else {
        _update.rejected = false
        _update.approved = false
        //_update.submit = false
        _update.approvedByNumber = null
      }
    }

    if (field === 'rejected' & value !== null) {

      if (value) {
        _update.rejected = true
        _update.approved = false
        _update.approvedByNumber = session.get('CurrentUser').number
      } else {
        _update.rejected = false
        _update.approved = false
        _update.approvedByNumber = null
      }
    }

    if ( clearFields(field) ) {
      delete _update[field]
      delete update[field]

      if (value) {
        _update[getClearFields(field)] = null
      }
        
    }

    const Updated = { ...update, ..._update }
    let Pruned = clean(Updated)

    if ( (field === 'approved' && value !== null) || ( field === 'rejected' && value !== null) ) {
      Pruned = Updated
    }


    if (( field === 'clearOpAssignment')) {
      modUpdate.operationalAssignment=null 
      
    }

   if (clearFields(field)) {
      Pruned = Updated
    } 
  
    setUpdate(Pruned)
  }


  const OpAssignmentInput = ({ defaultValue, placeholder, field, onInputBlur }) => {
    const [inputState, setInputState] = useState("");
    useEffect(() => {
      setInputState(defaultValue);
    }, [defaultValue]);
    return <Input field={field} placeholder={placeholder} value={inputState} onChange={(e) => setInputState(e.target.value)} onBlur={onInputBlur} />;
  };

  const ShiftNotes = ({ defaultValue, placeholder, field, onInputBlur, opts, rows }) => {
    const [inputState, setInputState] = useState("");
    useEffect(() => {
      setInputState(defaultValue);
    }, [defaultValue]);
    return <TextInput {...opts} field={field} rows={rows} placeholder={placeholder} value={inputState} onChange={(e) => setInputState(e.target.value)} onBlur={onInputBlur} />;
  };

  useEffect(() => {
    if (mounted && state) {
      const keys = Object.keys(state)   
      for (let k of keys) {
        setState({k: state[k]})
      }
      setClearCheckBoxes(false)
    } else {
      setMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCheckBoxes])


 // useEffect(() => {
 //   if (mounted && modUpdate) {
 //     setModUpdate({})
 //   } else {
 //     setMounted(true)
 //   }
 // }, [modUpdate])

  useEffect(() => {
    if (mounted && recsUpdated) {
      setState(false)
      setRecsUpdated(false)
      //forceUpdate()
    } else {
      setMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recsUpdated])


  const opts = {
    allowClear: true,
    unstyled: "yup",
    handleChange: handleFieldChange,
  }

  return (
    <section className="recordsFilter">
      <ul className="unstyledList">
        <div id="my-tab-content" className="tab-content">
          <li>
            <p style={{color:'red'}}><b><small>Editing Records</small></b></p>
          </li>
          <li>
            <div className="inputs">
            
              <Can I="edit:TimeEntry:process">
                <div className="input">
                  <label>Employee</label>
                  <EmployeeSelect {...opts} field="employeeNumber" value={update.employeeNumber} placeholder="Change Employee" />
                </div>
                <div className="input">
                  <label>Supervisor</label>
                  <SupervisorSelect {...opts} field="superNumber1" value={update.superNumber1} placeholder="Change Supervisor" />
                </div>
              </Can>
              
              <div className="input">
                <label>Operational Assignment</label>
                <OpAssignmentInput
                    field="operationalAssignment"
                    placeholder="Change Operational Assignment"
                    defaultValue={modUpdate.operationalAssignment || null}
                    onInputBlur={e => {
                      handleFieldChange({ field: "operationalAssignment", value: e.target.value })
                    }}
                />
              </div>
              <div className="input">
                <label>Assignment</label>
                <AssignmentSelect {...opts} field="assignmentId" value={modUpdate.assignmentId} />
              </div>
            </div>
          </li>
          <li>
            <div className="inputs">
              <div className="input">
                <label>FTO Hours</label>
                <DurationSelect {...opts} field="ftoHours" value={modUpdate.ftoHours} />
              </div>
              <div className="input">
                <label>Dive Hours</label>
                <DurationSelect {...opts} field="diveHours" value={modUpdate.diveHours} />
              </div>
            </div>
          </li>
          <small><li>
            <div className="inputs">
              <Row>
                <Col span={12}>
                <span style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}>Remove</span>
                <span>&nbsp;</span>
                <div>
                      <span>&nbsp;</span>
                        <CheckBox 
                          value={state.clearOpAssigment}
                          field='clearOpAssignment'
                          recsUpdated = {recsUpdated}
                          setRecsUpdated = {setRecsUpdated}
                          handleChange={e => {
                            handleFieldChange({field: 'clearOpAssignment', value: e.value})
                          }}>
                          </CheckBox>
                          <span>&nbsp;</span>
                          <label>Operational Assignmentt</label>
                  </div>
                  <div>
                      <span>&nbsp;</span>
                      <CheckBox 
                          value={state.clearAssigmentId}
                          field='clearAssignmentId'
                          recsUpdated = {recsUpdated}
                          setRecsUpdated = {setRecsUpdated}
                          handleChange={e => {
                            handleFieldChange({field: 'clearAssignmentId', value: e.value})
                          }}>
                          </CheckBox>
                          <span>&nbsp;</span>
                          <label>Assignment</label>
                  </div>
                  {/*} <div>
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearPayTypeCode}
                        field='clearPayTypeCode'
                        handleChange={e => {
                          handleFieldChange( { field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                      <span>&nbsp;</span>
                      <label>Clear Pay Type </label>
                    </div>
                    <div>
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearBusinessUnitCode}
                        field='clearBusinessUnitCode'
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                        <span>&nbsp;</span>
                        <label>Clear Business Unit</label>
                    </div>
                      */}
                    <div>
                    <Can I="edit:TimeEntry:process">
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearChargeOutCode}
                        field='clearChargeOutCode'
                        recsUpdated = {recsUpdated}
                        setRecsUpdated = {setRecsUpdated}
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                      
                        <span>&nbsp;</span>
                        <label>Charge Out</label>
                        </Can>
                    </div>
                </Col>
                <Col span={10}>
                <span style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}>&nbsp;</span>
                <span>&nbsp;</span>
                  <div>
                  <Can I="edit:TimeEntry:process">
                      <span>&nbsp;</span>
                      
                        <CheckBox 
                        value={state.clearSubLedger}
                        field='clearSubLedger'
                        recsUpdated = {recsUpdated}
                        setRecsUpdated = {setRecsUpdated}
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                        <span>&nbsp;</span>
                        <label>SubLedger</label>
                        </Can>
                    </div>
                    <div>
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearGroups}
                        field='clearGroups'
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                        <span>&nbsp;</span>
                      <label>All Groups</label>
                  </div>
                  <div>
                    <span>&nbsp;</span>
                      <CheckBox 
                      value={state.clearNotes}
                      field='clearNotes'
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      handleChange={e => {
                        handleFieldChange({ field: e.field, value: e.value } )
                      }}>
                      </CheckBox>
                      <span>&nbsp;</span>
                      <label>Notes</label>
                  </div>
                </Col>
              </Row>
            </div>
          </li></small>
          <li>
            <div className="inputs">
              <Row>
                <Col span={10}>
                  <div className="input">
                  <TriToggle 
                      {...opts} 
                      field="available" 
                      value={modUpdate.available}
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["available", "not available"]} /> 
                  </div>
                  <div className="input">
                  <TriToggle 
                      {...opts} 
                      field="submit" 
                      value={modUpdate.submit}
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["submit", "un submit"]} />
                  </div>
                  <div className="input">
                  <TriToggle 
                      {...opts} 
                      field="isSick" 
                      value={modUpdate.isSick}
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["sick", "not sick"]} /> 
                  </div>
                  <div className="input">
                    <TriToggle 
                        {...opts} 
                        field="locked" 
                        value={modUpdate.locked}
                        recsUpdated = {recsUpdated}
                        setRecsUpdated = {setRecsUpdated}
                        labels={["locked", "unlock"]} /> 
                  </div>
                </Col>
                <Col span={10}>
                  <div className="input">
                  <TriToggle
                      {...opts}
                      field="requestedOff"
                      value={modUpdate.requestedOff}
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["requested off", "not requested off"]}
                    /> 
                  </div>
                  <div className="input">
                  <TriToggle 
                      {...opts} 
                      field="approved" 
                      value={modUpdate.approved}
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["approved", "not approved"]} /> 
                  </div>
                  <div className="input">
                  <TriToggle 
                      {...opts} 
                      field="rejected" 
                      value={modUpdate.rejected} 
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["rejected", "not rejected"]} /> 
                  </div>
              </Col>
              </Row>
            </div>
          </li>
          <Can I="edit:TimeEntry:process">
            <li>
              <div className="inputs">
                <div className="input">
                  <label>Business Unit</label>
                  <BusinessUnitSelect {...opts} field="businessUnitCode" value={modUpdate.businessUnitCode} />
                </div>
                <div className="input">
                  <label>Charge Out</label>
                  <BusinessUnitSelect {...opts} field="costCenterChargeOutCode" value={modUpdate.costCenterChargeOutCode} />
                </div>
                <div className="input">
                  <label>Sub Ledger</label>
                  <SubLedgerSelect {...opts} field="subLedgerCode" value={modUpdate.subLedgerCode} />
                </div>
              </div>
            </li>
          </Can>
          <li>
            <div className="inputs">
              <div className="input">
                <label>Groups</label>
                <TagSelect {...opts} field="tagIds" value={modUpdate.tagIds} />
              </div>
              <div className="input">
                <label>notes</label>
                {/*<TextInput {...opts} field="notes" value={modUpdate.notes} />*/}
                <ShiftNotes
                    field="notes"
                    rows={2}
                    opts={opts}
                    defaultValue={modUpdate.notes || null}
                    onInputBlur={e => {
                      handleFieldChange({ field: "notes", value: e.target.value })
                    }}
                />
              </div>
            </div>
          </li>
          <li>
            <p style={{color:'red'}}><b><small>Editing Records</small></b></p>
           <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</ small></b></p>
          </li>
        </div>
      </ul>
    </section>
  )
}

export default Fields
