// This file should match in both CLIENT & MOBILE (with the exception of module paths)
// import { createContext } from "react"
import { createContextualCan } from "@casl/react"
// import { createCanBoundTo } from "@casl/react"
// import { ability } from "../ability"
// import { store } from "../store/psrs"

// export const AbilityContext = createContext();

import {AbilityContext} from "../store/context"

export const Can = createContextualCan(AbilityContext.Consumer);

// export const Can = createContextualCan(ability(user))
