import React, { useState } from "react"
import { store } from "../../store/psrs"
import Select from "react-select"
// import {AssignmentSelectOptions} from "./_SelectOptions"
import _ from "lodash"

export const AssignmentSelect = props => {
  const field = "assignmentId"
  const { record } = props
  const id = record ? record.id : props.id
  const value = record ? record[field] : props.value

  const Assignments = store.get("Assignments") || []
  let AssignmentSelectOptions = Assignments.map(r => ({ value: r.id, label: r.name }))
  AssignmentSelectOptions = _.sortBy(AssignmentSelectOptions, ['label']) 

  const [state, setState] = useState({
    options: AssignmentSelectOptions,
    value: value ? _.find(AssignmentSelectOptions, { value: value }) : null,
    sql: null,
  })


  const selected = _.filter(AssignmentSelectOptions, a => {
    return value ? value === a.value : null
  })

  let _selected = state.value
  if (state.value !== selected) _selected = selected


  const selectProps = {
    isDisabled: props.disabled,
    className: "psrs-select",
    classNamePrefix: "psrs",
    placeholder: "Acting Role",
    isClearable: true,
    isMulti: false,
    options: AssignmentSelectOptions,
    defaultValue: _selected, //state.value,
    value: _selected,
    onChange: (select, action) => {
      const val = select && select.value ? select.value : null
      const sql = val ? `"${field}" IS NOT NULL AND "${field}" = '${val}'` : null
      setState({ ...state, sql })
      props.handleChange({ ...props, value: val, field, id, sql })
      return
    },
  }

  return <Select {...selectProps} />
}

export default AssignmentSelect
