import React, { useRef, useState } from "react"
import IdleTimer from "react-idle-timer"
import { Modal } from "antd"
//import SessionTimeoutDialog from "./SessionTimeoutDialog"

let countdownInterval
//let timeout

const SessionTimeout = (props) => {


  const { isAuthenticated, logOut} = props
  let {timeout} = props

  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false)
  const [timeoutCountdown, setTimeoutCountdown] = useState(0)
  const idleTimer = useRef(null)


  const clearSessionTimeout = () => {
      //console.log('clearSessionTimeout')
    clearTimeout(timeout)
  }

  const clearSessionInterval = () => {
      //console.log('clearSession')
    clearInterval(countdownInterval)
  }

  const handleLogout = async (isTimedOut = false) => {
      //console.log('handleLogout')
    try {
        setTimeoutModalOpen(false)
        clearSessionInterval()
        clearSessionTimeout()
        logOut()
     } catch (err) {
        console.error(err)
      }
  }

  const handleContinue = () => {
      //console.log('handleContinue')
    setTimeoutModalOpen(false)
    clearSessionInterval()
    clearSessionTimeout()
  }

  const onActive = () => {
      //console.log('onActive')
    if (!timeoutModalOpen) {
      clearSessionInterval()
      clearSessionTimeout()
    }
  }

  const onIdle = () => {
      //console.log('onIdle')
    const delay = 1000 * 1
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 45
        setTimeoutModalOpen(true)
        setTimeoutCountdown(countDown)
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown)
          } else {
            handleLogout(true)
          }
        }, 1000)
      }, delay)
    }
  }

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={timeout}
      />
      <Modal
        title="Session Timeout"
        visible={timeoutModalOpen}
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        onOk={() => {
          handleContinue()
        }}
        onCancel={() => {
          handleLogout()
        }}
        okText="Stay"
        cancelText="Logout"
        >
        <p>
            The current session is about to expire in{" "}
            <span>{timeoutCountdown}</span> seconds.
        </p>
      </Modal>
    </>
  )
}

export default SessionTimeout