import React from "react"
import {store} from "../../store/psrs"
import moment from "moment-timezone"
import { DatePicker } from "antd"
const { RangePicker } = DatePicker


// const DEBUG = false
const DDEBUG = true

export const DateRangeFilter = props => {
  const { handleChange } = props
  const value = props.value && props.value.length === 2 ? props.value.map(p => moment(p)) : null
  // DEBUG && console.log("DateRangeFilter", value)
  const onChange = (date, dateString) => {    
    if (dateString[0] === dateString[1] || !dateString[1]) {
      DDEBUG && console.log("SINGLE DATE SELECTED", date)

      //const utcStartQ = moment(dateString[0] + ' 00:00:00').utc().format('YYYY-MM-DD');
      const utcStartQ = moment(dateString[0] + ' 00:00:00').utc().format();

      const utcEndQ = !!dateString[1] ? moment(dateString[1] + ' 24:00:00').utc().format() : moment(dateString[0] + ' 24:00:00').utc().format();
  
      //-original //--const sql = `"start" BETWEEN '${dateString[0]} 00:00:00' AND '${dateString[1]} 23:59:59'`
      //const sql = `"start" AT TIME ZONE '${userTimeZone}' BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
      //const sql = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
      let sql=''
      if (props.model === 'timeEntry') {
        sql =  `"shiftId" in (select "id" from shifts where "start"  BETWEEN '${utcStartQ}' AND '${utcEndQ}')`
      } else {
        sql = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
      }

      handleChange({ ...props, field: "range", value: date, sql })
    } else {
      DDEBUG && console.log("DATE RANGE SELECTED")

      const range = dateString
      const current = range.map(d => {
        if (d && d.value) {
          return  moment(d.value)
        } else {
          return moment(d)
        }
      })

      const YYYMMDD = current.map(c => c.format("YYYY-MM-DD"))
      const utcStartQ = moment(YYYMMDD[0] + ' 00:00:00').utc().format();
      const utcEndQ = moment(YYYMMDD[1] + ' 24:00:00').utc().format();

      //--/-const sql = `"start" BETWEEN '${dateString[0]}' AND '${dateString[1]}'`
      
      //const sql = `"start" AT TIME ZONE '${userTimeZone}' BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
      //const sql = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`

      let sql=''
      if (props.model === 'timeEntry') {
        sql =  `"shiftId" in (select "id" from shifts where "start"  BETWEEN '${utcStartQ}' AND '${utcEndQ}')`
      } else {
        sql = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
      }


      handleChange({ ...props, field: "range", value: date, sql })
    }
  }

  const currentPP = store.get('PayPeriodToday').range

  const selPP = [value[0].format(), value[1].format()]
  const prevPP = [moment(selPP[0]).subtract(2,'week').format(),moment(selPP[1]).subtract(2,'week').format() ]
  const nextPP = [moment(selPP[0]).add(2,'week').format(),moment(selPP[1]).add(2,'week').format() ]

  return <RangePicker data-cy="date-range-picker" defaultValue={value} onChange={onChange} allowClear={false} 
  
    ranges={{
      '<<': [moment(prevPP[0]), moment(prevPP[1])],
      'Current': [moment(currentPP[0].value), moment(currentPP[1].value)],
      '>>': [moment(nextPP[0]), moment(nextPP[1])]
    }}
    />
}

export default DateRangeFilter
