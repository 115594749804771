import React, { useEffect, useState } from "react"
import useSWR from "swr"
import moment from "moment-timezone"
import _ from "lodash"
import uuid from "uuid"
import { useStateIfMounted } from "use-state-if-mounted"
import { reqOptions, store, Emitter, REQ, fetch, employeeNumber, getRole} from "../store/psrs"
//import { useMyContext, useAbilityContext } from "../store/useMyContext"
import { EmployeeSelect, SupervisorSelect, StatsCounter, BusinessUnitSelect } from "./Forms"
import { PayPeriodLockTable } from "./PayPeriodStats/PayPeriodLockTable"
import { Layout, Card, Col, Row, Checkbox, Tabs, Tooltip, Spin, Skeleton, Modal, Button } from "antd"
import { FieldTimeOutlined, CalendarOutlined, HddOutlined} from "@ant-design/icons"

const { TabPane } = Tabs

export const PayPeriodStats = props => {
  //const context = useMyContext()
  //const ability = useAbilityContext()

  const payPeriod = store.get("PayPeriod")
  const filters = [payPeriod.start, payPeriod.end]
  const payPeriodId = payPeriod.id
  // const query = props.query(payPeriodId)

  //const deadline = moment(payPeriod.end)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()
  const now = moment().utc().tz(userTimeZone)
  const exp = moment(payPeriod.end).utc().tz(userTimeZone)
  
  // display
  //const days = diffDuration.days() > 0 ? diffDuration.days() : 0
  //const hours = diffDuration.hours() > 0 ? diffDuration.hours() : 0
  //const minutes  = diffDuration.minutes() > 0 ? diffDuration.minutes() : 0
  
  const days = exp.diff(now, 'days')
  const hours = exp.subtract(days, 'days').diff(now, 'hours')
  const minutes = exp.subtract(hours, 'hours').diff(now, 'minutes')
  

  // eslint-disable-next-line
  const [loading, setLoading] = useState(false)
  const [topTabsLoading, setTopTabsLoading] = useState(false)
  const [botTabsLoading, setBotTabsLoading]= useState(false)
  const [siderLoading, setSiderLoading] = useState(false)
  const [activeKey, setActiveKey] = useState(null)
  // const initial = session.get(page)
  
  const [isESModalVisible,setIsESModalVisible] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(false)

  //---const url = `${store.get("api")}/api/time_entries/stats`
  const url = `${store.get("api")}/api/statistics`

  const me = store.session.get("CurrentUser")
  const myRole = getRole() //store.session.get("role")
  //const allEmps = store.session.get("Employees")
 
  const [mounted, setMounted] = useState(false)
 
  //const [selectedSupervisor, setSelectedSupervisor] = useStateIfMounted(myRole === 'admin' ? null : me) //useState(me)
  const [selectedSupervisor, setSelectedSupervisor] = useStateIfMounted(null)

  const [selectedEmployee, setSelectedEmployee] = useStateIfMounted({ number: 0 })
  const [selectedBU, setSelectedBU] = useStateIfMounted(0)

  const [types, setTypes] = useStateIfMounted({})
  const [payCodes, setPayCodes] = useStateIfMounted({})
  const [empPayCodes, setEmpPayCodes] = useStateIfMounted({})

  //Bu
  const [buTypes, setBuTypes] = useStateIfMounted({})
  const [buCategory, setBuCategory] = useStateIfMounted({})
  const [buCatPayType, setBuCatPayType] = useStateIfMounted({})
  const [buPayType, setBuPayType] = useStateIfMounted({})

  //const [totalHours, setTotalHours] = useState(0)
  const [totalTimeEntry, setTotalTimeEntry] = useStateIfMounted(0)
  const [totalShifts, setTotalShifts] = useStateIfMounted(0)
  //const [totalProcessed, setTotalProcessed] = useState(0)
  const [totalCat, setTotalCat] = useStateIfMounted(0)
  const [totalCode, setTotalCode] = useStateIfMounted(0)
  const [miscStats, setMiscStats] = useStateIfMounted([
    {
      processed: "0",
      notProcessed: "0",
      rejected: "0",
      notRejected: "0",
      approved: "0",
      notApproved: "0",
      submitted: "0",
      notSubmitted: "0",
    },
  ])
  const [shiftStats, setShiftStats] = useStateIfMounted([
    {
      otShifts: "0",
      ptoShifts: "0",
      sickShifts: "0",
      diveShifts: "0",
      ftoShifts: "0",
      otHours: 0,
      ptoHours: 0,
      sickHours: 0,
      diveHours: 0,
      ftoHours: 0,
    },
  ])
  //const [refresh, setRefresh] = useState(true)

  const [payPeriodLock, setPayPeriodLock] = useStateIfMounted(false)
  const [shiftLock, setShiftLock] = useStateIfMounted(false)
  const [timeEntryLock, setTimeEntryLock] = useStateIfMounted(false)

  const [groupEmployee, setGroupEmployee] = useStateIfMounted(false)
  const [groupSupervisor, setGroupSupervisor] = useStateIfMounted(false)

  const [groupBusinessUnit, setGroupBusinessUnit] = useStateIfMounted(false)
  const [groupCategory, setGroupCategory] = useStateIfMounted(false)
  const [groupPayType, setGroupPayType] = useStateIfMounted(false)

  const getLockStatus = async () => {
    //console.log("getLockStatus -> ", payPeriodId)

    const lockStatus = await REQ(`/api/pay_period/locks/${payPeriodId}`, "GET").catch(err =>
      Emitter.emit("PayPeriodStats.js", { fn: "getLockStatus", error: err }),
    )
    //console.log("lockStatus -> ", lockStatus)
    setPayPeriodLock(lockStatus.payPeriodLocked)
    setShiftLock(lockStatus.shiftLocked)
    setTimeEntryLock(lockStatus.timeEntryLocked)

    Emitter.emit("ChangedLockStatus", {locked: lockStatus.payPeriodLocked} )



    //mutate(url, updated)
  }

  const fetcher = async body => {
    const options = {
      ...reqOptions,
      method: "POST",
      body: body,
    }
    setLoading(true)
    setSiderLoading(true)
    setTopTabsLoading(true)
    setBotTabsLoading(true)
    try {
      const res = await fetch(url, options)
      setLoading(true)
      setSiderLoading(true)
      setTopTabsLoading(true)
      setBotTabsLoading(true)
      setActiveKey(myRole === 'admin' ? "4" : "1")

      // add in here
      const timeEntryPayTypes = []
      const timeEntryPayCategory = []
      const timeEntryEmpPayTypes = []

      const byBu = []
      const byBuCat = []
      const byBuCatPayType = []
      const byBuPayType = []

      let _totalCode = 0
      let _totalCat = 0
      //let _totalEmpCode = 0
      const timeEntryStats = res

      const _payTypeCategories = _.sortBy(timeEntryStats.payTypeCategories, ["category"])
      _totalCat = 0
      for (let category of _payTypeCategories) {
        timeEntryPayCategory.push({ category: category.category, duration: category["duration"] })
        const _catDuration = parseFloat(category["duration"])
        _totalCat += _catDuration
      }

      const _payTypeCodes = _.sortBy(timeEntryStats.payTypeCodes, ["category", "payTypeCode"])
      _totalCode = 0
      let prevCat = ""
      let catOut = ""
      for (let code of _payTypeCodes) {
        catOut = code.category

        if (prevCat !== code.category) {
          prevCat = code.category
          catOut = code.category
        } else {
          catOut = ""
        }

        timeEntryPayTypes.push({ category: catOut, payTypeCode: code.payTypeCode, duration: code["duration"] })
        const _codeDuration = parseFloat(code["duration"])
        _totalCode += _codeDuration
      }

      const _empPayTypeCodes = _.sortBy(timeEntryStats.employeePayTypeCodes, [
        "supervisorName",
        "fullName",
        "category",
        "payTypeCode",
      ])
      //_totalEmpCode = 0
      let prevSuperName = ""
      let superOut = ""
      let prevEmpName = ""
      let empOut = ""

      for (let code_1 of _empPayTypeCodes) {
        superOut = code_1.supervisorName
        empOut = code_1.fullName

        if (prevSuperName !== code_1.supervisorName) {
          prevSuperName = code_1.supervisorName
          superOut = code_1.supervisorName
        } else {
          superOut = ""
        }

        if (prevEmpName !== code_1.fullName) {
          prevEmpName = code_1.fullName
          empOut = code_1.fullName
        } else {
          empOut = ""
        }

        timeEntryEmpPayTypes.push({
          supervisorName: superOut,
          fullName: empOut,
          category: code_1.category,
          payTypeCode: code_1.payTypeCode,
          duration: code_1["duration"],
          empSubTotal: code_1.empSubtotal,
          superSubTotal: code_1.superSubtotal,
        })
      }


      // Business Units
      let prevBusinessUnit = ""
      let businessUnitOut = ""
      let prevCategory = ""
      let categoryOut = ""
      let prevPayType = ""
      let payTypeOut = ""

      const _buTypes = timeEntryStats.buStats.byBu
      for (let buCode of _buTypes) {

        // do this with iniital data ---
        const _codeDuration_1 = parseFloat(buCode["hours"])
        _totalCode += _codeDuration_1
        //_totalCat += _totalCode
        _totalCat = _totalCode

        byBu.push({
          businessUnitCode: buCode.businessUnitCode ? buCode.businessUnitCode : 'NONE',
          businessUnitName: buCode.businessUnit ? buCode.businessUnit.name : 'NONE',
          duration: buCode.hours,
        })
      }


      prevBusinessUnit = ""
      businessUnitOut = ""
      prevCategory = ""
      categoryOut = ""
      prevPayType = ""
      payTypeOut = ""

      let _buSubTotal = 0
      let _buCatSubTotal = 0
      //let _buTotal=0
      const _buCategory = timeEntryStats.buStats.byBUCategory
      for (let buCode_1 of _buCategory) {

        businessUnitOut = buCode_1.businessUnitCode
        categoryOut = buCode_1.category
        payTypeOut = buCode_1.payTypeCode

        if (prevBusinessUnit !== buCode_1.businessUnitCode) {
          prevBusinessUnit = buCode_1.businessUnitCode
          businessUnitOut = buCode_1.businessUnitCode

          prevCategory = ''
          categoryOut = ''
          prevPayType = ''
          payTypeOut = ''

          _buSubTotal = await calcBUTotal(buCode_1.businessUnitCode, _buCategory)


        } else {
          businessUnitOut = ""

        }

        if (prevCategory !== buCode_1.category) {
          prevCategory = buCode_1.category
          categoryOut = buCode_1.category
          _buCatSubTotal = buCode_1.duration
        } else {
          categoryOut = ""

        }

        if (prevPayType !== buCode_1.payTypeCode) {
          prevPayType = buCode_1.payTypeCode
          payTypeOut = buCode_1.payTypeCode
        } else {
          payTypeOut = ""
        }


        byBuCat.push({
          businessUnitCode: buCode_1.businessUnitCode ? businessUnitOut : 'NONE',
          businessUnitName: buCode_1.name ? businessUnitOut === '' ? '' : buCode_1.name : 'NONE',
          category: categoryOut,
          duration: buCode_1.duration,
          catSubTotal: _buCatSubTotal,
          buSubTotal: _buSubTotal,
        })

      }


      prevBusinessUnit = ""
      businessUnitOut = ""
      prevCategory = ""
      categoryOut = ""
      prevPayType = ""
      payTypeOut = ""

      const _buCatPayType = timeEntryStats.buStats.byBUCatPayType
      for (let buCode_2 of _buCatPayType) {

        businessUnitOut = buCode_2.businessUnitCode
        categoryOut = buCode_2.payType.category
        payTypeOut = buCode_2.payTypeCode

        if (prevBusinessUnit !== buCode_2.businessUnitCode) {
          prevBusinessUnit = buCode_2.businessUnitCode
          businessUnitOut = buCode_2.businessUnitCode
          prevCategory = ''
          prevPayType = ''

          _buSubTotal = await calcBUTotal(buCode_2.businessUnitCode, _buCatPayType)

        } else {
          businessUnitOut = ""
        }

        if (prevCategory !== buCode_2.payType.category) {
          prevCategory = buCode_2.payType.category
          categoryOut = buCode_2.payType.category

          _buCatSubTotal = await calcCatTotal(buCode_2.businessUnitCode, buCode_2.payType.category, _buCatPayType)

        } else {
          categoryOut = ""
        }

        if (prevPayType !== buCode_2.payTypeCode) {
          prevPayType = buCode_2.payTypeCode
          payTypeOut = buCode_2.payTypeCode
        } else {
          categoryOut = ""
        }

        byBuCatPayType.push({
          businessUnitCode: buCode_2.businessUnit ? businessUnitOut : 'NONE',
          businessUnitName: businessUnitOut === '' ? '' : buCode_2.businessUnit ? buCode_2.businessUnit.name : 'NONE',
          category: categoryOut,
          payTypeCode: buCode_2.payTypeCode,
          payTypeCodeName: payTypeOut === '' ? '' : buCode_2.payType.rename ? buCode_2.payType.rename : buCode_2.payType.name,
          duration: buCode_2.hours,
          buCatSubTotal: _buCatSubTotal,
          buSubTotal: _buSubTotal,
        })
      }

      prevBusinessUnit = ""
      businessUnitOut = ""
      prevCategory = ""
      categoryOut = ""
      prevPayType = ""
      payTypeOut = ""

      const _buPayType = timeEntryStats.buStats.byBUPayType
      for (let buCode_3 of _buPayType) {

        businessUnitOut = buCode_3.businessUnitCode
        categoryOut = buCode_3.category
        payTypeOut = buCode_3.payTypeCode

        if (prevBusinessUnit !== buCode_3.businessUnitCode) {
          prevBusinessUnit = buCode_3.businessUnitCode
          businessUnitOut = buCode_3.businessUnitCode

          _buSubTotal = await calcBUTotal(buCode_3.businessUnitCode, _buPayType)

        } else {
          businessUnitOut = ""
        }

        if (prevCategory !== buCode_3.category) {
          prevCategory = buCode_3.category
          categoryOut = buCode_3.category
        } else {
          categoryOut = ""
        }

        if (prevPayType !== buCode_3.payTypeCode) {
          prevPayType = buCode_3.payTypeCode
          payTypeOut = buCode_3.payTypeCode
        } else {
          categoryOut = ""
        }

        byBuPayType.push({
          businessUnitCode: buCode_3.businessUnit ? businessUnitOut : 'NONE',
          businessUnitName: businessUnitOut === '' ? '' : buCode_3.businessUnit ? buCode_3.businessUnit.name : 'NONE',
          payTypeCode: payTypeOut,
          payTypeCodeName: payTypeOut === '' ? '' : buCode_3.payType.rename ? buCode_3.payType.rename : buCode_3.payType.name,
          duration: buCode_3.hours,
          buSubTotal: _buSubTotal,
        })
      }


      setTotalCat(_totalCat.toFixed(2))
      setTotalCode(_totalCode.toFixed(2))
      _totalCode = 0
      _totalCode = 0
      _totalCat = 0

      //setTotalHours(timeEntryStats.total)
      //setTotalProcessed(timeEntryStats.processed)
      setTotalShifts(timeEntryStats.totalShifts)
      setTotalTimeEntry(timeEntryStats.totalTimeEntries)
      setPayCodes(timeEntryPayTypes)
      setTypes(timeEntryPayCategory)
      setEmpPayCodes(timeEntryEmpPayTypes)

      setMiscStats(timeEntryStats.miscStats)
      setShiftStats(timeEntryStats.shiftStats)

      //Business Units
      setBuTypes(byBu)
      setBuCategory(byBuCat)
      setBuCatPayType(byBuCatPayType)
      setBuPayType(byBuPayType)

      //setLoading(false)
      // make sure employee only see's their time
      if (myRole === 'employee') {
        setSelectedEmployee({ number: me.number })
      }

      getLockStatus()
      setLoading(false)
      setSiderLoading(false)
      setTopTabsLoading(false)
      setBotTabsLoading(false)
      return await timeEntryStats
    } catch (err) {
      return Emitter.emit("TimeEntries/index.js", { fn: "fetcher", error: err })
    }
  }

  const handleSupervisor = async ({ field, value }) => {
    console.log("handleSupervisor -> ", field, " : ", value)
    if (value !== null) {
      setSelectedSupervisor({ number: value })
    } else {
      setSelectedSupervisor(null)
    }
    //mutate()
  }

  const handleEmployee = async ({ field, value }) => {
    console.log("handleEmployee -> ", field, " : ", value)
    setSelectedEmployee({ number: value })
    //mutate()
  }

  const handleBusinessUnit = async ({ field, value }) => {
    console.log("handleBusinessUnit -> ", field, " : ", value)
    setSelectedBU(value)
    //mutate()
  }

  const updateLockStatus = async (lockType, locked) => {
    //console.log("updateLockStatus -> ", lockType, " : ", locked)

    const update = {
      payPeriodId: payPeriodId,
      type: lockType,
      locked: locked,
    }

    const updated = await REQ(`/api/pay_period/locked/${payPeriodId}`, "POST", update).catch(err =>
      Emitter.emit("PayPeriodStats.js", { fn: "updateLockStatus", error: err }),
    )
    console.log("udpated -> ", updated)
    //const cachedPP = store.get('PayPeriod')

    //mutate(url, updated)
  }

  const calcBUTotal = async (buCodeIn, arrayIn) => {

    let buTotal = 0

    for (let el of arrayIn) {
      if (el.businessUnitCode === buCodeIn) {
        buTotal += el.duration ? parseFloat(el.duration) : el.hours ? parseFloat(el.hours) : 0
      }
    }

    return buTotal.toFixed(2)
  }

  const calcCatTotal = async (buCodeIn, catCodeIn, arrayIn) => {

    let catTotal = 0

    for (let el of arrayIn) {
      if (el.businessUnitCode === buCodeIn) {
        if (el.payType.category === catCodeIn) {
          catTotal += el.duration ? parseFloat(el.duration) : el.hours ? parseFloat(el.hours) : 0
        }
      }
      
    }

    return catTotal.toFixed(2)
  }

  const startDate = moment(filters[0])
  const endDate = moment(filters[1])

  const body = {
    start: startDate.utc().format("YYYY-MM-DDTHH:mm:ss"),
    end: endDate.utc().format("YYYY-MM-DDTHH:mm:ss"),
    payPeriodId: payPeriodId,
    statTypes:["AllEntries","AllShifts","shiftStats","ProcessedEntries","miscStats","ByBU","payTypeCategories"],

  }

  if (selectedSupervisor) {
    body["supervisor1"] = selectedSupervisor.number
  }

  if (selectedEmployee) {
    body["employeeNumber"] = selectedEmployee.number
  }

  if ( selectedBU) {
    body["businessUnitCode"] = selectedBU
  }

  const swrOpts = { revalidateOnFocus: false }
  //const { data, mutate } = useSWR(JSON.stringify(body), fetcher, swrOpts)

  const { mutate } = useSWR(JSON.stringify(body), fetcher, swrOpts)

  //console.log("data -> ", data)

  // 11-16-2021 removed
  /*
  useEffect(() => {
     if (mounted) mutate() 

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  */

  useEffect(() => {
    if (!mounted) setMounted(true)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const selectProps = {
    pk: "id",
    record: me,
    placeholder: 'Search for a Supervisor',
    storeKey: "supervisors",
    handleChange: handleSupervisor,
    id: me.id,
    isMulti: false
  }

  const employeeSelectProps = {
    pk: "id",
    record: me,
    storeKey: "supervisors",
    handleChange: handleEmployee,
    id: me.id,
    isMulti: false,
    disabled: true,
  }

  const buSelectProps = { 
    isMulti: false,
    handleChange: handleBusinessUnit
   }

  const { Content, Sider } = Layout

  const gridStyle = {
    width: "100%",
    height: "100%",
    textAlign: "center",
    overflowX: "scroll !important",
  }
  const gridStyle2 = {
    width: "100%",
    height: "100%",
    textAlign: "center",
    overflowX: "scroll !important",
  }

  const formatNumber = num => {
    if (typeof num === "string") {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return num
  }

  const EmployeePayTypeCodeRows = () => {
    //console.log("PayTypeRows", payTypeKeys)

    if (empPayCodes && empPayCodes.length) {
      return empPayCodes.map(pkey => (
        <React.Fragment key={uuid()}>
          {groupSupervisor && pkey.supervisorName !== "" && (
            <tr key={uuid()}>
              <td key={uuid()} colSpan={4}>
                <strong key={uuid()}>{pkey.supervisorName}</strong>
              </td>
              <td key={uuid()}>
                <strong key={uuid()}>{formatNumber(pkey.superSubTotal)}</strong>
              </td>
            </tr>
          )}
          {groupEmployee && pkey.fullName !== "" && (
            <tr key={uuid()}>
              <td key={uuid()}></td>
              <td key={uuid()} colSpan={3}>
                <strong key={uuid()}>{pkey.fullName}</strong>
              </td>
              <td key={uuid()}>
                <strong key={uuid()}>{formatNumber(pkey.empSubTotal)}</strong>
              </td>
            </tr>
          )}
          <tr key={uuid()}>
            {groupSupervisor ? <td key={uuid()}></td> : <td key={uuid()}>{pkey.supervisorName}</td>}
            {groupEmployee ? <td key={uuid()}></td> : <td key={uuid()}>{pkey.fullName}</td>}
            <td key={uuid()}>{pkey.category}</td>
            <td key={uuid()} className="code">
              {pkey.payTypeCode}
            </td>
            <td key={uuid()} className="duration">
              {formatNumber(pkey.duration)}
            </td>
          </tr>
        </React.Fragment >
      ))
    } else {
      return (
        <tr key={uuid()}>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
        </tr>
      )
    }
  }

  const PayTypeCodeRows = () => {
    //console.log("PayTypeRows", payTypeKeys)
    if (payCodes && payCodes.length) {
      return payCodes.map(key => (
        <tr key={key.category + uuid()}>
          <td key={uuid()}>{key.category}</td>
          <td key={uuid()} className="code">{key.payTypeCode}</td>
          <td key={uuid()} className="duration">{formatNumber(key.duration)}</td>
        </tr>
      ))
    } else {
      return (
        <tr key={uuid()}>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
        </tr>
      )
    }
  }

  const PayTypeCatRows = () => {
    //console.log("PayTypeRows", payTypeKeys)
    //console.log('types is -> ', typeof types )
    if (types && types.length) {
      const _types = types.map(key => (
        <tr key={key.category + uuid()}>
          <td key={uuid()}>{key.category}</td>
          <td key={uuid()} className="duration">{formatNumber(key.duration)}</td>
        </tr>
      ))

      return _types
    } else {
      //setRefresh(true)
      return (
        <tr key={uuid()}>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
        </tr>
      )
    }
  }

  //Business Units
  const BUTypeRows = () => {
    
    if (buTypes && buTypes.length) {
      const _bu = buTypes.map(key => (
        <tr key={key.businessUnitCode + uuid()}>
          <td key={uuid()}>{key.businessUnitCode}</td>
          <td key={uuid()}>{key.businessUnitName}</td>
          <td key={uuid()} className="duration">{formatNumber(key.duration.toFixed(2).toString())}</td>
        </tr>
      ))

      return _bu
    } else {
      //setRefresh(true)
      return (
        <tr key={uuid()}>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
        </tr>
      )
    }
  }

  const BUCategoryRows = () => {
    
    if (buCategory && buCategory.length) {
      const _bu = buCategory.map(key => (
        <React.Fragment key={uuid()}>
          { groupBusinessUnit && key.businessUnitCode !== '' && (
          <tr key={key.businessUnitCode + uuid()} >
            <td key={uuid()} colSpan={3}>
              <strong>{key.businessUnitCode} / {key.businessUnitName} </strong>
            </td>
            <td key={uuid()} style={{textAlign: 'right'}}><strong>{formatNumber(key.buSubTotal)}</strong></td>
          </tr> )}
          {groupCategory && key.payTypeCode !== "" && (
              <tr key={key.category + uuid()}>
                <td key={key.category + uuid()}></td>
                <td key={key.category + uuid()} colSpan={3}>
                  <strong>{key.category}</strong>
                </td>
                <td key={key.category + uuid()}>
                  <strong>{formatNumber(key.empSubTotal)}</strong>
                </td>
              </tr>
            )}
          {groupPayType && key.payTypeCode !== "" && (
              <tr key={key.category + uuid()}>
                <td key={key.category + uuid()}></td>
                <td key={key.category + uuid()} colSpan={3}>
                  <strong>{key.payTypeCode} / {key.payTypeCodeName}</strong>
                </td>
                <td key={key.category + uuid()}>
                  <strong>{formatNumber(key.empSubTotal)}</strong>
                </td>
              </tr>
            )}
          <tr key={key.businessUnitCode + uuid()}>
          {groupBusinessUnit ? <td></td> : <td>{key.businessUnitCode}</td>}
          {groupBusinessUnit ? <td></td> : <td>{key.businessUnitName}</td>}
          {groupCategory ? <td></td> : <td>{key.category}</td>}
            <td className="duration">{formatNumber(key.duration.toString())}</td>
          </tr>
        </React.Fragment>
      ))

      return _bu
    } else {
      //setRefresh(true)
      return (
        <tr key={uuid()}>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
        </tr>
      )
    }
  }

  const BUPayTypeCatRows = () => {
    
    if (buCatPayType && buCatPayType.length) {
      const _bu = buCatPayType.map(key => (
        <React.Fragment key={uuid()}>
          { groupBusinessUnit && key.businessUnitCode !== '' && (

          <tr key={key.businessUnitCode + uuid()} >
            <td key={uuid()} colSpan={5}>
              <strong>{key.businessUnitCode} / {key.businessUnitName} </strong>
            </td>
            <td style={{textAlign: 'right'}}><strong>{formatNumber(key.buSubTotal.toString())}</strong></td>
          </tr> )}
          {groupCategory && key.category !== "" && (
              <tr key={key.category + uuid()}>
                <td key={key.category + uuid()}></td>
                <td key={key.category + uuid()} colSpan={4}>
                  <strong>{key.category}</strong>
                </td>
                <td key={key.category + uuid()} style={{textAlign: 'right'}}>
                  <strong>{formatNumber(key.buCatSubTotal)}</strong>
                </td>
              </tr>
            )}
          {groupPayType && key.payTypeCode !== "" && (
              <tr key={key.category + uuid()}>
                <td key={key.category + uuid()}></td>
                <td key={key.category + uuid()} colSpan={3}>
                  <strong>{key.payTypeCode} / {key.payTypeCodeName}</strong>
                </td>
                <td key={key.category + uuid()}>
                  <strong>{formatNumber(key.empSubTotal)}</strong>
                </td>
              </tr>
            )}

          <tr key={key.businessUnitCode + uuid()}>
            {groupBusinessUnit ? <td key={uuid()}></td> : <td>{key.businessUnitCode}</td>}
            {groupBusinessUnit ? <td key={uuid()}></td> : <td>{key.businessUnitName}</td>}
            {groupCategory ? <td key={uuid()}></td> : <td>{key.category}</td>}
            {groupPayType ? <td key={uuid()}></td> : <td>{key.payTypeCode}</td>}
            {groupPayType ? <td key={uuid()}></td> : <td>{key.payTypeCodeName}</td>}
            <td className="duration">{formatNumber(key.duration.toFixed(2).toString())}</td>
          </tr>
        </React.Fragment>
      ))

      return _bu
    } else {
      //setRefresh(true)
      return (
        <tr key={uuid()}>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
        </tr>
      )
    }
  }

  const BUPayTypeRows = () => {
        
    if (buPayType && buPayType.length) {
      const _bu = buPayType.map(key => (
        <React.Fragment key={uuid()}>
        { groupBusinessUnit && key.businessUnitCode !== '' && (

        <tr key={key.businessUnitCode + uuid()} >
          <td colSpan={4}>
            <strong>{key.businessUnitCode} / {key.businessUnitName} </strong>
          </td>
          <td style={{textAlign: 'right'}}><strong>{formatNumber(key.buSubTotal)}</strong></td>
        </tr> )}
        {groupPayType && key.payTypeCode !== "" && (
            <tr key={key.category + uuid()}>
              <td key={key.category + uuid()}></td>
              <td key={key.category + uuid()} colSpan={3}>
                <strong>{key.payTypeCode} / {key.payTypeCodeName}</strong>
              </td>
              <td key={key.category + uuid()}>
                <strong>{formatNumber(key.empSubTotal.toString())}</strong>
              </td>
            </tr>
          )}

        <tr key={key.businessUnitCode + uuid()}>
          {groupBusinessUnit ? <td key={uuid()}></td> : <td>{key.businessUnitCode}</td>}
          {groupBusinessUnit ? <td key={uuid()}></td> : <td>{key.businessUnitName}</td>}
          {groupPayType ? <td key={uuid()}></td> : <td>{key.payTypeCode}</td>}
          {groupPayType ? <td key={uuid()}></td> : <td>{key.payTypeCodeName}</td>}
          <td className="duration">{formatNumber(key.duration.toFixed(2).toString())}</td>
        </tr>
        </React.Fragment>
      ))

      return _bu
    } else {
      //setRefresh(true)
      return (
        <tr key={uuid()}>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
          <td key={uuid()}></td>
        </tr>
      )
    }
  }

  const catTab = async (data) => {
    let _totalCat = 0
    const pcat = []
    const _payTypeCategories = _.sortBy(data.payTypeCategories, ["category"])
        _totalCat = 0
        for (let category of _payTypeCategories) {
          //pcat.push({ category: category.category, duration: category["duration"] })
          const _catDuration = parseFloat(category["duration"])
          _totalCat += _catDuration
        }

    //const _ptypeViaMap = 
    _payTypeCategories.map(el => {
      return pcat.push({category: el.category, duration: el.duration })
    })
    setTypes(pcat)
    setTotalCat(_totalCat.toFixed(2))

  }

  const payTypeTab = async (data) => {
    const _payTypeCodes = _.sortBy(data.payTypeCodes, [
      "category",
      (obj) => parseInt(obj.payTypeCode, 10)
    ])
      
      //["category", "payTypeCode"])
    let _totalCode = 0
    let prevCat = ""
    let catOut = ""
    const timePaytypes = []
    for (let code of _payTypeCodes) {
      catOut = code.category

      if (prevCat !== code.category) {
        prevCat = code.category
        catOut = code.category
      } else {
        catOut = ""
      }

      timePaytypes.push({ category: catOut, payTypeCode: code.payTypeCode, duration: code["duration"] })
      const _codeDuration = parseFloat(code["duration"])
      _totalCode += _codeDuration
    }
    setPayCodes(timePaytypes)
    setTotalCode(_totalCode.toFixed(2))
  }

  const empSummaryTab = async (data) => {

    const timeEntryEmpPayTypes = []
    const _empPayTypeCodes = _.orderBy(data.employeePayTypeCodes, [
      "supervisorName",
      "fullName",
      "category",
      (obj) => parseInt(obj.payTypeCode, 10)
    ],
    ["asc","asc","asc","asc"]
    )

    //_totalEmpCode = 0
    let prevSuperName = ""
    let superOut = ""
    let prevEmpName = ""
    let empOut = ""

    for (let code of _empPayTypeCodes) {
      superOut = code.supervisorName
      empOut = code.fullName

      if (prevSuperName !== code.supervisorName) {
        prevSuperName = code.supervisorName
        superOut = code.supervisorName
      } else {
        superOut = ""
      }

      if (prevEmpName !== code.fullName) {
        prevEmpName = code.fullName
        empOut = code.fullName
      } else {
        empOut = ""
      }

      timeEntryEmpPayTypes.push({
        supervisorName: superOut,
        fullName: empOut,
        category: code.category,
        payTypeCode: code.payTypeCode,
        duration: code["duration"],
        empSubTotal: code.empSubtotal,
        superSubTotal: code.superSubtotal,
      })
      //const _codeDuration = parseFloat(code["duration"])
      //_totalEmpCode += _codeDuration
    }
    setEmpPayCodes(timeEntryEmpPayTypes)


  }

  const buSummaryTab = async (data) => {
    // Business Units
    const byBu = []
    // eslint-disable-next-line
    let prevBusinessUnit = ""
    // eslint-disable-next-line
    let businessUnitOut = ""
    // eslint-disable-next-line
    let prevCategory = ""
    // eslint-disable-next-line
    let categoryOut = ""
    // eslint-disable-next-line
    let prevPayType=""
    // eslint-disable-next-line
    let payTypeOut=""

    const _buTypes = data.buStats.byBu
    for (let buCode of _buTypes) {


      byBu.push({
        businessUnitCode: buCode.businessUnitCode ? buCode.businessUnitCode: 'NONE',
        businessUnitName: buCode.businessUnit ? buCode.businessUnit.name : 'NONE',
        duration: buCode.hours
      })
    }
    setBuTypes(byBu)
  }

  const buCatSummaryTab = async (data) => {
    const byBuCat=[]
    let prevBusinessUnit = ""
    let businessUnitOut = ""
    let prevCategory = ""
    let categoryOut = ""
    let prevPayType=""
    // eslint-disable-next-line
    let payTypeOut=""
    
    let _buSubTotal = 0
    let _buCatSubTotal=0
    //let _buTotal=0

    const _buCategory = _.sortBy(data.buStats.byBUCategory, ["businessUnitCode","category"])

    for (let buCode of _buCategory) {

      businessUnitOut = buCode.businessUnitCode
      categoryOut = buCode.category
      payTypeOut = buCode.payTypeCode
      
      if (prevBusinessUnit !== buCode.businessUnitCode) {
        prevBusinessUnit = buCode.businessUnitCode
        businessUnitOut = buCode.businessUnitCode

        prevCategory = ''
        categoryOut = ''
        prevPayType = ''
        payTypeOut = ''

        _buSubTotal = await calcBUTotal(buCode.businessUnitCode, _buCategory )


      } else {
        businessUnitOut = ""
          
      }

      if ( prevCategory !== buCode.category) {
        prevCategory = buCode.category
        categoryOut = buCode.category
        _buCatSubTotal=buCode.duration
      } else {
        categoryOut = ""
        
      }

      if (prevPayType !== buCode.payTypeCode) {
        prevPayType = buCode.payTypeCode
        payTypeOut = buCode.payTypeCode
      } else {
        payTypeOut = ""
      }
    

      byBuCat.push({
        businessUnitCode: buCode.businessUnitCode ? businessUnitOut : 'NONE',
        businessUnitName: buCode.name ? businessUnitOut === '' ? '' : buCode.name : 'NONE',
        category: categoryOut,
        duration: buCode.duration,
        catSubTotal: _buCatSubTotal,
        buSubTotal: _buSubTotal,
        
      })

    }
    setBuCategory(byBuCat)


  }

  const buCatPayTypeTab = async (data) => {

    const byBuCatPayType = []
    // eslint-disable-next-lines
    let prevBusinessUnit = ""
    let businessUnitOut = ""
    let prevCategory = ""
    let categoryOut = ""
    let prevPayType=""
    let payTypeOut=""
    let _buCatSubTotal = 0
    let _buSubTotal = 0

    const _buCatPayType = _.sortBy(data.buStats.byBUCatPayType,[
      "businessUnitCode",
      "payType.category",
      (obj) => parseInt(obj.payTypeCode, 10)
    ])
      
    for (let buCode of _buCatPayType) {
      
      businessUnitOut = buCode.businessUnitCode
      categoryOut = buCode.payType.category
      payTypeOut = buCode.payTypeCode

      if (prevBusinessUnit !== buCode.businessUnitCode) {
        prevBusinessUnit = buCode.businessUnitCode
        businessUnitOut = buCode.businessUnitCode
        prevCategory = ''
        prevPayType = ''

        _buSubTotal = await calcBUTotal(buCode.businessUnitCode, _buCatPayType )

      } else {
        businessUnitOut = ""
      }

      if (prevCategory !== buCode.payType.category) {
        prevCategory = buCode.payType.category
        categoryOut = buCode.payType.category

        _buCatSubTotal = await calcCatTotal(buCode.businessUnitCode, buCode.payType.category, _buCatPayType)

      } else {
        categoryOut = ""
      }

      if (prevPayType !== buCode.payTypeCode) {
        prevPayType = buCode.payTypeCode
        payTypeOut = buCode.payTypeCode
      } else {
        categoryOut = ""
      }

      byBuCatPayType.push({
        businessUnitCode: buCode.businessUnit ? businessUnitOut : 'NONE',
        businessUnitName: businessUnitOut === '' ? '' : buCode.businessUnit ? buCode.businessUnit.name : 'NONE',
        category: categoryOut,
        payTypeCode: buCode.payTypeCode,
        payTypeCodeName: payTypeOut === '' ? '' : buCode.payType.rename ? buCode.payType.rename : buCode.payType.name,
        duration: buCode.hours,
        buCatSubTotal: _buCatSubTotal,
        buSubTotal: _buSubTotal,
      })
    }
    setBuCatPayType(byBuCatPayType)

  }

  const buPayTypeTab = async (data) => {

    const byBuPayType=[]
    let prevBusinessUnit = ""
    let businessUnitOut = ""
    let prevCategory = ""
    // eslint-disable-next-line
    let categoryOut = ""
    let prevPayType=""
    let payTypeOut=""
    let _buSubTotal=0


    //const _buPayType = data.buStats.byBUPayType

    //const _buPayType = _.sortBy(data.buStats.byBUPayType,["businessUnitCode","payTypeCode"])

    const _buPayType = _.sortBy(data.buStats.byBUPayType, [
      "businessUnitCode",
      (obj) => parseInt(obj.payTypeCode, 10)
    ])
    //["businessUnitCode","payTypeCode"])



    //const _buCategory = _.sortBy(data.buStats.byBUCategory, ["businessUnitCode","category"])

    for (let buCode of _buPayType) {
    
      businessUnitOut = buCode.businessUnitCode
      categoryOut = buCode.category
      payTypeOut = buCode.payTypeCode

      if (prevBusinessUnit !== buCode.businessUnitCode) {
        prevBusinessUnit = buCode.businessUnitCode
        businessUnitOut = buCode.businessUnitCode

        _buSubTotal = await calcBUTotal(buCode.businessUnitCode, _buPayType )

      } else {
        businessUnitOut = ""
      }

      if (prevCategory !== buCode.category) {
        prevCategory = buCode.category
        categoryOut = buCode.category
      } else {
        categoryOut = ""
      }

      if (prevPayType !== buCode.payTypeCode) {
        prevPayType = buCode.payTypeCode
        payTypeOut = buCode.payTypeCode
      } else {
        categoryOut = ""
      }

      byBuPayType.push({
        businessUnitCode: buCode.businessUnit ? businessUnitOut : 'NONE',
        businessUnitName: businessUnitOut === '' ? '' : buCode.businessUnit ? buCode.businessUnit.name : 'NONE',
        payTypeCode: payTypeOut,  
        payTypeCodeName: payTypeOut === '' ? '' : buCode.payType.rename ? buCode.payType.rename : buCode.payType.name,
        duration: buCode.hours,
        buSubTotal: _buSubTotal,


      })
    }
    setBuPayType(byBuPayType)


  }

  const getTabData = async (body) => {
    setLoading(true)
    setBotTabsLoading(true)
    const tabData = await REQ(`/api/statistics`, "POST", body).catch(err =>
      Emitter.emit("PayPeriodStats.js", { fn: "getTabData", error: err }),
     )


    setLoading(false)
    setBotTabsLoading(false)
    return tabData
  }

  const onTabClick = async (key, event) => {
    body.statTypes=['filter']
    //mutate()
  }

  const onClick = async (activeKey) => {

    setActiveKey(activeKey)


    const filters = {
      1: {statType: 'payTypeCategories', dataShape: catTab },
      2: {statType: 'payTypeCodes', dataShape: payTypeTab }, 
      3: {statType: 'employeePayTypeCodes', dataShape: empSummaryTab },
      '3B': {statType: 'employeePayTypeCodes', dataShape: empSummaryTab },
      4: {statType: 'ByBU', dataShape: buSummaryTab },
      5: {statType: 'ByBUCategory', dataShape: buCatSummaryTab },
      6: {statType: 'ByBUCatPayType', dataShape: buCatPayTypeTab },
      7: {statType: 'ByBUPayType', dataShape: buPayTypeTab },
    }
    
    body.statTypes=[filters[activeKey].statType]
    const tabData = await getTabData(body)
    await filters[activeKey].dataShape(tabData)
    //mutate()
  }


  const showESModal = () => {
    setIsESModalVisible(true)  
  }

  const handleESOk = () => {
    setIsESModalVisible(false)
  }

  const handleESCancel = () => {
    setIsESModalVisible(false)
  }


  const EmpStatTable = () => {
    return (
      <>
        <div key={uuid()} style={{width: '100%'}}>
          <h2 key={uuid()} style={{ textAlign: "left" }}>By Employee/Category/Pay Type</h2>
        </div>
        <div key={uuid()} style={{ textAlign: "center" }}>
          <Checkbox
            key={uuid()}
            disabled={false}
            checked={groupSupervisor}
            onChange={e => {
              setGroupSupervisor(e.target.checked)
              if (!e.target.checked) setGroupEmployee(false)
              //mutate(key)
            }}
          >
            Group by Supervisor{" "}
          </Checkbox>
          <Checkbox
            key={uuid()}
            disabled={false}
            checked={groupEmployee}
            onChange={e => {
              if (e.target.checked) setGroupSupervisor(true)
              setGroupEmployee(e.target.checked)
              //mutate(key)
            }}
          >
            Group by Employee{" "}
          </Checkbox>

          {/*isESModalVisible && <Button icon="download" shape="round" size="small" type="primary" key={uuid()} onClick={tableToCSV} >Download</Button>*/}
          {!isESModalVisible && <Button key={uuid()} disabled={isESModalVisible} size="small" type="primary" onClick={showESModal}>
            Open Table
          </Button>}
        </div>
        <div  key={uuid()} className="lockTable" style={ {overflowY: "scroll", height:"680px" } }>
          <table key={uuid()} className="head">
            <thead key={uuid()} className="head">
              <tr key={uuid()}>
                <th key={uuid()}>Supervisor</th>
                <th key={uuid()}>Employee</th>
                <th key={uuid()}>Pay Type Category</th>
                <th key={uuid()}>Pay Type Code</th>
                <th key={uuid()}>Hours</th>
              </tr>
            </thead>
            <tbody key={uuid()} className="inner-table">
              <EmployeePayTypeCodeRows />
            </tbody>
            <tfoot key={uuid()}>
              <tr key={uuid()}>
                <th key={uuid()} id="typeTotal" colSpan="4">
                  Total Hours
                </th>
                <td key={uuid()} className="duration">
                  <strong>{formatNumber(totalCat)}</strong>
                </td>
              </tr>
            </tfoot>
          </table> 
        </div>
      </>
    )
  }


  const tableToCSV = () => {
    let totalHrs = formatNumber(totalCat)
      
    let csvString = [
      [
        "Supervisor Name",
        "Employee Name",
        "Category",
        "Pay Type Code",
        "Duration",
        //"Employee Sub Total",
        //"Supervisor Sub Total",
      ],
      ...empPayCodes.map(e => [
        //e.supervisorName.replace(',','-'),
        //e.fullName.replace(',','-'),
        `"${e.supervisorName}"`,
        `"${e.fullName}"`,
        e.category,
        e.payTypeCode,
        e.duration,
        //e.empSubTotal,
        //e.superSubTotal, 
      ])
    ]
     .map(e => e.join(",")) 
     .join("\n")

     csvString = csvString.concat(`\n"Total Hours",,,,"${totalHrs}"\n`);
     //.join("\t")
  
      //console.log(csvString);
       // Call this function to download csv file 
      downloadCSVFile(csvString);
    
  }
  


  const downloadCSVFile = (csv_data) => {

    // Create CSV file object and feed our
    // csv_data into it
    const CSVFile = new Blob([csv_data], { type: "text/csv" });
  
    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement('a');
  
    // Download csv file
    temp_link.download = "Employee Summary.csv";
    var url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;
  
    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
  
    // Automatically click the link to trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }
  
  const onCollapse = (collapsed) => {
    setIsCollapsed(collapsed)
  }
  
  return (
   
   
    <Layout className="layout" style={{ overflowX: "scroll !important" }}>
        <>
        {/*<Spin spinning={loading} size='default' >*/}
          <Sider breakpoint="xl" collapsedWidth="0" collapsible={true} collapsed={isCollapsed} onCollapse={onCollapse} width={300} style={{background: 'white'}}>
            <Card loading={siderLoading} size="small" sytle={{ width: 300 }}>
              <h2 style={{textAlign: 'center'}}>Pay Period Lock Status</h2>
              <PayPeriodLockTable
                updateLockStatus={updateLockStatus}
                payPeriodLock={payPeriodLock}
                setPayPeriodLock={setPayPeriodLock}
                shiftLock={shiftLock}
                setShiftLock={setShiftLock}
                timeEntryLock={timeEntryLock}
                setTimeEntryLock={setTimeEntryLock}
                payPeriodId={payPeriodId}
                filters={filters}
                superNumber1={selectedSupervisor}
                employeeNumber={employeeNumber}
                role={myRole}
                mutate={mutate}
              />
            </Card>     
            <Card loading={siderLoading} size="small" style={{ width: 300}}>
              <Row justify="center">
                <Col span={20}>
                  <h2>Pay Period Ends</h2>
                </Col>
              </Row>
              <Row gutter={[3,3]} justify="center">
                <Col span={6}><strong>{days > 0 ? String(days).padStart(2, '0') : String(0).padStart(2, '0')}</strong></Col>
                <Col span={6}>Days</Col>
              </Row>
              <Row gutter={[3,3]} justify="center">
                <Col span={6}><strong>{hours > 0 ? String(hours).padStart(2, '0') : String(0).padStart(2, '0')}</strong></Col>
                <Col span={6}>Hrs</Col>
              </Row>
              <Row gutter={[3,3]} justify="center">
                <Col span={6}><strong>{minutes > 0 ? String(minutes).padStart(2, '0') : String(0).padStart(2, '0') }</strong></Col>
                <Col span={6}>Mins</Col>
              </Row>
            </Card>
          </Sider>
        {/*</Spin>*/}

        <Content>
          <div className="site-card-wrapper">
            {/*<Spin spinning={topTabsLoading} size='large' >*/}
              <Tabs size="large" centered={true} animated={false}>
                <TabPane tab="Time Entry Stats" key="payperiod">
                  {/*<Col span={3}>&nbsp;&nbsp;</Col>*/}
                  <Col span={4}>
                  <Tooltip title='Total Time Entries for pay period'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={myRole === 'employee'? "#/timeentries/mine" : "#/timeentries/direct"}>
                            <FieldTimeOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Total Time Entries" counter={totalTimeEntry} icon='check-circle' />
                      </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                  <Tooltip title='Total Time Entries Submitted'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Submitted" counter={miscStats && miscStats.length> 0  ? miscStats[0].submitted : 0} total={totalTimeEntry} />
                      </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                  <Tooltip title='Total Time Entries Not Submitted'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Not Submitted" counter={miscStats && miscStats.length> 0  ? miscStats[0].notSubmitted : 0} total={totalTimeEntry} />
                      </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                  <Tooltip title='Number of Time Entries Approved'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Approved" counter={miscStats && miscStats.length> 0 ? miscStats[0].approved : 0} total={totalTimeEntry} />
                    </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                  <Tooltip title='Number of Time Entries Rejected'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Rejected" counter={miscStats && miscStats.length> 0 ? miscStats[0].rejected : 0} total={totalTimeEntry} />
                      </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                  <Tooltip title='Number of Time Entries Processed'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Processed" counter={miscStats && miscStats.length> 0  ? miscStats[0].processed : 0} total={totalTimeEntry} />
                      </Card>
                    </Tooltip>
                  </Col>
                </TabPane>
                <TabPane key="Shifts Stats" tab="Shifts Stats">
                {/*<Col span={3}>&nbsp;&nbsp;</Col>*/}
                <Col span={4}>
                  <Tooltip title='Total Shifts for pay period'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={myRole === 'employee'? "#/shifts/mine" : "#/shifts/direct"}>
                            <CalendarOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Total Shifts" counter={totalShifts} icon='check-circle' />
                      </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                  <Tooltip title='Total Sick Shifts'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Total Sick Shifts" counter={shiftStats && shiftStats.length> 0  ? shiftStats[0].sickShifts : 0} total={totalShifts}/>
                      </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                  <Tooltip title='Total Leave Shifts'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Total Leave Shifts" counter={shiftStats && shiftStats.length> 0 ? shiftStats[0].ptoShifts : 0} total={totalShifts}/>
                      </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                    <Tooltip title='Total Overtime Shifts'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Total OT Shifts" counter={shiftStats && shiftStats.length> 0 ? shiftStats[0].otShifts : 0} total={totalShifts} />
                      </Card>
                    </Tooltip>
                  </Col>
                  <Col span={4}>
                    <Tooltip title='Total Dive Shifts'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Total Dive Shifts" counter={shiftStats && shiftStats.length> 0  ? shiftStats[0].diveShifts : 0} total={totalShifts} />
                      </Card>
                      </Tooltip>
                  </Col>
                  <Col span={4}>
                    <Tooltip title='Total FTO Shifts'>
                      <Card
                        loading={topTabsLoading}
                        size="small"
                        style={{ width: 200 }}
                        extra={
                          <a href={ myRole !== 'employee' ? "#/records" : "#/"}>
                            <HddOutlined />
                          </a>
                        }
                      >
                        <StatsCounter label="Total FTO Shifts" counter={shiftStats && shiftStats.length> 0 ? shiftStats[0].ftoShifts : 0} total={totalShifts}/>
                      </Card>
                      </Tooltip>
                  </Col>
                </TabPane>
              </Tabs>
            
              <Row>&nbsp;&nbsp;</Row>
            {/*</Spin>*/}
            </div>
            {/*<Spin spinning={botTabsLoading} size='large' >*/}
            <div>
              <Card title="Pay Period Stats">
                
                 <div style={{display: "flex", justifyContent: "space-between", padding: '5px', background: "dodgerblue"}}>
                   <div style={{ width: "100%", padding: '5px'}}>
                        <h4>Supervisor</h4>
                        <SupervisorSelect
                          supervisor={selectedSupervisor}
                          value={selectedSupervisor && selectedSupervisor.number ? selectedSupervisor.number : null}
                          field="number"
                          {...selectProps}
                          disabled={false} //{myRole === 'employee'? true : false}

                        />
                   </div>
                   {/* </Card>
                    <Card loading={topTabsLoading} style={{ width: 400 }}>*/}
                   <div style={{ width: "100%", padding: "5px"}}>
                        <h4>Employee</h4>
                        <EmployeeSelect
                          employee={selectedEmployee}
                          value={selectedEmployee.number}
                          field="number"
                          {...employeeSelectProps}
                          disabled={myRole === 'employee'? true : false}
                        />
                        </div>
                    {/*</Card>
                    <Card loading={topTabsLoading} style={{ width: 400 }}>*/}
                   <div style={{ width: "100%", padding: "5px"}}>
                      <h4>Business Units</h4>
                      <BusinessUnitSelect
                          {...buSelectProps}
                          disabled={false} //{myRole === 'employee'? true : false}
                          field="businessUnitCode"
                          value={selectedBU}
                          record={selectedBU}
                      />
                      </div>
                 </div>
                <Tabs defaultActiveKey={myRole === 'admin' ? "4":"1" } activeKey={activeKey} tabPosition="left" animated={false} size="small" onChange={onClick} onTabClick={onTabClick}>
                  <TabPane tab="Category Summary" key="1">
                    <Card.Grid style={gridStyle}>
                    {botTabsLoading && <div style={{display: "flex", height: "100%", width: "100%", justifyContent: "center"}}><Spin size='large'><Skeleton active/></Spin></div>}
                      {!botTabsLoading && 
                        <>
                        <h2 style={{ textAlign: "left" }}>By Category</h2>
                        <table className="statsTable">
                          <thead>
                            <tr>
                              <th>Pay Type Category</th>
                              <th>Hours</th>
                            </tr>
                          </thead>
                          <tbody>
                            <PayTypeCatRows />
                          </tbody>
                          <tfoot>
                            <tr>
                              <th id={'catTotal' + uuid()} colSpan="1">
                                Total Hours
                              </th>
                              <td className="duration">
                                <strong>{formatNumber(totalCode)}</strong>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </>}
                    </Card.Grid>
                  </TabPane>
                  <TabPane tab="Pay Types Summary" key="2">
                    <Card.Grid style={gridStyle}>
                    {botTabsLoading && <div><Spin size='large'><Skeleton active/></Spin></div>}
                      {!botTabsLoading && 
                        <>
                          <h2 style={{ textAlign: "left" }}>By Category/Pay Types</h2>
                          <table className="statsTable">
                            <thead>
                              <tr>
                                <th>Pay Type Category</th>
                                <th>Pay Type Code</th>
                                <th>Hours</th>
                              </tr>
                            </thead>
                            <tbody>
                              <PayTypeCodeRows />
                            </tbody>
                            <tfoot>
                              <tr>
                                <th id="typeTotal" colSpan="2">
                                  Total Hours
                                </th>
                                <td className="duration">
                                  <strong>{formatNumber(totalCat)}</strong>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </>}
                    </Card.Grid>
                  </TabPane>
                  {/* 
                  <TabPane tab="Employees Summary" key="3">
                    <Card.Grid  style={gridStyle2}>
                      {botTabsLoading && <div><Spin size='large'><Skeleton active/></Spin></div>}
                      {!botTabsLoading && 
                        <>
                          <div>
                            <h2 style={{ textAlign: "left" }}>By Employee/Category/Pay Type</h2>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <Checkbox
                              disabled={false}
                              checked={groupSupervisor}
                              onChange={e => {
                                setGroupSupervisor(e.target.checked)
                                if (!e.target.checked) setGroupEmployee(false)
                                //mutate(key)
                              }}
                            >
                              Group by Supervisor{" "}
                            </Checkbox>
                            <Checkbox
                              disabled={false}
                              checked={groupEmployee}
                              onChange={e => {
                                if (e.target.checked) setGroupSupervisor(true)
                                setGroupEmployee(e.target.checked)
                                //mutate(key)
                              }}
                            >
                              Group by Employee{" "}
                            </Checkbox>
                          </div>
                          <table className="lockTable">
                            <thead>
                              <tr key={uuid()}>
                                <th>Supervisor</th>
                                <th>Employee</th>
                                <th>Pay Type Category</th>
                                <th>Pay Type Code</th>
                                <th>Hours</th>
                              </tr>
                            </thead>
                            <tbody>
                              <EmployeePayTypeCodeRows />
                            </tbody>
                            <tfoot>
                              <tr>
                                <th id="typeTotal" colSpan="4">
                                  Total Hours
                                </th>
                                <td className="duration">
                                  <strong>{formatNumber(totalCat)}</strong>
                                </td>
                              </tr>
                            </tfoot>
                          </table> 
                        </>}
                    </Card.Grid>
                  </TabPane>
                  */}
                  <TabPane tab="Employees Summary" key="3">
                    <Card.Grid  style={gridStyle2}>
                      {botTabsLoading && <div key={uuid()}><Spin key={uuid()} size='large'><Skeleton key={uuid()} active/></Spin></div>}
                      {!botTabsLoading && 
                      <>
                         <Modal 
                           key={uuid()}
                           title="Employees Summary" 
                           style={{ top: 20 }}
                           visible={isESModalVisible} 
                           onOk={handleESOk} 
                           onCancel={handleESCancel}
                           width={800}
                           bodyStyle={{overflowX: 'scroll'}}
                           footer={[
                              <div key={uuid()}>
                                <Button icon="download" type="dashed" key={uuid()} onClick={tableToCSV} >Download</Button>
                                <Button type="primary" key={uuid()} onClick={handleESOk}>
                                  OK
                                </Button>
                              </div>
                           ]}
                          >
                            <EmpStatTable />
                        </Modal>
                        <EmpStatTable />
                      </>}
                    </Card.Grid>
                  </TabPane>
                  { myRole !== 'employee' && <TabPane tab='Business Unit Summary' key="4" disabled={myRole === 'employee' ? true : false }>
                    <Card.Grid style={gridStyle2}>
                    {botTabsLoading && <div><Spin size='large'><Skeleton active/></Spin></div>}
                      {!botTabsLoading && 
                        <>
                          <h2 style={{ textAlign: "left" }}>By Business Unit</h2>
                          <table className="statsTable">
                            <thead>
                              <tr key={uuid}>
                                <th>Business Unit Code</th>
                                <th>Business Unit Name</th>
                                <th>Hours</th>
                              </tr>
                            </thead>
                            <tbody>
                                <BUTypeRows />
                            </tbody>
                            <tfoot>
                              <tr>
                                <th id={'buTotal' + uuid()} colSpan="2">
                                Total Hours
                                </th>
                                <td className="duration">
                                  <strong>{formatNumber(totalCode)}</strong>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </>}
                      </Card.Grid>
                  </TabPane> }
                  { myRole !== 'employee' && <TabPane tab='BU Cat Summary' key="5" disabled={myRole === 'employee' ? true : false }>
                    <Card.Grid style={gridStyle2}>
                    {botTabsLoading && <div><Spin size='large'><Skeleton active/></Spin></div>}
                      {!botTabsLoading && 
                        <>
                        <div>
                        <h2 style={{ textAlign: "left" }}>BU/Category</h2>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <Checkbox
                            disabled={false}
                            checked={groupBusinessUnit}
                            onChange={e => {
                              setGroupBusinessUnit(e.target.checked)
                              if (!e.target.checked) setGroupPayType(false)
                            }}
                          >
                            Group by Business Unit{" "}
                          </Checkbox>
                        </div>
                        <table className="lockTable">
                          <thead>
                            <tr key={uuid}>
                              <th>Business Unit Code</th>
                              <th>Business Unit Name</th>
                              <th>Pay Type Category</th>
                              <th>Hours</th>
                            </tr>
                          </thead>
                          <tbody>
                              <BUCategoryRows />
                          </tbody>
                          <tfoot>
                            <tr>
                              <th id={'buTypeTotal' + uuid()} colSpan="3">
                              Total Hours
                              </th>
                              <td className="duration">
                                <strong>{formatNumber(totalCode)}</strong>
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </>}
                      </Card.Grid>
                  </TabPane> }
                  { myRole !== 'employee2' && <TabPane tab='BU Cat Pay Type Summary' key="6" disabled={myRole === 'employee2' ? true : false }>
                    <Card.Grid style={gridStyle2}>
                    {botTabsLoading && <div><Spin size='large'><Skeleton active/></Spin></div>}
                      {!botTabsLoading && 
                        <>
                        <div>
                          <h2 style={{ textAlign: "left" }}>BU/Category/Pay Type</h2>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <Checkbox
                              disabled={false}
                              checked={groupBusinessUnit}
                              onChange={e => {
                                setGroupBusinessUnit(e.target.checked)
                                if (!e.target.checked) {
                                  setGroupCategory(false)
                                }
                              }}
                            >
                              Group by Business Unit{" "}
                            </Checkbox>
                            <Checkbox
                              disabled={false}
                              checked={groupCategory}
                              onChange={e => {
                                if (e.target.checked) {
                                  setGroupBusinessUnit(true)
                                }
                                setGroupCategory(e.target.checked)
                              }}
                            >
                              Group by Category{" "}
                            </Checkbox>
                          </div>
                          <table className="statsTable">
                            <thead>
                              <tr key={uuid}>
                                <th>Business Unit Code</th>
                                <th>Business Unit Name</th>
                                <th>Pay Type Category</th>
                                <th>Pay Type Code</th>
                                <th>Pay Type Desc</th>
                                <th>Hours</th>
                              </tr>
                            </thead>
                            <tbody>
                                <BUPayTypeCatRows />
                            </tbody>
                            <tfoot>
                              <tr>
                                <th id={'buCatCodeTotal' + uuid()} colSpan="5">
                                Total Hours
                                </th>
                                <td className="duration">
                                  <strong>{formatNumber(totalCode)}</strong>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </>}
                      </Card.Grid>
                  </TabPane> }
                  { myRole !== 'employee' && <TabPane tab='BU Pay Type Summary' key="7" disabled={myRole === 'employee' ? true : false }>
                    <Card.Grid style={gridStyle2}>
                    {botTabsLoading && <div><Spin size='large'><Skeleton active/></Spin></div>}
                      {!botTabsLoading && 
                        <>
                          <div>
                            <h2 style={{ textAlign: "left" }}>BU/Pay Type</h2>  
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <Checkbox
                              disabled={false}
                              checked={groupBusinessUnit}
                              onChange={e => {
                                setGroupBusinessUnit(e.target.checked)
                                if (!e.target.checked) setGroupPayType(false)
                              }}
                            >
                              Group by Business Unit{" "}
                            </Checkbox>
                          </div>
                          <table className="statsTable">
                            <thead>
                              <tr key={uuid}>
                                <th>Business Unit Code</th>
                                <th>Business Unit Name</th>
                                <th>Pay Type Code</th>
                                <th>Pay Type Desc</th>
                                <th>Hours</th>
                              </tr>
                            </thead>
                            <tbody>
                                <BUPayTypeRows />
                            </tbody>
                            <tfoot>
                              <tr>
                                <th id={uuid()} colSpan="4">
                                Total Hours
                                </th>
                                <td className="duration">
                                  <strong>{formatNumber(totalCode)}</strong>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </>}
                      </Card.Grid>
                  </TabPane>
                  }
        
                </Tabs>        
              </Card>
              </div>
            {/*</Spin>*/}
        </Content>
        </>
    </Layout>
   
    
    
  )
}

export default PayPeriodStats
