import React from "react"
import { TimePicker } from "antd"
import moment from "moment"

export const EndTimeField = props => {
  // console.log('EndTimeField END', moment(props.value.end).toDate());
	//console.log("EndTime: " + props.value.end);

  const { field, id, handleChange } = props
  var value = { start:null, end:null }, dvalue = null;
	if( props.value ) {
		value = props.value;
		var td = new Date(props.value.end);
		//console.log("TZO:" + td.getTimezoneOffset());
		dvalue = moment(td.getTime()); // moment adjusts it already .subtract(td.getTimezoneOffset(), 'm');
		//console.log("F:"+dvalue.format("HH:mm"));
	}

  const options = {
    isDisabled: props.disabled,
    disabled: props.disabled,
    minuteStep: 6,
    onChange: val => {
      const start = moment(value.start)
      const startTime = parseInt(start.format("HHmm"))

			/*
      val = new Date(Number(val));
      var h = val.getHours();
      var m = val.getMinutes();
      val.setUTCHours(h);
      val.setUTCMinutes(m);
			*/

      const end = moment(val)
      const endTime = parseInt(end.format("HHmm"))
      const endNextDay = endTime < startTime
      var nextDay = moment(start).add(1, "day").hour(end.hour()).minute(end.minutes())
      var sameDay = moment(start).hour(end.hour()).minute(end.minutes())
      var returnEnd = endNextDay ? nextDay : sameDay
      // console.log("\n ======================")
      // console.log(`END NEXT DAY? ${endNextDay} ... startTime: ${startTime} endTime: ${endTime}`)
      // console.log(`start: ${start.format("MM/DD HH:mm")}`)
      // console.log(`val: ${end.format("MM/DD HH:mm")}`)
      //console.log(`returnEnd: ${returnEnd.format("MM/DD HH:mm")}`)
			console.log("END: " + returnEnd.toISOString());

      handleChange({ ...props, field, value: returnEnd.toDate(), id })
    },
    value: dvalue,
    format: "HH:mm",
    className: "EndTimeField",
    allowClear: false,
    placeholder: "Select End Time",
  }
  return <TimePicker data-cy="end-time-picker" {...options} />
}

export default EndTimeField
