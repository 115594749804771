import React, { useEffect, useState } from 'react';

//import { Timeline, Collapse, Icon } from "antd"
import { Timeline, TimelineEvent } from "react-event-timeline";
import {Icon } from "antd"
import moment from "moment-timezone"
//const { Panel } = Collapse;

import { store, reqOptions } from  "../../store/psrs"


//import "./ShiftTimeLine.css"

const formatFieldName = (fieldName) => {
  if (fieldName === '_created') {
    return "Created"
  } else {
    return fieldName
    .replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space before capital letters
    .replace(/([0-9])/g, ' $1')           // Add space before numbers
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  }
  
};

const ShiftTimeLine = ({id, modelName}) => {

  const [sortedChanges, setSortedChanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const BuildTimeLineItems = (change, index) => {

    const timeLineEvents = ['submit','approved','rejected','processed','locked','notification','nEmailRequest','nEmailResponse','nSMSRequest','nSMSResponse']

    const timeLineColors = {
      I: '#6fba1c',
      U: '#4281A4',
      D: 'red',
      created: '#6fba1c',
      submit: '#4281A4',
      approved: '#4FACFF',
      rejected: 'red',
      processed: '#6fba1c',
      locked: 'red',
      nEmailRequest: '#6fba1c',
      nSMSRequest: '#6fba1c',
      nEmailResponse: '#03a9f4',
      nSMSResponse: '#03a9f4',
      notification: '#6fba1c',
    }  
  
    const timeLineIcons = {
      I: 'add_circle',
      U: 'update',
      D: 'delete',
      _created: 'add_circle',
      userid: 'update',
      created: 'add_circle',
      submit: 'send',
      approved: 'thumb_up',
      rejected: 'thumb_down',
      processed: 'check_circle',
      locked: 'lock',
      nEmailRequest: 'email',
      nSMSRequest: 'sms',
      nEmailResponse: 'email',
      nSMSResponse: 'sms',
      notification: 'email',
    
    }
    
    const timelineSubTitles = {
      I: '',
      U: 'Updated',
      D: 'Deleted'
    }

    // get Icon's, colors etc. 
    const changeField = change.field.toLowerCase()
    const action = change.action.toUpperCase()
    let timeLineColor = '#4281A4'
    let timeLineIcon = ''
    
    if (timeLineEvents.includes(changeField)) {
      timeLineColor = timeLineColors[changeField]
      timeLineIcon = timeLineIcons[changeField]
    } else {
      timeLineColor = timeLineColors[action]
      timeLineIcon = timeLineIcons[action]
    }

    const timelineSubTitle = timelineSubTitles[action]

    const BuildTable = (change) => {

      return (
        //change.map(row => {
          <>
            <tr><th>Action</th><td>{change.action}</td></tr>
            <tr><th>Value Before</th><td>{change.valueBefore}</td></tr>
            <tr><th>Value After</th><td>{change.valueAfter}</td></tr>
            <tr><th>Changed By</th><td>{change.changedBy}</td></tr>
            {change.sentToEmail1 && <tr><th>Email1 To</th><td>{change.sentToEmail1}</td></tr>}
            {change.statusEmail1 && <tr><th>Email1 Status</th><td>{change.statusEmail1}</td></tr>}
            {change.sentToEmail2 && <tr><th>Email2 To</th><td>{change.sentToEmail2}</td></tr>}
            {change.statusEmail2 && <tr><th>Email2 Status</th><td>{change.statusEmail2}</td></tr>}
            
            {change.emailSubject && <tr><th>Email Subject</th><td>{change.emailSubject}</td></tr>}
            {change.emailBody && <tr><th>Email Message</th><td><span dangerouslySetInnerHTML={{__html: change.emailBody}} /></td></tr>}
            
            {change.sentToPhone1 && <tr><th>Phone1 To</th><td>{change.sentToPhone1}</td></tr>}
            {change.statusPhone1 && <tr><th>Phone1 Status</th><td>{change.statusPhone1}</td></tr>}
            {change.sentToPhone2 && <tr><th>Phone2 To</th><td>{change.sentToPhone2}</td></tr>}
            {change.statusPhone2 && <tr><th>Phone2 Status</th><td>{change.statusPhone2}</td></tr>}
            
            {change.smsBody && <tr><th>SMS Message</th><td>{change.smsBody}</td></tr>}
            
            {change.notificationSent && <tr><th>Notification Sent</th><td>{change.notificationSent ? 'true' : 'false'}</td></tr>}
            {change.cause && <tr><th>Trigger</th><td>{change.cause}</td></tr>}

          </>
        //})
      )
      
    }  

    return (
      <TimelineEvent
          key={index}
          style={{overflowY: 'scroll', overflowX: 'scroll'}}         
          title={`${change.modelName ? change.modelName : change.cause ? change.cause : ''} - ${formatFieldName(change.field)}`}
          titleStyle={{ fontWeight: 'bold' }}
          subtitle={timelineSubTitle}
          subtitleStyle={{ fontWeight: 'bold' }}
          createdAt={<b>{change.updatedAt}</b>}
          icon={<i className="material-icons md-18">{timeLineIcon}</i>}
          iconColor={timeLineColor}
          collapsible={true}
          showContent={false}
          //container='card'
          //cardHeaderStyle={{ backgroundColor: '#8bc34a', color: '#503331' }}
          //contentStyle={{ backgroundColor: '#00BCD4', color: '#fff' }}

        >
         <table id="audit">
          <tbody>
              
              {BuildTable(change)}

          </tbody>
      </table>
      </TimelineEvent>
    )

    
    

  }

  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        setLoading(true);
        setError(null);

        const uri = modelName === 'Shift' ? `${store.get("api")}/api/reports/audit/shift/${id}` : `${store.get("api")}/api/reports/audit/time_entry/${id}`
        //const uri = `${store.get("api")}/api/reports/audit/shift/${id}`

        const params = { ...reqOptions, method: "GET" }
        const response = await fetch( uri, params ) 
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();

        // Function to flatten and sort changes
        const flattenAndSortChanges = (reportDataArray) => {
          const flattenedChanges = [];

          reportDataArray.forEach(report => {
            Object.keys(report.reportData.changes).forEach(changeKey => {
              const change = report.reportData.changes[changeKey];
              flattenedChanges.push({
                id: report.reportData.id,
                employeeNumber: report.reportData.employeeNumber,
                fullName: report.reportData.employeeName,
                dateCreated: report.reportData.date_created,
                dateUpdated: report.reportData.date_updated,
                field: changeKey,
                modelName: change.modelName,
                action: change.action,
                valueBefore: change.before,
                valueAfter: change.after,
                changedBy: change.userId,
                createdAt: change.dateCreated,
                updatedAt: change.dateUpdated,
              });
            });

            //get notifications added
            Object.keys(report.reportData.notifications).forEach(changeKey => {
              const change = report.reportData.notifications[changeKey]
              flattenedChanges.push({
                id: report.reportData.id,
                employeeNumber: report.reportData.employeeNumber,
                fullName: report.reportData.employeeName,
                dateCreated: report.reportData.date_created,
                dateUpdated: report.reportData.date_updated,
                field: "notification",
                action: "I",
                valueBefore: '',
                valueAfter: change.type,
                changedBy: change.userId,
                createdAt: change.createdAt,
                updatedAt: moment(change.updatedAt).format('MM-DD-YYYY HH:MM:SS.SSS'),
                sentToEmail1: change.email1.to ? change.email1.to : 'Email1 Not Subscribed',
                statusEmail1: change.email1.status,
                sentToEmail2: change.email2.to ? change.email2.to : 'Email2 Not Subscribed',
                statusEmail2: change.email2.status, 
                emailSubject: change.emailSubject,
                emailBody: change.emailBody,
                sentToPhone1: change.phone1.to ? change.phone1.to : 'Phone1 Not Subscribed',
                statusPhone1: change.phone1.status,
                sentToPhone2: change.phone2.to ? change.phone2.to : 'Phone2 Not Subscribed',
                statusPhone2: change.phone2.status,
                smsBody: change.smsBody, 
                notificationSent: change.notificationSent,
                cause: change.cause,

              });
            });

          });

          //.tz(process.env.API_TZ).format('MM-DD-YYYY HH:mm')

          // Sort by action and then by dateUpdated
          flattenedChanges.sort((a, b) => {
            //if (a.action < b.action) return -1;
            //if (a.action > b.action) return 1;
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          });

          return flattenedChanges;
        };

        // Set the sorted changes in state
        setSortedChanges(flattenAndSortChanges(jsonData));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchJsonData();
  }, [id, modelName]);

  if (loading) {
    //eturn <p>Loading...</p>;
    
    return <div><Icon style={{color: "DodgerBlue"}}type="loading" /><p>Loading audit data this may take some time...</p></div>;

  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div style={{overflowY: 'scroll', overflowX: 'scroll'}}>
      <Timeline  style={{ position: "relative", minHeight: "40vh"}}>
        {sortedChanges.map((change, index) => (
          BuildTimeLineItems(change, index)
          
        ))}
    </Timeline>
    </div>
  );
};

export default ShiftTimeLine;
