import React from "react"

export const FlexBlock = (props) => {
  const justify = props.justify || 'space-between'
  const align = props.align || 'center'
  return (
    <div className={`FlexBlock justify-${justify} align-${align}`}>
      {props.children}
    </div>
  )
}
