import React from "react"
import { Button } from "antd"
import Icon from "antd/es/icon"

export const PayPeriodSelect = props => {

  let fetchData = async (args) => {
    //console.log('fetchData', props.fetchData)
  }

  let payPeriod = JSON.parse(localStorage.getItem('PayPeriod'))

  const isCurrent = payPeriod.id === payPeriod.todayId ? "primary" : "dashed"

  return (
    <Button.Group data-cy="payperiod-nav-buttons">
      <Button
        data-cy="payperiod-previous-button"
        size="small"
        key="previous"
        onClick={e => {
          fetchData({ payPeriod: payPeriod.prevPPID })
        }}
      >
        <Icon type="left" /> Prev
      </Button>
      <Button
        data-cy="payperiod-current-button"
        size="small"
        key="current"
        type={isCurrent}
        onClick={e => {
          fetchData({ payPeriod: payPeriod.currentPPID })
        }}
      >
        Current
      </Button>
      <Button
        data-cy="payperiod-next-button"
        size="small"
        key="next"
        onClick={e => {
          fetchData({ payPeriod: payPeriod.nextPPID })
        }}
      >
        Next <Icon type="right" />
      </Button>
    </Button.Group>
  )
}

export default PayPeriodSelect
