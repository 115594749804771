import React, { useEffect, useState } from "react"
import { store, Emitter, Can } from "../../../store/psrs"
import _ from "lodash"
import * as F from "../../Forms"
import { Spin, Row, Col, Button, Switch } from "antd"
//import { CloseOutlined, CheckOutlined, CheckCircleOutlined } from '@ant-design/icons';
import cleanDeep from "clean-deep"
import moment from "moment-timezone"
import { mutate } from "swr"
//import Icon from "antd/es/icon"
//import { mutate } from "swr"    

import useMyContext from "../../../store/useMyContext"
//import PayPeriodNav from "../../PowerView/PayPeriodNav"
//import { CheckBox } from "../../Forms"



const DEBUG = false

const { TagSelect, TriToggle, BusinessUnitSelect, SubLedgerSelect } = F
const { EmployeeSelect, StringFilter, PayTypeSelect } = F
const { SupervisorSelect, AssignmentSelect } = F
const { DateRangeFilter } = F

const Filters = props => {
  const { query, setQuery, filters, setFilters, loading, setLoading, setParanoid } = props
  const { sup1, setSup1, sup2, setSup2, sup3, setSup3 } = props

  const [state, setState] = useState({
    currentPayPeriod: false,
    allYear: false,
  })

  const [clearFilters, setClearFilters] = useState(false)

  const [mounted, setMounted] = useState(false)


  const context = useMyContext()
  //const toggleLoading = () => setState({ ...state, loading: !state.loading })
  //let payPeriod = JSON.parse(localStorage.getItem('PayPeriod'))
  //const isCurrent = payPeriod.id === payPeriod.todayId ? "primary" : "dashed"


  const handleFilterChange = async props => {
    setLoading(true)
    const { value, field } = props
    let { sql } = props
    const updatedFilters = { ...filters }
    updatedFilters[field] = value
    if (field === "tagIds" ) {
			if( value && value.length > 0 ) {
      	sql = ' "shiftId" IN (SELECT "shiftId" FROM "tagShifts" WHERE "tagShifts"."tagId" = ANY(\'{' + value + '}\'::text[]))'; 
			}
    }
    if (field === "tagIds-FTS") {
      query.tagIds = sql
    }
    if (field === "unedited") {
      query.unedited = `"createdAt"="updatedAt"`
    }
    if (field === "date") {
      query.range = null
      updatedFilters.range = null
    }
    if (field === "range") {
      query.date = null
      updatedFilters.date = null
    }
    if (field === "status" || field === 'sync') {
      if (sql !== null) sql = value ? `"status" = 'synced'` : `"status" = 'sync'`
      //updatedFilters['status'] = value ? 'synced' : 'sync'
    }
    if ( field === 'deletedAt') {
      setParanoid(true)
      sql=""
      query.deletedAt=""
      
      if (value) {
        query.deletedAt = 'is not null'
        sql=`"deletedAt" is not null`
        setParanoid(false)
      } else {
        sql=""
        query.deletedAt=""
        updatedFilters.deletedAt=null
      }
    }

    // handle superisor switches
      // handle supervisor levels

      if (field === 'superNumber') {
        sql = ''
        if (value !== null) {
          if (!props.sup1 && !props.sup2 && !props.sup3) {
            setSup1(true)
          }


          // assume superNumber1
          let  supervisorSQL = `("superNumber1"='${value}')`

          // sup2 only
          if (!props.sup1 && props.sup2 && !props.sup3) {
            supervisorSQL = `("superNumber2"='${value}')` 
          }
          //sup3 only
          if (!props.sup1 && !props.sup2 && props.sup3) {
            supervisorSQL = `("superNumber3"='${value}')` 
          }
          // superNumber1 & superNumber2
          if (props.sup1 && props.sup2 && !props.sup3) {
            supervisorSQL = `("superNumber1"='${value}' OR "superNumber2"='${value}')` 
          }
          // superNumber1 & superNumber3
          if (props.sup1 && !props.sup2 && props.sup3) {
            supervisorSQL =  `("superNumber1"='${value}' OR "superNumber3"='${value}')`
          }
          // superNumber2 & superNumber3
          if (!props.sup1 && props.sup2 && props.sup3) {
            supervisorSQL = `("superNumber2"='${value}' OR "superNumber3"='${value}')`
          }
          // all supervisors
          if (props.sup1 && props.sup2 && props.sup3) {
            supervisorSQL = `("superNumber1"='${value}' OR "superNumber2"='${value}' OR "superNumber3"='${value}')`
          }
          sql = supervisorSQL

        } else {
          setSup1(false)
          setSup2(false)
          setSup3(false)
      
        }
      }

    // end 
    setFilters(updatedFilters)
    const updatedQuery = { ...query }
    updatedQuery[field] = sql
    const Query = { ...query, ...updatedQuery }
    setQuery(cleanDeep(Query))
  }

  const setDateSelector = async (props) => {
    setState({ currentPayPeriod: false, allYear: false})
    handleFilterChange( props )
  }

/*
props = {
  field: 'range',
  model: 'timeEntry',
  sql: '"\"shiftId\" in (select \"id\" from shifts where \"start\"  BETWEEN '2021-10-03T06:00:00Z' AND '2021-10-17T06:00:00Z')",
  unstyled: 'yup',
  value: [moment, momement]
  handleChange:  async function...setDateSelector.....
}


*/

  const setPayPeriod = async () => {
    setState({ currentPayPeriod: false, allYear: false })
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()
    const range = store.get("PayPeriod").range
    const current = range.map(d => moment(d.value).utc())
    const YYYMMDD = current.map(c => c.tz(userTimeZone).format("YYYY-MM-DD"))
    //const sql = `"start" BETWEEN '${YYYMMDD[0]}' AND '${YYYMMDD[1]}'`

    // new utc
    const utcStartQ = moment(YYYMMDD[0] + ' 00:00:00').utc().format()
    const utcEndQ = moment(YYYMMDD[1] + ' 24:00:00').utc().format()

    //const sql = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
    // sql = `"start" AT TIME ZONE '${userTimeZone}' BETWEEN '${utcStartQ}' AND '${utcEndQ}'`

    const sql =  `"shiftId" in (select "id" from shifts where "start"  BETWEEN '${utcStartQ}' AND '${utcEndQ}')`

    filters.range[0] = moment(range[0].value)
    filters.range[1] = moment(range[1].value)

    handleFilterChange({ sql, field: "range", value: current })


    const dateProps = {
      field: 'range',
      model: 'timeEntry',
      sql: sql,
      unstyled: 'yup',
      value: filters.range,
      handleChange:  setDateSelector
    }
    setDateSelector(dateProps)

  }
  const setCurrentPayPeriod = async () => {
    setState({ currentPayPeriod: true, allYear: false })
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()
    const range = store.get("PayPeriodToday").range
    const current = range.map(d => moment(d.value).utc())
    const YYYMMDD = current.map(c => c.tz(userTimeZone).format("YYYY-MM-DD"))
    //const sql = `"start" BETWEEN '${YYYMMDD[0]}' AND '${YYYMMDD[1]}'`

    // new utc
    const utcStartQ = moment(YYYMMDD[0] + ' 00:00:00').utc().format()
    const utcEndQ = moment(YYYMMDD[1] + ' 24:00:00').utc().format()

    //const sql = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
    // sql = `"start" AT TIME ZONE '${userTimeZone}' BETWEEN '${utcStartQ}' AND '${utcEndQ}'`

    const sql =  `"shiftId" in (select "id" from shifts where "start"  BETWEEN '${utcStartQ}' AND '${utcEndQ}')`

    filters.range[0] = moment(range[0].value)
    filters.range[1] = moment(range[1].value)

    handleFilterChange({ sql, field: "range", value: current })
  }

  const resetFilters = async () => {
    console.log('reset filters')
    const ss = store.session.get("RecordsViewer")

    if (ss && ss.filters.range) {
      
      const range = ss.filters.range.map(a => moment(a))

      // new utc
      //const YYYMMDD = range.map(c => c.format("YYYY-MM-DD"))
      //const utcStartQ = moment(YYYMMDD[0] + ' 00:00:00').utc().format()
      //const utcEndQ = moment(YYYMMDD[1] + ' 23:59:59').utc().format()
      //const updatedRange = [ moment(YYYMMDD[0] + ' 00:00:00').utc(), moment(YYYMMDD[1] + ' 23:59:59').utc() ]
      const utcStartQ = moment(range[0].format("YYYY-MM-DD") + ' 00:00:00').utc().format()
      const utcEndQ = moment(range[1].format("YYYY-MM-DD") + ' 24:00:00').utc().format()

      //query.range = `"start" BETWEEN '${ss.filters.range[0]}' AND '${ss.filters.range[1]}'`
      //-//query.range = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`
      query.range =  `"shiftId" in (select "id" from shifts where "start"  BETWEEN '${utcStartQ}' AND '${utcEndQ}')`


      const allFilters = {
        payTypeCode: null,
        submit: null,
        process: null,
        unassignedEmployee: null ,
        isSick: null,
        requestedOff: null,
        imbalanced: null,
        isCollision: null,
        available:  null,
        caseNumber: null,
        employeeNumber: null,
        superNumber: null,
        assignmentId: null,
        businessUnitCode: null,
        costCenterChargeOutCode: null,
        subLedgerCode: null,
        needsApproved: null,
        approved: undefined,
        rejected: null,
        unedited: null,
        isFieldTraining: null,
        isDive: null,
        paidLunch: null,
        isOT: null,
        notes: null,
        tagIds: null,
        status: null,
        sync: null,
        locked: null,
        deletedAt: null,
      }

      const filters = {range: range, ...allFilters}
      const viewerState = {
        state: ss.state,
        update: ss.update,
        filters: cleanDeep(filters),
        query: query,
        date: Date.now(),
      }
      //store.session.remove("RecordsViewer")
      store.session.set("RecordsViewer", cleanDeep(viewerState))
      
      setSup1(false)
      setSup2(false)
      setSup3(false)

      setFilters(cleanDeep(filters))
      const Query = { ...query, ...allFilters }
      setQuery(cleanDeep(Query))
      setClearFilters(true)

    }
  }

  DEBUG && console.log("Filters", filters)

  /*
  const allYear = async () => {
    setState({ currentPayPeriod: false, allYear: true })
    const start = moment().startOf("year")
    const end = moment().endOf("year")
    const range = [start, end]
    const YYYMMDD = range.map(c => c.format("YYYY-MM-DD"))
    const sql = `"start" BETWEEN '${YYYMMDD[0]}' AND '${YYYMMDD[1]}'`
    handleFilterChange({ sql, field: "range", value: range })
  }
  */

  const opts = {
    unstyled: "yup",
    handleChange: handleFilterChange,
  }

  const dateOpts = {
    model: 'timeEntry',
    unstyled: "yup",
    handleChange: setDateSelector,
  }

  useEffect(() => {
    DEBUG && console.log("TimeEntry Query", query)
  }, [query])

  useEffect(() => {
    setState({...state })
    mutate(window.location.href)
    //const refresh = () => mutate(props.url)
    // eslint-disable-next-line
  }, [filters, sup1, sup2, sup3])

  useEffect(() => {
    //mounted && _setApprovals()
    Emitter.on("ChangedPayPeriod", () => {
      //console.log("Emitter.on('ChangedPayPeriod')")
      //mounted && _setApprovals()
      mounted && setPayPeriod()

    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, mounted])

  /* 
  useEffect(() => {
    console.log("filters userEffect() -> ", filters.range)
  },  [filters])
  */

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <section className="recordsFilter">
      <Spin spinning={loading}>
        <ul className="unstyledList">
          <div id="my-tab-content" className="tab-content">
          <li>
            <div className="inputs">
              <div className="input">
                <DateRangeFilter {...dateOpts} field="range" value={filters.range} />
                <Button
                  className="ml-4"
                  size="small"
                  type={!!state.currentPayPeriod ? "primary" : "default"}
                  onClick={setCurrentPayPeriod}
                >
                  Current PP
                </Button>
                {/* DATE */}
              </div>
            </div>
            </li>
            <li>
              <div className="inputs">
                <div align = "right">
                  <Button
                    className="ml-4"
                    size="small"
                    type="dashed"
                    onClick={resetFilters}
                  >
                    Clear All Filters</Button>
                </div>
                <div className="input">
                  <label>Employee</label>
                  <EmployeeSelect
                    {...opts}
                    field="employeeNumber"
                    employee={filters.employeeNumber ? _.find(store.get("Employees"), { number: filters.employeeNumber }) : null}
                  />
                </div>
                <div className="input">
                  <label>Supervisor</label>
                  <SupervisorSelect
                    {...opts}
                    field="superNumber"
                    supervisor={filters.superNumber ? _.find(store.get("Supervisors"), { number: filters.superNumber }) : null}
                  />
                </div>
                <div className="input">
                  <label>Supervisor: </label>
                    <div style={{textAlign: "center", margin: "0px 20px 0px 3px"}}>
                      <Switch 
                        disabled={filters.superNumber ? false : true}
                        size="small" 
                        checkedChildren="One " 
                        unCheckedChildren="One "
                        checked = {sup1}
                        defaultChecked = {true}
                        onChange = {(checked)=> {
                          setSup1(checked)
                          let superNumber = filters.superNumber
                          if (!sup2 && !sup3 && !checked) {
                            superNumber = null
                            setFilters({...filters, superNumber: null})
                          } 
                          handleFilterChange({field: 'superNumber', value: superNumber ? superNumber: null, sup2, sup3, sup1: checked})
                        }}
                      />
                    </div>
                    <div style={{textAlign: "center", margin: "0px 20px 0px 3px"}}>
                      <Switch 
                        disabled={filters.superNumber ? false : true} 
                        size="small" 
                        checkedChildren="Two " 
                        unCheckedChildren="Two "
                        checked = {sup2}
                        defaultChecked = {true}
                        onChange = {(checked)=> {
                          setSup2(checked)
                          let superNumber = filters.superNumber
                          if (!sup1 && !sup3 && !checked) {
                            superNumber = null
                            setFilters({...filters, superNumber: null})
                          } 
                          handleFilterChange({field: 'superNumber', value: superNumber ? superNumber: null, sup1, sup3, sup2: checked})
                        }}
                      />
                    </div>
                    <div style={{textAlign: "center", margin: "0px 20px 0px 3px"}}>
                      <Switch 
                        disabled={filters.superNumber ? false : true} 
                        size="small" 
                        checkedChildren="Three" 
                        unCheckedChildren="Three"
                        checked = {sup3}
                        defaultChecked = {true}
                        onChange = {(checked)=> {
                          setSup3(checked)
                          let superNumber = filters.superNumber
                          if (!sup1 && !sup2 && !checked) {
                            superNumber = null
                            setFilters({...filters, superNumber: null})
                          } 
                          handleFilterChange({field: 'superNumber', value: superNumber ? superNumber: null, sup1, sup2, sup3: checked})
                        }}
                      />
                    </div>
                </div>
              </div>
            </li>
            <li>
              <div className="inputs">
                <div className="input">
                  <label>Group</label>
                  <TagSelect {...opts} field="tagIds" value={filters.tagIds} />
                </div>
              </div>
            </li>
            <li>
              <div className="inputs">
                <div className="input">
                  <label>Assignment</label>
                  <AssignmentSelect {...opts} field="assignmentId" value={filters.assignmentId} />
                </div>
                <div className="input">
                  <label>Business Unit</label>
                  <BusinessUnitSelect {...opts} field="businessUnitCode" value={filters.businessUnitCode} />
                </div>
                <div className="input">
                  <label>Cost Ctr ChargeOut</label>
                  <BusinessUnitSelect {...opts} field="costCenterChargeOutCode" value={filters.costCenterChargeOutCode} />
                </div>
                <div className="input">
                  <label>Sub Ledger</label>
                  <SubLedgerSelect {...opts} field="subLedgerCode" value={filters.subLedgerCode} />
                </div>
                <div className="input">
                  <label>Pay Type</label>
                  <PayTypeSelect {...opts} field="payTypeCode" value={filters.payTypeCode} includeAll={true} />
                </div>
              </div>
            </li>
            <li>
              <div className="inputs">
                <Row>
                  <Col span={12}>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="needsApproved"
                        value={filters.needsApproved}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["needs approved", "dsnt need aprvd"]}
                      />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} field="approved" 
                        value={filters.approved} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["approved", "not approved"] } />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="rejected" 
                        value={filters.rejected} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["rejected", "not rejected"]} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="input">
                      <TriToggle 
                        {...opts} field="submit" 
                        value={filters.submit}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["submitted", "not submitted"]} />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} field="process" 
                        value={filters.process} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["processed", "not processed"]} />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="unedited" 
                        value={filters.unedited} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["un edited", "edited"]} />
                    </div>
                  </Col>
                </Row>
              </div>
            </li>
            <li>
              <div className="inputs">
                <Row>
                  <Col span={12}>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="isFieldTraining"
                        value={filters.isFieldTraining}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["is fto hrs", "not fto hrs"]}
                      />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="isDive" 
                        value={filters.isDive} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["is dive hrs", "not dive hrs"]} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="input">
                      <TriToggle 
                        {...opts} field="isOT" 
                        value={filters.isOT} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["is OT", "not OT"]} />
                    </div>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="paidLunch"
                        value={filters.paidLunch && filters.payTypeCode === '380'}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["Paid Lunch", "Un Paid Lunch"]}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </li>
            <li>
              <Row>
                <Col span={12}>
                  <div className="input">
                    <TriToggle 
                      {...opts} 
                      field="sync" 
                      //value={filters.status === 'synced' ? true : filters.status === 'sync' ? false:  null } 
                      value={filters.sync}
                      clearFilters={clearFilters}
                      setClearFilters={setClearFilters}
                      labels={["Synced to JDE", "Not Synced to JDE"]} />
                    </div>
                </Col>
                <Col span={12}>
                  <div className="input">
                    <TriToggle 
                      {...opts} 
                      field="locked" 
                      value={filters.locked}
                      clearFilters={clearFilters}
                      setClearFilters={setClearFilters}
                      labels={["Locked","Unlocked"]} />
                    </div>
                </Col>
              </Row>
              <Row>
                  <Col span={12}>
                    {/*
                      <div className="input">
                        <TriToggle
                          {...opts}
                          field="deletedAt"
                          value={filters.deletedAt}
                          clearFilters={clearFilters}
                          setClearFilters={setClearFilters}
                          dataType="number"
                          labels={["Is Deleted", "Not Deleted"]}
                        />
                      </div>
                    */}
                      <Can I="restore:TimeEntry">
                        <label>Restore Deleted ?</label>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Switch 
                          style={{color: 'green'}}
                          //checkedChildren={<CheckCircleOutlined />} 
                          //unCheckedChildren={<CloseOutlined />}
                          size='small'
                          loading={loading}
                          checked={filters.deletedAt}
                          defaultChecked={false}
                          onChange={e => {
                            handleFilterChange({field: 'deletedAt', value: e})
                          }}
                        />  
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      </Can>
                    </Col>
              </Row>
            </li>
            <li>
              <div className="inputs">
                <div className="input">
                  <label>Search Notes</label>
                  <StringFilter {...opts} field="notes" value={filters.notes} />
                </div>
              </div>
            </li>
            <li>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</ small></b></p>
            </li>
          </div>
        </ul>
      </Spin>
    </section>
  )
}

export default Filters
