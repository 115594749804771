import React, { useState, useMemo, useEffect } from "react"
import { useMyContext, useAbilityContext } from "../store/useMyContext"
import useSWR, { mutate } from "swr"
//import uuid from "uuid"
import { reqOptions, a, Can, REQ, store, session, Emitter } from "../store/psrs"
import { Keyboard, Box, CheckBox } from "grommet"
import { Button, Input, Tooltip, Popconfirm, notification, Icon, Switch } from "antd"
import BidsTable from "./ShiftDrawer/BidsTable"
import TimeEntriesTable from "./ShiftDrawer/TimeEntriesTable"
import LeaveEntriesTable from "./ShiftDrawer/LeaveEntriesTable"
import SickEntriesTable from "./ShiftDrawer/SickEntriesTable"
import { TagSelect, BusinessUnitSelect, StartTimeField, EndTimeField, DurationField, ReasonSelect } from "./Forms"
import { EmployeeSelect, EquipmentSelect, AssignmentSelect, SupervisorSelect, SubLedgerSelect } from "./Forms"
import { Drawer } from "./Drawer"
import  moment from "moment-timezone"
import { ApprovalFinePrint, FinePrint } from "./FinePrint"
import Help from "./Help"
import { CheckRequiredfields, ImbalancedMessage, RequiredFieldMessage } from "./ShiftDrawer/CheckRequiredFields"

const ButtonGroup = Button.Group

export const ShiftDrawer = props => {

  //var originalShift= null
  //let changeDetected = false
  let requestApproval = false

  //init to true -- new 
  //-//session.set("changeDetected", true)

  const context = useMyContext()
  const ability = useAbilityContext()
  const { shiftDrawerId } = context
  const id = context.shiftDrawerId
  const empNum = session.get("CurrentUser").number
  const url = `${store.get("api")}/api/shifts/${shiftDrawerId}`

  const myRole = store.session.get("role")

  const [state, setState] = useState({ loading: true, currentUserNumber: empNum, buttonsLocked: false })

  const [changeDetected2, setChangeDetected2] = useState(true);  // try to init to true
  const [shiftLocked, setShiftLocked] = useState(true)
  const [timeEntryLocked, setTimeEntryLocked] = useState(true)
  //const [buttonsLocked, setButtonsLocked] = useState(false)
  const [priorPayPeriod, setPriorPayPeriod] = useState(false)
  const [notesRequired, setNotesRequired] = useState(false)
  const [canEdit, setCanEdit] = useState(true)
  const [canEditReasonCode, setCanEditReasonCode] = useState(true)
  const [hasNotifications, setHasNotifications] = useState(false)

  const fetcher = async (url) => {
    const options = { ...reqOptions }
    return fetch(url, options)
      .then(res => res.json())
      .catch(err => {
         console.log('time error --> ', err)
         Emitter.emit("ShiftDrawer", { fn: "fetcher", error: err }) 
      })
  }

  const { data, error } = useSWR(url, fetcher, { refreshInterval: 0 })

  const drawerProps = useMemo(() => {
    const opts = {
      visible: "visible",
      open: true,
      className: "ShiftDrawer",
      closable: true,
      mask: true,
      maskClosable: true,
      title: "Shift Drawer",
      width: 800,
      height: 400,
      zIndex: 4,
      placement: "right",
      destroyOnClose: false,
      onClose: e => {
        context.setShiftDrawerId(0)
        session.remove("originalShift")
        session.remove("originalTimeEntries")
        session.remove("changeDetected")
      },
    }
    return opts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    // console.log("DATA CHANGED", data)
    if (!!data) {
      // Emitter.emit('ShiftDrawerUpdate')
      const Reasons = context.getRecords("Reasons")
      const { superNumber1, superNumber2, superNumber3 } = data
      const approvers = [superNumber1, superNumber2, superNumber3]

      ///let newEntryChangeDetected = !state.changeDetected && (data.approved || data.submit) ? false : true 
      state.changeDetected = state.changeDetected ? state.changeDetected : session.get("changeDetected")
      //let newEntryChangeDetected = data.approved || data.submit ? false : state.changeDetected 
      let newEntryChangeDetected = data.approved || data.submit || data.locked ? false : state.changeDetected 
      
      setShiftLocked(false)
      setTimeEntryLocked(false)
      setCanEdit(true)
      setCanEditReasonCode(true)

      if (data && data.locked ) {
        setShiftLocked(true)
        //setButtonsLocked(true)
        setTimeEntryLocked(true)

        if (priorPayPeriod) {
          //setButtonsLocked(false)
          state.changeDetected = false
          setState({ ...state, changeDetected: false })
        }

      }

      if (data && data.shiftTemplateId !== null && data.shiftTemplate) {

          //if (myRole === 'admin') {
          //  setCanEdit(true)
          //} else  {
            setCanEdit(data.shiftTemplate.canEdit)
          //}
      }

      if (data && data.shiftTemplate &&  data.shiftTemplate.shiftJson.reasonId ) {
        setCanEditReasonCode(true)
      } else {
      //  if (myRole === 'admin') {
      //    setCanEditReasonCode(true)
      //  } else {
          setCanEditReasonCode(false)
       // }
      }

      // notificaiton indicator??
      if (data && data.notifications && data.notifications.length > 0) {
        setHasNotifications(true)
      } else {
        setHasNotifications(false)
      }


    /* // lazy locking.... 
      if (data && data.timeEntries) {
        let isLocked = false
        for (let e of data.timeEntries) {
          if (e.process) isLocked = true
        }
         setShiftLocked(isLocked)
      }
    */
     //setShiftLocked(data.process)

      // make notes required based on different criteria
      setNotesRequired(false)
      //if (data.isOT || data.isSick || data.requestedOff) setNotesRequired(true)
      if (data.isOT || data.isSick ) setNotesRequired(true)
      //if (data.ftoHours > 0 && !data.ftoHoursIncluded) setNotesRequired(true)
      //if (data.diveHours > 0 && !data.diveHours) setNotesRequired(true)
      
      // changes to new shift create templates
      //if (data.name === 'NEW SHIFT BUTTON') setNotesRequired(true)
      //if (data.name.includes('NEW SHIFT BUTTON')) setNotesRequired(true)
      if (data.shiftTemplateId !== null ) setNotesRequired(true)


      if (data && data.timeEntries) {

        data.timeEntries.forEach((entry) => {

          // if time entries are OT, Leave, Sick, etc... 
          const _catFilters = ['OVERTIME','LEAVE', 'SICK','VACATION' ]
          const _payTypes = store.get("PayTypes")
          const _payType= _payTypes.find(el => el.code === entry.payTypeCode)  
          let _category = 'NONE'
          if (_payType) _category = _payType.category  
          if (_catFilters.includes(_category) && !data.requestedOff) {
              setNotesRequired(true)
          }
        })
      }
      
      setChangeDetected2(newEntryChangeDetected)

      const _state = {
        loading: false,
        approvers,
        canApprove: store.session.get("role") !== "employee",
        employee: false,
        reasons: Reasons,
        rejectionReasons: Reasons.filter(reason => reason.category && reason.category.toLowerCase() === "rejected"),
        updated: Date.now(),
      }

     let _changeState = {
        //changeDetected: state.changeDetected? state.changeDetected : session.get("changeDetected"), 
        changeDetected: !newEntryChangeDetected ? state.changeDetected? state.changeDetected : session.get("changeDetected") : newEntryChangeDetected, 
        requestApproval, 
        count: 0,
      }

      setState({ ...state, ..._state, ..._changeState })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const autoMutate = () => {
    mutate(url, { ...data })
  }

  useEffect(() => {
    if (!shiftDrawerId) mutate(url, null)
    if (!!shiftDrawerId && data) mutate(url, [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftDrawerId])
  
  useEffect(() => {
    setState(prevState => {
      return {
        ...prevState, 
        changeDetected: changeDetected2
      }
    });
    session.set("changeDetected", changeDetected2?changeDetected2:false)
  }, [changeDetected2]) 

  if (error) return <Drawer {...drawerProps} title="error" />
  if (!data) return <Drawer {...drawerProps} title="loading" />
  if (data) {
    const Shift = data
    const unAssigned = !Shift.employeeNumber
    const available = Shift.available
    const shiftStatus = unAssigned ? `(unassigned ${available ? "available" : "un-available"})` : ""

    const shiftType = Shift.isSick ? "SICK" : Shift.requestedOff ? "VACATION" : Shift.isOT ? "OVERTIME" : "REGULAR"
   
    const titleLock = shiftLocked ? 'Locked' : 'Unlocked'

    const fromTemplate = Shift.shiftTemplateId !== null ? ` | ${Shift.shiftTemplate ? Shift.shiftTemplate.name : ''}` : ''

    const DisplayLock = (props) => { 
      
      const iconProps = { type: shiftLocked ? "lock" : "unlock" } 
       return  (
         <>
           <Tooltip title={shiftLocked? "Locked" : "Unlocked"}>
            <Icon {...iconProps } />
           </Tooltip>
         </ >
        )
    }
    
    const NotifIcon = () => {
      const iconProps = { type: "notification" } 
       return  (
         <>
           <Tooltip title={hasNotifications? "Has Notification" : ""}>
            <Icon {...iconProps } />
           </Tooltip>
         </ >
        )

    }
    const AdminEdit = () => {
       /*ADMIN EDIT*/
       const iconProps = { 
         type: 'edit', 
         onClick: () => {
           setCanEdit(!canEdit)
           setCanEditReasonCode(!canEditReasonCode)
          }
        } 

       return (
         <>
            <Tooltip 
              title={  canEdit ? 'Admin Editing': 'Admin Edit' }
            >
               <Icon {...iconProps}  theme={canEdit ? "filled": ""} style={canEdit && Shift.shiftTemplateId !== null ? {color: "red"} : {}} />
            </Tooltip>
         </>
       ) 
    }


    const newShiftButton = data.shiftTemplateId !== null ? '*' : ''
    const title = data ? `${moment(data.start).format("ddd MMM Do")} ${shiftStatus} | ${shiftType}${fromTemplate}${newShiftButton} | ${titleLock} ` : "Loading"
    const employeeNumber = session.get("CurrentUser").number
    const disableButtons = false
    const bids = new Set(Shift && Shift.bids ? Shift.bids : [])

    
    // save it all    
    if (!session.get("originalShift")) {
      session.set("originalShift", Shift)
      session.set("originalTimeEntries", Shift.timeEntries)
    }

    var shiftUpdates = []
    const updateShift = async ({ field, value }) => {
      const update = field ? {} : value
      if (field) {
        update[field] = value
      }
      shiftUpdates.push(update)
      
      let changeDetected = false
      if (value !== Shift[field]) {
        changeDetected = true
      }

      if (state.changeDetected !== changeDetected) {
          setChangeDetected2(changeDetected)
      }
      
      const updated = await REQ(`/api/shifts/${Shift.id}`, "PATCH", update)
     
      if (updated.isOT || updated.isSick || update.requestedOff) {
        requestApproval = true
      } else {
        requestApproval = false
      }
      mutate(url, updated)

    }

    const confirmChangesClose = async () => {
      await confirmChanges()
      context.setShiftDrawerId(0)
    }

    const confirmChanges = async () => {
      //-//299var ids = [Shift.id]     
      let params = {}
      for (let update of shiftUpdates ) {
        Object.assign(params, update);
      }
      session.set("changeDetected", false)
      setChangeDetected2( false )
      setState({...state, changeDetected: false })
      requestApproval = false
      
      // still send notifications for sick/leave but not for OT
      if (Shift.isSick || Shift.requestedOff ) {
        const updateNotification = await REQ(`/api/notifications/${Shift.id}`, "POST")
        //const updateNotification = await REQ(`/api/notifications`, "POST", sendNotification)
        console.log('updateNotification -> ', updateNotification)
      }
      // save it all again
      session.set("originalShift", Shift)
      session.set("originalTimeEntries", Shift.timeEntries)
      notification.open({
        message: "Changes Saved",
        description: `Shift for ${Shift.employee.fullName} Saved.`,
        placement: "topRight",
        duration: 3,
      })
      mutate(url, Shift)
    }
    
    const undoChanges = async (originalShiftData) => {

      const originalShift = !!originalShiftData ? session.get("originalShift") : originalShiftData

      if (originalShiftData) {
        //const originalShift = session.get("originalShift")  
        // eslint-disable-next-line    
        const undoChanges = await REQ(`/api/utils/undoshiftchange`, 'POST', originalShift  )
        session.set("changeDetected", false)
       
        const description = !!originalShift.employee ? `Shift for ${originalShift.employee.fullName} Restored` : `Shift Restored`
        notification.open({
          message: "Changes rolled back",
          description,
          placement: "topRight",
          duration: 3,
        })
      }

      setChangeDetected2( false) 
      mutate(url, originalShift)
      

    }

    const approve = async () => {

      // check for required fields before allowing a submit
      let {checkFields, okSubmit} = await CheckRequiredfields(Shift)

      if (Shift.imbalanced) {
        ImbalancedMessage(Shift)
        okSubmit = false
      }

      if (checkFields.length > 0) {
        RequiredFieldMessage(Shift,checkFields)
      }

      if (okSubmit) {
        updateShift({
          field: false,
          value: { approved: !Shift.approved, approvedByNumber: session.get("CurrentUser").number },
        })
      }
      // end of submit checks
    }

    const reject = () => {
      updateShift({
        field: false,
        value: { rejected: !Shift.rejected, approvedByNumber: session.get("CurrentUser").number },
      })
    }
    const confirmDelete = async x => {
      const DeletedShift = await REQ(`/api/shifts/${shiftDrawerId}`, "DELETE")
      console.log("DeletedShift", DeletedShift)
      context.setShiftDrawerId(0)
      session.remove("originalShift")
      session.remove("originalTimeEntries")
    }
    const cancelDelete = x => {
      console.log("cancelDelete", x)
    }
    const callInSick = () => updateShift({ field: "isSick", value: !Shift.isSick })
    const requestOff = () => updateShift({ field: "requestedOff", value: !Shift.requestedOff })
    
    const markAsWorked = async () => {

      // check for required fields before allowing a submit
      let {checkFields, okSubmit} = await CheckRequiredfields(Shift)

      if (Shift.imbalanced) {
        ImbalancedMessage(Shift)
        okSubmit = false
      }

      if (checkFields.length > 0) {
        RequiredFieldMessage(Shift,checkFields)
      }

      if (okSubmit) {
        // handle isOT notificaiton on submit
        if (Shift.isOT ) {
          const updateNotification = await REQ(`/api/notifications/${Shift.id}`, "POST")
          //const updateNotification = await REQ(`/api/notifications`, "POST", sendNotification)
          console.log('Submit updateNotification -> ', updateNotification)
        }

        updateShift({ field: "submit", value: !Shift.submit })
        setChangeDetected2( false )
        session.set("changeDetected", false)
      }
      // end of submit checks

    } 
    const bidOnShift = () => {
      if (bids.has(employeeNumber)) bids.delete(employeeNumber)
      else bids.add(employeeNumber)
      // REQ(`/api/resources/shift/${Shift.id}`, "PATCH", { bids: Array.from(bids) })
      updateShift({ field: "bids", value: Array.from(bids) })
    }
    const selectProps = {
      pk: "id",
      record: Shift,
      storeKey: "shifts",
      handleChange: updateShift,
      id: Shift.id,
    }

    const boxProps = {
      // align: 'center',
      // alignContent: 'stretch',
      // basis: "auto",
      className: "Box",
      direction: "row",
      // fill: false,
      // flex: 'grow',
      gap: "1.5rem",
      // justify: 'stretch',
      pad: "small",
    }

    // const canEditShift = ability.can("edit", a("Shift", Shift))
    let cantEditShift = ability.cannot("edit", a("Shift", Shift))
    const cantEditEmployee = !ability.can("edit:shift:Employee")
    let cantEditSupervisor = ability.cannot("edit:shift:Supervisor") 
    const cantEditGroups = ability.cannot("edit:shift:Groups")
    const cantEditAssignment = ability.cannot("edit:shift:Assignment")
    let cantEditBusinessUnit = ability.cannot("edit:shift:BusinessUnit")
    let cantEditSubLedger = ability.cannot("edit:shift:SubLedger")
    const canCallInSick = ability.can("sick", a("Shift", Shift))
    const canRequestOff = ability.can("pto", a("Shift", Shift))
    //const canSubmit = ability.can("submit", a("Shift", Shift))
    const cantEditLunch = ability.cannot("edit:shift:paidLunch")
    const cantFtoHours = ability.cannot("add:FTOtime")
    const cantDiveHours = ability.cannot("add:DiveTime")


    // override edit supervisor if from a templates
    if ( Shift.shiftTemplateId !== null && Shift.shiftTemplateId !== undefined ) {
      cantEditSupervisor = false

      if (Shift.shiftTemplate.canEdit) {
        cantEditBusinessUnit = false
        cantEditShift = false
        cantEditSubLedger = false
      }
      
    }

    // processed 
    //const canModShift = ability.can("edit", a("Shift",Shift))
    //const canModTimeEntry = ability.can("edit", a("TimeEntry",Shift.timeEntries))
   
    // new
    let canDeleteShift =  ability.can("delete", a("Shift", Shift)) || Shift.shiftTemplateId !== null || Shift.name === 'NEW SHIFT BUTTON'
    canDeleteShift = !Shift.submit && !Shift.approved && !Shift.locked && !Shift.processed && canDeleteShift

    //if ( canDeleteShift ) cantEditAssignment = false

    let evidenceShift = Shift.payTypeCode === '250' || Shift.payTypeCode === '251' ? true : false
    if ('timeEntries' in Shift) {
      for (let t of Shift.timeEntries) {
        evidenceShift = t.payTypeCode === '250' || t.payTypeCode === '251' ? true : false
      }
    }

    const newCanSubmit = moment(Shift.end).subtract(1,"hours").toISOString() <= moment().toISOString() ? true:false

    const shiftHelp = {
      id: Shift.id,
      name: Shift.name,
      available: Shift.available,
      payPeriodId: Shift.payPeriodId,
      utcStart: Shift.start,
      utcEnd: Shift.end,
      employeeNumber: Shift.employeeNumber,
      supervisorNumber: Shift.superNumber1,
      duration: Shift.duration,
      durationOt: Shift.durationOt,
      durationOg: Shift.durationOg,
      isSick: Shift.isSick,
      isLeave: Shift.requestedOff,
      isOT: Shift.isOT,
      isScheduledOT: Shift.scheduledOT,
      isApproved: Shift.approved,
      isRejected: Shift.rejected,
      isSubmitted: Shift.submit,
      isProcessed: Shift.isProcessed,
      isLocked: Shift.locked,
      isImbalanced: Shift.imbalanced,
      createdAt: Shift.createdAt,
      updatedAt: Shift.updatedAt,
      userId: Shift.userId,
      shiftTemplateId: Shift.shiftTemplateId,
      Notifications: Shift.notifications && Shift.notifications.length > 0 ? true : false
      //notes: Shift.notes,
      //timeEntries: Shift.timeEntries,
    }
    return (
      <Drawer {...drawerProps} title={title} >
        <Keyboard
          target="document"
          onEsc={() => {
            //console.log("ESCAPE")
            context.setShiftDrawerId(0)
            
            if (state.changeDetected) undoChanges( session.get("originalShift"))
            session.remove("originalShift")
            session.remove("originalTimeEntries")
            session.remove("changeDetected")
          }}
          onClose={() => {
            //console.log("CLOSE")
            context.setShiftDrawerId(0)
            if (state.changeDetected) confirmChanges()
            session.remove("originalShift")
            session.remove("originalTimeEntries")
            session.remove("changeDetected")
          }}
        >
          {data && (
            <div className="ShiftDrawerForm">
              <Box {...boxProps}>
                <div className="employeeSelect flex1">
                  <h4>Employee</h4>
                  <EmployeeSelect employee={Shift.employee} field="employeeNumber" {...selectProps} disabled={cantEditEmployee || shiftLocked || !canEdit} />
                </div>
                <div className="supervisorSelect flex1">
                  <h4>Supervisor</h4>
                  <SupervisorSelect
                    supervisor={Shift.supervisor1}
                    field="superNumber1"
                    isClearable={store.session.get("role") !== "employee" ? true : false}
                    {...selectProps}
                    disabled={cantEditSupervisor || shiftLocked}
                  />
                </div>
                <div className="flex1">
                  <h4>Operational Assignment</h4>
                  <Input
                    defaultValue={Shift.operationalAssignment}
                    placeholder="optional"
                    onBlur={e => updateShift({ field: "operationalAssignment", value: e.target.value })}
                    //disabled={false}
                    disabled={shiftLocked}
                  />
                </div>
              </Box>
              <Box {...boxProps}>
                <div className="tagsSelect flex1">
                  <h4>Groups</h4>
                  <TagSelect {...selectProps} value={Shift.tags} disabled={cantEditGroups || shiftLocked } />
                </div>
                <div className="equipmentSelect flex1">
                  <h4>Equipment</h4>
                  <EquipmentSelect {...selectProps} value={Shift.gizmos} disabled={cantEditShift || shiftLocked || !canEdit} />
                </div>
                <div className="assignmentSelect flex1">
                  <h4>Acting Role</h4>
                  <AssignmentSelect field="assignmentId" {...selectProps} disabled={ cantEditAssignment || shiftLocked } />
                </div>
              </Box>
              <Box {...boxProps}>
                <div className="startTimeField flex1">
                  <h4>Start Time</h4>
                  <StartTimeField
                    field="start"
                    value={{ start: Shift.start, end: Shift.end }}
                    handleChange={updateShift}
                    disabled={cantEditShift || evidenceShift || shiftLocked}
                  />
                </div>
                <div className="endTimeField flex1">
                  <h4>End Time</h4>
                  <EndTimeField
                    field="end"
                    value={{ end: Shift.end, start: Shift.start }}
                    record={Shift}
                    handleChange={updateShift}
                    disabled={cantEditShift || evidenceShift || shiftLocked}
                  />
                </div>
                <div className="durationField flex1">
                  <h4>duration</h4>
                  <DurationField
                    field="duration"
                    value={Shift.duration ? Shift.duration : undefined}
                    handleChange={updateShift}
                    disabled={true}
                  />
                </div>
                {/* <div className="durationField flex1">
                  <h4>duration OG</h4>
                  <DurationField
                    field="duration"
                    value={Shift.durationOg ? Shift.durationOg : undefined}
                    handleChange={updateShift}
                    disabled={true}
                  />
                </div>
                <div className="durationField flex1">
                  <h4>duration Optional</h4>
                  <DurationField
                    field="duration"
                    value={Shift.durationOt ? Shift.durationOt : undefined}
                    handleChange={updateShift}
                    disabled={true}
                  />
                </div> */}

                {/* DIVE HOURS */}
                {/* <Can I="add:DiveTime"> */}
                <div className="durationField flex1">
                  <h4>dive hours</h4>
                  <DurationField
                    field="diveHours"
                    disabled={cantDiveHours}
                    value={Shift.diveHours ? Shift.diveHours : undefined}
                    handleChange={updateShift}
                    id={Shift.id}
                  />
                </div>
                {/* </Can> */}

                {/* FTO HOURS */}
                {/* <Can I="add:FTOtime"> */}
                <div className="durationField flex1">
                  <h4>FTO hours</h4>
                  <DurationField
                    field="ftoHours"
                    disabled={cantFtoHours}
                    value={Shift.ftoHours ? Shift.ftoHours : undefined}
                    handleChange={updateShift}
                    id={Shift.id}
                  />
                </div>
                {/* </Can> */}

                <div className="lunchField flex1">
                  <h4>Unpaid Lunch</h4>
                  <CheckBox
                    disabled={cantEditLunch || evidenceShift || shiftLocked || !canEdit} 
                    checked={!Shift.paidLunch}
                    onChange={e => {
                      //console.log(`PAID LUNCH: ${!e.target.checked}`)
                      updateShift({ field: "paidLunch", value: !e.target.checked })
                    }}
                  />
                </div>
              </Box>
              <Box {...boxProps}>
                {/* REASONS */}
                {/* <Can I="see:shift:reasons" this={Shift}> */}
                <div className="flex1">
                  <h4>{Shift.isOT && !Shift.scheduledOT ? <b>Reason*</b> : 'Reason'}</h4>
                  <ReasonSelect  value={Shift.reasonId} field="reasonId" {...selectProps} disabled={!Shift.isOT || shiftLocked || canEditReasonCode } />
                </div>
                {/* </Can> */}

                {/* BUSINESS UNIT */}
                <div className="businessUnitSelect flex1">
                  <h4>Business Unit Override</h4>
                  <BusinessUnitSelect field="costCenterChargeOutCode" {...selectProps} disabled={cantEditBusinessUnit || shiftLocked || !canEdit} />
                </div>

                {/* SUBLEDGER */}
                <div className="subLedgerSelect flex1">
                  <h4>Sub Ledger</h4>
                  <SubLedgerSelect field="subLedgerCode" {...selectProps} disabled={cantEditSubLedger || shiftLocked || !canEdit} />
                </div>
              </Box>
              <Box {...boxProps}>
                {/* NOTES */}
                <div className="flex1">
                  <h4>{notesRequired ? <b>Notes*</b> : 'Notes'}</h4>
                  <Input
                    defaultValue={Shift.notes}
                    placeholder={notesRequired ? "Required notes" : "Optional notes"}
                    onBlur={e => updateShift({ field: "notes", value: e.target.value })}
                    disabled={cantEditShift || shiftLocked }
                  />
                </div>
              </Box>
              <section className="timeEntries">
                {/* BIDS TABLE */}
                <Can I="see:shift:bids" this={Shift}>
                  <div style={{padding: '10px'}}>
                    <Can I="manageBids" this={Shift}>
                      <Switch
                          disabled={false}
                          checked={Shift.takingBids}
                          checkedChildren={<Icon type="check" />}
                          unCheckedChildren={<Icon type="close" />}
                          onChange={e => {
                            updateShift({ field: "takingBids", value: e })
                          }}
                        /> <label>Available for Bids</label>
                    </Can>
                    <BidsTable handleUpdate={updateShift} shift={Shift} />
                  </div>
                </Can>

                {/* TIME ENTRIES TABLE */}
                <Can I="see:shift:timeEntries" this={Shift}>
                    <TimeEntriesTable 
                      timeEntries={Shift.timeEntries || []} 
                      shift={Shift} 
                      mutate={autoMutate} 
                      url={url} 
                      changeDetected={state.changeDetected} 
                      requestApproval={requestApproval}
                      shiftLocked={shiftLocked || !canEdit}
                      setShiftLocked={setShiftLocked}
                      //setButtonsLocked = {setButtonsLocked}
                      changeDetected2={changeDetected2}
                      setChangeDetected2={setChangeDetected2}
                      priorPayPeriod={priorPayPeriod}
                      setPriorPayPeriod={setPriorPayPeriod}
                      timeEntryLocked={timeEntryLocked}
                      setTimeEntryLocked={setTimeEntryLocked}
                    /> 
                </Can>

                {/* SICK ENTRIES TABLE */}
                <Can I="see:shift:sickEntries" this={Shift}>
                  <SickEntriesTable 
                     shift={Shift} 
                     mutate={autoMutate} 
                     url={url}
                     shiftLocked={shiftLocked}
                     setShiftLocked={setShiftLocked}
                     //setButtonsLocked = {setButtonsLocked}
                  />
                </Can>

                {/* LEAVE ENTRIES TABLE */}
                <Can I="see:shift:leaveEntries" this={Shift}>
                  <LeaveEntriesTable 
                     shift={Shift} 
                     mutate={autoMutate} 
                     url={url} 
                     shiftLocked={shiftLocked}
                     setShiftLocked={setShiftLocked}
                     //setButtonsLocked = {setButtonsLocked}
                  />
                </Can>

                {/* BID BUTTON */}
                <Can I="bidOn" this={Shift}>
                  <Box pad="medium">
                    <Button onClick={() => bidOnShift(context.shiftDrawerId)}>
                      {bids.has(`${employeeNumber}`) ? "Remove" : ""} Bid for Shift
                    </Button>
                  </Box>
                </Can>
              </section>
              <section className="shiftActions">
                {!Shift.available && (
                  <ButtonGroup>
                    <Can I="approve" this={Shift}>
                      <Tooltip title={Shift.approved ? "Un Approve" : "Approve"}>
                        <Popconfirm
                          title={Shift.approved ? "Un Approve Shift" : <ApprovalFinePrint/>}
                          onConfirm={approve}
                          okText={Shift.approved ? "Un Approve" : "Approve"}
                          cancelText="Cancel"
                          disabled = {shiftLocked }
                        >
                          <Button
                            icon="like"
                            disabled={(state.canApprove ? false : true ) || shiftLocked }
                            type={Shift.approved ? "primary" : "default"}
                            size="large"
                          />
                        </Popconfirm>
                      </Tooltip>
                      <Tooltip title={Shift.rejected ? "Un Reject" : "Reject"}>
                      <Popconfirm
                          title={Shift.rejected ? "Un Reject Shift Fine Print" : <ApprovalFinePrint/>}
                          onConfirm={reject}
                          okText={Shift.rejected ? "Un Reject" : "Reject"}
                          cancelText="Cancel"
                          disabled = {shiftLocked }
                        >
                        <Button
                          icon="dislike"
                          disabled={(state.canApprove ? false : true) || shiftLocked }
                          type={Shift.rejected ? "primary" : "default"}
                          size="large"
                        />
                        </Popconfirm>
                      </Tooltip>
                    </Can>
                    <Can I="edit" this={Shift}>
                      <Tooltip title="Request Off">
                        <Button
                          disabled={!canRequestOff || disableButtons || evidenceShift || shiftLocked || !canEdit}
                          icon="message"
                          type={Shift.requestedOff ? "primary" : "default"}
                          onClick={requestOff}
                          size="large"
                        />
                      </Tooltip>
                      <Tooltip title="Call in Sick">
                        <Button
                          disabled={!canCallInSick || disableButtons || evidenceShift || shiftLocked || !canEdit}
                          icon="medicine-box"
                          type={Shift.isSick ? "primary" : "default"}
                          onClick={callInSick}
                          size="large"
                        />
                      </Tooltip>
                    </Can>
                    {/*<Can I="delete" this={Shift}>*/}
                      <Tooltip title="Delete Shift">
                        <Popconfirm
                          title="Are you sure you want to delete this Shift and it's associated Time Entries?"
                          onConfirm={confirmDelete}
                          onCancel={cancelDelete}
                          okText="Yes"
                          cancelText="No"
                          disabled={!canDeleteShift }
                        >
                          <Button 
                          type="default" size="large" icon="delete" disabled={!canDeleteShift || shiftLocked }/>
                        </Popconfirm>
                      </Tooltip>
                    {/*</Can>*/}

                    {/* UNDO */}
                    <div className="FlexBlock" >
                    <Tooltip title='Undo Changes'>
                        <Popconfirm
                          disabled={state.changeDetected? false: true || !canEdit}
                          title='Undo All Changes' 
                          onConfirm={undoChanges}
                          okText='Undo All Changes?'
                          cancelText="Cancel"
                        >
                          <Button
                            disabled={state.changeDetected? false: true || !canEdit} 
                            icon="undo"
                            //type={state.changeDetected ? "default" : "primary"}
                            size="large"
                            style={state.changeDetected  ? { background: "", borderColor: "orange" } :{ background: "", borderColor: "" } }
                          />
                        </Popconfirm>
                      </Tooltip>

                    {/*SAVE*/}
                    <Tooltip 
                        //title={ Shift.isOT || Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                        title={ Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                    >
                        <Popconfirm
                          disabled={state.changeDetected ? false : true}
                          //title={ Shift.isOT || Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                          title={ Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                          onConfirm={confirmChanges}
                          //okText={ Shift.isOT || Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                          okText={ Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                          cancelText="Cancel"
                        >
                          <Button
                            disabled={state.changeDetected? false: true}
                            //icon={ Shift.isOT || Shift.isSick || Shift.requestedOff? 'notification': 'save'}
                            icon={ Shift.isSick || Shift.requestedOff? 'notification': 'save'}
                            //type={state.changeDetected ? "primary" : "default"}
                            size="large"
                            style={state.changeDetected  ? { background: "", borderColor: "orange" } :{ background: "", borderColor: "" } }
                          />
                        </Popconfirm>
                      </Tooltip>

                      {/*SAVE AND CLOSE */}
                      <Tooltip title={'Save Shift and Close'}>
                          <Popconfirm
                            disabled={state.changeDetected ? false : true}
                            title='Save Shift and Close'
                            onConfirm={confirmChangesClose}
                            okText={'Save Shift and Close'}
                            cancelText="Cancel"
                          >
                            <Button
                              disabled={state.changeDetected ? false : true}
                              icon='close'
                              size="large"
                              style={state.changeDetected  ? { background: "", borderColor: "orange" } :{ background: "", borderColor: "" } }
                            />
                          </Popconfirm>
                        </Tooltip>

                      {/*ADMIN EDIT*/}
                      {/* {myRole === 'admin' && Shift.shiftTemplateId !== null && <Tooltip 
                        //title={ Shift.isOT || Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                        title={  canEdit ? 'Admin Editing': 'Admin Edit' }
                    >
                        <Popconfirm
                          disabled={false }
                          //title={ Shift.isOT || Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                          title={ canEdit ? 'Admin Editing': 'Admin Edit' }
                          onConfirm={()=>setCanEdit(!canEdit)}
                          //okText={ Shift.isOT || Shift.isSick || Shift.requestedOff? 'Save & Request Approval': 'Save'}
                          okText={ canEdit ? 'Stop Editing': 'Start Editing' }
                          cancelText="Cancel"
                        >
                          <Button
                            disabled={false}
                            icon={'edit'}
                            type={canEdit ? "primary" : "default"}
                            size="large"
                            style={state.changeDetected  ? { background: "", borderColor: "orange" } :{ background: "", borderColor: "" } }
                          />
                        </Popconfirm>
                      </Tooltip>} */}

                      {/*SUBMIT*/}
                      <Tooltip 
                        title={Shift.submit? "Un Submit" : !newCanSubmit ? "Submit allowed 1 hr prior to shift end time":`Submit ${Shift.isOT ? ' and Request OT Approval' : '' }`}
                      >
                        <Popconfirm
                          disabled={(state.changeDetected || !newCanSubmit || shiftLocked)}
                          title={Shift.submit? "Unsubmit Shift" : <FinePrint />}
                          onConfirm={markAsWorked}
                          okText={Shift.submit? "Un Submit" : "Submit"}
                          cancelText="Cancel"
                        >
                          <Button
                          disabled={(state.changeDetected || !newCanSubmit || shiftLocked)}
                            icon="check-circle"
                            type={Shift.submit ? "primary" : "default" }
                            size="large"
                          />
                        </Popconfirm>
                      </Tooltip>
                      {/*LOCK*/}
                      {/*
                      <Tooltip title={shiftLocked? "Pay Period Locked" : "Pay Period Unlocked"}>
                        <Popconfirm
                          disabled={false}
                          title={shiftLocked? "Locked" : "Unlocked"}
                          onConfirm={handleLock}
                          okText={shiftLocked? "Unlock" : "Lock"}
                          cancelText="Cancel"
                       >
                          <Button
                          disabled={false}
                            icon= { shiftLocked? "lock" : "unlock" }
                            type={shiftLocked ? "danger" : "ghost" }
                            size="large"
                          />
                       {/* </Popconfirm>
                      </Tooltip>
                       */}
                      </div>
                  </ButtonGroup>
                )}
                {!!Shift.originalShiftId && (
                  <Button
                    disabled={shiftLocked}
                    className="link"
                    type="primary"
                    ghost={true}
                    onClick={() => context.setShiftDrawerId(Shift.originalShiftId)}
                  >
                    Original Shift
                  </Button>
                )}
                {!!Shift.sickShiftId && (
                  <Button
                    disabled={shiftLocked}
                    className="link"
                    type="primary"
                    ghost={true}
                    onClick={() => {
                      context.setShiftDrawerId(Shift.sickShiftId)
                    }}
                  >
                    {Shift.isSick ? "Sick Shift" : "Leave Shift" } 
                  </Button>
                )}
              </section>
              {/* <div>{Shift.id}</div> */}
             <div style={{float: 'right'}}><Help text={shiftHelp} type='shiftHelp'/></div>
             { hasNotifications && <div style={{float: 'right'}}><NotifIcon /></div> }
             <div style={{float: 'right'}}><DisplayLock /></div>
             { ( myRole === 'admin' && Shift.shiftTemplateId !== null ) &&  <div style={{float: 'right'}}><AdminEdit /></div> }
            </div>
            
          )}
        </Keyboard>
      </Drawer>
    )
  }
}

export default ShiftDrawer
