import React from "react"
import { Button, Popconfirm, Tooltip } from "antd"
import moment from "moment"
import {REQ, a} from "../../store/psrs"
import { CheckBox } from "grommet"
import { ApprovalFinePrint } from "../FinePrint"

export const timeEntryColumns = (props) => {

  const {ability, context, refresh} = props
  //console.log('timeEntryColumns', ability);

  const approveEntry = async (model, id, value) => {
    await REQ(`/api/resources/timeEntry/${id}`, "PATCH", { approved: value, rejected: false })
    refresh()
  }

  const rejectEntry = async (model, id, value) => {
    await REQ(`/api/resources/timeEntry/${id}`, "PATCH", { approved: false, rejected: value })
    refresh()
  }

  return [
    {
      Header: "Employee",
      headerClassName: "alignLeft",
      sortable: true,
      accessor: "employee.fullName",
      Cell: row => (row.value ? row.value : "unassigned"),
    },
    {
      Header: "Date Of",
      headerClassName: "alignLeft",
      sortable: true,
      accessor: "start",
      Cell: row => moment(row.value).format("ddd MMM Do"),
    },
    {
      Header: "Hours",
      sortable: true,
      accessor: "duration",
      Cell: row => `${parseFloat(row.value).toPrecision(3)} Hrs`,
    },
    {
      Header: "Supervisor",
      headerClassName: "alignLeft",
      sortable: true,
      accessor: "supervisor1.fullName",
      Cell: row => row.value,
    },
    {
      Header: "Pay Type",
      accessor: "payType.description",
      sortable: true,
      Cell: row => row.original.payType.description,
    },
    {
      Header: "Unpaid Lunch",
      accessor: "paidLunch",
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => <> <CheckBox disabled={true} checked={!cell.value}></CheckBox></>,
    },
    {
      Header: "",
      accessor: "shiftId",
      width: 200,
      className: "justify-around",
      sortable: false,
      Cell: row => {
        const rec = row.original
        const disabled = ability.cannot("approve", a("TimeEntry", rec))
        return (
          <Button.Group>
            {/* IF SUPERVISOR OR ADMIN */}
            {/*<Button
              disabled={disabled}
              value={rec.id}
              className={!!row.original.approved ? "approved" : ""}
              icon="like"
              type={rec.approved ? "primary" : "default"}
              onClick={e => approveEntry("timeEntry", rec.id)}
            />
            <Button
              disabled={disabled}
              value={rec.id}
              className={!!row.original.rejected ? "rejected" : ""}
              icon="dislike"
              type={rec.rejected ? "primary" : "default"}
              onClick={e => rejectEntry("timeEntry", rec.id)}
            />*/}

            {/* APPROVED */}
             {/* APPROVE */}  
             <Tooltip title={`${rec.approved ? "Un Approve" : "Approve"}`}>
              	<Popconfirm
                	title={rec.approved ? "Un Approve Time Entry" : <ApprovalFinePrint />}
                	onConfirm={ e => 
                    //approveEntry("shift", rec.id)}
                   (!rec.rejected) && approveEntry("timeEntry", rec.id, !rec.approved )}
                	okText={rec.approved ? "Un Approve" : "Approve"}
                	cancelText="Cancel"
              	>
                	<Button
                  	icon="like"
                  	disabled={disabled}
                  	type={rec.approved ? "primary" : "default"}
                	/>
              	</Popconfirm>
            	</Tooltip>

              {/* REJECT */}
              <Tooltip title={`${rec.rejected ? "Un Reject" : "Reject"}`}>
              	<Popconfirm
                	title={rec.approved ? "Un Reject Time Entry" : <ApprovalFinePrint />}
                	onConfirm={ e => 
                    //approveEntry("shift", rec.id)}
                   (!rec.approved) && rejectEntry("timeEntry", rec.id, !rec.rejected )}
                	okText={rec.rejected ? "Un Rject" : "Reject"}
                	cancelText="Cancel"
              	>
              <Button
                className={!!row.original.rejected ? "rejected" : ""}
                icon="dislike"
                disabled={disabled}
                type={rec.rejected ? "primary" : "default"}
              />
              	</Popconfirm>
            	</Tooltip>

            <Button
              value={rec.shiftId}
              icon="eye"
              onClick={e => {
                context.setShiftDrawerId(row.value)
              }}
            >
              View
            </Button>
          </Button.Group>
        )
      },
    },
  ]
}

export default timeEntryColumns
