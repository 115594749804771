import React, {useState, useEffect} from "react"
import * as F from "../../Forms"
import { Can,session } from "../../../store/psrs"
import { Row, Col } from "antd"
import { CheckBox } from "../../Forms"
import clean from "clean-deep"
import moment from "moment-timezone"
// import _ from "lodash"

const { TriToggle, BusinessUnitSelect, SubLedgerSelect } = F
const { AssignmentSelect, PayTypeSelect } = F
const { TagSelect, TextInput } = F
//const { SupervisorSelect, EmployeeSelect } = F

const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()
const userId = session.get("CurrentUser") ? session.get("CurrentUser").email : null

const clearFields = (type) => {
  
  var  clearableFields =  {  
    'clearAssignmentId': true, 
    'clearBusinessUnitCode': true,
    'clearChargeOutCode': true,
    'clearPayTypeCode': true,
    'clearSubLedger': true,
    'clearGroups': true,
    'clearNotes': true, 
    "syncStatus": true,
    "restore": true,
  }

  return clearableFields[type] || false
}

const getClearFields = (type) => {
  
  var  clearableFields =  {  
    'clearAssignmentId': 'assignmentId', 
    'clearBusinessUnitCode': 'businessUnitCode',
    'clearChargeOutCode': 'costCenterChargeOutCode',
    'clearPayTypeCode': 'payTypeCode',
    'clearSubLedger': 'subLedgerCode',
    'clearGroups': 'tagIds',
    'clearNotes': 'notes', 
    "clearStatus": "status",
    "status": "status"
  }

  return clearableFields[type] || false
}

const TimeEntryNotes = ({ defaultValue, placeholder, field, onInputBlur, opts }) => {
  const [inputState, setInputState] = useState("");
  useEffect(() => {
    setInputState(defaultValue);
  }, [defaultValue]);
  return <TextInput {...opts} field={field} placeholder={placeholder} value={inputState} onChange={(e) => setInputState(e.target.value)} onBlur={onInputBlur} />;
};

const Fields = props => {
  const { update, setUpdate } = props
  const {recsUpdated, setRecsUpdated} = props
  const {paranoid, setParanoid} = props

  const modUpdate = update ? update : {}

  const [ clearCheckBoxes, setClearCheckBoxes] = useState(false) 

  const [mounted, setMounted] = useState(false)
  const [state, setState] = useState({
    clearAssigmentId: false,
    clearBusinessUnitCode: false,
    clearChargeOutCode: false,
    clearPayTypeCode: false,
    clearSubLedger: false,
    clearGroups: false,
    clearNotes: false, 
    syncStatus: false,
    locked: false,
    restore: false,
  })

  const handleFieldChange = args => {
    const { field, value } = args
    const _update = {}

   

    if ( value === null || value === '') {
      _update[field] = null
    } else {
      _update[field] = value
    }

    if (field === 'status') {
      if (value) {
        _update.status = 'sync'
        _update.syncUserId = userId
        _update.lastSyncTime=moment().tz(userTimeZone).format()

      } else {
        _update.status = null
        _update.syncUserId = null
        _update.lastSyncTime = null
      }
     
    }

    if (field === 'approved' ) {

      if (value === null) {
        _update.approved = null
        _update.rejected = null
        _update.submit = null      
      } else { 
        if (value) {
          _update.rejected = false
          _update.approved = true
          _update.submit = true
          _update.approvedByNumber = session.get('CurrentUser').number
        } else {
          _update.rejected = false
          _update.approved = false
          _update.submit  =false
          _update.approvedByNumber = null
        }
      }
    }

    if (field === 'rejected') {

      if (value === null) {
        _update.rejected = null
        _update.approved = null
        _update.submit = null
      } else {
        if (value) {
          _update.rejected = true
          _update.approved = false
          _update.submit = false
          _update.approvedByNumber = session.get('CurrentUser').number
        } else {
          _update.rejected = false
          _update.approved = false
          _update.submit = false
          _update.approvedByNumber = null
        }
      }
    }

    if ( clearFields(field) ) {
      delete _update[field]
      delete update[field]

      if (value) {
        _update[getClearFields(field)] = null
      }
        
    }

    if (field === 'assignmentId' || field === 'costCenterChargeOutCode' || field === 'subLedgerCode' || field === 'notes' ) {
       //setState({ clearAssigmentId: false })
       setClearCheckBoxes(true)
       //state.clearAssigmentId = false
    }
    //if (field === 'costCenterChargeOutCode') setState({ clearChargeOutCode: false })
    //if (field === 'subLedgerCode') setState({ clearSubLedger: false })
   

    const Updated = { ...update, ..._update }
    let Pruned = clean(Updated)
    
    if ( (field === 'approved' && value !== null) || ( field === 'rejected' && value !== null ) ) {
       Pruned = Updated
    }

   
    if (clearFields(field)) {
      Pruned = Updated
    } 
    

    setUpdate(Pruned)

    
  }


  const opts = {
    allowClear: true,
    unstyled: "yup",
    handleChange: handleFieldChange,
  }

  // timeClockStartDateTime
  // timeClockEndDateTime

  useEffect(() => {
    if (mounted && state) {
      const keys = Object.keys(state)   
      for (let k of keys) {
        setState({k: state[k]})
      }
      setClearCheckBoxes(false)
       //handleFieldChange({field: 'clearAssignmentId', value: e.value})
      //setState(false)
      //setRecsUpdated(false)
    } else {
      setMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCheckBoxes])


  useEffect(() => {
    if (mounted && recsUpdated) {
      setState(false)
      setRecsUpdated(false)
    } else {
      setMounted(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recsUpdated])

  return (
    <section className="recordsFilter">
      <ul className="unstyledList">
        <div id="my-tab-content" className="tab-content">
      <li>
          <p style={{color:'red'}}><b><small>Editing Records</small></b></p>
          </li>
          <li>
            <div className="inputs">
              {/*
              <div className="input">
                <label>Employee</label>
                <EmployeeSelect {...opts} field="employeeNumber" value={update.employeeNumber} placeholder="Change Employee" />
              </div>
              <div className="input">
                <label>Supervisor</label>
                <SupervisorSelect {...opts} field="superNumber1" value={update.superNumber1} placeholder="Change Supervisor" />
              </div>
              */}
              <div className="input">
                <label>Assignment</label>
                <AssignmentSelect {...opts} field="assignmentId" value={modUpdate.assignmentId} />
              </div>
              <div className="input">
                <label>PayType</label>
                <PayTypeSelect {...opts} field="payTypeCode" value={modUpdate.payTypeCode} includeAll={true} />
              </div>
            </div>
          </li>
          {/* 
          <li>
            <div className="inputs">
              <div className="input">
                <label>hours Worked</label>
              </div>
            </div>
          </li>
          */}
          <li>
            <div className="inputs">
              <Row>
                <Col span={12}>
                <span style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}>Remove</span>
                <span>&nbsp;</span>
                  <div>
                      <span>&nbsp;</span>
                        <CheckBox 
                          value={state.clearAssigmentId}
                          field='clearAssignmentId'
                          recsUpdated = {recsUpdated}
                          setRecsUpdated = {setRecsUpdated}
                          handleChange={e => {
                            handleFieldChange({field: 'clearAssignmentId', value: e.value})
                          }}>
                          </CheckBox>
                          <span>&nbsp;</span>
                          <label>Assignment</label>
                      </div>
                  {/*} <div>
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearPayTypeCode}
                        field='clearPayTypeCode'
                        handleChange={e => {
                          handleFieldChange( { field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                      <span>&nbsp;</span>
                      <label>Clear Pay Type </label>
                    </div>
                    <div>
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearBusinessUnitCode}
                        field='clearBusinessUnitCode'
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                        <span>&nbsp;</span>
                        <label>Clear Business Unit</label>
                    </div>
                      */}
                    <div>
                    <Can I="edit:TimeEntry:process">
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearChargeOutCode}
                        field='clearChargeOutCode'
                        recsUpdated = {recsUpdated}
                        setRecsUpdated = {setRecsUpdated}
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                        <span>&nbsp;</span>
                        <label>Charge Out</label>
                        </Can>
                    </div>
                </Col>
                <Col span={12}>
                  <span style={{borderBottom: '1px solid black', width: '100%',display: 'block'}}>&nbsp;</span>
                  <span>&nbsp;</span>
                  <div>
                  <Can I="edit:TimeEntry:process">
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearSubLedger}
                        field='clearSubLedger'
                        recsUpdated = {recsUpdated}
                        setRecsUpdated = {setRecsUpdated}
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                        <span>&nbsp;</span>
                        <label>SubLedger</label>
                    </Can>
                    </div>
                    <div>
                      <span>&nbsp;</span>
                      <CheckBox 
                        value={state.clearGroups}
                        field='clearGroups'
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                        <span>&nbsp;</span>
                      <label>All Groups</label>
                      </div>
                      <div>
                        <span>&nbsp;</span>
                        <CheckBox 
                          value={state.clearNotes}
                          field='clearNotes'
                          recsUpdated = {recsUpdated}
                          setRecsUpdated = {setRecsUpdated}
                          handleChange={e => {
                            handleFieldChange({ field: e.field, value: e.value } )
                          }}>
                          </CheckBox>
                          <span>&nbsp;</span>
                          <label>Notes</label>
                        </div>
                </Col>
              </Row>
            </div>
          </li>
          <li>
            <div className="inputs">
              <Row>
                <Col span={12}>
                  <div className="input">
                    <TriToggle 
                      {...opts} 
                      field="submit" 
                      value={modUpdate.submit} 
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["submit", "un submit"]} />
                  </div>
                  <div className="input">
                    <TriToggle 
                      {...opts} 
                      field="approved" 
                      value={modUpdate.approved} 
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["approve", "un approve"]} />
                  </div>
                  <div className="input">
                    <TriToggle 
                      {...opts} 
                      field="rejected" 
                      value={modUpdate.rejected}
                      recsUpdated = {recsUpdated}
                      setRecsUpdated = {setRecsUpdated}
                      labels={["reject", "un reject"]} />
                  </div>
                </Col>
                <Col span={12}>
                  <Can I="edit:TimeEntry:process">
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="process" 
                        value={modUpdate.process} 
                        recsUpdated = {recsUpdated}
                        setRecsUpdated = {setRecsUpdated}
                        labels={["process", "un process"]} />
                    </div>
                  </Can>
                  <Can I="edit:TimeEntry:lock">
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="locked" 
                        value={modUpdate.locked} 
                        recsUpdated = {recsUpdated}
                        setRecsUpdated = {setRecsUpdated}
                        labels={["lock", "unlock"]} />
                    </div>
                  </Can>
                </Col>
              </Row>
            </div>
          </li>
          <Can I="edit:TimeEntry:process">
            <li>
              <div className="inputs">
                  <div className="input">
                    <label>Business Unit</label>
                    <BusinessUnitSelect {...opts} field="businessUnitCode" value={modUpdate.businessUnitCode} />
                  </div>
                  <div className="input">
                    <label>Charge Out</label>
                    <BusinessUnitSelect {...opts} field="costCenterChargeOutCode" value={modUpdate.costCenterChargeOutCode} />
                  </div>
                  <div className="input">
                    <label>Sub Ledger</label>
                    <SubLedgerSelect {...opts} field="subLedgerCode" value={modUpdate.subLedgerCode} />
                  </div>
              </div>
            </li>
          </Can>
          <li>
            <div className="inputs">
              <div className="input">
                <label>Groups</label>
                <TagSelect {...opts} field="tagIds" value={modUpdate.tagIds} />
              </div>
              <div className="input">
                <label>notes</label>
                {/*<TextInput {...opts} field="notes" value={modUpdate.notes} />*/}
                <TimeEntryNotes
                    field="notes"
                    opts={opts}
                    defaultValue={modUpdate.notes || null}
                    onInputBlur={e => {
                      handleFieldChange({ field: "notes", value: e.target.value })
                    }}
                />
              </div>
            </div>
          </li>
          <li>
              <Can I="edit:TimeEntry:process">
              <span>&nbsp;</span>
                <div>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                      <CheckBox 
                        value={state.syncStatus}
                        field='status'
                        handleChange={e => {
                          handleFieldChange({ field: e.field, value: e.value } )
                        }}>
                        </CheckBox>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      <label>Force Resync to JDEdwards</label>
                </div>
                <span>&nbsp;</span>
                <span>&nbsp;</span>
                <div>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                      <CheckBox 
                        value={state.restore}
                        field='status'
                        handleChange={e => {
                          //handleFieldChange({ field: e.field, value: e.value } )
                          setParanoid(!paranoid)
                        }}>
                        </CheckBox>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      <label>Restore Delete Time Entries</label>
                </div>
                <span>&nbsp;</span>
              </Can>
          </li>
          <li>
            <p style={{color:'red'}}><b><small>Editing Records</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
            <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
          </li>
          
        </div>
      </ul>
    </section>
  )
}

export default Fields
