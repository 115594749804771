import React, { useState, useEffect } from "react"
// import { subject } from "@casl/ability"
import { reqOptions, REQ, store, Can } from "../store/psrs"
import ReactTable from "react-table"
import { useHistory } from "react-router-dom"
import useSWR, { mutate } from "swr"
import { Search } from "./Admin/fields"
import { Button, message, Tag, Tooltip, Popconfirm } from "antd"
import { deleteResource } from "./Admin/fields"
import _ from "lodash"

export const Teams = props => {
  const history = useHistory()
  const eNumber = store.session.get("CurrentUser").number
  const supervisorNumber = store.session.get("CurrentUser").supervisorNumber
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    pk: "id",
    model: "team",
    ids: [],
    plural: "teams",
    all: false,
    defaultPageSize: 50,
    //pageSizeOptions: [25, 50, 100],
    //pageSize: 25,
    defaultSorted: [
      { id: "updatedAt", desc: true },
      { id: "id", desc: true },
    ],
    className: "-striped -highlight flex-1",
    initialRecords: undefined,
  })

  const [records, setRecords] = useState(state.initialRecords || [])

  const fetcher = async url => {
    setLoading(true)
    const options = { ...reqOptions }
    let data = await fetch(url, options).then(res => res.json())
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    setLoading(false)
    return data
  }

  var { data } = useSWR(props.url, fetcher, { initialData: state.initialRecords })

  const deleteTeam = async id => {
    setLoading(true)
    const Resource = await REQ(`/api/resources/${state.model}/${id}`)
    if (Resource.templates && Resource.templates.length === 0) {
      await deleteResource({ state, setState, id, mutate, url: props.url })
    } else {
      message.warning("This team has schedules and can't be deleted.")
    }
    setLoading(false)
  }

  useEffect(() => {
      store.set("_Teams", data)

      return() => { 
        store.remove("_Teams")
      }
    
  }, [data])

  useEffect(() => {
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ids])

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{props.title}</h1>
        <div className="actions">
          <Can I="viewAll:Teams">
            {props.page === "myTeams" && <Button onClick={() => history.push("/teams/all")}>All Teams</Button>}
            {props.page === "allTeams" && <Button onClick={() => history.push("/teams/mine")}>My Teams</Button>}
          </Can>
          <Can I="create:Teams">
            <Button
              disabled={loading}
              data-cy="CreateResourceButton"
              icon="plus-square"
              onClick={async () => {
                setLoading(true)
                const attributes = {
                  name: `NEW TEAM ${new Date().getUTCMilliseconds()}`,
                  superNumber1: eNumber,
                  superNumber2: supervisorNumber,
                }
                const newTeam = await REQ(`/api/resources/team`, "POST", { ...attributes })
                history.push(`/team/${newTeam.id}`)
              }}
            >
              Create a {state.model}
            </Button>
          </Can>
          <Search state={state} setState={setState} />
        </div>
      </div>

      <div className="view_content">
        <ReactTable
          noDataText={loading ? "" : `No Records Found`}
          loading={loading ? true : data ? false : true}
          data={records}
          defaultPageSize={state.defaultPageSize}
          pageSizeOptions={state.pageSizeOptions}
          pageSize={state.pageSize}
          defaultSorted={state.defaultSorted}
          className={state.className}
          getTrProps={state.getTrProps}
          columns={[
            {
              Header: "Updated",
              accessor: "updatedAt",
              show: false,
            },
            { Header: "id", accessor: "id", show: false },
            { Header: "Name", accessor: "name", className: "cx-name" },
            {
              Header: "Groups",
              accessor: "tags",
              Cell: row => {
                const id = row.original.id
                const tags = row.value.map(tag => <Tag key={`${id}${tag.id}`}>{tag.name}</Tag>)
                return <div>{tags}</div>
              },
            },
            {
              Header: "Supervisor 1",
              accessor: "supervisor1",
              className: "cx-supervisor1",
              Cell: row => {
                const Employee = row.value
                return Employee ? (
                  <span>
                    <Tag>{Employee.jobTitle}</Tag> {Employee.lastName}{" "}
                  </span>
                ) : (
                  "--"
                )
              },
            },
            {
              Header: "Supervisor 2",
              accessor: "supervisor2",
              className: "cx-supervisor2",
              Cell: row => {
                const Employee = row.value
                return Employee ? (
                  <span>
                    <Tag>{Employee.jobTitle}</Tag> {Employee.lastName}{" "}
                  </span>
                ) : (
                  "--"
                )
              },
            },
            {
              Header: "Supervisor 3",
              accessor: "supervisor3",
              className: "cx-supervisor3",
              Cell: row => {
                const Employee = row.value
                return Employee ? (
                  <span>
                    <Tag>{Employee.jobTitle}</Tag> {Employee.lastName}{" "}
                  </span>
                ) : (
                  "--"
                )
              },
            },
            {
              Header: "Actions",
              className: "justify-around cx-actions",
              Cell: row => {
                const recordId = row.original.id
                const templates = row.original.templates
                return (
                  <Button.Group>
                    <Button icon="edit" data-cy={`edit ${recordId}`} onClick={() => history.push(`/team/${recordId}`)} />
                    <Can I="delete" this={row.original}>
                      <Popconfirm
                        data-cy="confirm"
                        okText="yes delete"
                        title={`Are you sure you want to DELETE this ${state.model}? ${recordId}`}
                        onConfirm={() => deleteTeam(recordId)}
                        onCancel={() => message.warning("Phew ... That was close!")}
                      >
                        <Tooltip title="Delete">
                          <Button
                            data-cy={`delete ${recordId}`}
                            value={recordId}
                            icon="delete"
                            type="danger"
                            disabled={templates && templates.length}
                          />
                        </Tooltip>
                      </Popconfirm>
                    </Can>
                  </Button.Group>
                )
              },
            },
          ]}
        />
      </div>
    </React.Fragment>
  )
}

export default Teams
