import React from "react"
import {DatePicker} from "antd"
import moment from "moment"

export const DateTimeFilter = (props) => {
  const {value} = props
  const dt = value ? moment(value) : undefined

  const onChange = (value, dateString) => {
    //console.log("Selected Time: ", value)
    //console.log("Formatted Selected Time: ", dateString)
    props.handleChange({...props, value })
  }

  const onOk = (value) => {
    console.log("onOk: ", value)
  }

  return (
    <DatePicker data-cy="date-time-picker" showTime placeholder="Select Time" onChange={onChange} onOk={onOk} defaultValue={dt} />
  )
}

export default DateTimeFilter
