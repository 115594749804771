import React from "react"
import { createSession } from "../store/psrs"
import { useHistory } from "react-router-dom"

export const Blank = props => {
  const history = useHistory()
  const loginRedirect = window.location.hash.startsWith("#/id_token")
  if (!!loginRedirect) createSession(history)

  return (
    <div className="BlankPage">
      {loginRedirect && (
        <div className="loggingYouIn">
          <h1>We're logging you in</h1>
          <p>This will only take a second.</p>
        </div>
      )}
    </div>
  )
}

export default Blank
