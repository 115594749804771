import React from "react"

export const FinePrint = () => (
<p>
    By clicking the OK button below, you are <br />
    electronically certifying that the time you <br />
    are submitting is the actual and accurate time you <br />
    worked during the subject period. When you click <br />
    the OK button, the system records your electronic <br />
    signature and makes your timecards available to your <br />
    supervisor for review and approval.
</p>
)

export const ApprovalFinePrint = () => (
<p>
    WE THE UNDERSIGNED ACCEPT THIS AS A TRUE AND VALID DOCUMENT CERTIFYING ITS <br />
    CORRECTNESS AS TO NAME, TIME CHARGED AND WORK DESCRIPTION.
</p>
)

export const SMSFinePrint = () => (
  <p>
      By clicking the Accept button you are <br />
      electronically certifying that you opt-in <br />
      to recieving SMS Notifications from SchedulerPro.<br />
       <br />
      You may opt-out at any time.<br />
      Msg & data rates may apply. Msg frequency varies.<br />
  </p>
  )

export const VersionHistory = () => {
    return [
      {
        version: "V1.0.11",
        section: "New ⭐️",
        items: [
          "Bidder Workbench (US090):  Administrators/Supervisors can enable/disable open assignments to allow bidding",
          "Approvals Workbench: Enhanced the Approvals Workbench and is now the default for Supervisors/Administrator",
          "Initial SEARCH box on Shifts, Time Entries and Approvals, whihc allows searching by employee, supervisor, day, date and time",
          "Mobile avaialble for all users"
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "Fixed bug where wrong approver by be used when approving shifts",
          "Fixed bug when bulking generating shifts not getting to Azure Queue",
          "Check for required fields on Supervisor approval and won't allow approval until fields are properly filled-in (US078)",
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "Added more filters to the Approval Workbench to make it easier to use",
          "Query retrival improvements to get data on the forms quicker for the user",
          "Enhance data payload related to deletes to better filter and check before committing delete in EnterpriseOne",
          "Seeding new API end points for future enhancements such as find employee by their email address",
        ]
      },
      {
        version: "",
        section: "Coming Soon 📣",
        items: [
          "Admin shift and time entry auditing.  The ability to view a shift and time entry history will allow adminstrators to understand how a shift or time has changed over time",
          "Users will be able to subscribe to the notifications they want to see and choose how they want them delivered on a notification-by-notification bases",
          "A new in-App notification option to view notifications in the app and save them for the next time you login",
          "Actionable notifications, click to approve/reject",
          //"We are always working on new features for ease of use and fixing issues we find",
          //"The team is activily reviewing backlog items including user request as we plan the next release(s)"
        ]
      },
      {
        version: "V1.0.10",
        section: "New ⭐️",
        items: [
          "Iniital enhancenment to all administrators to enable/disable assignments",
          "Mobile client limited use.  We are starting to rollout mobile access and will be adding more users over the next few months.",
          "Verify button for phones on employees.  This will be used to allow mobile login via a One Time Passcode via an SMS message in place of a password"
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "Fixed bug where wrong approver by be used when approving shifts",
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "Enhance data payload related to deletes to better filter and check before committing delete in EnterpriseOne",
        ]
      },
      {
        version: "V1.0.9",
        section: "New ⭐️",
        items: [
          "Mobile client limited use.  We are starting to rollout mobile access and will be adding more users over the next few months.",
          "Verify button for phones on employees.  This will be used to allow mobile login via a One Time Passcode via an SMS message in place of a password"
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "Shift Templates -> Fixed how FTO and Dive shifts time entry and shift duration balances.  There was an issue when creating FTO or Dive shifts from a template and showing out of balance.  ",
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "Records Viewer -> Auto filter for superviosr on entry and re-etnry",
          "Records Viwer -> Filter by Super Visor 1, Supervisor 2 and/or Supervisor 3",
          "Shift Templates -> When administrators configure an FTO or Dive shift there is now an explicit toggle switch to indicate the shift is FTO or Dive.  This ensures we can properly crate the FTO or Dive shift from a template"      
        ]
      },
      {
        version: "V1.0.8",
        section: "New ⭐️",
        items: [
          "This is mostly a maintenance release to imporve or fix known issues",
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "Notifications and ensuring they will send based on various scenarios",
          "Fixed an issue when re-assigning and employee on a shift would not update the time entry records", 
          "Added split between employee assingment and employees bid award so shifts allow proper employee reassignment"      
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "Deleting Shifts now checks to see if the shift has been submitted, approved, processed or locked and if any are true will not allow the shift to be deleted",
          "Deleteing a schedule in Teams now analyizes the attarched shifts and time entries and if any have been submitted, approved, processed or locked then the scheule can not be deleted",
          "Notifications for OT, Sick and PTO Request will be reset and resent if the Shift is changed or un-approved, un-submitted.  The next submit will re-send the request again",
          "Notification responses when OT, Sick or PTO are approved can be resent if the shift changes, un-approved or un-submitted.",
          "Notificaitons are dispatched better from the Records Viewer and the Shifts/Time Entry forms when using the quick submit, quick approval"
          
        ]
      },
      {
        version: "",
        section: "Coming Soon 📣",
        items: [
          "Mobile is comning soon",
          "New Web Client Approval form to align with Mobile Approvals",
          "Admin shift and time entry auditing.  The ability to view a shift and time entry history will allow adminstrators to understand how a shift or time has changed over time",
          "Users will be able to subscribe to the notifications they want to see and choose how they want them delivered on a notification-by-notification bases",
          "A new in-App notification option to view notifications in the app and save them for the next time you login",
          "Actionable notifications, click to approve/reject",
          //"We are always working on new features for ease of use and fixing issues we find",
          //"The team is activily reviewing backlog items including user request as we plan the next release(s)"
        ]
      },
      {
        version: "V1.0.7",
        section: "New ⭐️",
        items: [
          "Updated Login Graphics",
          "Shift Templates: Shift Templates are here.  Administrators can now configure Shift Templates with shift defaults.  These templates are accessed by other users in the Add Shift Button.  There is a new look when adding shifts and easier to find shifts by categories",
          "Shift Templates: Allow Admins and Supervisor to create shifts for other employee/supervisors.  Employees can create shifts for themselves with a different supervisor",      
          "The Admin Role can edit/override shifts created from a template in the Shift Drawer.  I new edit icon has been added for the Admin Role",
    
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "When going to Shifts in Records Viewer all shifts are unselected by default",
          "General bug fixes and imporovements throughout different modules",
          "Pagination issue with Shift Templates Form",
          "Fixed security on Approve/Reject for Shift Templates in the Shift Drawer",
          "Fixed Charge Out Code selector in Shifts/Time Entries",
          "Only Admins can update Business Unit and Charge Out Code updates for shifts and time entries in Records Viewer ",
          "Fixed but in Employees where data could be corrupted if no business unit for the employee was passed",
          "Fixed a bug where a notification which had not been sent could be removed from a shift/time entry when using the Undo or Undo & save in the shift drawer",
          "Fixed a bug where a notification may not have been properly created/attached to a shift created with the New Shift Button",
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "Employees list automatically hides the Disabled Role from the list.  A switch to see all disabled has been added by the search box.  Users can still search for disabled if needed",
          "The Pay Period Stats dashboard now has loading indicators.",
          "The Pay Period Stats dashboard now loads data tabs when needed instead of all at once",
          "Evidence Shift special handling | default Duration handled",
          "New Shift Drawer button to save and exit from the shift drawer",
          "If a shift has a notification action, and icon is displayed in the lower right to help indicate a notficaiton is attached to the shift/time entry",
          "Performace updates for Admin Shift Template Administrative Grid view",
          "Shift Template Administrative Grid View Grouping now a multi-select dropdown",
          "Notification are now soft-deleted and can be manually recoved if needed",
        ]
      },
      {
        version: "",
        section: "Coming Soon 📣",
        items: [
          "Mobile is comning soon",
          "Admin shift and time entry auditing.  The ability to view a shift and time entry history will allow adminstrators to understand how a shift or time has changed over time",
          "Users will be able to subscribe to the notifications they want to see and choose how they want them delivered on a notification-by-notification bases",
          "A new in-App notification option to view notifications in the app and save them for the next time you login",
          "Actionable notifications, click to approve/reject",
          //"We are always working on new features for ease of use and fixing issues we find",
          //"The team is activily reviewing backlog items including user request as we plan the next release(s)"
        ]
      },
      {
        version: "V1.0.6",
        section: "New ⭐️",
        items: [
          "Enterprise Technology Feedback link.  Administrators/Supervisors can submit feedback for bugs, enhancements, general questions direcly from PSRS using the notify icon on the top of the side navigator tool bar.",
          "Employees with the 480 Hour leave bank will have a new Pay Type code to use - 96.  And Leave Balance will currently show in the 'Other' bucket",
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "Pay Period Navigator- Showing wrong year when starts in 2020 and ends in 2021.  Was using start date to display year",
          "Team Shift Template Generation - Pay Period selector now filters out pay periods that are locked so past won't be accessible",
          "Team Shift Template Generation - Because of filtering only showing current year + 1, shifts into 2023 will not show until 2021.  Opened to current year + 3",
          "New Shift Create - Fixed issue with Shift Business Unit getting overridend when a Cost Center Charge Out Code (BU) was selected",
          "New Shift Create - Random issue when chekcing if a chosen date is in a locked pay period, changed how date format is sent",
          "Quick submit from Shifts was always asking for Reason on OT shifts, this now works as intended",
          "General bug fixes and imporovements throughout different modules"
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "The employee specialities for FTO and Dive which sync's over from JDE is not longer reqiured to use FTO/DIVE pay type codes",
          "Reworked the Timeline view(s) for Administrators and Supervisors.  This is an imporviement to the Timeline View with more improvements scheduled"
        ]
      },
      {
        version: "",
        section: "Coming Soon 📣",
        items: [
          "Mobile is comning soon",
          "Users will be able to subscribe to the notifications they want to see and choose how they want them delivered on a notification-by-notification bases",
          "A new in-App notification option to view notifications in the app and save them for the next time you login",
          "Actionable notifications, click to approve/reject",
          //"We are always working on new features for ease of use and fixing issues we find",
          //"The team is activily reviewing backlog items including user request as we plan the next release(s)"
        ]
      },
      {
        version: "V1.0.5",
        section: "New ⭐️",
        items: [
          "Pay Period Locking.  Administrators can now lock a pay period including shifts and time entries when getting ready to process to JDEdwards.",
          "Pay Period Un-lock.  Adminstrators can unlock a locked pay period for individual shifts and time entries.",
          "Pay Period Statistics.  There is a new Pay Period Stats page for all and the information avaiable is role based. Employees can get a quick look at their tim entries, Supervisors can review direct reports, admin can see all stats",
          "Revover deleted shifts / time entries.  Adminstrators can now recover deleted shifts and time entry records.  All deletes are now SOFT deletes and recoverable by administrators.",
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "Adding/Splitting shifts when using the Reaquested Off and Add Leave Entry ",
          "Fixed Pagination issue with My Teams when viewoing all teams",
          "Records Viewer -> some tri-toggles not clearing after an update",
          "Reset Approval/Reject/submit flags if shift or time entry is changed after submitted/approved/rejected",
          "Approvals by supervisors not in direct chain of command not showing approved for employee",
          "Pay Type maintence for Administrator.  Now loads properly and quickly Generic Names in the grid",
          "Reason Code not diplaying in shift drawer correctly.  Was being saved, just not re-displayed",
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "Security -> Better lockdown at route/url to help with unauthorized access",
          "Security -> Business Units maintenance form only accessible by ADMIN",
          "Security -> Groups maintenance form only accessbile by ADMIN",
          "Security -> Pay Types maintenance form only accessible by ADMIN",
          "Security -> Subledgers maintenance form only accessible by ADMIN", 
          "Submit   -> When submitting, Notes for OT, Sick shfits/time entries are required to submit",
          "Submit   -> When submitting, Pay Type Code and Hrs are reqiored, ",
          "Submit   -> When submitting, Time Entries and Shift Hrs must be in balance to submit",
          "Submit   -> If the shift is not a scheduled OT shift, then a reason is required to submit",
          "Records Viewer Date Range Filter -> Administrators and supervisors can now navigate previous / current / next pay periods directly in the date filter."   
        ]
      },
      {
        version: "",
        section: "Coming Soon 📣",
        items: [
          "Mobile is comning soon",
          "Users will be able to subscribe to the notifications they want to see and choose how they want them delivered on a notification-by-notification bases",
          "A new in-App notification option to view notifications in the app and save them for the next time you login",
          "Actionable notifications, click to approve/reject",
          //"We are always working on new features for ease of use and fixing issues we find",
          //"The team is activily reviewing backlog items including user request as we plan the next release(s)"
        ]
      },
      {
        version: "V1.0.4",
        section: "New ⭐️",
        items: [
          "Exciting feature work scheduled to begin soon.  This will include general product and user feature requrest",
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "Notifications to primary (Oracle) email when notifications are enabled",
          "Submit time window moved to 1 hr before shift end",
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "Records Viewer  -> Can now remove certain fields - Assignment, Charge Out, SubLedger, Groups, Notes, Operational Assignment",
          "Records Viewer  -> Modify fields now properly show and update the PSRS Record(s) and will CLEAR after update is complete",
          "Shift Drawer    -> Added Acting Role to Shift Drawer Time Entry table.",
          "Time Entry View -> Added status icons to grid an ability to approve/reject from grid without needing to open shift drawer",
          "General         -> Catpture the user adding/updating, create/update date time for activity in Shifts and Time Entries.  This is a record level audit first step in more details change ledger process",
        ]
      },
      {
        version: "V1.0.3a",
        section: "New ⭐️",
        items: [
          "CSV Export in Records Viewer -> Admins and Supervisors can now export from the Records Viewer to a CSV/Excel file for both Time Entries and Shifts.  The export supports all filters and record selection in the grid.  Time Entries will contain fields related to the parent Shift",
          "Exciting feature work scheduled to begin soon.  This will include general product and user feature requrest",
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "1.0.3a -> Approval Tri-selector causing erro on Time Entry form.  Found a small bug and is now fixed.  ",
          "1.0.3a -> Records Viewer query when filter using supervisor  ",
          "Next iteration on Pay Period bug when new pay period starts.  Less caching and going directly to the database to build pay period data",
          "Changed window for when time can be sumitted after a shift ends from 1/2 hour before to 1 hour before",
          "Fixed securirty so all three supervisors in an employees chain of command can now see and approve from the shift drawer",
          "Fixed securirty so all Admin can see and approve from the shift drawer",
          "Records Viewer -> When approving from the records viewer the person approving is properly captured in the Appproved By field in Shfits and Time Entries",
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "Records Viewer  -> Users can now collapse the filter/modify tabs to provide a full grid view of the data",
          "Records Viewer  -> Navigation footer now allows for go to first page - previous page - next page - last page",
          "Shift Drawer    -> If record has been approved and submitted then viewing in the shift draw will not require pressing the save button",
          "Shift Drawer    -> We've un-filtered the unpaid lunch time entry record to show in Sick and Leave tables in the Shift Drawer. This is to better show how the hours are totaled",
          "Records Viewer  -> Added filter to quickly see whats been sent/synced to JDE ",
    
        ]
      },
      {
        version: "V1.0.2",
        section: "New ⭐️",
        items: [
          "  ✅ Admin and Supervisors can view logged in user sessions",
          "  ✅ Admin can delete old sessions",
        ]
      },
      {
        version: "",
        section: "Fixes 🛠",
        items: [
          "  ✅ Fixed issue with changing time in Shift Drawer where it was not updating time entry records",
          "  ✅ Fixed issue in New Shifts where regular schedule would 'light up' Overtime icon in Shifts Grid",
          "  ✅ Fixed Reason Admin form to properly save associated Pay Type Code when selected",
          "  ✅ Fixed issue with generating Over Time shifts when checking unpaid lunch would not account for extra 1/2 hour and generated shifts had wrong total for Shift",
        ]
      },
      {
        version: "",
        section: "Improvements 🔔",
        items: [
          "  ✅ Session Viewer -> Can now easily see who has logged in and their last activity time",
        ]
      },
        {
          version: "V1.0.1",
          section: "New ⭐️",
          items: [
            "  ✅ FAQ Link to Enterprise Technologies PSRS Wiki",
            "  ✅ Changed FAQ Link on side navigation to open new PSRS Wiki in New tab",
            "  ✅ We now have on-line documentation for various processes within the Resource Scheduler",
          ]
        },
        {
          version: "",
          section: "Fixes 🛠",
          items: [
            "  ✅ Added new Approval Fine Print to Shift Drawer and Grids",
            "  ✅ Pay Period -> Fixed bug with cache refresh and fixed data in PayPeriods table",
            "  ✅ Shift Drawer -> Ranme Sheduled start/end to start time and end time",
            "  ✅ Employees -> Fixed refresh when on employee grid.  Note still takes a few seconds to update due to background data fetching",
            "  ✅ Slient Invite -> Fixed update to employee when record exist in Authentication module but Employee record is out of sync",
            "  ✅ Unpaid Lunch  -> Carries over to schedule properly checked on the Team Members section when createing schedules",
            "  ✅ FTO Pay Type 262 -> Removed filter to exclude FTO/Dive from Pay Types even if not FTO or Dive",
            "  ✅ Fixed New Shift hanging -> Check for proper data before allowing to continue.  This will stop the wait/hang but could supress Notficaiton",
            "  ✅ Fixed Leave/Sick Select -> Returns proper pay type now and ensure it's saved to database",
          ]
        },
        {
          version: "",
          section: "Improvements 🔔",
          items: [
            "  ✅ Reports -> Selection now clears properly previous reports when selecting different reports and daily view",
            "  ✅ Reports -> Moved checkbox to include employees not in a team",
            "  ✅ Leave/Sick Pay Types -> Leave and Sick pay types are now searchable",
          ]
        },
        {
          version: "",
          section: "",
          items: [
            "NOTICE:  This is the Release V1.0 Candidate",
            "NOTICE:  Icons made by https://www.flaticon.com/authors/eucalyp, Eucalyp from https://www.flaticon.com/",
          ]
        },
        {
          version: "V1.0.0",
          section: "New ⭐️",
          items: [
            "  ✅ FAQ Link to Enterprise Technologies PSRS Wiki",
            "  ✅ Changed FAQ Link on side navigation to open new PSRS Wiki in New tab",
            "  ✅ We now have on-line documentation for various processes within the Resource Scheduler",
          ]
        },
        {
          version: "",
          section: "Fixes 🛠",
          items: [
            "  ✅ Added new Approval Fine Print to Shift Drawer and Grids",
            "  ✅ Pay Period -> Fixed bug with cache refresh and fixed data in PayPeriods table",
            "  ✅ Shift Drawer -> Ranme Sheduled start/end to start time and end time",
            "  ✅ Employees -> Fixed refresh when on employee grid.  Note still takes a few seconds to update due to background data fetching",
            "  ✅ Slient Invite -> Fixed update to employee when record exist in Authentication module but Employee record is out of sync",
            "  ✅ Unpaid Lunch  -> Carries over to schedule properly checked on the Team Members section when createing schedules",
          ]
        },
        {
          version: "",
          section: "Improvements 🔔",
          items: [
            "  ✅ Reports -> Selection now clears properly previous reports when selecting different reports and daily view",
            "  ✅ Reports -> Moved checkbox to include employees not in a team",
            "  ✅ Leave/Sick Pay Types -> Leave and Sick pay types are now searchable",
          ]
        },
        {
          version: "",
          section: "",
          items: [
            "NOTICE:  This is the Release V1.0 Candidate",
            "NOTICE:  Icons made by https://www.flaticon.com/authors/eucalyp, Eucalyp from https://www.flaticon.com/",
          ]
        },
        {
          version: "V0.99.v.7",
          items: [
            "v0.99.v.6 with the following",
            "  ✅ Changed FAQ Link on side navigation to open new PSRS Wiki in New tab",
            "  ✅ Added new Approval Fine Print to Shift Drawer and Grids",
            "  ✅ Reports -> Selection now clears properly previous reports when selecting different reports and daily view",
            "  ✅ Reports -> Moved checkbox to include employees not in a team",
            "  ✅ Pay Period -> Fixed bug with cache refresh and fixed data in PayPeriods table",
            "  ✅ Shift Drawer -> Ranme Sheduled start/end to start time and end time",
            "  ✅ Leave/Sick Pay Types -> Added the ability to search for PTO/Sick Paytypes in Shift Drawer",
            "NOTICE:  This is the Release V1.0 Candidate",
            "NOTICE:  Icons made by https://www.flaticon.com/authors/eucalyp, Eucalyp from https://www.flaticon.com/",
          ]
        },
        {
          version: "V0.99.v.6",
          items: [
            "v0.99.v.5 with the following",
            "✅ Session Idle Timeout -> Re-enabled session idle timeouts for all users.  Prod defualts to 1 hour, QA is set for 10 minutes",
            "✅ Session Idle Timeout -> When session is about to expire, a pop-up lets the user know gives them 45 seconds to continue or logout.  If the user is away or doesn't make a choice they will be logged out after 45 seconds",
            "✅ Reports -> Reports cleanup and querying fix",
            "✅ Reports -> Daily Board now has a checkbox for including/excluding employees with no team(s).  Include by defualt",
            "✅ Shift Drawer -> Fix the Shift Drawer Time Entry totals to include shift duration + Dive Hours + FTO Hours to things are in-balance",
            "✅ Records Viewer -> Timezone adjustments to date selectors in the Records Viewer specifically for single date selection.",
          ]
        },
        {
          version: "V0.99.v.5",
          items: [
            "v0.99.v.4 with the following",
            "✅ Reports -> allow multiple command (watch commanders) and supervisors",
            "✅ Timezone adjustments to date selectors in the Records Viewer.  For Time Entries we now use the Shift start/end vs what's on the time entry",
            "✅ Made Pay Type Selector seach both Pay Type Codes and Descriptions or Genric name overrides",
            "✅ Dropdown/selects are now sorted alpha - Groups, Business Units, SubLedger and Acting Role",
            "✅ Added Acting role to grid columns for Time Entries and Shifts in Records Viewer, Shift and Time Entries.  Also added icon status indicator (bubble) in Records Viewer and Shifts",
            "✅ Bug fix for Groups not carrying over when creating new teams.  Note, this may still be an issue with the asynchronous processing.  We are keeping this as an open bug and will continue to refine.  Work around is to just add the team again if it doesn't carry over",
            "✅ Fixed issue where global cache was not properly updatind in PRODUCTION so when pay period changed it wasn't being picked up.  This fixes all places where pay periods are selected",
          ]
        },
        {
          version: "V0.99.v.4",
          items: [
            "v0.99.v.3 with the following",
            "✅ Acting Role update:  add jobCode to assignments table for dynamic mapping when assignming roles",
            "✅ Assignment Admin Form:  add jobCode, jobStepCode and jobStepTimecardOverrride as editable fields",
            "✅ Assignment table works as a lookup/mapping when assigning an Acting role so E1 get proper values",
          ]
        },
        {
          version: "V0.99.v.3",
          items: [
            "✅ Fixed Records Viewer filters for Shift and Time Entries",
            "✅ Records Viewer saves filter details and re-applies if you leave and come back",
            "✅ Records Viewer clear all filters button - Note clears all filters for Shifts and Time Entries",
            "✅ Group Selector search fixed",
            "✅ Tritoggle cleanup some were not working.",
            "✅ Treitoggle update to handle clear all filters and re-applying if user leaves and returns to record viewer",
            "✅ Shift Drawer allows all users to access Groups and Assignment now",
            "✅ New Shift properly handles Time Entry fields when assignment is overriden",
            "✅ New OT type shifts will now properly generate a Notification for Approval to Supervisor",
            "✅ Misc bug cleanup found during testing"
          ]
        },
        {
          version: "V0.99.v.2",
          items: [
            "✅ Fixed Records Viewer Time Entry Tag Filter Logic from FTS to join to tags",
            "✅ Fixed Filtering for pay types",
            "✅ Fixed backend Full Text search shaping and updates",
          ]
        },
        {
          version: "V0.99.v.1",
          items: [
            "---- This was a cleanup update ----",
            "✅ Fixed some filtering on Approvals to not include 'unaasigned' shfits",
            "✅ Added a badge count for Approvals - dynamic for Time Entries (default) and Shifts",
            "✅ Added 'pill' for Sick / PTO back in and also added to regular pay type selector",
            "✅ Updated New Shift for 'Court' to be pay type 261",
            "✅ Fixed hours for OT shift when extending shift in Shift drawer",
            "✅ Fixed Approvals form to show payperiod navigator",
            "✅ Removed Jury Duty from New Shift Button",
            "✅ Teams - removed inline edit to Group field(s)",
            "✅ Added visual for selected dates for new OT Shift in Teams",
            "✅ Cleaned up Sick Leave and Time Off drop downs in Shift Drawer",
            "✅ Removed Employee Select and Supervisor Select in Modify Tab for Shifts and Time Entries",
            "✅ When logging in, the system takes you to “My shifts”, the pay period on this screen is not the current pay period. Have added 10 minute inactity timeout to force re-login",
            "✅ OT shifts, we had previously discussed to remove the “lunch” option when creating an OT shift, the default should be no lunch.",
            "✅ Time Zone fixed",
            "✅ All drop down menus, at least those listing employees, should be displayed by Alpha.  There is currently no logic to these drop downs.",
            "✅ We don’t want Line-Level members to have access to the report feature.",
            "✅ Timeline for Admin/Supervisors only",
            "✅ On the shift drawer (and other areas in the system), we need the Business Unit to go first then the Sub Ledger.",
            "✅ When creating an OT shift and overriding the BU and then clicking the “create OT shift” button, we see that the time entry created at the bottom defaults to the employee’s home business unit.  The system should transfer the information from the BU Override field to the time entry field.",
            "---- REPORTS ----",
            "✅ Added Business Unit Filter and logic to pass to backend",
            "   -- This is only a filter and does not group by business unit",
            "   -- Grouping by Business Unit (or override Business Units would require a new report",
          ]
        },
        {
          version: "V0.99.v",
          items: [
            "Nofification Handler updates ",
            "Various bug fixes to logic",
            "Various Front-end client fixes",
            "  -> Shift Drawer Leave Balances updates - Pay Types and show employee leave balances",
            "  -> Shift Drawer Sick Balances update - Pay Types and show employee leave balances",
            "  -> New shift Drawer fixes to make it more consistent",
            "  -> New Shift Drawer fixes to Evidence only shifts",
            "  -> Records Viewer Udpates",
            "    --> Fixed date selection to handle UTC",
            "    --> Fixed current PP to update date selection",
            "    --> Fixed current PP to handle UTC",
            "    --> Added icons on Filter and Modify tab to visually see each tab",
            "    --> Modify Tab now yellow background to know you are editing",
            "    --> Added text in Modify tab to indicate Editing Records",
            "    --> Fixed counts to show proper total to handle Paid Lunch and UTC",
            "    --> Fixed Selection / check boxes on Time Entries and Shfits so only check will be updates if modified/deleted etc",
            "    --> Added a select add/unselect all to header for Shifts and Time Entries",
            "    --> Added filter to Shifts for Unassigned",
            "    --> Reviewed and fixed general filters",
            "        ---> Needs Approved/Dnst Need Approved - this for a field called needsApproved", 
            "        ---> Approved just filters on approved field",
            "        ---> Rejected just filters on rejected field",
            "        ---> Processed just filters on processed field",
            "        ---> Un editd just filters on create vs edit dates field",
            "    --> New Shift button matches multi-select for Shifts view",
            "    --> [Add Shift] button now works the same as on Shifts and Time Entries",
            "    -->  Added a manual [Refresh] button to Power Viewer since closing shift drawer is not always keeping state",
            "  -> Added indicator and hover text for ENVIRONMENT",
            "    --> Local environment color : lightseagreen",
            "    --> Development DV environment color:  blue",
            "    --> QA environment color: darkolivegreen",
            "    --> Production PD environment color: black",
            "  -> Fixed breadcrumb link for Employees for non-supervisor/Admin so they can not navigate to all employees",
            "  -> Update FAQ to PSRS Quick Reference Document v0.99.u r6",
          ]
        },
        {
          version: "V0.99.u",
          items: [
            "Shift Drawer fixes - undo logic, save logic, send nofications on save button press",
            "New shift Drawer fixes to make it more consistent"
          ]
        },
        {
          version: "V0.99.t",
          items: [
            "✅ TR244 - Records Viewer - Don't clear filters when shift drawer is closed. Should refresh the filter results after the shift drawer is closed",
            "✅ PD01 - Turn on employee sync",
            "✅ PD02 - Procedure for Gricel to silent invite new users at oceansideca.org, to help adding silent invite to the Employees grid view",
            "✅ PD03 - Teams: Groups no longer can be created 'endline' and are manged in Admin Group editor",
            "✅ PD04 - Fix browser client cache refresh so it has updates.  For example employee in the Employee admin list but not in the drop down list in Teams, cache issue.",
            "✅ PD06 - Employees: Chief's record crashes app on entry.  Needed to add City Manger, Mayer and a 0 record for the city",
            "✅ PD08 - For Officers, they should NOT be able to approve shifts.  Currently they can.  Shift drawer works correctly",
            "✅ PD09 - Shift Drawer - Taking leave with an unpaid lunch, filter out the the unpaid lunch time entry line, but preserve the shift + unpaid lunch total",
            "✅ PD11 - when requesting leave, show all leave types in the dropdown except fire and family Medical.  I looks like now only non-zero leave shows",
            "✅ PD12 - PD12 - Do not allow Employee & Supervisors to change Roles",
          ]
        },
        {
          version: "V0.99.s",
          items: [
            "✅ TR241 - Unpaid Lunch logic refine.  Now adds 0.5 hrs when checked when generating shifts.  Shows Lunch in Shift Drawer",
            "✅ TR242 - Officer Submitted Time allowed for future.",
            "✅ TR243 - Charge out Business Unit - On Update not updated in Time Entries and JDE",
            "✅ TR245 - Change default sort on Shifts, Time Entries and Approvals.",
            "✅ TR246 - Leave Balance Buckets are not correct in employee record.",
            "✅ TR247 - Sub Ledger entry not saving change to Sub Ledger Code",
            "✅ TR230 - Create a Destroy logic to capture Delete of time entries in JDE in to Azure Storage Queue",
            "✅ TR232 - Updates:  Fix Destroy token logic so that mass  update on Bulk  Process to JDE.",
            "✅ TR45 - expose badge number (already in database) to Today report.",
          ],
        },
        // {
        //   version: "V0.99.r",
        //   items: [
        //     "⬜️ TR5 - Add error for non unique team name.  Right now it just reverts back to the old name with out feedback"
        //     "⬜️ TR7 - Timeline view schedule : getting second letter of first name and last name, doesn't currently show correctly with first initial and las name",
        //     "⬜️ TR10 - Timeline group, supervisor and pay period selector /  filters are not working.",
        //     "⬜️ TR11 - Timeline viewport for Week, pay period, 28 days",
        //     "⬜️ TR12 - Timeline have a date filter to jump to a specific day",
        //   ],
        // },
        {
          version: "V0.99.q",
          items: [
            "⬜️ TR32 - Records viewer Filter by Group does not work.",
            "🅾️ TR37 - Verify Silent Invite also merges existing users email with new account",
            "✅ TR83 - Collision - Indicate visually for the supervisor that awarding the shift bid will cause collision.",
            "✅ TR83 - Collision - Check for collision on update of shift start or end",
            "✅ TR84 - Awarding bid shift doubles time entries for the new shift.",
            "✅ TR85 - Adding a time entry to split or changing pay type does not commit change to database.",
            "⬜️ TR86 - User should not be able to submit time for a shift in the future.  only can be submitted after 30min before end of shift.",
            "⬜️ TR89 - Daily Report Changes",
            "✅ TR90 - Add short shift indicator to data for shifts, so that we can report on it. Short Shift Early or Late",
            "🅱️ TR92 - Bulk delete - Can an admin override the shifts delete warnings and delete shifts from the past?? (TR105)",
            "✅ TR93 - Employee Data Sync: Include the JDE specialties as everywhere in PSRS specialties are displayed",
            "✅ TR94 - Shift Generation / Overtime - When I create an Overtime shift (say for 9/25), It gets created today (9/22) instead",
            "✅ TR94 - Team View - Changing the supervisor kills the View functionality",
            "✅ TR95 - Teams - Changing supervisor in a Team causes Add Subordinates to disappear and add Members to fail.",
            "✅ TR95 - Override BU does not get populated to JDE Time entry",
            "✅ TR96 - Bulk Delete failed",
            "🅰️ TR98 - All overtime entries created showed in collision and they should not be. I think time entries did not get created.",
            "🅰️ TR99 - Collision logic seems heavy on processing time.",
            "🅰️ TR100 - Application slowed down and hung. Time for micro services?",
            "🅾️ TR101 - Notification is not flowing. Email and SMS",
            "🅾️ TR103 - Configurations file with environment variables for notifications, environments, database connectors, etc.",
            "✅ TR104 - Business Units - Cannot check include checkbox.",
            "🅱️ TR105 - Bulk Delete - Add option to delete past shifts (TR92)",
            "✅ TR106 - Cannot awards bidder shift to officer while in collision.",
            "✅ TR108 - Schedules Validation only require 2 supervisors.",
            "✅ Employees View Layout Fix",
            // "Employee Edit: Error feedback for non unique email & phone numbers",
          ],
        },
        {
          version: "V0.99.p",
          items: [
            "TR32 : Records Viewer: Filter By Group Working (needs some tuning may turn into a search field)",
            "TR83 : Available Shift Bids: Identify Bidders which would cause a collision",
            "TR34 : Search Working for the following views ...",
            "Employees, Assignments, BusinessUnits, Equipment, Groups, SubLedgers, Specialties, PayTypes, Teams",
            "Fixed Loading of All Teams",
          ],
        },
        {
          version: "V0.99.O",
          items: [
            "Updated Hostname logic with DV app service URL",
            "✅ TR80 : (almost there) Collision Logic updated to 17 hours from start time for a rolling 24 hour period.",
            "✅ TR81 : Bulk Shift Schedule: Proper assignment of payPeriodId",
            "✅ TR85 : Adding a time entry to split or changing pay type saves to database.",
            "✅ TR87 : Shift Drawer: Clock Out 6 minute increments",
            "✅ TR88 : Shift Drawer: Change to minute increment updates duration",
            "✅ TR90 : Added Short Shift fields to employees table",
            "✅ Shift Drawer Equipment Selector working",
            "✅ Assignments View: Changed back to Assignments, added column for type (acting, schedule assignment...)",
            "✅ Created Location Model to handle Sector assignments on shifts",
            "✅ Records Viewer TriToggles ... update label to better exaplain the filter",
          ],
        },
        {
          version: "V0.99.n",
          items: [
            "V1 of bulk delete working ✅",
            "Records Viewer: update to Unedited records filter ✅",
            "Business Units View checkbox issue fix ✅",
            "Team Members: Assignment Select removed ✅",
            "Records Viewer: Bulk Delete Modal ... Deleting feedback added ✅",
            "Schedule Template Roster Slots: Short Shift Placeholders restored. ✅",
            // "Employee Edit: Error feedback for non unique email & phone numbers",
          ],
        },
        {
          version: "V0.99.m",
          items: [
            "Removed callSign from Employee model",
            "Added badgeNumber column to Employee model",
            "Employee / Settings View ... Added editable field for badgeNumber",
            "-----------------------------------",
            "OPERATIONAL ASSIGNMENT (* OA) ...",
            "Models (Shifts, Members & Slots) ... Added OA columns",
            "Team View ... Added editable OA Field to Members, Created Template Slots inherit Team Member OAs",
            "Template View ... Added editable OA Field to Slots, Scheduled Shifts inherit from Template Slot OAs",
            "Records Viewer ... Added OA column, Added OA field to Bulk Update",
            "Shifts View ... Added OA column to table",
            "Shift Drawer ... Added OA field up top after Supervisor Selector",
            "Template Bulk Shift creation updated to create non Paid Lunch timeEntry",
            "-----------------------------------",
            "Shift Imbalance (* SI)",
            "Models (Shifts) ... Added imbalanced & timeTotal columns",
            "Created method in TimeEntry hooks to update Shift timeTotal & check for balance",
            "Existing Shifts ... Added migration to update imbalanced & timeTotal values",
            "Shifts View ... Added column for new timeTotal value, Added SI Icon to Status column",
            "Records Viewer ... Added SI Icon to Status column, Added SI tri-toggle to shift filters",
            "Shift Drawer ... Added imbalance message to Shift Drawer TimeEntries, Leave & Sick Entry tables",
            "Shift Drawer ... Calculating of timeEntry time now handled by the API",
            "-----------------------------------",
            "Created Migration to delete test Employees & associated records (1111, 2222, 3333)",
          ],
        },
        {
          version: "V0.99.l",
          items: [
            "Adjusted Employee settings to reflect Oracle Phone & Email",
            "Added disabled conditions for actions on empty Employee contact fields",
            "Now using Cookie for Session Store on API",
            "Support Data is now comming from REDIS cache to speed up loading",
            "Fine Print added",
            "Reduced Re-Renders caused by Shift Drawer",
          ],
        },
        {
          version: "V0.99k",
          items: [
            "TR23 : Shift Drawer ... Enable Add Time, add fields to time entries ✅",
            "BF71 : Fine print from Submit and approve. 🥊 (I'M SICK OF SEEING THIS DANGLING ... I NEED FINE PRINT) ✅",
            "TR?? : Allow Employees to Edit Time Entry fields in shift drawer ✅",
            "TR?? : Allow Employee to create Shifts",
            "TR?? : On Employee generated shifts, send approval notification to supervisor of shift",
            "TR?? : Wire Up Employee Settings",
            "TR?? : Make Reason Required for Shifts with unscheduled OT",
            "TR24 : In Approval, when closing the shift drawer, update the time entries to approve grid.",
            "TR?? : Records Viewer: Roll Single Day select into Range Selector",
          ],
        },
        {
          version: "V0.99j",
          items: [
            "TR01 : When paid lunch is off need to add 30 minutes to shift unpaid. ✅",
            "BF52 : Implement Collision Logic on Shift scheduling. ✅",
            "TR05 : !!Kick out change when error ... Add ui for non unique team name error ✅",
            "TR18 : Teams|Schedule – populate the team name into the default schedule name <teamname> Reg Schedule1 ✅",
            "TR21 : Provide collision filter in records viewer. ✅",
            "TR22 : Template: Date Selector updates with Sync All (spinoff from original TR22) ✅",
            "TR29 : Remove Employees data from Local Storage if user has a role of employee. ✅",
            "TR30 : Move Records Viewer Settings into Session Storage. ✅",
            "TR21 : Records Viewer: Create UI to filter by a single day ✅",
            "TR?? : Records Viewer: Add filter for unedited Shifts & Time Entries ✅",
          ],
        },
        {
          version: "V0.99i",
          items: [
            "BF63 : From shifts—> View—> select sick. Drop down doesn’t select leave type ✅",
            "TR04 : Provide link/button to refresh cache ... (click the logo in the upper left corner of the screen) ✅",
            "TR06 : Supervisor delete own teams, Admin can delete ANY team. ✅",
            "TR08 : Shift drawer allows supervisor or admin change anything for a subordinates shift. ✅",
            "TR09 : Review all default sorting for all views. should by name or date ✅",
            "TR13 : Shift drawer add lunch / paid or not paid to show paid/unpaid etc... ✅",
            "TR22 : Template: Date Selector Displays the selected dates ✅",
            "TR26 : Leave request does not show up in supervisors Approval Queue. ✅",
            "TR28 : Shift drawer - Overtime house needs for 30 min shows 0 hours. Need to fix UI for rounding to hours. ✅",
          ],
        },
        {
          version: "V0.99h",
          items: [
            "Schedule Template: Date Selectors now open to scheduled dates by default ✅",
            "Employee Edit: Change an Employees Role ✅",
            "Team Edit: Name field is not limited to 20 characters ✅",
            "Logging out does not clear the cache ✅",
            "Admins can delete any shift they want ✅",
            "Shift Drawer: Supervisor or Admin can now edit all fields ✅",
            "Supervisors can now delete their own shifts ✅",
          ],
        },
        {
          version: "V0.99g",
          items: ["Fix for Shift submition permission ✅", "Fixed editing shifts for Supervisors ✅"],
        },
        {
          version: "V0.99f",
          items: [
            "Fix for Team Member Employee Select ✅",
            "Fix for Group Selector ... creating and updating Groups ✅",
            "Shift Drawer: Leave Balance Selector Wired ✅",
          ],
        },
        {
          version: "V0.99e",
          items: [
            "Records Viewer: Reordered status toggles ✅",
            "Shift Drawer: Fixed permissions so supervisor can see time entries ✅",
            "Template View: Fixed Pay Period End select to show end of pay period ✅",
            "Template View: Moved Sync All button to breadcrumb header ✅",
            "Employees View: Removed multi select & hid json debugging pannel ✅",
            "Employee Edit View: Updates ✅",
          ],
        },
        {
          version: "V0.99d",
          items: [
            "Users can now submit shifts from the Shifts index status icon ✅",
            "Employees: Add Leave Balances ✅",
            "Employee: Submit Time from Shifts Grid.. ✅",
            "Employee: Fixed missing supervisor value ✅",
            "Records Viewer - grid needs Approved/Rejected/Supbmitted/Processed Indicators ✅",
            "Approvals: Supervisor Time Entry Drawer: Supervisor needs approve button to approve team's time. ✅",
            "Shift Drawer - Fix Reasons Selector width ✅",
            "Timeline: Fix Open Drawer ✅",
            "Added a feature to clear localStorage if version gets changed ✅",
            "Records Views - Fix Supervisor filter ✅",
            "Records Viewer - Modify approval does not make changes. ✅",
            "Records View - Bulk submit. ✅",
            "Did a little load optimization on the admin views. Nothing major ✅",
            "Timeline: Week to fit in view. ✅",
          ],
        },
        {
          version: "V0.99c",
          items: [
            "Approvals View - Shift Approvals now Loading ✅",
            "Loading Indicators added throughout app ✅",
            "Assignments View - Fixed Creating Records ✅",
            "Shift Drawer - Hitting Escape now closes the drawer ✅",
            "Shift Drawer - Improved the add timeEntry action ✅",
            "Schedule Timeline - functional-ish ✅",
            "Record Viewer - Closing the shift drawer now updates table data ✅",
            "Records Viewer - Added active state to the Current Pay Period & All Year buttons ✅",
            "Records Viewer - Fixed View title bug ✅",
            "Records Viewer - Tuned up TimeEntry column order ✅",
            "Template View - Added Sync All button ✅",
            "Time Entries Views - Updated and fixed View Button ✅",
            "(in progress) Print Schedule is functional ✅",
            "(in progress) Settings View is functional ✅",
          ],
        },
        {
          version: "V0.99b",
          items: [
            "Creating a new Team redirects the user to the new Team view ✅",
            "Improved login redirect page ✅",
            "Shift Drawer, showing delete button to if current user is a supervisor on shift ✅",
            "Deleting a shift in Shift Drawer updates the Shifts Table ✅",
            "Added delete buttons to Shift Drawer timeEntry rows ✅",
            "Fixed Team & Template Group Select values ✅",
          ],
        },
        {
          version: "V0.99a",
          items: [
            "Re-arranging the Team View ✅",
            "Approval View Row buttons working ✅",
            "Records Viewer Employee & Supervisor Filters improved ✅",
            "Records Viewer Current Pay Period button fixed ✅",
          ],
        },
        {
          version: "V0.98z",
          items: [
            "Fix extra time entries added to bid shift ✅",
            "Added Subordinates Button in Schedule Template View ✅",
            "Approvals Views are now functional ✅",
            "Fixed the Create Shift Drawer ✅",
            "Added a My Time Entries button for supervisors Time Entry View ✅",
            "Removed Unapproved buttons from Shift and Time Entry views ✅",
            "Creating Schedules from Team View now show up without refresh ✅",
            "Selecting an employee from the bids list populates the employee field ✅",
            "Clearing out the employee field brings back the list of bids ✅",
            "Added link from generated (unassigned) backfill shift back to original shift ✅",
            "Added case filter and column to Records Viewer ✅",
            "Proper redirection after login restored ✅",
            "Navigation to Schedule Templates restored ✅",
            "Closing a tab or browser now requires users to log back in ✅",
          ],
        },
        {
          version: "V0.98y",
          items: [
            "Refactored from indexedDB to localStorage ✅",
            "Streamlined the Auth0 activity ✅",
            "Fixed some memory leaks ✅",
            "And plenty more ✅",
          ],
        },
        {
          version: "V0.98w",
          items: [
            "Employee Settings View created and wired up to employee number in employees view ✅",
            "Side Nav: added settings icon back in ✅",
            "Group Selector: Updated to use polymorphic associations ✅",
            "BF25 - Overtime - Users woulds like to add a case number field to the Overtime Reason. ✅",
            "BF24 - Overtime Reasons Notes - Users would like to add freeform notes to the Overtime Reasons ✅",
            "Pickup Shift button removed from row actions on regular Shift views ✅",
            "Shift Drawer, added button for supervisor to assign eployee to shift ✅",
            "Select Components were causing problems. Replaced and refactored with new library ✅",
            "Saving app state to Local Storage for faster loading ✅",
            "Fixed all the model associations ✅",
            "Fixed My Teams Search ✅",
            "Fiexd stuck loading state when revisiting views ✅",
            "Created JSONB searchable column as a step towards proper search index creation ✅",
            "Fixed all the bugs I could find with syncing slots in Schedule template view ✅",
            "Created new Search inputs that take advantage of the server side text search ✅",
            "Created script to auto assign Dive, Moto, Swat, FTO specialties to those who qualify ✅",
      
            // "BF17 * RECORDS VIEWER DATES: NOT DONE",
      
            // "BF26 - Subordinate Time Entries Search by name does not work.",
            // "BF27 - Start and end time sent to JDE is incorrect.  Date and time worked is correct.",
            // "BF10 - Pay Types:  Disply needs to be updated when a change is made.  It appears like the change is not committed, but after refresh it shows that it has been updated. currently flashes on and off.",
            // "BF08a - GROUPS VIEW CREATE: NOT DONE",
            // "BF15 * PRINT SCHEDULE: Too broken to fix in time",
            // "BF18 - Add task to ensure all Shifts have proper Time Entries (Integrity)",
            // "BF19 - Cannot Login to app - Need to clear local database in Chrome so that Auth 0 will callback application.  Maybe not logging off / timing out?",
            // "BF22 - Records Viewer does not re-inquire after update",
            //
      
            // "Admins and Supervisors can set shifts to available",
            // "Auto generated blank sick shift is not available by default"
            // "SHIFT DRAWER: User can no longer delete last solitary time entry in Shift Drawer",
            // "SHIFT DRAWER: Added Title that reads Shift Details",
            // "Approvals View: UI working and updating",
            //
            // "RECORDS VIEWER: Stubbed in a disabled button for setting range to current year",
            // "SUPERVISED VIEWS: Worked back through appropriate presentation of Approve and Reject Buttons",
          ],
        },
        {
          version: "V0.98v",
          items: [
            "Search: Converted code and employeeNumber fields to strings and included in search index ✅",
            "Search: (aware of bug) Records with null fields aren't gettinig indexed for search ✅",
            "BF14 - REQUESTS & APPROVALS: Shift Drawer no longer throwing error - but still fixing the new issue with saving fields ✅",
            "BF11 - Schedule: I've got the fields in for supervisors. But still need to sort out the layout ✅",
            "BF03 - Wired up Bid buttons (in row and in shift drawer) and updated tool tip text from pick up shift to bid on shift ✅",
            "BF03 - Wired up Bid list visiblity for supervisors in shift drawer ✅",
            "Created Script to split Employee fullName to first & last ✅",
          ],
        },
        {
          version: "V0.98u",
          items: [
            "Team View: Improved new schedlue buttons and default names ✅",
            "Team View: Added create ot/reg schedule buttons to Team Members ✅",
            "Schedule: Can now alter all 3 Supervisors. Fixed scrolling to the bottom ✅",
            "Schedule: Added Group Selector to Reg & OT views ✅",
            "Improved Records View loading ✅",
            "Fixed Powerview loading Error ✅",
            "Added columns caseNumber & bids to shifts table ✅",
            "Shift Drawer: Roughed in Bid list and Bid Button ✅",
          ],
        },
        {
          version: "V0.98t",
          items: [
            "BF02 * Turn on Notifications. DONE - ROUGH but done ✅",
            "BF12 * SLOT DATA SAVING: DONE ✅",
            "BF16 * ALL TEAMS: DONE ✅",
            "BF01 - CONTACT ROSTER: NOT NECESSARY ✅",
          ],
        },
        {
          version: "V0.98s",
          items: [
            "SUBLEDGERS ADMIN FIX",
            "SEARCH: Offloaded search functionality to Postgres. Currently only handling strings. Soon to be updated ✅",
            "UI FEEDBACK: The Index views now give better feedback when searching and loading data ✅",
            "ADMIN VIEWS: All Admin views got tuned up and should be working better than ever. ✅",
            "TEAMS INDEX: Creating, Deleting is good. ✅",
            "TEAMS INDEX: Search is currently disabled while I find out how to search a Query Set ✅",
            "TEAMS INDEX: Added a button to toggle between the users teams and all teams ✅",
          ],
        },
        {
          version: "V0.98r",
          items: [
            "DB: Implemented a script to solve for the bug in QA where Admins are loading as regular employees ✅",
            "DB: toggling a time entry records processed value changes the Trans Type from L to A ✅",
            "DB: Tuned up the script that formats the job titles so it handles assigning default roles ✅",
            "RECORDS VIEWER: Stubbed in a disabled button for setting range to current pay period ✅",
            "RECORDS VIEWER: Fields in the Modify pane no longer triggering filter queries ✅",
            "RECORDS VIEWER: !! Shift Drawer error from Time Entry Row click (ABOUT TO FIX) ✅",
            "SUPERVISED VIEWS: Tuned up some of the columns ✅",
            "SUPERVISED VIEWS: There's way too many line items to list ✅",
            "SHIFTS & TIME ENTRY VIEWS: employees and admins default to MY Views. ✅",
            "SHIFTS & TIME ENTRY VIEWS: supervisors default to Subordinates / Supervised Views ✅",
            "SHIFTS & TIME ENTRY VIEWS: supervisors Can switch into their MY Views from inside their default Views ✅",
            "SHIFTS VIEW: Added Available Shifts as nav item within My Shifts and Supervised Shifts views ✅",
            "AVAILABLE SHIFTS: Non supervisor / admin users can't alter fields in Shift Drawer for available shifts ✅",
            "AVAILABLE SHIFTS: Action Buttons for Sick, PTO, Submit no longer showing in the rows and shift drawer ✅",
            "EMPLOYEES: Roughed in a column for assigning a role to an record ✅",
            "ROUTING: The routes and pathnames have been altered to extend the active navigation styling ✅",
            "MY APPROVALS: Tuned up columns ✅",
            "MY APPROVALS: Disabled Approve and Reject buttons so users can not approve or reject their own entries ✅",
            "MY APPROVALS: Killed the over abundance of rows leading to unnecessary vertical scrolling ✅",
            "UX: Quick Color System for Shifts and Time Entries to reduce confusion ✅",
            "UX: Super roughed page intro UI (it's mocked into the Side Nav as a traffic light ) ✅",
            "VERSION: Changed Version log from Alert to Modal (as it was truncating the text) ✅",
            "LOGIN: Role specific default home views ✅",
          ],
        },
        {
          version: "V0.98q",
          items: [
            "Sub-Supervisors directed to Shifts view after login ✅",
            "Sub-Supervisors only see minimum UI and nav (work in progress) ✅",
            "Print Schedule hidden in Side Navigation for now ✅",
            "Fixed Team Schedule Names in list of links ✅",
            "Improvements to Role based UI / UX ✅",
            "Added a quick badge to the Profile nav item to make role visible ✅",
          ],
        },
        {
          version: "V0.98p",
          items: ["Added this fancy double click feature ✅", "Logging out if Auth0 JWT is expired ✅"],
        },
        {
          version: "V0.98o",
          items: ["Fixed Admin View loading bugs ✅"],
        },
      ]
      

    }



