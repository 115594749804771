import React, { useState } from "react"
import Select from "react-select"
import {SpecialtySelectOptions} from "./_SelectOptions"
import _ from "lodash"

export const SpecialtySelect = props => {
  const field = props.field || "specialtyId"
  const { record } = props
  const id = record ? record.id : props.id
  const value = record ? record[field] : props.value

  const [state, setState] = useState({
    options: SpecialtySelectOptions,
    value: value ? _.find(SpecialtySelectOptions, {value: value}) : null,
    sql: null,
  })

  const selectProps = {
    className: "psrs-select",
    classNamePrefix: "psrs",
    placeholder: "Select a Specialty",
    isMulti: false,
    options: state.options,
    defaultValue: state.value,
    onChange: select => {
      const val = select && select.value ? select.value : null
      const sql = val ? `"${field}" IS NOT NULL AND "${field}" = '${val}'` : null
      setState({ ...state, sql })
      props.handleChange({ ...props, value: val, field, id, sql })
      return
    },
  }

  return <Select {...selectProps} />
}

export default SpecialtySelect
