import React from "react"
import { Button, notification} from "antd"
import uuid from "uuid"
import { REQ, store } from "../../store/psrs"

export  const BuildMessage = (props) => {

    const {fields} = props

    return fields.map( key => (
      <React.Fragment key={uuid()}>
        <li><b>{key.field}</b></li>
      </React.Fragment>
    ))
    
}

 export const ImbalancedMessage = (Shift) => {
    const key = `open${Date.now()}`
    const btn = (
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Confirm
        </Button>
    )

    notification.open({
        message: `The Shift duration and total hours are not in balance`, 
        description: `Please fix the Time Entries so the total matches the Shift duration for shift`,
        placement: "topRight",
        btn,
        key,     
    })
}

export const RequiredFieldMessage = ( Shift, fields, message=null ) => {
    const key = `open${Date.now()}`
    const btn = (
        <Button type="primary" size="small" onClick={() => notification.close(key)}>
        Confirm
        </Button>
    )

    const defaultMessage = <p>The following fields are required<br /> for shift to be sumitted or approved</p>
    
    //`The following fields are required\n for shift to be sumitted or approved`

    notification.open({
        message: message || defaultMessage, 
        description: <ul><BuildMessage fields={fields}/></ul> ,
        placement: "topRight",
        btn,
        key,
        //duration: 8,
    })
}

export const CheckRequiredfields = async ( Shift ) => {


    // check for required fields before allowing a submit
    let okSubmit = true
    let checkFields = []
    let { timeEntries } = Shift

    if (!Shift.submit) {

        if (!timeEntries) {
            // get time entries here....
           timeEntries = await REQ(`/api/timeEntriesByShiftIds`, 'POST', { ids: [Shift.id] } )
        }
        
        if (Shift.notes === '' || Shift.notes === null ) {
        
            // Notes only required for Sick, OT, Leave for full shift
            //if (Shift.isOT || Shift.isSick || Shift.requestedOff) {
            if (Shift.isOT ) {
                okSubmit = false
                const msgType = 'OVERTIME'
                const field = 'OVERTIME Shift Notes'
                if (!checkFields.filter( el => el.type === msgType).length) {
                    checkFields.push({type: msgType,  field: field})
                }
            } 

            if (Shift.isSick ) {
                okSubmit = false
                const msgType = 'SICK'
                const field = 'SICK Shift Notes'
                if (!checkFields.filter( el => el.type === msgType).length) {
                    checkFields.push({type: msgType,  field: field})
                }
            } 
            
            // Notes required for any shift from new shift button
            if (Shift.name === 'NEW SHIFT BUTTON') {
                okSubmit = false
                if (!checkFields.filter( el => el.type === 'NEW').length) {
                    checkFields.push({type: 'NEW', field: 'New Shift Add Notes'})
                }
            }

           // If Dive Hours
           // if (Shift.diveHours != 0 && !Shift.diveHoursIncluded) {
           // checkFields.push({field: 'Dive Hours Shift Notes'})
           // okSubmit = false
           // }

           // If FTP Hours
           // if (Shift.ftoHours != 0 && !Shift.ftoHoursIncluded){
           // checkFields.push({field: 'FTO Hours Shift Notes'})
           // okSubmit = false
           // }

        } 
        
        if (Shift.isOT && (!Shift.reasonId || Shift.reasonId === '' || Shift.reasonId === null)) {
            checkFields.push({type: 'REASON', field: 'Reason Code Required'})
            okSubmit = false
        }

        timeEntries.forEach((entry) => {
            if (entry.payTypeCode === null) {
                checkFields.push({ type: 'PAYTYPE',field: 'Pay/Leave Type'})
                okSubmit = false
            } else if (Shift.requestedOff && ( entry.payTypeCode ==='1' || entry.payTypeCode === null)) {
                checkFields.push({ type: 'PAYTYPE', field: 'Pay/Leave Type'})
                okSubmit = false
            }

            // if time entries are OT, Leave, Sick, etc... to handle split shifts
            const _catFilters = ['OVERTIME','LEAVE', 'SICK','VACATION' ]
            const _payTypes = store.get("PayTypes")
            const _payType= _payTypes.find(el => el.code === entry.payTypeCode)
            let _category = 'NONE'
          
            if (_payType) _category = _payType.category 
            
            if (Shift.notes === '' || Shift.notes === null ) {
                if (_catFilters.includes(_category)) {
                    if (!Shift.requestedOff) {
                        okSubmit = false
                        const field = `${_category} Shift Notes`
                        if (!checkFields.filter( el => el.type === _category).length) {
                            checkFields.push({type: _category, field: field})
                        }
                    }
                    
                    //if (entry.notes === '' || entry.notes === null ) {
                    //    checkFields.push({field: `${_category} Time Entry Notes`})
                    //    okSubmit = false
                    //} 
                }
            }
        })
    }

    return ({ okSubmit: okSubmit, checkFields: checkFields})
}


export const getTimeEntryShiftNotes = async (shiftId) => {
    const shift = await REQ(`/api/shifts/${shiftId}`,"GET")
    return({ 
      id: shift.id, 
      notes: shift.notes, 
      reason: shift.reason,
      reasonId: shift.reasonId, 
      submit: shift.submit, 
      imbalanced: shift.imbalanced,
      diveHours: shift.diveHours,
      diveHoursIncluded: shift.diveHoursIncluded,
      ftoHours: shift.ftoHours,
      ftoHoursIncluded: shift.ftoHoursIncluded,
      isOT: shift.isOT,
      isSick: shift.isSick,
      requestedOff: shift.requestedOff,
      name: shift.name,

    })
  }

export const checkShiftNotes = async (shiftId) => {
    return await getTimeEntryShiftNotes(shiftId).notes
}



