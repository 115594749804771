
import React, { useState } from "react"
import { Layout } from "antd"
import uuid from "uuid"


import * as G from "grommet"

export const EmployeeLeaveTable = props => {

    const leave = props.leave

    const { Content } = Layout

    const { Accordion, AccordionPanel, Box, TableBody } = G
    const { Table, TableHeader, TableRow, TableCell } = G
    //const altTable = G.Table

    const [active, setActive] = useState([0, 1, 2, 3])
    const TH = { scope: "col", border: "bottom", align: "center", size: "small" }
    const TD = { scope: "row",  align: "center", size: "small", border: "bottom" }

    if (leave) {
        return (
            <Content className="UserSettings">
            <Accordion
                multiple = {false}
                activeIndex={active}
                onActive={a => {
                    setActive(a)
                }}
            >
                <AccordionPanel label="Leave Balances"  >
                    <Box background="white" pad="xxsmall" margin="xxsmall">          
                        <Table className="employeeLeaveTable">
                            <TableHeader key={uuid()}>
                                <TableRow key={uuid()}>
                                <TableCell key={uuid()} {...TH}>Comp Time</TableCell>
                                <TableCell key={uuid()} {...TH}>Executive</TableCell>
                                <TableCell key={uuid()} style={ { display: 'none'}} {...TH}>Fire</TableCell>
                                <TableCell key={uuid()} {...TH}>Sick</TableCell>
                                <TableCell key={uuid()} {...TH}>Vacation</TableCell>
                                <TableCell key={uuid()} {...TH}>Holiday</TableCell>
                                <TableCell key={uuid()} {...TH}>Floating Holiday</TableCell>
                                <TableCell key={uuid()} style={ { display: 'none'}} {...TH}>Family Medical</TableCell>
                                <TableCell key={uuid()} {...TH}>Other</TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody key={uuid()}>
                                <TableRow key={uuid()}>
                                <TableCell key={uuid()} {...TD}>{leave.compTime}</TableCell>
                                <TableCell key={uuid()} {...TD}>{leave.executiveAccruals}</TableCell>
                                <TableCell key={uuid()} style={ { display: 'none'}} {...TD}>{leave.fireLeaveBank}</TableCell>
                                <TableCell key={uuid()} {...TD}>{leave.sickAccruals}</TableCell>
                                <TableCell key={uuid()} {...TD}>{leave.vacationAccruals}</TableCell>
                                <TableCell key={uuid()} {...TD}>{leave.holidayAccruals}</TableCell>
                                <TableCell key={uuid()} {...TD}>{leave.floatingHolidayAccruals}</TableCell>
                                <TableCell key={uuid()} style={ { display: 'none'}} {...TD}>{leave.famMedLeaveAccruals}</TableCell>
                                <TableCell key={uuid()} {...TD}>{leave.otherLeave}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                </Box>
            </AccordionPanel>
            </Accordion>
            </Content> 
        )
    } else {
        return <Table/>
    }
}