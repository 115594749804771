import React, { useState, useEffect } from "react"
import { reqOptions, store, Can, getRole } from "../store/psrs"
import useSWR, { mutate } from "swr"
import ReactTable from "react-table"
import { Button, Popconfirm, Tooltip } from "antd"
import { Search, InPut } from "./Admin/fields"
import { deleteResource, createResource } from "./Admin/fields"
import _ from "lodash"

export const SubLedgers = props => {
  const pageTitle = "Sub Ledger"
  const createTitle = "Create New Sub Leger"

  const [state, setState] = useState({
    pk: "code",
    model: "subLedger",
    ids: [],
    //initialRecords: store.get("_SubLedgers") || undefined,
    initialRecords: undefined,
  })

  const [records, setRecords] = useState(state.initialRecords || [])

  const fetcher = async url => {
    const options = { ...reqOptions }
    const data = await fetch(url, options).then(res => res.json())
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    return data
  }
  const key = `${store.get("api")}/api/resources/subLedger`
  const { data } = useSWR(key, fetcher, { initialData: state.initialRecords })

  const _props = { state, setState, mutate, url: key }

  const columns = [
    {
      Header: "Updated",
      accessor: "updatedAt",
      show: false,
    },
    {
      Header: "",
      accessor: "i",
      sortable: false,
      show: true,
      width: 50,
      style: {
        color: 'white',
      },
    },
    {
      Header: "Name",
      accessor: "description",
      sortable: false,
      Cell: row => <InPut allowClear={true} disabled={false} value={row.value} field="description" id={row.original.id} {..._props} />,
    },
    {
      Header: "Code",
      accessor: "code",
      sortable: false,
      className: "code_column justify-around",
      Cell: row => <InPut allowClear={true} value={row.value} field="code" id={row.original.id} {..._props} />,
    },
    {
      Header: "Actions",
      sortable: false,
      width: 100,
      Cell: row => (
        <Can I="create:SubLedgers">
          <div className="row_actions">
          <Popconfirm
              title={`Are you sure you want to DELETE this Entry?`}
              onConfirm={() => deleteResource({ ..._props, id: row.original.id })}
            >
            <Tooltip title="Delete">
              <Button icon="delete" shape="circle" type="danger" />
            </Tooltip>
          </Popconfirm>
          </div>
        </Can>
      ),
    },
  ]

  useEffect(() => {
    store.set("_SubLedgers", data)
    store.set("SubLedgers", data)

    return() => { 
      store.remove("_SubLedgers")
    }

  }, [data])

  useEffect(() => {
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
  }, [state.ids, data])

  const allowAccess = getRole() === 'admin' ? true : false

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{pageTitle}</h1>
        <div className="actions">
          <Search {..._props} />
          <Can I="create:SubLedgers">
            <Button data-cy="CreateResourceButton" type="dashed" icon="plus-square" onClick={() => createResource(_props)}>
              {createTitle}
            </Button>
          </Can>
        </div>
      </div>
      <div data-cy="RecordsTable" className="view_content">
        { allowAccess && <ReactTable
          columns={columns}
          loading={false}
          data={records}
          defaultPageSize={100}
          className="-striped -highlight flex-1"
          filterable={true}
          multiSort={false}
          defaultSorted={[{ id: "i", desc: true}]}
        /> }
      </div>
    </React.Fragment>
  )
}

export default SubLedgers
