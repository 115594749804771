import React from "react"
import { Progress, Statistic, Icon} from 'antd'

export const StatsCounter = (props) => {
  const {label, counter, total  } = props

  const percentComplete = total ? (1 - ((total - counter)/total))*100 : 0


  const iconMap = {
    Approved: {icon: 'like', color: '#4dacff', filled:'filled'},
    Rejected: {icon: 'dislike', color : 'red', filled:'filled'},
    Processed: {icon: 'dollar', color: '#06A77D', filled:''},
    Submitted: {icon: 'check-circle', color: '#006494', filled:''},
    'Not Submitted': {icon: 'check-circle', color: 'red', filled:''},
    'Total Sick Shifts': { icon: 'medicine-box',color: 'brown', filled:''},
    'Total Leave Shifts': { icon: 'message',color: '#f36d33', filled:''},
    'Total OT Shifts':  { icon: 'clock-circle',color: '', filled:''},
    'Total Dive Shifts': { icon: 'vertical-align-bottom',color: '', filled:''},
    'Total FTO Shifts': { icon: 'team',color: '', filled:''},  
    'Total Time Entries': {icon: 'info-circle',color: 'white', filled:''},
    'Total Shifts': {icon: 'info-circle',color: 'white', filled:''},
  }

  const ofTotal = total ? `/${total}` : ''
  //const prefix = label === 'Approved' ? <Icon type="like"  theme="filled" style={{fontSize: '16px', color: 'green'}}/> : label === 'Rejected' ? <Icon type="dislike" theme="filled" style={{fontSize: '16px', color: 'red'}}/> : ''

  const prefixIcon = iconMap[label]
  //console.log('prefixIcon is -> ', prefixIcon, 'label is -> ', label)

  const prefix = prefixIcon ? <Icon type={prefixIcon.icon} theme={prefixIcon.filled} style={{fontSize: '16px', color: prefixIcon.color}}/> : ' '


  return (
    <>
        { total ? 
        <div style={{ textAlign: "center" }}>
          <Progress 
            type="dashboard" 
            width={40} 
            percent={Math.round(percentComplete)} 
            size="small" 
            format={percent => `${percent}%`}
          />
        </div>  : 
        <div style={{height: "40px", textAlign: "center"}}>
          <Icon type={"dashboard"} theme={''} style={{fontSize: '16px', color: 'black'}}/>
        </div>}
          <div style={{ textAlign: "center" }}> <Statistic title={label} value={counter} suffix={ofTotal} prefix={prefix}></Statistic> </div>
    </ >
  )
}

export default StatsCounter

