import { Modal, Tooltip, Tabs, Button } from "antd"
import React, { useState } from "react"
import uniqid from "uniqid"
import { Environment } from "../store/psrs"
import { VersionHistory } from "./FinePrint"

const { TabPane } = Tabs


const updates = [
  {
    version: "V1.0.12",
    section: "New ⭐️",
    items: [
      "Advanced Audit Trail (US094).  New field level auditing available on the Web Client in both a table view and a timeline view",
    ]
  },
  {
    version: "",
    section: "Fixes 🛠",
    items: [
      "Fixed few bugs on our list",
    ]
  },
  {
    version: "",
    section: "Improvements 🔔",
    items: [
      "Enhanced the Bidder's Workbench (US090).  Added ability to bulk enable/disable shifts for bidding (Taking Bids)",
      "Added a checkbox to Shift related grids.  Currently on the Bidder's Workbench (US090) uses the checked grid recods.  More features on other Shift grids will be added in the future",
    ]
  }
]

const comingSoon = [
  {
    version: "",
    section: "Coming Soon 📣",
    items: [
      "Users will be able to subscribe to the notifications they want to see and choose how they want them delivered on a notification-by-notification bases",
      "A new in-App notification option to view notifications in the app and save them for the next time you login",
      "Actionable notifications, click to approve/reject",
      //"We are always working on new features for ease of use and fixing issues we find",
      //"The team is activily reviewing backlog items including user request as we plan the next release(s)"
    ]
  }
]

const history = VersionHistory()

const NewStuff = () => {
  const versions = []
  updates.forEach((update, i) => {
    versions.push(
      <section key={uniqid()}>
        <h2>{update.version}</h2>
        <h2>{update.section}</h2>
        <ul className="version-bullet">
          {update.items.map(item => (
            <li key={`${uniqid()}${i}`}>{item}</li>
          ))}
        </ul>
      </section>,
    )
  })
  return <div className="versions_modal">{versions}</div>
}

const ComingSoon = () => {
  const versions = []
  comingSoon.forEach((update, i) => {
    versions.push(
      <section key={uniqid()}>
        <h2>{update.version}</h2>
        <h2>{update.section}</h2>
        <ul className="version-bullet">
          {update.items.map(item => (
            <li key={`${uniqid()}${i}`}>{item}</li>
          ))}
        </ul>
      </section>,
    )
  })
  return <div className="versions_modal">{versions}</div>
}

const HistoryItems = () => {
  const versions = []
   history.forEach((update, i) => {
    versions.push(
      <section key={uniqid()}>
        <h2>{update.version}</h2>
        <h2>{update.section}</h2>
        <ul>
          {update.items.map(item => (
            <li key={`${uniqid()}${i}`}>{item}</li>
          ))}
        </ul>
      </section>,
    )
  })
  return <div className="versions_modal">{versions}</div>
}

export const Version = props => {
  const [modal, setModal] = useState({ visible: false })

  const showModal = () => setModal({ ...modal, visible: true })
  const handleOk = () => setModal({ ...modal, visible: false })
  const handleCancel = () => setModal({ ...modal, visible: false })
  const currentVersion = updates[0].version
  const previousVersion = localStorage.getItem("version")

  const [tab, setTab] = useState("newstuff");

  if (currentVersion !== previousVersion) {
    //console.log("CLEARING CACHE")
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem("version", currentVersion)
    window.location.href = window.location.origin
  }

  // set color based on Enviornment 
  // Local: light sea green
  // DV: blue
  // QA: dark olive green
  // PD: dark slate grey

  let envColor = 'darkslategrey'
  if (Environment === 'Local') envColor = 'lightseagreen'
  if (Environment === 'DV') envColor = 'blue'
  if (Environment === 'QA') envColor = 'darkolivegreen'

  return (
    <div className="Version">
      <div onDoubleClick={showModal} style={{ background: envColor}} className="clientVersion">
      <Tooltip title={`Environment: ${Environment}`} >{currentVersion}</Tooltip>
      </div>
      <Modal
         title="Release Notes" 
         footer={[
          <Button type="primary" key="back" onClick={handleCancel}>
            OK
          </Button>
         ]}
         visible={modal.visible} 
         onOk={handleOk} 
         onCancel={handleCancel} 
         width={800}> 
             <p style={{color: '#1E90FF'}}>{ `Enterprise Technologies Resoruce Sechduler`} { updates[0].version} </p><br />
            <div style={ {overflowY: "scroll", height:"400px" } }>
              <Tabs 
              defaultActiveKey="newstuff" 
              activeKey={tab} 
              onChange={key => {
                setTab(key)
              }}
            >
              <TabPane tab='Release Notes' key='newstuff' forceRender = {true} >
                <NewStuff />          
              </TabPane>
              <TabPane tab='Coming Soon' key='soon' forceRender = {true} >
                <ComingSoon />          
              </TabPane>
              <TabPane tab='History' key='history' forceRender = {true} >
                <HistoryItems />          
              </TabPane>
            </Tabs>
        </div>
      </Modal>
    </div>
  )
}

export default Version
