import React from "react"
import moment from "moment"
import { DatePicker } from "antd"

const DEBUG = false

export const DateFilter = props => {
  const { handleChange } = props
  const value = !!props.value ? props.value : null
  DEBUG && console.log("DateFilter", value)
  const dateProps = {
    defaultValue: value ? moment(value) : null,
    fullScreen: false,
    allowClear: true,
    onChange: (date, dateString) => {
      let sql = null
      if (date) {
        const _start = date.format("YYYY-MM-DD")
        const _end = date.add(1, "day").format("YYYY-MM-DD")
        sql = date ? `"start" >= '${_start}' AND "end" <= '${_end}'` : null
      }
      handleChange({
        ...props,
        field: "date",
        value: dateString || null,
        sql: sql,
      })
    },
  }

  return <DatePicker {...dateProps} />
}

export default DateFilter
