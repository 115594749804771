import React, { useState, useEffect } from "react"
import { Button, DatePicker } from "antd"
import { REQ } from "../../store/psrs"
// import cloneDeep from "lodash/fp/cloneDeep"
// const {cloneDeep} = _
import moment from "moment-timezone"

const DateSelector = props => {
  const { disabled, slot, template /*, setTemplate*/ } = props
  const Model = slot ? slot : template

  const [state, setState] = useState({
    open: false,
    mounted: false,
    datesToSchedule: new Set(Model.datesToSchedule),
    datesScheduled: new Set(Model.datesScheduled),
  })

  const handleDateChange = date => {
    // without the timestamp the screen shows - 1 day but is correct. 
    //const formattedDate = date.format("YYYY-MM-DDTHH:mm:ss.sssZ")
    const formattedDate = date.format("YYYY-MM-DDTHH:mm")
    const _datesToSchedule = new Set(Array.from(state.datesToSchedule))
    const inToSchedule = _datesToSchedule.has(formattedDate)
    if (inToSchedule) {
      _datesToSchedule.delete(formattedDate)
      setState({ ...state, datesToSchedule: _datesToSchedule })
    } else {
      _datesToSchedule.add(formattedDate)
      setState({ ...state, datesToSchedule: _datesToSchedule })
    }
  }

  const update = async () => {
    const updateParams = {
      datesToSchedule: Array.from(state.datesToSchedule),
      firstWeekDays: [],
      secondWeekDays: [],
			timeZone:Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
    //console.log("update", updateParams.datesToSchedule)
    slot && (await REQ(`/api/resources/slot/${slot.id}`, "PATCH", updateParams))
    template && (await REQ(`/api/resources/template/${template.id}`, "PATCH", updateParams))
  }

  const confirm = async () => {
    //console.log("confirm")
    await update()
    setState({ ...state, open: false })
  }

  const clearDates = () => {
    state.datesToSchedule = new Set()
    setState({ ...state, open: false })
    update()
  }

  const _datesToSchedule = Array.from(state.datesToSchedule.values())
  const datesToSchedule = _datesToSchedule.map(d => moment(d).format('MM/DD'))
  const defaultMonth = _datesToSchedule[0] ? moment(_datesToSchedule[0]) : undefined

  const datePickerProps = {
    className: "DateSelector",
    allowClear: false,
    showTime: false,
    showToday: false,
    open: state.open,
    //size: 'large',
    // defaultValue: defaultMonth,
    defaultPickerValue: defaultMonth,
    value: undefined,
    placeholder: datesToSchedule.length ? datesToSchedule.join(", ") : 'No Dates',
    disabled: disabled,
    onChange: date => {
      // localStorage.setItem("dateOpen", true)
      handleDateChange(date)
      return false
    },
    onOpenChange: status => {
      setState({ ...state, open: !state.open })
    },
    renderExtraFooter: mode => {
      return (
        <div>
          <Button size="small" onClick={confirm}>
            Ok
          </Button>
          <Button size="small" onClick={clearDates}>
            Clear
          </Button>
        </div>
      )
    },
    dateRender: current => {
      const style = {}
      const date = current.format("YYYY-MM-DDTHH:mm")
      if (state.datesScheduled.has(date)) {
        style.color = "#fff"
        style.background = "green"
      }
      if (state.datesToSchedule.has(date)) {
        style.color = "#fff"
        style.background = "#40a9ff"
        // style.color = "#40a9ff"
        // style.border = "1px solid #40a9ff"
      }
      return (
        <div className="ant-calendar-date" style={style}>
          {current.date()}
        </div>
      )
    },
  }

  const ClickMask = () => {
    return <div className={`ClickMask ${state.open ? "open" : "closed"}`} onClick={() => confirm()} />
  }

  const formatDateString = (dateArray) => {

    var newDateArray = []
  
    // iterate over the dates list from above
    for(let d of dateArray) {
        let date = moment(d).utc().format('MM/DD');
        newDateArray.push(date)
    }
    return newDateArray.join(',  ')

  }  

  useEffect(() => {
    setState({ ...state, mounted: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.mounted && !state.open) {
      //console.log("state.datesToSchedule", state.datesToSchedule)
      update()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.open])

  useEffect(() => {
    if (state.mounted) console.log("state.datesToSchedule", state.datesToSchedule)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.datesToSchedule])

  useEffect(() => {
    if (state.mounted) {
      setState({
        ...state,
        ...state,
        datesToSchedule: new Set(slot.datesToSchedule),
        datesScheduled: new Set(slot.datesScheduled),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slot])

  return (
    <React.Fragment>
      {(!!slot || template) && <DatePicker  {...datePickerProps} />}
      {((!!slot || template) && state.datesToSchedule.size >0  ) && <div style={ { textAlign:'left', border: '.05em solid #0094C6',padding: '.25em' }}> { formatDateString(state.datesToSchedule) } </div>}
      {(!!slot || template) && <ClickMask />}
    </React.Fragment>
  )
}

export default DateSelector
