import React from "react"
import store from "store2"
import { Emitter } from "../../store/psrs"
import { Button, Icon } from "antd"
import useMyContext from "../../store/useMyContext"

const PayPeriodNav = props => {
  const context = useMyContext()

  const PayPeriod = store.get("PayPeriod")
  const PayPeriodToday = store.get("PayPeriodToday")
  const PayPeriodNext = store.get("PayPeriodNext")
  const PayPeriodPrev = store.get("PayPeriodPrev")
  const isCurrent = PayPeriod.id === PayPeriod.todayId ? "primary" : "dashed"

  const setPayPeriod = Period => {
    props.toggleLoading(true)
    store.set("PayPeriod", Period)
    store.set("PayPeriodNext", context.getRecord("PayPeriods", Period.nextId))
    store.set("PayPeriodPrev", context.getRecord("PayPeriods", Period.prevId))
    Emitter.emit("ChangedPayPeriod")
    props.toggleLoading(false, Period)
  }

  return (
    <Button.Group className="PayPeriodNav" size="small">
      <Button key="previous" onClick={e => setPayPeriod(PayPeriodPrev)}>
        <Icon type="left" />
      </Button>
      <Button key="current" type={isCurrent} onClick={e => setPayPeriod(PayPeriodToday)}>
        Current
      </Button>
      <Button key="next" onClick={e => setPayPeriod(PayPeriodNext)}>
        <Icon type="right" />
      </Button>
    </Button.Group>
  )
}

export default PayPeriodNav
