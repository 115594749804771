import React from "react"
import { Button, Popconfirm, Tooltip, Tag } from "antd"
import moment from "moment"
import {REQ, a} from "../../store/psrs"
import { CheckRequiredfields, ImbalancedMessage, RequiredFieldMessage } from "../ShiftDrawer/CheckRequiredFields"
import { ShiftGridStatus } from "../Forms"

export const shiftColumns = (props) => {
  //console.log('shiftColumns props', props);

  const {ability, context, refresh} = props

  //const canApprove = ability.can("approve", a("Shift", shift))

  const validateFields = async (shift) => {
    let valid = true

    const { checkFields, okSubmit } = await CheckRequiredfields(shift)

    if (!okSubmit) {
      valid = false 
      const message = `Required Fields Missing. Please open the Shift Drawer and update the required fields for Shift`
      RequiredFieldMessage(shift,checkFields,message)
    }

    if (shift.imbalanced ) {
      valid = false
      ImbalancedMessage(shift)
    } 

    return valid
  }

  const approveEntry = async (model, id, value) => {
   
    await REQ(`/api/resources/shift/${id}`, "PATCH", { approved: value, rejected: false, needsApproved: !value })
    refresh()
  }

  const rejectEntry = async (model, id, value) => {
    await REQ(`/api/resources/shift/${id}`, "PATCH", { approved: false, rejected: value, needsApproved: !value })
    refresh()
  }
  
  return [
    {
      Header: "Employee",
      headerClassName: "alignLeft",
      accessor: "employee.fullName",
      sortable: true,
      Cell: row => <Tooltip title={`Employee Number: ${row.original.employee.number}`}><Tag style={{fontSize: 'small'}} >{row.value ? row.value : "UNASSIGNED"}</Tag></Tooltip>,
    },
    {
      Header: "Date Of",
      headerClassName: "alignLeft",
      accessor: "start",
      sortable: true,
      Cell: row => moment(row.value).format("ddd MMM Do"),
    },
    {
      Header: "Hours",
      accessor: "duration",
      sortable: true,
      Cell: row => `${parseFloat(row.value).toPrecision(3)} Hrs`,
    },
    {
      Header: "Supervisor 1",
      headerClassName: "alignLeft",
      accessor: "supervisor1.fullName",
      sortable: true,
      Cell: row => {
        const s1 = row && row.original && row.original.supervisor1 && row.original.supervisor1.number ? row.original.supervisor1.number : ''
        const s2 = row && row.original && row.original.supervisor2 && row.original.supervisor2.number ? row.original.supervisor2.number : ''
        const s3 = row && row.original && row.original.supervisor3 && row.original.supervisor3.number ? row.original.supervisor3.number : ''
        const s1Name = row && row.original && row.original.supervisor1 && row.original.supervisor1.lastName ? row.original.supervisor1.lastName : ''
        const s2Name = row && row.original && row.original.supervisor2 && row.original.supervisor3.lastName ? row.original.supervisor2.lastName : ''
        const s3Name = row && row.original && row.original.supervisor3 && row.original.supervisor3.lastName ? row.original.supervisor3.lastName : ''

        return (
        <Tooltip title={
          <small><ol>
            <li>Supervisor 1: {s1} / {s1Name}</li>
            <li>Supervisor 2: {s2} / {s2Name}</li>
            <li>Supervisor 3: {s3} / {s3Name}</li>
          </ol></small>
          }>
            <Tag style={{fontSize: 'small'}} >{row.value}</Tag>
        </Tooltip>)
      }
    },
    {
      Header: "Type",
      accessor: "id",
      sortable: false,
      Cell: row => {
        const rec = row.original
        const returnRec = rec.isSick ? "SICK" : rec.requestedOff ? "LEAVE" : rec.isOT ? "OVERTIME" : "REGULAR"
        //return rec.isSick ? "SICK" : rec.requestedOff ? "LEAVE" : rec.isOT ? "OVERTIME" : "REGULAR"

        return (<Tag style={{fontSize: 'small'}} >{returnRec}</Tag>)
      }
    },
    /* {
      Header: "Unpaid Lunch",
      accessor: "paidLunch",
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => <> <CheckBox disabled={true} checked={!cell.value}></CheckBox></>,
    },
    {
      Header: "In Collision",
      accessor: "isCollision",
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => <> <CheckBox disabled={true} checked={cell.value}></CheckBox></>,
    }, */
    {
      Header: "Status",
      width: 260,
      accessor: "id",
      sortable: false,
      Cell: row => ShiftGridStatus({model: 'shift', record: row.original}),
    },
    {
      Header: "",
      className: "justify-around",
      accessor: "id",
      sortable: false,
      Cell: row => {
        const rec = row.original
        const disabled = ability.cannot("approve", a("Shift", rec))
        return (
          <>
            <Button.Group>
              {/* IF SUPERVISOR OR ADMIN 
              <Button
                disabled={disabled}
                value={rec.id}
                className={!!row.original.approved ? "approved" : ""}
                icon="like"
                type={rec.approved ? "primary" : "default"}
                onClick={e => approveEntry("shift", rec.id)}
              /> */}
						

              {/* APPROVE */}  
              <Tooltip title={`${rec.isCollision && rec.approved ? "Un Approve Shift in Collision" : rec.approved ? "Un Approve" : !rec.isCollision ? "Approve" : "Approve Shift in Collision" }`}>
              	<Popconfirm
                	title={rec.isCollsion && rec.approved ? "Un Approve Shift in Collision" : rec.approved ? "Un Approve Shift" : "Approve Shift Fine Print"}
                	onConfirm={ async e => { 
                    //approveEntry("shift", rec.id)}

                     // check required fields
                    const valid = await (validateFields(rec))

                    !rec.rejected && valid && approveEntry("shift", rec.id, !rec.approved )
                  }}
                	okText={rec.approved ? "Un Approve" : "Approve"}
                	cancelText="Cancel"
              	>
                	<Button
                  	icon="like"
                    size="small"
                  	disabled={disabled}
                  	type={rec.isCollision && rec.approved ? "danger" : rec.approved ? "primary" : "default"}
                	/>
              	</Popconfirm>
            	</Tooltip>

              {/* REJECT */}
              <Tooltip title={`${rec.isCollision && rec.rejected ? "Un Reject Shift in Collision" : rec.isCollision && !rec.rejected ? "Reject Shift in Collision" : rec.rejected ?"Un Reject" : "Reject"}`}>
              	<Popconfirm
                	title={rec.isCollision && rec.rejected ? "Un Reject Shift in Collision" : rec.rejected ? "Un Reject Shift" : "Reject Shift Fine Print"}
                	onConfirm={ e => 
                    //approveEntry("shift", rec.id)}
                   (!rec.approved) && rejectEntry("shift", rec.id, !rec.rejected )}
                	okText={rec.rejected ? "Un Rject" : "Reject"}
                	cancelText="Cancel"
              	>
              <Button
                className={!!row.original.rejected ? "rejected" : ""}
                size="small"
                icon="dislike"
                disabled={disabled}
                //type={ rec.isCollision && rec.rejected ? "danger" : rec.rejected ? "primary" : "default"}
                type={ rec.rejected ? "danger" : rec.rejected ? "primary" : "default"}
              />
              	</Popconfirm>
            	</Tooltip>
              {/*
              <Button
                disabled={disabled}
                value={rec.id}
                className={!!row.original.rejected ? "rejected" : ""}
                icon="dislike"
                type={rec.rejected ? "primary" : "default"}
                onClick={e => rejectEntry("shift", rec.id)}
              />
              */}
  
              <Button
                value={row.value}
                icon="eye"
                size="small"
                onClick={e => {
                  context.setShiftDrawerId(row.value)
                }}
              >
                View
              </Button>
            </Button.Group>
          </>
        )
      },
    },
  ]
}

export default shiftColumns
