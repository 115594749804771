import React, { useState } from "react"
import { REQ, Emitter, session } from "../../store/psrs"
import { FlexBlock } from "../UI"
import { Icon, Input, Button, Table, Popconfirm} from "antd"
import { PayTypeSelect, DurationField, BusinessUnitSelect, SubLedgerSelect, AssignmentSelect, /*PayPeriodEndSelector*/ } from "../Forms"
import uuid from "uuid"
import _ from "lodash"
// import moment from "moment"

const TimeEntriesTable = props => {
  const { 
    timeEntries, 
    mutate, 
    url, 
    //changeDetected2,
    setChangeDetected2,
    shiftLocked,
    //setShiftLocked,
    //priorPayPeriod,
    setPriorPayPeriod,

  } = props
  
  
  const Shift = props.shift
  Shift.paidLunch = Shift.paidLunch ? true : false

  const [showMore, setShowMore] = useState(false)

  const updateEntry = async ({ field, value, id }) => {
    const update = field ? {} : value
    if (field) update[field] = value
    //console.log("updated timeEntry", update)
    const updatedShift = await REQ(`/api/time_entries/${id}`, "PATCH", update).catch(err =>
      Emitter.emit("TimeEntriesTable.js", { fn: "updateEntry", error: err }),
    )

    session.set("changeDetected", true)
  
    //console.log("updated timeEntry", updatedShift)
    mutate(url, updatedShift)
  }

  const addEntry = async () => {
    const updatedShift = await REQ(`/api/time_entries/create`, "POST", Shift).catch(err =>
      Emitter.emit("TimeEntriesTable.js", { fn: "addEntry", error: err }),
    )
    session.set("changeDetected", true)

    setChangeDetected2(true)
    
    if (shiftLocked) {
      setPriorPayPeriod(true)
      //setShowMore(true)
    }
    
    mutate(url, updatedShift)
  }

  const removeEntry = async id => {
    const updatedShift = await REQ(`/api/time_entries/${id}`, "DELETE").catch(err =>
      Emitter.emit("TimeEntriesTable.js", { fn: "removeEntry", error: err }),
    )
    session.set("changeDetected", true)
    //setShiftLocked(true)
    //if (shiftLocked) setShowMore(false)

    mutate(url, updatedShift)
  }

  const showMoreFields = async () => {
    setShowMore(!showMore)

  }

  // disable entry if evidence 2hr or 4hr pay type code
  let evidenceDisable = false
  for (let timeEntry of timeEntries) {
    evidenceDisable = timeEntry.payTypeCode === '250' || timeEntry.payTypeCode === '251' ? true : false
  }

  const selectProps = { 
    //disabled: evidenceDisable, 
    handleChange: updateEntry 
  }


  const renderTotal = (text, shift, index) => {
    const { timeEntries } = shift
		//console.log(timeEntries);
    if (timeEntries && timeEntries.length > 0 ) {
      // const shiftDuration = shift.duration
      // const durations = timeEntries.map(e => e.duration)
      // const subTotal = durations.reduce((total, val) => total + val)
      const balanced = shift.imbalanced ? "error" : "equal"
      return <span className={balanced}>{shift.timeTotal}</span>
    } else {
      return <span>0</span>
    }
  }

  const renderBlank = (text, record, index) => ""

  const subTotalOptions = {
    rowClassName: "timeEntryTotals",
    dataSource: [Shift],
    pagination: false,
    showHeader: false,
    bordered: false,
    rowKey: "id",
    columns: [
      { width: 90, key: uuid(), title: "", render: renderTotal, align: "center" },
      {
        key: uuid(),
        title: "",
        align: "left",
        render: () => `Entry Total Hours ${Shift.imbalanced ? "do NOT match the shift duration! Please fix." : ""}`,
      },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
      { key: uuid(), title: "", render: renderBlank },
    ],
  }

  //const payPeriodStartId = store.get("PayPeriodToday").id
  //const payPeriodEndId = store.get("PayPeriodToday").id

  const columns = [
    {
      align: "center",
      title: "Hours*",
      dataIndex: "duration",
      width: 90,
      render: (text, record, index) => (
        <DurationField
          noLabel={true}
          disabled={ evidenceDisable || record.locked || shiftLocked }
          field="duration"
          value={parseFloat(text).toFixed(2)}
          id={record.id}
          handleChange={updateEntry}
        />
      ),
    },
    {
      align: "center",
      title: "Pay Type*",
      dataIndex: "payTypeCode",
      //width: 220,
      render: (text, record, index) => {
        return <PayTypeSelect disabled={record.locked || shiftLocked} value={record.payTypeCode} {...selectProps} id={record.id} />
      },
      style: { paddingRight: 0 },
    },
    {
      title: "Business Unit",
      dataIndex: "Business Unit Override",
      //width: 220,
      render: (text, record, index) => (
        <BusinessUnitSelect
          {...selectProps}
          disabled={record.locked || shiftLocked}
          field="costCenterChargeOutCode"
          value={record.costCenterChargeOutCode || record.businessUnitCode}
          record={record}
        />
      ),
    },
    {
      title: "Sub Ledger",
      dataIndex: "subLedger.description",
      //width: 220,
      render: (text, record, index) => (
        <SubLedgerSelect {...selectProps} record={record} disabled={record.locked || shiftLocked} field="subLedgerCode" value={record.subLedgerCode} />
      ),
    },
    
    {
      title: "Acting Role",
      dataIndex: "assignment.description",
      //width: 210,
      render: (text, record, index) => (
         <AssignmentSelect {...selectProps} record={record} disabled={record.locked || shiftLocked} field="assignmentId" value={record.assignmentId} />
      ),
    },
    {
      title: "Case",
      dataIndex: "caseNumber",
      //width: 100,
      render: (text, record, index) => {
        return (
          <Input
            defaultValue={text}
            placeholder="optional"
            onBlur={e => updateEntry({ field: "caseNumber", value: e.target.value, id: record.id })}
            disabled={record.locked}
          />
        )
      },
    },
    /*
    {
      title: "Pay Period End Override",
      dataIndex: "payPeriodEndOverride",
      // width: 100,
      render: (text, record, index) => {
        return (
          <PayPeriodEndSelector
            data-cy="templatePayPeriodRangeSelector"
            defaultValue={text}
            placeholder="optional"
            disabled={record.locked}
            value={{ payPeriodStartId: record.payPeriodEndOverride, payPeriodEndId: record.payPeriodEndOverride }}
            record={timeEntries}
            handleChange={e => {
              console.log('over ride the pay period end')
              updateEntry({ field: "payPeriodEndOverride", value: e.value, id: record.id })}
            }
          />
        )
      },
    },
  */
    {
      align: "center",
      title: "",
      //width: 40,
      key: uuid(),
      del: true, 
      render: (text, record, index) => (
        <Popconfirm
          title="Are you sure you want to delete this Time Entry?"
          onConfirm={() => removeEntry(record.id)}
          okText="Yes"
          cancelText="No"
          disabled={ record.ediLineNumber==='1.000' || _.includes(['200','380'],record.payTypeCode)? true : false || record.locked || shiftLocked}
        >
          {!record.locked && !shiftLocked && <Icon type="delete" theme="filled" className="ShiftDrawerTimeEntryDelete" />}
        </Popconfirm>
      ),
    },
  ]

  //blw want paid lunch in grid
  const dataSource = _.orderBy(timeEntries, ["i"], ["asc"])
  // const dataSource = _.filter(timeEntries, t => t.name !== "Unpaid Lunch")

  const filters = [
     { type: "dataIndex", name: "caseNumber" }, 
     { type: "dataIndex", name: "payPeriodEndOverride" },
  ];

  const extraFilters = [
    { type: "dataIndex", name: "caseNumber" }, 
    { type: "dataIndex", name: "payPeriodEndOverride" },
    { type: "dataIndex", name: "Business Unit Override"},
    { type: "dataIndex", name: "duration" },
    { type: "dataIndex", name: "payTypeCode" },
    { type: "del", name: true},
  ]
  
  const standardFields = columns.filter(el => filters.every(filterEl => el[filterEl.type] !== filterEl.name))
  const extraFields = columns.filter(el => extraFilters.some(filterEl => el[filterEl.type] === filterEl.name))

  const timeEntryOptions = {
    dataSource,
   // columns: columns,
    //columns: showMore ? columns : columns.filter(ea => ea.dataIndex !== 'caseNumber'),
    columns: showMore ? extraFields: standardFields,
    pagination: false,
    showHeader: true,
    bordered: true,
    rowKey: () => uuid(),
  }

  return (
    <React.Fragment>
      <FlexBlock>
        <section>
          <h2>Time Entries</h2>
        </section>
        <section>
          <Button.Group>
            <Button  type="primary" size="small" icon="more" ghost={true} onClick={showMoreFields}>
              {!showMore ? 'Show More ' : 'Show Less '}     
            </Button> 
            <Button disabled={evidenceDisable || shiftLocked } type="primary" size="small" icon="file-add" ghost={true} onClick={addEntry}>
              Add a Time Entry
            </Button>
          </Button.Group>
        </section>
      </FlexBlock>
      <Table key="timeEntriesTable" {...timeEntryOptions} />
      <Table key="timeEntryTotal" {...subTotalOptions} />
    </React.Fragment>
  )
}

export default TimeEntriesTable
