import React, { useState } from "react"
import { store } from "../../store/psrs"
import Select from 'react-select'
import useMyContext from "../../store/useMyContext"
// import {EmployeeSelectOptions} from "./_SelectOptions"
import _ from "lodash"

export const EmployeeSelect = props => {
  // console.log('EmployeeSelect')

  const context = useMyContext()
  const Employees = store.get("Employees") || []

  // sort by label (fullName)
  let  EmployeeSelectOptions = Employees.map(r => ({ value: r.number, label: r.fullName, role: r.role }))
   EmployeeSelectOptions = _.sortBy(EmployeeSelectOptions, ['label']) 

  // remove any employee with role of disabled
  EmployeeSelectOptions = _.filter(EmployeeSelectOptions, e => e.role !== "disabled")


  const { record, value, employee } = props
  const field = props.field || "employeeNumber"
  const id = record ? record.id : props.id
  // const Employee = employee ? employee : record ? record[field] : value ? context.getRecord('Employees', value, 'number') : null
  
  const Employee = employee
  ? employee
  : record
  ? record[field]
  : value
  ? context.getRecord("Employees", value, "number")
  : null
  
  const [state, setState] = useState({
    // value: Employee ? Employee.number : null,
    // selected: Employee ? _.find(EmployeeSelectOptions, {value: Employee.number}) : null,
    sql: null,
  })

  const selectProps = {
    id: "cyEmployeeSelect",
    className: "psrs-select",
    classNamePrefix: "psrs",
    dropdownClassName: "cyEmployeeSelectDropdown EmployeeSelectDropdown",
    placeholder: props.placeholder || "Search for an Employee",
    isDisabled: props.disabled ? true : false,
    isClearable: true,
    //value: employee && employee.number ? _.find(EmployeeSelectOptions, {value: employee.number}) : null,
    value: Employee && Employee.number ? _.find(EmployeeSelectOptions, {value: Employee.number}) : null,
    options: EmployeeSelectOptions,
    onChange: select => {
      const val = select && select.value ? select.value : null
      const sql = val ? `"${field}" IS NOT NULL AND "${field}" = '${val}'` : null
      setState({ ...state, selected: select, sql })
      props.handleChange({ ...props, value: val, field, id, sql })
    },
  }

  ////// cacheOptions 
  //if (!employee) selectProps.value = null
  if (!Employee) selectProps.value = null

  return (
    <Select {...selectProps} />
  )
}

export default EmployeeSelect
