import React from "react"
import _ from "lodash"
import { Button, Input, Checkbox, Switch, Typography, notification} from "antd"
import { fetch, store, REQ } from "../../store/psrs"

const { Paragraph } = Typography;


export const query = async ({ search, state, setState, mutate, url }) => {
  const { model } = state
  if (search !== '') {
    const currentPP = store.get("PayPeriod")
    let theSearch = search.replace(/\s/g, '') 
    theSearch = encodeURIComponent(theSearch.trim())

    let searchUrl = `${store.get("api")}/api/search?model=${model}&search=${theSearch}`
    //const ids = await fetch(`${store.get("api")}/api/search?model=${model}&query="payPeriodId"='${currentPP.id}'&search=${theSearch}`)

    if (model === 'shift' || model === 'timeEntry') {
      searchUrl = `${store.get("api")}/api/search?model=${model}&query="payPeriodId"='${currentPP.id}'&search=${theSearch}`
    } 

    const ids = await fetch(searchUrl)


    // console.log("QUERY", ids)
    setState({ ...state, ids })
    if (ids.length === 0) {
      notification['info']({
        message: "Search",
        description: "Not Found",
        placement: "topRight",
        top: 60,
        //bottom: 50,
        duration: 2,
      })
    }
    

  } else {
    setState({ ...state, ids: []})
  }
}

export const handleSearch = _.debounce(query, 1500, { leading: false, trailing: true })

export const Search = props => {
  const args = {
    placeholder: "input search text",
      onChange: e => handleSearch({ ...props, search: e.target.value }),
    style: { width: props.width? props.width : 200, margin: "0 1em" },
    allowClear: true,
  }
  return <Input.Search data-cy="Search" {...args} />
}


export const updateResources = async props => {
  //console.log("Admin/fields > updateResource > props", props)
  const { id, updateFields } = props

  await updateFields.forEach(async element => {  
      const update = {}
      update[element.field] = element.value
      //await REQ(`/api/resources/${props.state.model}/${id}`, "PATCH", update)
      await REQ(`/api/shifttemplate/update/${id}`, "PUT", update)

  })
  
  //mutate(url)
}

export const updateResource = async props => {
  //console.log("Admin/fields > updateResource > props", props)
  const { mutate, url, id, value, field } = props
  const update = {}
  update[field] = value
  const model = props.model? props.model : props.state.model
  await REQ(`/api/resources/${model}/${id}`, "PATCH", update)
  mutate(url)
}

export const createTemplateResource = async props => {
  const { mutate, url } = props
  const newTemplate = await REQ(`/api/resources/${props.state.model}`, "POST", props.attributes)
  mutate(url)
  return newTemplate
}

export const copyTemplateResource = async props => {
  const { mutate, url } = props
  const newTemplate = await REQ(`/api/resources/${props.state.model}`, "POST", props.attributes)
  mutate(url)
  return newTemplate
}

export const createResource = async props => {
  const { mutate, url } = props
  await REQ(`/api/resources/${props.state.model}`, "POST", props.attributes)
  mutate(url)
}

export const deleteResource = async props => {
  const { mutate, url } = props
  await REQ(`/api/resources/${props.state.model}/${props.id}`, "DELETE")
  mutate(url)
}

export const CreateResourceButton = props => {
  return (
    <Button icon="plus-square" onClick={() => createResource(props)}>
      {" "}
      {`create a ${props.state.model}`}{" "}
    </Button>
  )
}

export const JDEButton = async props => {
  return (
    <Button icon="cloud-sync" onClick={props.refresh}>
      {" "}
      Sync JDE{" "}
    </Button>
  )
}

export const ToggleViewFlavorButton = async props => {
  return (
    <Button icon="block" onClick={props.toggleViewFlavor}>
      {" "}
      {props.label || "click me"}{" "}
    </Button>
  )
}

export const CheckBox = props => {
  const args = {
    ...props,
    field: props.field,
    value: props.value === props.toValue ? props.unValue : props.toValue,
    id: props.id,
  }
  return <Checkbox disabled = {props.disabled} checked={props.value === props.toValue} onChange={e => updateResource({ ...args })} />
}

export const Toggle = props => {
  const args = {
    ...props,
    field: props.field,
    value: props.value === props.toValue ? props.unValue : props.toValue,
    id: props.id,
  }
  return <Switch 
    disabled={props.disabled} 
    defaultChecked={false} 
    checked={props.value === props.toValue} 
    checkedChildren={props.checkedChildren}
    unCheckedChildren={props.unCheckedChildren}
    onChange={e => updateResource({ ...args })} 
  />

  //return <Checkbox disabled = {props.disabled} checked={props.value === props.toValue} onChange={e => updateResource({ ...args })} />
}

export const EditParagraph = props => {
  const args = {
    ...props,
    field: props.field,
    value: props.value,
    id: props.id,
    ellipsis: props.ellipsis,

  }

  const onChange = (str) => {
    console.log('value -> ', str)
    updateResource({ ...args, value:str })
  } 

  return <Paragraph
    className="InlineEdit"
    ellipsis={props.ellipsis} 
    editable={{ onChange: onChange }}
    
  >
    {props.value}
  </Paragraph>  

}

export const InPut = props => {
  const args = {
    //value: props.isSorting ? props.value: undefined,
    id: props.value,
    className: "InlineEdit",
    placeholder: props.placeholder || "Enter text",
    defaultValue: props.value,
    disabled: props.disabled,
    allowClear: props.allowClear,
    onBlur: e => {
      console.log("onBlur", e.target.value)
      const value = e.target.value || ""
      updateResource({ ...props, value })
    },
  }
  if (props.forceRefresh) {
    if (props.value) {
      args['value'] = props.value
    }
    props.setForceRefresh(false)
  }

  return <Input {...args} />
}

export const sortStringAsInt = (a = 0, b = 0, desc) => {
  a = a ? parseInt(a) : 0
  b = b ? parseInt(b) : 0
  if (a > b) {
    return 1
  }
  if (a < b) {
    return -1
  }
  return 0
}
