import React, {useState} from "react"
import { REQ } from "../../store/psrs"
import uid from "uniqid"
import { Spin, Button } from "antd"
import Slot from "./Slot"
import Help from "../Help"
import helpText from "../Help/text"
import _ from "lodash"
const text = helpText.views.team

const Roster = props => {
  const { disabled, mutate, url } = props
  const Template = props.template
  const TemplateSlots = Template.slots
  const Supervisor1 = !!Template.supervisor1 && !!Template.supervisor1.subordinates && !!Template.supervisor1.subordinates.length
  const Subordinates = Supervisor1 ? Template.supervisor1.subordinates : []
  // const subordinateNumbers = Subordinates ? Subordinates.map(s => s.number) : []
  const slots = _.orderBy(Template.slots, 'i', 'desc')

  const [loading, setLoading] = useState(props.loading || false)


  const addSlot = async () => {
    setLoading(true)
    const _Template = await REQ(`/api/templates/${Template.id}/add_slot`, "POST")
    setLoading(false)
    mutate(url, _Template)
  }

  const clearSlots = async () => {
    setLoading(true)
    const _Template = await REQ(`/api/templates/${Template.id}/clear_slots`, "DELETE")
    setLoading(false)
    mutate(url, _Template)
  }

  const addSlotsForSubordinates = async () => {
    setLoading(true)
    const _Template = await REQ(`/api/templates/${Template.id}/add_subordinates`, "POST")
    setLoading(false)
    mutate(url, _Template)
  }

  const buttonProps = {
    size: "small",
    ghost: true,
    style: { marginLeft: "10px" },
    theme: "filled",
  }

  const showSubordinateButton = !!Subordinates.length && !TemplateSlots.length ? true : false

  const showRemoveButton = Template.slots && Template.slots.length > 0

  const renderSlots = slots.map(slot => <Slot loading={loading} template={Template} data-cy={`Slot-${slot.id}`} key={uid()} slot={slot} mutate={mutate} url={url} />)

  // console.log('Roster', showSubordinateButton, subordinateNumbers)


  return (
    <section className="roster grid_section">
      <header>
        Schedule Roster
        <Help text={text.roster} />
        <div style={{ float: "right" }}>

          <Button {...buttonProps} disabled={loading || disabled} onClick={addSlot}>
            Add Slot
          </Button>

          {showRemoveButton && (
            <Button data-cy="RemoveAllButton" {...buttonProps} disabled={loading || disabled} onClick={clearSlots} icon="delete">
              Remove All
            </Button>
          )}
          {showSubordinateButton && (
            <Button
              data-cy="AddSubordinatesButton"
              {...buttonProps}
              disabled={loading || disabled || !Template.superNumber1 ? true : false}
              onClick={addSlotsForSubordinates}
              icon="plus-circle"
            >
              Add Subordinates
            </Button>
          )}
        </div>
      </header>
      <Spin spinning={props.loading}>
      <table className="rosterTable">
        <tbody data-cy="SlotRows">{renderSlots}</tbody>
      </table>
      </Spin>
    </section>
  )
}

export default Roster
