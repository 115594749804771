import React, { useEffect } from "react"
import { useStateIfMounted } from "use-state-if-mounted"
import { fetch, headers, store, Emitter, REQ, a  } from "../store/psrs"
import { useMyContext, useAbilityContext } from "../store/useMyContext"
import useSWR, { mutate } from "swr"
import ReactTable from "react-table"

import checkboxHOC from "react-table/lib/hoc/selectTable"

import columns from "./Shifts/_columns"
import {Layout, Tooltip, Button} from "antd"
import _ from "lodash"
import { Search } from "./Admin/fields"
import ShiftAuditModal from "./Audit/ShiftAuditModal"

import "react-table/react-table.css";
import styles from "./Shifts/CheckboxTableStyles.module.css"

const { Content } = Layout
const CheckboxTable = checkboxHOC(ReactTable);

export const Shifts = props => {
  const context = useMyContext()
  const ability = useAbilityContext()
  const payPeriod = store.get("PayPeriod")
  const payPeriodId = payPeriod.id
  const myRole = store.session.get("role")
  //const  query = props.query(payPeriodId)
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()
  let query = props.page === 'AvailableShifts' ? props.query(userTimeZone) :  props.query(payPeriodId)

  const page = props.page
  const me = store.session.get("CurrentUser")


  if (myRole === 'admin' && props.adminQuery) query = props.adminQuery(payPeriodId)
    
  const [loading, setLoading] = useStateIfMounted(false)
 
  const [state, setState] = useStateIfMounted({
    selection: [],
    //timeEntrySelected: [],
    shiftSelected: [],
    selectAll: false, 
    pk: "id",
    model: "shift",
    ids: [],
    initialRecords: undefined,
  })

  const [records, setRecords] = useStateIfMounted(state.initialRecords || [])

  //const [expanded, setExpanded] = useStateIfMounted({})
  const [allDisabled, setAllDisabled] = useStateIfMounted(true)
  const [isAuditModalVisible,setIsAuditModalVisible] = useStateIfMounted({id: 0, visible: false, type: 'audittable'})

  const showAuditModal = () => {
    setIsAuditModalVisible({...isAuditModalVisible, visible: true})  
  } 

  const _props = { state, setState, mutate, url: props.url, width: 300}

  // const initial = session.get(page)
  const fetcher = async url => {

    const method = props.page === 'AvailableShifts' || props.page === "XXAvailableShiftsWB" ? 'GET' : 'POST'
    const body = props.page === 'AvailableShifts' || props.page === "XXAvailableShiftsWB" ? JSON.stringify({}) : JSON.stringify({ query })
    const options = {
      method: method,
      headers: headers(),
      body: body //JSON.stringify({ query }),
    }

    if (method === 'GET') delete options.body

    //console.log('Shifts -> supervisorSQL -> ', supervisorSQL)
    setLoading(true)

    const data = await fetch(props.url, options)
    
    setLoading(false)
    
    if (data && data.length > 0) Emitter.emit("ChangedLockStatus", { locked: data[0].locked })

    const filteredData = state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data
    setRecords(filteredData)

    return filteredData
    //return data
  }


  const updateShift = async ({ field, value, shiftId }) => {
   // console.log("Shifts.js updateShift", field, value, shiftId)
    const body = { id: shiftId }
    body[field] = value

    if (field === 'approved' && value === true ) {
      body['isCollision'] = false
    }

    //const updated = 
    await REQ(`/api/shifts/${shiftId}`, "PATCH", body)

    mutate(query)
    //console.log("updated shift", updated)
  }

  const startBidsAllSelected = async (props ) => {

    const {model, shiftSelected } = props;

    const shiftIds = shiftSelected

    if (model === 'shift') {
      // update shifts to take bids
      await REQ(`/api/resources/shift`, "PATCH", { ids: shiftIds, update: { takingBids: true}})

    } 
    

    setState({...state, selection: [], shiftSelected: [], selectAll: false, })
    mutate(query, data)
  }

  const stopBidsAllSelected = async (props ) => {

    const {model, shiftSelected } = props;

    const shiftIds = shiftSelected
    
    if (model === 'shift') {
      //stop taking bids records
      await REQ(`/api/resources/shift`, "PATCH", { ids: shiftIds, update: { takingBids: false}})
    } 
    
    setState({...state, selection: [], shiftSelected: [], selectAll: false, })
    mutate(query, data)

  }


  // const swrOpts = {revalidateOnFocus: false, initialData: initial && initial.length ? initial : null }
  const swrOpts = { revalidateOnFocus: false }
  const { data } = useSWR(query, fetcher, swrOpts)

  useEffect(() => {
    Emitter.on("ShiftDrawerUpdate", () => {
      //console.log("Emitter.on('ShiftDrawerUpdate', ()", query)
      mutate(query)
    })
    Emitter.on("ShiftDrawerClose", () => {
      //console.log("Emitter.on('ShiftDrawerClose', ()", query)
      mutate(query)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (state.ids) {
      //const matching = _.filter(data, r => state.ids.includes(r.id))
      setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    } else {
       //mutate()
    }
    
   // eslint-disable-next-line
  }, [state.ids, data])

  const tableSort = props.page === 'AvailableShiftsWB' ?
    [
      { id: "payPeriod.end", desc: true },
      //{ id: "employee.fullName", asc: true },
      { id: "supervisor1.fullName", asc: true, desc: false },
      { id: "start", asc: true },
    ] 
  :
    [
      { id: "employee.fullName", asc: true },
      { id: "supervisor1.fullName", asc: true, desc: false },
      { id: "start", asc: true },
    ]

  const isSelected = key => {
    return state.selection.includes(`select-${key}`) ;
  };
  
  const toggleSelection = (key, shift, row) => {
    //console.log(`toggleSelection -> ${key} - ${shift} - ${row}`)
    
    let selection = [...state.selection];
    let shiftSelected = [...state.shiftSelected]

    const keyIndex = selection.indexOf(key);
    const shiftKeyIndex = shiftSelected.indexOf(row.id);

    // check to see if the key exists
    if (keyIndex >= 0 && shiftKeyIndex >=0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
      
      shiftSelected = [
        ...shiftSelected.slice(0, shiftKeyIndex),
        ...shiftSelected.slice(shiftKeyIndex + 1)
      ];
      //delete shiftSelected[shiftKeyIndex]

    } else {
      // it does not exist so add it
      if (state.selectAll) {
        if (row.employeeNumber !== me.number) {
          selection.push(key)
          shiftSelected.push(row.id)
          
        }
      } else {
        selection.push(key)
        shiftSelected.push(row.id)
      }
    
      const disabled = ability.cannot("manageBids", a("Shift", row))
      setAllDisabled(disabled)

    }
   
    // update the state
    setState({...state, selection, shiftSelected, selectAll: false });
  }

  const toggleAll = () => {

    //console.log('toggleAll')

    const selectAll = state.selectAll ? false : true;
    const selection = [];
    const shiftSelected = []

    if (selectAll) {
      const currentRecords = data
      
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
          selection.push(`select-${item.id}`);
          shiftSelected.push(item.id)
          const disabled = ability.cannot("manageBids", a("Shift", item))
          setAllDisabled(disabled)
      });
    }
    setState({ ...state, selectAll, selection, shiftSelected });

  }


  const prepareDataWithStripes = (data) => {
    return data.map((item, index) => ({
      ...item,
      isEven: index % 2 === 0,
    }))
  }

  const fixedData = records ? prepareDataWithStripes(records) : []

  const tableOptions = {
    columns: columns({ ...props, context, ability, updateShift, setIsAuditModalVisible, showAuditModal }),
    multiSort: true,
    defaultPageSize: 100,
    //className : "-striped -highlight",
    className: `-striped -highlight ${styles.tableCustom}`,
    /* defaultSorted: [
      { id: "employee.fullName", asc: true },
      { id: "supervisor1.fullName", asc: true, desc: false },
      { id: "start", asc: true },
    ], */
    defaultSorted: tableSort,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    filterable: true,
    //data: data ? data : [],
    data: fixedData ? fixedData : [],
    loading,
    noDataText: "",
  }

  const checkboxProps = {
    selectAll: state.selectAll,
    isSelected,
    toggleSelection,
    toggleAll,
    selectType: "checkbox",
    getTrProps: (s, r) => {

      const selected = isSelected(r && r.original ? r.original.id : false);

      let exp = false
      /* if (r) {
        if (r.nestingPath[0] in expanded) {
          if (expanded[r.nestingPath[0]] === true) {
              exp = true
          }
        }
      } */
      
      let mystyle = {
        backgroundColor: selected || exp ? "AliceBlue" : "inherit",
      }
      
      if (r) {
        const index = s.page * s.pageSize + r.viewIndex;
        if (index % 2 === 0 )  { 
          mystyle =  { backgroundColor: selected || exp ? "AliceBlue" : "#F7F7F7"}
        }
      }
      
      return {
        // data-qnt is for removing arrow when row has no sub-compoents.  
        //'data-qnt':  page === "AvailableShiftsWB" || page === "AvaialbleShifts" ? 1 : 0, 
        //   style: {
        //    backgroundColor: selected || exp ? "AliceBlue" : "inherit"
        //  } 
        style: mystyle

      }
    } 
  };

  // added layout/content for scrolling on small screens
  return (
    <>
      <Layout className="RecordsViewer">
        <div>
          <Search {..._props} />
             {'   '}
             { page === 'AvailableShiftsWB' && 
                <Tooltip title='Allow Bids on All Selected'>
                    <Button
                      icon="check"
                      disabled={state.selection.length === 0 || allDisabled}
                      type="primary"
                      size="small"
                      onClick={() => {
                        const shiftData = state.shiftSelected.map( el => records.find( r => el === r.id && !r.takingBids)).filter(notUndefined => notUndefined !== undefined)
                        startBidsAllSelected({ model:"shift", selection: state.selection.find(e => e === shiftData.id), shiftSelected: state.shiftSelected })
                      }}
                    >
                      Taking Selected Bids
                    </Button>
                  
                </Tooltip>
             }
                {'   '}
                { page === 'AvailableShiftsWB' && 
                  <Tooltip title='Stop Allowing Bids on All Selected'>
                    <Button
                      icon="stop"
                      disabled={state.selection.length === 0 || allDisabled}
                      type="danger"
                      size="small"
                      onClick={() => {
                        const shiftData = state.shiftSelected.map( el => records.find( r => el === r.id && r.takingBids)).filter(notUndefined => notUndefined !== undefined)
                        stopBidsAllSelected({ model:"shift", selection: state.selection.find(e => e === shiftData.id), shiftSelected: state.shiftSelected  })
                      }}
                    >
                      Stop Taking Selected Bids
                    </Button>

                </Tooltip>
                }
              {'   '}
        </div>
        <Content className="Content">
          <CheckboxTable 
            //style={{height: '94%'}}
            keyField="id"
            {...tableOptions } 
            {...checkboxProps}
          />
          <ShiftAuditModal 
            centered = {true}
            confirmLoading = {true}
            destroyOnClose={true}
            //zIndex = {-1}
            shiftId = { isAuditModalVisible.id }
            id= { isAuditModalVisible.id }
            isAuditModalVisible = { isAuditModalVisible.visible }
            setIsAuditModalVisible = { setIsAuditModalVisible } 
            showAuditModal = { showAuditModal }
            context = {context}
            shift = {data ? data : records ? records : []}
            data= {data ? data : records ? records : []}
            modelName="Shift"
            type = {isAuditModalVisible.type}
          />
        </Content>
      </Layout>
    </>)
}

export default Shifts
