import React, {useState, useEffect} from "react"
import _ from "lodash"
// import useMyContext from "../store/useMyContext"
import AssignmentSelect from "./Forms/AssignmentSelect"
import BusinessUnitSelect from "./Forms/BusinessUnitSelect"
import CheckBox from "./Forms/CheckBox"
import DateRangeFilter from "./Forms/DateRangeFilter"
import DateTimeFilter from "./Forms/DateTimeFilter"
import DateTimePicker from "./Forms/DateTimePicker"
import DurationField from "./Forms/DurationField"
import DurationSelect from "./Forms/DurationSelect"
import EmployeeSelect from "./Forms/EmployeeSelect"
import EndTimeField from "./Forms/EndTimeField"
import EquipmentSelect from "./Forms/EquipmentSelect"
import InlineEdit from "./Forms/InlineEdit"
// import JobPill from "./Forms/JobPill"
import LeaveBalanceSelect from "./Forms/LeaveBalanceSelect"
import PayPeriodRange from "./Forms/PayPeriodRangeSelector"
import PayPeriodSelect from "./Forms/PayPeriodSelect"
import PayTypeSelect from "./Forms/PayTypeSelect"
import ReasonSelect from "./Forms/ReasonSelect"
import ShortDaySelect from "./Forms/ShortDayFields"
import SpecialtySelect from "./Forms/SpecialtySelect"
import StartTimeField from "./Forms/StartTimeField"
import StringFilter from "./Forms/StringFilter"
import SubLedgerSelect from "./Forms/SubLedgerSelect"
import SupervisorSelect from "./Forms/SupervisorSelect"
import TagSelect from "./Forms/TagSelect"
// import TeamSelect from "./Forms/TeamSelect"
import TextInput from "./Forms/TextInput"
import TimeField from "./Forms/TimeField"
import TriToggle from "./Forms/TriToggle"
import WeekdayPicker from "./Forms/WeekdayPicker"
import Json from 'react-json-pretty'
import 'react-json-pretty/themes/monikai.css'


export const FormComponents = (p) => {
  const V = { value: null, rvalue: null }
  const storedState = JSON.parse(localStorage.getItem('FormComponents'))
  const defaultState = {
    RESOURCE1: {...V},
    RESOURCE2: {...V},
    RESOURCE3: {...V},
    RESOURCE4: {...V},
    RESOURCE5: {...V},
    RESOURCE6: {...V},
    RESOURCE7: {...V},
    RESOURCE8: {...V},
    RESOURCE9: {...V},
    RESOURCE10: {...V},
    RESOURCE11: {...V},
    RESOURCE12: {...V},
    INPUT1: {...V},
    INPUT2: {...V},
    INPUT3: {...V},
    INPUT4: {...V},
    INPUT5: {...V},
    DATE1: {...V},
    DATE2: {...V},
    DUR1: {...V},
    DUR2: {...V},
    TIME1: {...V},
    TIME2: {...V},
    TIME3: {...V},
    RANGE1: {...V},
    RANGE2: {...V, value: { payPeriodStartId: null, payPeriodEndId: null }},
    TEMPLATE1: {...V},
    TEMPLATE2: {...V},
  }
  const [state, setState] = useState(storedState || defaultState)

  useEffect(() => {
  }, [])

  const Row = C => {
    const Component = C.c
    const rValue = state[C._id].rvalue
    // const pretty = rValue ? ppJSON.toHtml(rValue, {indent: 2}) : ""
    return (
      <section>
        <header>{`${C.example}`}</header>
        <dl className="component"> <Component {...C} value={state[C._id].value} /></dl>
        {rValue && <dl className="returnValue"> <Json data={rValue} /> </dl> }
      </section>
    )
  }

  const handleChange = async (args) => {
    //console.log('handleChange', args)
    if (args._id) {
      const _state = {...state }
      _state[args._id].value = args.value
      _state[args._id].rvalue = _.omit(args, ['example', 'about'])
      localStorage.setItem('FormComponents', JSON.stringify(_state))
      setState(_state)
    }
  }


  const P = {
    handleChange,
    about: "So ...",
    example: "<Example />"
  }

  return (
    <div className="FormComponents">
      <Row _id="RESOURCE1" c={AssignmentSelect} {...P} example="<AssignmentSelect />" />
      <Row _id="RESOURCE2" c={BusinessUnitSelect} {...P} example="<BusinessUnitSelect />" />
      <Row _id="RESOURCE3" c={EmployeeSelect} {...P} example="<EmployeeSelect />" />
      <Row _id="RESOURCE4" c={SupervisorSelect} {...P} example="<SupervisorSelect />" />
      <Row _id="RESOURCE5" c={PayTypeSelect} {...P} example="<PayTypeSelect />" />
      <Row _id="RESOURCE6" c={ReasonSelect} {...P} example="<ReasonSelect />" />
      <Row _id="RESOURCE7" c={EquipmentSelect} {...P} example="*<EquipmentSelect />" />
      <Row _id="RESOURCE8" c={TagSelect} {...P} example="*<TagSelect />" />
      <Row _id="RESOURCE9" c={SubLedgerSelect} {...P} example="<SubLedgerSelect />" />
      <Row _id="RESOURCE10" c={SpecialtySelect} {...P} example="<SpecialtySelect />" />
      <Row _id="RESOURCE11" c={LeaveBalanceSelect} {...P} example="<LeaveBalanceSelect />" />
      <Row _id="RESOURCE12" c={PayPeriodSelect} {...P} example="<PayPeriodSelect />" />

      <Row _id="INPUT1" c={InlineEdit} {...P} example="<InlineEdit />" />
      <Row _id="INPUT2" c={TextInput} {...P} example="<TextInput />" />
      <Row _id="INPUT3" c={CheckBox} {...P} example="<CheckBox />" />
      <Row _id="INPUT4" c={TriToggle} {...P} example="*<TriToggle />" />
      <Row _id="INPUT5" c={StringFilter} {...P} example="<StringFilter />" />

      <Row _id="DATE1" c={DateTimeFilter} {...P} example="<DateTimeFilter />" />
      <Row _id="DATE2" c={DateTimePicker} {...P} example="<DateTimePicker />" />

      <Row _id="DUR1" c={DurationField} {...P} example="<DurationField />" />
      <Row _id="DUR2" c={DurationSelect} {...P} example="<DurationSelect />" />

      <Row _id="TIME1" c={EndTimeField} {...P} example="*<EndTimeField />" />
      <Row _id="TIME2" c={StartTimeField} {...P} example="<StartTimeField />" />
      <Row _id="TIME3" c={TimeField} {...P} example="*<TimeField />" />

      <Row _id="RANGE1" c={DateRangeFilter} {...P} example="*<DateRangeFilter />" />
      <Row _id="RANGE2" c={PayPeriodRange} {...P} example="<PayPeriodRange />" />

      <Row _id="TEMPLATE1" c={WeekdayPicker} {...P} example="*<WeekdayPicker />" />
      <Row _id="TEMPLATE2" c={ShortDaySelect} {...P} example="<ShortDaySelect />" />
    </div>
  )

}

export default FormComponents
