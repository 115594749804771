import React from "react"
import {Switch, Icon } from "antd"

export const FilterToggle = props => {

    const {onChange, checked, label, checkOn, iconType} = props
    
    const isSup = label.toLowerCase().includes("supervisor")

    let switchStyle = {
        color: checked ? checkOn : ""
    }

   return ( 
      <>
        <Switch 
            size="small" 
            checked={checked} 
            onChange={onChange} 
        /> <Icon 
              type={iconType}
              style={ switchStyle }
              theme={isSup ? "" : checked ? "filled" : "outlined"} 
            />  {label}      
      </>)
}

export default FilterToggle