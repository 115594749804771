import React from "react"
import {Input} from "antd"

export const TextInput = (props) => {
  const {field, value, handleChange, disabled, id, rows} = props

  const inputProps = {
    rows: rows? rows : 3,
    disabled: disabled || false,
    defaultValue: value,
    onBlur: (e) => {
      const val = e.target.value
      const sql = `"${field}" IS NOT NULL AND "${field}" ILIKE '%${val}%'`
      handleChange({...props, field, id, value: val, sql})
    }
    // onChange: (e) => {
    //   console.log("onChange", e.target.value)
    // },
    // onPressEnter: (e) => {
    //   console.log("onPressEnter", e.target.value)
    // }
  }

  return <Input.TextArea data-cy="textarea" {...inputProps} />
}

export default TextInput
