import React from "react"
import {Input} from "antd"

export const DurationField = (props) => {
  const {size, field, handleChange, id, disabled, step, noLabel} = props
  const value = props.value
  const precision = props.precision || 2
  const options = {
    disabled: disabled || false,
    onBlur: (e) => {
      const val = e.target.value
      handleChange({...props, field, value: val, id})
    },
    // onChange: (e) => console.log(e.targetValue),
    // defaultValue: value ? value : defaultValue,
    type: "number",
    step: step || ".1",
    precision: precision,
    size: size || "default",
  }
  if (!noLabel) options.addonAfter = "HRS"
  if (disabled) options.value = value
  if (!disabled) options.defaultValue = value

  // if (field === "diveHours") {
  //   console.log(options.defaultValue, value)
  // }

  // if (disabled) options.value = value
  // if (!disabled) options.defaultValue = value ? value : 12

  return <Input data-cy="duration-input" className={props.className} {...options} />
}

export default DurationField
