import React, { useState, useEffect } from "react"
import toggle from "toggle-in-array"

export const WeekdayPicker = props => {
  const { disabled, labels, handleChange } = props
  const record = props.record || { id: 0 }
  // const id = record.id
  const [state, setState] = useState({
    mounted: false,
    firstWeekDays: record.firstWeekDays || [],
    secondWeekDays: record.secondWeekDays || [],
  })

  const { firstWeekDays, secondWeekDays } = state

  const click = (week, weekDayInt) => {
    if (disabled) return
    // TOGGLE THE DAY IN THE FIRST WEEK DAYS
    if (week === 1) setState({ ...state, firstWeekDays: toggle(firstWeekDays, weekDayInt) })
    // TOGGLE THE DAY IN THE SECOND WEEK DAYS
    if (week === 2) setState({ ...state, secondWeekDays: toggle(secondWeekDays, weekDayInt) })
    // ADD OR REMOVE THE DATES TO SCHEDULE OR DESTROY
    // if (modelName === "slot") {
    //   for (let day of record.firstWeekDays) forRange(day, 1)
    //   for (let day of record.secondWeekDays) forRange(day, 2)
    //   record.firstWeekDays = AR.returnUnique(record.firstWeekDays)
    //   record.secondWeekDays = AR.returnUnique(record.secondWeekDays)
    // }
    // REQ(`/api/resources/${modelName}/${id}`, "PATCH", {...record})
  }

  const toggleAllFirstWeekDays = () => {
    if (disabled) return
    if (firstWeekDays.length > 0) setState({ ...state, firstWeekDays: [] })
    else setState({ ...state, firstWeekDays: [0, 1, 2, 3, 4, 5, 6] })
  }

  const toggleAllSecondWeekDays = () => {
    if (disabled) return
    if (secondWeekDays.length > 0) setState({ ...state, secondWeekDays: [] })
    else setState({ ...state, secondWeekDays: [0, 1, 2, 3, 4, 5, 6] })
  }

  useEffect(() => {
    if (state.mounted) {
      handleChange({ ...props, value: { firstWeekDays, secondWeekDays }, id: record.id })
    } else {
      setState({ ...state, mounted: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.firstWeekDays, state.secondWeekDays])

  return (
    <div data-cy="weekdaypicker" className={`WeekDayPicker ${disabled && "disabled"}`}>
      <div className="week firstWeek">
        {labels && (
          <div data-cy="all-first-days" className="label first" onClick={toggleAllFirstWeekDays}>
            all
          </div>
        )}
        <div data-cy="first-sun" onClick={() => click(1, 0)} className={firstWeekDays.includes(0) ? "on" : "off"}>
          Su
        </div>
        <div data-cy="first-mon" onClick={() => click(1, 1)} className={firstWeekDays.includes(1) ? "on" : "off"}>
          M
        </div>
        <div data-cy="first-tue" onClick={() => click(1, 2)} className={firstWeekDays.includes(2) ? "on" : "off"}>
          T
        </div>
        <div data-cy="first-wed" onClick={() => click(1, 3)} className={firstWeekDays.includes(3) ? "on" : "off"}>
          W
        </div>
        <div data-cy="first-thu" onClick={() => click(1, 4)} className={firstWeekDays.includes(4) ? "on" : "off"}>
          Th
        </div>
        <div data-cy="first-fri" onClick={() => click(1, 5)} className={firstWeekDays.includes(5) ? "on" : "off"}>
          F
        </div>
        <div data-cy="first-sat" onClick={() => click(1, 6)} className={firstWeekDays.includes(6) ? "on" : "off"}>
          S
        </div>
      </div>
      <div className="week secondWeek">
        {labels && (
          <div data-cy="all-second-days" className="label second" onClick={toggleAllSecondWeekDays}>
            all
          </div>
        )}
        <div data-cy="second-sun" onClick={() => click(2, 0)} className={secondWeekDays.includes(0) ? "on" : "off"}>
          Su
        </div>
        <div data-cy="second-mon" onClick={() => click(2, 1)} className={secondWeekDays.includes(1) ? "on" : "off"}>
          M
        </div>
        <div data-cy="second-tue" onClick={() => click(2, 2)} className={secondWeekDays.includes(2) ? "on" : "off"}>
          T
        </div>
        <div data-cy="second-wed" onClick={() => click(2, 3)} className={secondWeekDays.includes(3) ? "on" : "off"}>
          W
        </div>
        <div data-cy="second-thu" onClick={() => click(2, 4)} className={secondWeekDays.includes(4) ? "on" : "off"}>
          Th
        </div>
        <div data-cy="second-fri" onClick={() => click(2, 5)} className={secondWeekDays.includes(5) ? "on" : "off"}>
          F
        </div>
        <div data-cy="second-sat" onClick={() => click(2, 6)} className={secondWeekDays.includes(6) ? "on" : "off"}>
          S
        </div>
      </div>
    </div>
  )
}

export default WeekdayPicker
