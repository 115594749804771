import React from "react"
import { Button, Popconfirm, Tooltip, Tag } from "antd"
import moment from "moment"
import {REQ, a, session} from "../../store/psrs"
//import { CheckBox } from "grommet"
import { ApprovalFinePrint } from "../FinePrint"

import { CheckRequiredfields, ImbalancedMessage, RequiredFieldMessage, getTimeEntryShiftNotes } from "../ShiftDrawer/CheckRequiredFields"
import { ShiftGridStatus } from "../Forms"

export const timeEntryColumns = (props) => {

  const {ability, context, refresh} = props
  //console.log('timeEntryColumns', ability);

  const validateFields = async (shift) => {
    let valid = true

    const { checkFields, okSubmit } = await CheckRequiredfields(shift)

    if (!okSubmit) {
      valid = false 
      const message = `Required Fields Missing. Please open the Shift Drawer and update the required fields for Shift`
      RequiredFieldMessage(shift,checkFields,message)
    }

    if (shift.imbalanced ) {
      valid = false
      ImbalancedMessage(shift)
    } 

    return valid
  }

  const approveEntry = async (model, id, value, timeEntry) => {
    const approvedByNumber = value ? session.get("CurrentUser").number : null

    const shiftFields = await getTimeEntryShiftNotes(timeEntry.shiftId)
    const valid = await (validateFields(shiftFields))  
    if (valid) {
      await REQ(`/api/resources/timeEntry/${id}`, "PATCH", { approved: value, rejected: false, approvedByNumber: approvedByNumber})
    }
    refresh()
  }

  const rejectEntry = async (model, id, value) => {
    const approvedByNumber = value ? session.get("CurrentUser").number : null

    await REQ(`/api/resources/timeEntry/${id}`, "PATCH", { approved: false, rejected: value,approvedByNumber: approvedByNumber })
    refresh()
  }

  return [
    {
      Header: "Employee",
      headerClassName: "alignLeft",
      sortable: true,
      show: false,
      accessor: "employee.fullName",
      Cell: row => (row.value ? row.value : "unassigned"),
    },
    {
      Header: "Date Of",
      headerClassName: "alignLeft",
      sortable: true,
      accessor: "start",
      Cell: row => moment(row.value).format("ddd MMM Do"),
    },
    {
      Header: "Hours",
      sortable: true,
      accessor: "duration",
      //Cell: row => `${parseFloat(row.value).toPrecision(3)} Hrs`,
      Cell: row => `${parseFloat(row.value).toFixed(2)} Hrs`,
    },
    {
      Header: "Supervisor",
      headerClassName: "alignLeft",
      sortable: true,
      accessor: "supervisor1.fullName",
      //Cell: row => row.value,
      Cell: row => {

        const s1 = row && row.original && row.original.supervisor1 && row.original.supervisor1.number ? row.original.supervisor1.number : ''
        const s2 = row && row.original && row.original.supervisor2 && row.original.supervisor2.number ? row.original.supervisor2.number : ''
        const s3 = row && row.original && row.original.supervisor3 && row.original.supervisor3.number ? row.original.supervisor3.number : ''

        const s1Name = row && row.original && row.original.supervisor1 && row.original.supervisor1.lastName ? row.original.supervisor1.lastName : ''
        const s2Name = row && row.original && row.original.supervisor2 && row.original.supervisor3.lastName ? row.original.supervisor2.lastName : ''
        const s3Name = row && row.original && row.original.supervisor3 && row.original.supervisor3.lastName ? row.original.supervisor3.lastName : ''

        return (
        <Tooltip title={
          <small><ol>
            <li>Supervisor 1: {s1} / {s1Name}</li>
            <li>Supervisor 2: {s2} / {s2Name}</li>
            <li>Supervisor 3: {s3} / {s3Name}</li>
          </ol></small>
          }>
            <Tag style={{fontSize: 'small'}} >{row.value}</Tag>
        </Tooltip>)
      }
    },
    {
      Header: "Pay Type Code",
      accessor: "payTypeCode",
      sortable: true,
      Cell: row => <Tag style={{fontSize: 'small'}} >{row.original.payTypeCode}</Tag>,
    },
    {
      Header: "Pay Type",
      accessor: "payType.description",
      sortable: true,
      Cell: row => <Tag style={{fontSize: 'small'}} >{row.original.payType.description}</Tag>,
    },
    {
      Header: "Status",
      width: 260,
      accessor: "id",
      sortable: false,
      Cell: row => ShiftGridStatus({model: 'entry', record: row.original}),
    },
    /* {
      Header: "Unpaid Lunch",
      accessor: "paidLunch",
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => <> <CheckBox disabled={true} checked={!cell.value}></CheckBox></>,
    }, */
    {
      Header: "",
      accessor: "shiftId",
      width: 200,
      className: "justify-around",
      sortable: false,
      Cell: row => {
        const rec = row.original
        const disabled = ability.cannot("approve", a("TimeEntry", rec))
        return (
          <Button.Group>
            {/* IF SUPERVISOR OR ADMIN */}
            {/*<Button
              disabled={disabled}
              value={rec.id}
              className={!!row.original.approved ? "approved" : ""}
              icon="like"
              type={rec.approved ? "primary" : "default"}
              onClick={e => approveEntry("timeEntry", rec.id)}
            />
            <Button
              disabled={disabled}
              value={rec.id}
              className={!!row.original.rejected ? "rejected" : ""}
              icon="dislike"
              type={rec.rejected ? "primary" : "default"}
              onClick={e => rejectEntry("timeEntry", rec.id)}
            />*/}

            {/* APPROVED */}
             {/* APPROVE */}  
             <Tooltip title={`${rec.approved ? "Un Approve" : "Approve"}`}>
              	<Popconfirm
                	title={rec.approved ? "Un Approve Time Entry" : <ApprovalFinePrint />}
                	onConfirm={ e => 
                    //approveEntry("shift", rec.id)}
                   (!rec.rejected) && approveEntry("timeEntry", rec.id, !rec.approved, rec)}
                	okText={rec.approved ? "Un Approve" : "Approve"}
                	cancelText="Cancel"
              	>
                	<Button
                  	icon="like"
                  	disabled={disabled}
                  	type={rec.approved ? "primary" : "default"}
                	/>
              	</Popconfirm>
            	</Tooltip>

              {/* REJECT */}
              <Tooltip title={`${rec.rejected ? "Un Reject" : "Reject"}`}>
              	<Popconfirm
                	title={rec.approved ? "Un Reject Time Entry" : <ApprovalFinePrint />}
                	onConfirm={ e => 
                    //approveEntry("shift", rec.id)}
                   (!rec.approved) && rejectEntry("timeEntry", rec.id, !rec.rejected )}
                	okText={rec.rejected ? "Un Rject" : "Reject"}
                	cancelText="Cancel"
              	>
              <Button
                className={!!row.original.rejected ? "rejected" : ""}
                icon="dislike"
                disabled={disabled}
                type={rec.rejected ? "primary" : "default"}
              />
              	</Popconfirm>
            	</Tooltip>

            <Button
              value={rec.shiftId}
              icon="eye"
              onClick={e => {
                context.setShiftDrawerId(row.value)
              }}
            >
              View
            </Button>
          </Button.Group>
        )
      },
    },
  ]
}

export default timeEntryColumns
