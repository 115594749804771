import React from "react"
import * as Sentry from "@sentry/browser"
import RobotError from "../svg/robot.svg"



export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {error: null}

    this.divContainer = { 
      height: "100%",
      width: "100%",
      position: "relative",
      padding: '50px',

      //"border": "3px solid green" 
    }

    this.divProps = {
      margin: "0",
      position: "absolute",
      top: "50%",
      left: "50%",
      padding: '50px',
      "-ms-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)"
    }

    this.divCenter = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "200px",
      //"border": "3px solid green" 
    }

    this.img = {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      width: 200,
      height: 200,
      //width="200" height="200"
    }
    
  }


  

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {error: error}
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error})
    //Sentry.init({ dsn: "https://01d9ee26a0c147beb81e57f009876b5a@sentry.io/1388791" })
    Sentry.init({ dsn: "https://01d9ee26a0c147beb81e57f009876b5a@o230320.ingest.sentry.io/1388791" })

     Sentry.withScope((scope) => {
       Object.keys(errorInfo).forEach((key) => {
         scope.setExtra(key, errorInfo[key])
       })
       Sentry.captureException(error)

     })
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI

      
      return (
          <>
            <div style={this.divContainer}>
              <table style={this.divCenter}>
                <tbody style={{ position: 'relative', textAlign: "center"}} >
                  {/* <td style={{ textAlign: "center"}}> */}
                    <tr><td>{' '}</td></tr>
                    <tr><td>{' '}</td></tr>
                    <tr><td>{' '}</td></tr>
                    <tr><td>{' '}</td></tr>
                    <tr><td>{' '}</td></tr>
                    <tr><td>{' '}</td></tr>
                    <tr><td>{' '}</td></tr>
                    <tr><td>{' '}</td></tr>
                    <tr>
                      <td>
                      <img style={this.img} src={RobotError} alt="Error" />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h1>Oops!! Looks like you have found an issue before we did</h1>
                      </td>
                    </tr>
                    <tr><td>{' '}</td></tr>
                    <tr >
                      <td>
                        <h2>Please Logout and Log Back in</h2>
                      </td>
                    </tr>
                    <tr><td>{' '}</td></tr>
                    <tr>
                      <td>The Enterprise Technlogy Team has been notified and looking into the issue</td>
                    </tr>
                    <tr><td>{' '}</td></tr>
                  {/* </td> */}
                </tbody>
              </table>
            </div>
          </>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
