import React, { useState } from "react";
//import Paragraph from "antd/es/typography/Paragraph";
import { Tooltip, Typography } from "antd";

const { Paragraph } = Typography

const TooltipParagraph = ({
  children,
  ellipsis,
  ...props
}) => {
  const [truncated, setTruncated] = useState(true);

  const handleEllipsis = (isTruncated) => {
    setTruncated(isTruncated)
  }
  
  return (
    <Tooltip title={truncated ? children : undefined}>
      <Paragraph
        {...props}
        ellipsis={{ ...ellipsis, onEllipsis: handleEllipsis }}
      >
        {/* NOTE: Fragment is necessary to avoid showing the title */}
        <>{children}</>
      </Paragraph>
    </Tooltip>
  );
};

export default TooltipParagraph;