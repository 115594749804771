import auth0 from "auth0-js"
import { subject as A } from "@casl/ability"
import EventEmitter from "eventemitter3"
import __ from "lodash"
import Store from "store2"
import filesize from "filesize"
// indexedDB - WIP import Localbase from 'localbase'

const DEBUG = false
const SessionKeys = ["CurrentUser", "role"]

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID

const Auth0ptions = {
  domain: auth0Domain, //"dev-4zomju7t.auth0.com",
  clientID: auth0ClientId, //"f8BmF8ipublfwBxx2fGH6N6jj2G7J1tD",
  redirectUri: `${window.location.origin}`,
  returnTo: `${window.location.origin}`,
  responseType: "id_token",
  _disableDeprecationWarnings: true,
}
const Auth0 = new auth0.WebAuth(Auth0ptions)

// indexedDB - WIP let db = new Localbase('db')
// indexedDB - WIP db.config.debug = false


export const a = A
export const _ = __
export const store = Store
// indexedDB - WIP export const idb = db
export const session = store.session
export const AppDataKeys = [
  "Assignments",
  "BusinessUnits",
  "Employees",
  "Gizmos",
  "PayPeriod",
  "PayPeriods",
  "PayPeriodToday",
  "PayPeriodNext",
  "PayPeriodPrev",
  "PayTypes",
  "Reasons",
  "Specialties",
  "SubLedgers",
  "Supervisors",
  "Tags",
]
export const isLegit = x => {
  if (!x) return false
  if (_.isArray(x)) return !!x.length
  if (_.isObject(x)) return !!_.keys(x).length
  if (_.isString(x)) return !!x.length
}
export const ValidAppData = () => {
  const Store = store.getAll()
  for (let key of AppDataKeys) {
    if (!isLegit(Store[key])) {
      DEBUG && console.log("NOT LEGIT STORE", key)
      return false
    }
  }
  return true
}
export const ValidSession = () => {
  const Session = session.getAll()
  for (let key of SessionKeys) {
    if (!isLegit(Session[key])) {
      DEBUG && console.log("NOT LEGIT SESSION", key)
      return false
    }
  }
  return true
}
export const ValidAppState = () => {
  // DEBUG && console.log(`ValidAppState ${ValidSession()} ${ValidAppData()}`);
  return !!ValidSession() && !!ValidAppData()
}
export const Emitter = new EventEmitter()
export const emit = (name, payload) => {
  Emitter.emit(name, payload)
}
export const getRecords = CollectionName => {
  return store.get(CollectionName)
}

export const getPayTypeRecord = (CollectionName, value, key) => {
  const Records = store.get(CollectionName)
  const Record = Records ? _.find(Records, r => r[key] === value.toString()) : {}
  return Record
}

export const getRecord = (CollectionName, value, key = "id") => {
  const Records = store.get(CollectionName)
  const Record = _.find(Records, r => r[key] === value)
  return Record
}
export const getCurrentPayPeriod = () => {
  const PayPeriods = store.get("PayPeriods")
  return _.find(PayPeriods, { id: PayPeriods[0].todayId })
}
export const updateAssignments = async () => {
  const r = await REQ("/api/resources/assignment")
  //if (_.isArray(r) && r.length) store.set("Assignments", r)
  if (_.isArray(r) && r.length) store.set("Assignments", _.filter(r, { forever: true}))

  /* indexedDB - WIP 
  //db.collection('Assignments').delete()

  if (_.isArray(r) && r.length) {
    for (let [index, val] of r.entries()) {
      db.collection('Assignments').add(val,index.toString())
    }
  }
  */
  return r
}
export const updateBusinessUnits = async () => {
  const r = await REQ("/api/resources/businessUnit")
  if (_.isArray(r) && r.length) store.set("BusinessUnits", _.filter(r, { included: true }))

  /* indexedDB - WIP 
  //db.collection('BusinessUnits').delete()

  const activeBU = _.filter(r, { included: true })

  if (_.isArray(r) && r.length) {
    for (let [index, val] of activeBU.entries()) {
      db.collection('BusinessUnits').add(val,index.toString())
    }
  }
*/
  return r
}
export const updateEmployees = async () => {
  const r = await REQ("/api/employees?min=true")
  if (_.isArray(r) && r.length) {
    let employees = r.map(e => {
      return _.pick(e, ["fullName", "number", "jobTitle", "firstName", "lastName", "role","businessUnitCode"])
    })

    // remove disabled employees from here
    // sort by label (fullName)
    ////employees = _.sortBy(employees, ['fullName']) 

    // remove any employee with role of disabled
    ////employees = _.filter(employees, e => e.role !== "disabled")


    const supervisors = _.filter(employees, { role: "supervisor" })
    const admins = _.filter(employees, { role: "admin" })
    let supers = [...supervisors, ...admins]

    // remove disabled employees from here
    // sort by label (fullName)
    ////supers = _.sortBy(supers, ['fullName']) 

    // remove any employee with role of disabled
    ////supers = _.filter(supers, e => e.role !== "disabled")

    store.set("Employees", employees)
    store.set("Supervisors", supers)
  }
}
export const updateEquipment = async () => {
  const r = await REQ("/api/resources/gizmo")
  if (_.isArray(r) && r.length) store.set("Gizmos", r)

  /* // indexedDB - WIP 
  //db.collection('Gizmos').delete()
  if (_.isArray(r) && r.length) {
    for (let [index, val] of r.entries()) {
      db.collection('Gizmos').add(val,index.toString())
    }
  }
  */
  return r
}
export const updatePayTypes = async () => {
  const r = await REQ("/api/resources/payType")
  if (_.isArray(r) && r.length) store.set("PayTypes", _.filter(r, { included: true }))

  /* // indexedDB - WIP 
  //db.collection('PayTypes').delete()
  if (_.isArray(r) && r.length) {
    for (let [index, val] of _.filter(r, { included: true }).entries()) {
      db.collection('PayTypes').add(val,index.toString())
    }
  }
  */

  return r
}
export const updateReasons = async () => {
  const r = await REQ("/api/resources/reason")
  if (_.isArray(r) && r.length) store.set("Reasons", r)

  /* // indexedDB - WIP 
  //db.collection('Reasons').delete()
  if (_.isArray(r) && r.length) {
    for (let [index, val] of r.entries()) {
      db.collection('Reasons').add(val,index.toString())
    }
  }
  */

  return r
}
export const updateSpecialties = async () => {
  const r = await REQ("/api/resources/specialty")
  if (_.isArray(r) && r.length) store.set("Specialties", r)

  /* // indexedDB - WIP 
  //db.collection('Specialties').delete()
  if (_.isArray(r) && r.length) {
    for (let [index, val] of r.entries()) {
      db.collection('Specialties').add(val,index.toString())
    }
  }
  */
  return r
}
export const updateSubLedgers = async () => {
  const r = await REQ("/api/resources/subLedger")
  if (_.isArray(r) && r.length) store.set("SubLedgers", r)
  
  /* // indexedDB - WIP 
  //db.collection('SubLedgers').delete()
  if (_.isArray(r) && r.length) {
    for (let [index, val] of r.entries()) {
      db.collection('SubLedgers').add(val,index.toString())
    }
  }
  */

  return r
}
export const updateTags = async () => {
  const r = await REQ("/api/resources/tag")
  if (_.isArray(r) && r.length) store.set("Tags", r)

  /* // indexedDB - WIP 
  //db.collection('Tags').delete()
  if (_.isArray(r) && r.length) {
    for (let [index, val] of r.entries()) {
      db.collection('Tags').add(val,index.toString())
    }
  }
  */

  return r
}
export const updatePayPeriods = async () => {
  const payPeriods = await REQ("/api/pay_periods")
  if (_.isArray(payPeriods) && payPeriods.length) {
    const payPeriod = _.find(payPeriods, { id: payPeriods[0].todayId })
    const payPeriodNext = _.find(payPeriods, { id: payPeriod.nextId })
    const payPeriodPrev = _.find(payPeriods, { id: payPeriod.prevId })
    store.set("PayPeriods", payPeriods)
    store.set("PayPeriodToday", payPeriod)
    store.set("PayPeriod", payPeriod)
    store.set("PayPeriodNext", payPeriodNext)
    store.set("PayPeriodPrev", payPeriodPrev)
  }
  return payPeriods
}
export const seedData = async () => {
  DEBUG && console.log("seedData")
  // if (ValidAppData()) return true
  // if (!ValidSession()) return false
  // DEBUG && console.log("Seed App Data")
  await Promise.all([
    updateAssignments(),
    updateBusinessUnits(),
    updateEmployees(),
    updateEquipment(),
    //  updatePayPeriods(),
    updatePayTypes(),
    updateReasons(),
    updateSpecialties(),
    updateSubLedgers(),
    updateTags(),
  ])
}
export const reSeedData = async () => {
  const version = store.get("version")
  const api = store.get("api")
  store.clear()
  store.set("version", version)
  store.set("api", api)
  const role = store.session.get("role")
  if (role === "employee") store.remove("Employees")
  if (role !== "employee") await updateEmployees()
  await Promise.all([
    updateAssignments(),
    updateBusinessUnits(),
    updateEquipment(),
    updatePayPeriods(),
    updatePayTypes(),
    updateReasons(),
    updateSpecialties(),
    updateSubLedgers(),
    updateTags(),
  ])
  return true
}
export const headers = () => {
  return {
    "Content-Type": "application/json",
  }
}
export const reqOptions = {
  headers: {
    "Content-Type": "application/json",
  },
  credentials: "include",
  method: "GET",
  mode: "cors",
}
export const fetch = async (url, options) => {
  const params = { ...reqOptions, ...options }
  DEBUG && console.log("fetch options", params)
  DEBUG && console.log(`fetch url: ${url}`)
  const fetched = await window.fetch(url, params).catch(err => {
    DEBUG && console.log("FETCH error", err)
  })
  if (fetched && fetched.status === 401) {
    ////blw here
    Logout()
    return false
  }
  //session timeout
  if (fetched && fetched.status === 400) {
    ////blw here
    Logout()
    return false
  }
  const res = await fetched.json()
  return res
}
export const REQ = async (route, method = "GET", props) => {
  const URL = `${store.get("api")}${route}`
  const params = { ...reqOptions, method }
  if (props) params.body = JSON.stringify({ ...props })
  const response = await fetch(URL, params)
  if (!response) {
    /////blw here
    Logout()
    return false
  } else {
    DEBUG && console.log("REQ response", response)
    return response
  }
}
export const Host = (() => {
  const LOCATIONS = {
    DEVELOPMENT: "===========================================",

    "192.168.0.7:5000": "https://localhost:5001",
    "localhost:5000": "https://localhost:5001",
    "127.0.0.1:5000": "https://localhost:5001",
    "0.0.0.0:5000": "https://localhost:5001",
    "localhost:5001": "https://localhost:5001",
    "spdemo.enterprisetechnologies.com": "https://spapi.enterprisetechnologies.com",
    "localhost:5002": "https://localhost:5001",
    "mobile-dvopd.schedulerpro.local": "https://opd-dv.schedulerpro.local",
    "dvopd.schedulerpro.local": "https://opd-dv.schedulerpro.local",

    
    "AZURE PRODUCTION": "======================================",
    //"opd.psrs.online": "https://opd-qa.psrs.online",
    //"mobile.opd.psrs.online": "https://opd-pd.psrs.online",
    "opd.azureedge.net": "https://psrs-opd.azurewebsites.net",
    "mobile-opd.azureedge.net": "https://psrs-opd.azurewebsites.net",
    //"opd.enterprisetechnologies.com": "https://psrs-opd.azurewebsites.net",
    //"mobile.opd.enterprisetechnologies.com": "https://psrs-opd.azurewebsites.net",
    "opd.enterprisetechnologies.com": "https://opd-pd.enterprisetechnologies.com",
    "mobile.opd.enterprisetechnologies.com": "https://opd-pd.enterprisetechnologies.com",

    "AZURE QA": "==============================================",
    //"opd1.z22.web.core.windows.net": "https://opd-qa.psrs.online",
    //"qa.opd.psrs.online": "https://opd-qa.psrs.online",
    //"qa.mobile.opd.psrs.online": "https://opd-qa.psrs.online",
    "qa-opd.azureedge.net": "https://psrs-opd-qa.azurewebsites.net",
    "qa-mobile-opd.azureedge.net": "https://psrs-opd-qa.azurewebsites.net",
    //"qa.opd.enterprisetechnologies.com": "https://psrs-opd-qa.azurewebsites.net",
    //"qa.mobile.opd.enterprisetechnologies.com": "https://psrs-opd-qa.azurewebsites.net",
    "qa.opd.enterprisetechnologies.com": "https://opd-qa.enterprisetechnologies.com",
    "qa.mobile.opd.enterprisetechnologies.com": "https://opd-qa.enterprisetechnologies.com",

    "AZURE DV": "==============================================",
    "dv.opd.psrs.online": "https://psrs-opd-dv2.azurewebsites.net",
    "dv-opd.azureedge.net": "https://psrs-opd-dv2.azurewebsites.net", 
    "dv-mobile-opd.azureedge.net": "https://psrs-opd-dv2.azurewebsites.net", 
    "dv.opd.enterprisetechnologies.com": "https://psrs-opd-dv2.azurewebsites.net", 
    "dv.mobile.opd.enterprisetechnologies.com": "https://psrs-opd-dv2.azurewebsites.net", 
    
  }
  const APIHOST = LOCATIONS[window.location.host]
  store.set("api", APIHOST)
  return APIHOST
})()

export const Environment = (() => {
  const LOCATIONS = {
    DEVELOPMENT: "===========================================",
    "192.168.0.7:5000": "Local",
    "localhost:5000": "Local",
    "127.0.0.1:5000": "Local",
    "0.0.0.0:5000": "Local",
    "localhost:5001": "Local",
    "dvopd.schedulerpro.local": "Local",

    "AZURE PRODUCTION": "======================================",
    "opd.azureedge.net": "PD",
    "mobile-opd.azureedge.net": "PD",
    "opd.enterprisetechnologies.com": "PD",
    "mobile.opd.enterprisetechnologies.com": "PD",

    "AZURE QA": "==============================================",
    "qa-opd.azureedge.net": "QA",
    "qa-mobile-opd.azureedge.net": "QA",
    "qa.opd.enterprisetechnologies.com": "QA",
    "qa.mobile.opd.enterprisetechnologies.com": "QA",

    "AZURE DV": "==============================================",
    "dv.opd.psrs.online": "DV",
    "dv-opd.azureedge.net": "DV", 
    "dv-mobile-opd.azureedge.net": "DV", 
    "dv.opd.enterprisetechnologies.com": "DV", 
    "dv.mobile.opd.enterprisetechnologies.com": "DV", 
    
  }
  const APIENV = LOCATIONS[window.location.host]
  store.set("env", APIENV)
  return APIENV
})()

export const CheckMem = () => {
  const mem = window.performance.memory
  const MEM = {
    remaining: filesize(mem.totalJSHeapSize - mem.usedJSHeapSize),
    used: filesize(mem.usedJSHeapSize),
    total: filesize(mem.totalJSHeapSize),
    limit: filesize(mem.jsHeapSizeLimit),
  }
  return console.table(MEM)
}
export const MemCheck = function () {
  // Tune these for your application.
  var MAX_MEMORY_LIMIT = 20 * 1048576 // 20MB
  var MAX_PERCENT_THRESHOLD = 90
  if (!window.performance || !window.performance.memory || !window.requestAnimationFrame || !window.trackJs) {
    return
  }
  var hasAlarmed = false
  requestAnimationFrame(function onFrame() {
    if (performance.memory.usedJSHeapSize > MAX_MEMORY_LIMIT) {
      hasAlarmed = true
      var overage = performance.memory.usedJSHeapSize - MAX_MEMORY_LIMIT
      console.error("Exceeded memory maximum limit by " + overage + " bytes")
    }
    if (performance.memory.usedJSHeapSize > (MAX_PERCENT_THRESHOLD / 100) * performance.memory.jsHeapSizeLimit) {
      hasAlarmed = true
      console.error("Memory usage exceeded " + MAX_PERCENT_THRESHOLD + "% of maximum: " + performance.memory.jsHeapSizeLimit)
    }
    if (!hasAlarmed) {
      requestAnimationFrame(onFrame)
    }
  })
}
export const refreshCache = async history => {
  
}
export const destroySession = async history => {
  const URL = `${store.get("api")}/api/logout`
  const params = { ...reqOptions, method: "GET" }
  //const data = 
  await fetch(URL, params)
}

export const createSession = async history => {
  const id_token = window.location.hash.replace("#/id_token=", "")
  const URL = `${store.get("api")}/api/session`
  const params = { ...reqOptions, method: "POST", body: JSON.stringify({ token: id_token }) }
  DEBUG && console.log("CREATE SESSION FROM id_token", URL, params)
  const data = await fetch(URL, params)
  const storeData = data.store
  const sessionData = data.session
  DEBUG && console.log("SESSION DATA", sessionData)
  store.setAll({ ...storeData })
  session.setAll({ ...sessionData })
  const CurrentPeriod = store.get("PayPeriodToday")
  const { PayPeriods } = storeData
  store.set("PayPeriod", CurrentPeriod)
  store.set(
    "PayPeriodNext",
    _.find(PayPeriods, p => p.id === CurrentPeriod.nextId),
  )
  store.set(
    "PayPeriodPrev",
    _.find(PayPeriods, p => p.id === CurrentPeriod.prevId),
  )

  DEBUG && console.log("ValidAppState", ValidAppState())

  if (ValidAppState()) {
    const role = session.get("role") || false
    // this one is real 
    //const to = { supervisor: "/shifts/direct", admin: "/records", employee: "/shifts/mine" }
    const to = { supervisor: "/shifts/direct", admin: "/payperiodstats", employee: "/shifts/mine" }
    history.replace(to[role])
    return true
  }
}
export const InitAuth0 = async history => {
  log.g("InitAuth0")
  await Auth0.parseHash({ hash: window.location.hash }, async (err, auth) => {
    if (err) {
      log.g("InitAuth0 error")
      Logout()
      // if (session.get("token")) await setCurrentUser()
      return false
    }
    if (auth) {
      DEBUG && console.log("InitAuth0 auth", auth.idTokenPayload)
      // auth.employeeNumber = auth.idTokenPayload.nickname
      // auth.token = `Bearer ${auth.idToken}`
      // session.set("token", auth.token)
      const employeeNumber = auth.idTokenPayload.nickname
      await createSession(employeeNumber)
      session.remove("loggedOut")
      if (ValidAppState()) {
        const role = session.get("role") || false
        const to = { supervisor: "/shifts/direct", admin: "/records", employee: "/shifts/mine" }
        history.replace(to[role])
        return true
      }
    }
  })
}
export const Login = () => {

  cleanUpBrowser()
  
  Auth0.authorize({})
}
export const Logout = () => {
  // window.location.hash = ""

  session.remove("loginConfirmed")
  session.remove("RecordsViewer")
  session.remove("CurrentUser")
  session.remove("Auth0")
  session.remove("token")
  session.remove("role")
  session.remove('sessionTimeOut')
  session.set("loggedOut", true)

  // cleanup new session variables for Records Viewer
  session.remove("SelectAll")
  session.remove("RecordsViewerExcluded")
  session.remove("Indeterminate")
  session.remove("SelectAllTimeEntry")
  session.remove("IndeterminateTimeEntry")
  session.remove("TimeEntryRecsExcluded")
  session.remove("SelectAllShifts")
  session.remove("IndeterminateShift")
  session.remove("ShiftRecsExcluded")

  cleanUpBrowser()
  store.remove('_Teams')
  store.remove('Employee')
  store.remove('_Employee')
  store.remove('Shifts')
  store.remove('ReportsView')
  store.remove('Shift')
  store.remove('env')
  store.remove('shiftDrawerId')
  store.remove('templateDrawerId')

  DEBUG && console.log("Logout")
 
  destroySession(employeeNumber)
  Auth0.logout(Auth0ptions)
  
}
export const log = {
  g: x => {
    // DEBUG && console.log(`%c${x}`, "color:green"),
  },
}
// cleanup stored data and cookies
export const cleanUpBrowser = () => {

  // clean up browser Local Storage
  for (let key of AppDataKeys) {
    store.remove(key)
    store.remove(`_${key}`)
  }

  // Clean up the cookies generated by Auth0 client before
  document.cookie.split('; ').forEach(cookie => {
    const key = cookie.split('=')[0];
  
    if (key.includes('com.auth0.auth.') || (key.includes('psrs'))) {
      document.cookie = key +'=; Max-Age=-99999999;';  
    }
  
  });

}
export const getRole = () => {
  try {
    return session.get("CurrentUser").role
  }
  catch (e) {
    return ''
  }
}

export { Can } from "../components/Can"
const currentUser = store.session.get("CurrentUser")
export const employeeNumber = currentUser ? currentUser.number : null
