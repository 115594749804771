import React, { useState, useEffect } from "react"
import moment from "moment-timezone"
import { Modal } from "antd"
import { fetch, store } from "../../../store/psrs"
import _ from "lodash"

export const StatsModal = props => {

  //const today = moment()
  
  const { statsOpen, setStatsOpen, filters,okText, cancelText } = props
  const [loading, setLoading] = useState(true)
  const [types, setTypes] = useState({})
  const [payCodes, setPayCodes] = useState({})
  const [totalHours, setTotalHours] = useState(0)
  const [totalProcessed, setTotalProcessed] = useState(0)
  const [totalCat, setTotalCat] = useState(0)
  const [totalCode, setTotalCode] = useState(0)

  const formatNumber = (num) => {
    if (typeof num === 'string') {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num
  }

  const preProcCat = (rec) => {
    return ({
      dateFilterStartDate: moment(store.session.get('RecordsViewer').filters.range[0]).format('MM/DD/YYYY'),
      dateFilterEndDate: moment(store.session.get('RecordsViewer').filters.range[1]).format('MM/DD/YYYY'),
      category: rec.category,
      duration: rec.duration
    })
  
  }
  const preProcCode = (rec) => {
    return ({
      dateFilterStartDate: moment(store.session.get('RecordsViewer').filters.range[0]).format('MM/DD/YYYY'),
      dateFilterEndDate: moment(store.session.get('RecordsViewer').filters.range[1]).format('MM/DD/YYYY'),
      category: rec.category,
      payTypeCode: rec.payTypeCode,
      duration: rec.duration
    })
  
  }
  
  const exporter = async (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrDataIn =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;
    
    // preprocess the data    
    var arrData = []

    for (let rec of arrDataIn) {  
     let exportRec = {}
     if (ReportTitle === 'CatByPayCode') {
       exportRec = preProcCode(rec)
     } else {
       exportRec = preProcCat(rec)
     }
  
      arrData.push(exportRec)
          
    }
  
    // end preprocessor
    var CSV = "";
  
    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";
  
      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
  
        // make these look better
        const firstPass = index.replace(/([A-Z])/g, " $1")
        const finalResult = firstPass.charAt(0).toUpperCase() + firstPass.slice(1)
        row += finalResult + ","
        //row += index + ","
      }
  
      row = row.slice(0, -1);
  
      //append Label row with line break
      CSV += row + "\r\n";
    }
  
    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      row = "";
  
      //2nd loop will extract each column and convert it in string comma-seprated
      for ( index in arrData[i]) {
        row += '"' + arrData[i][index] + '",'
      }
  
      row.slice(0, row.length - 1)
  
      //add a line break after each row
      CSV += row + "\r\n";
    }
  
    if (CSV === "") {
      alert("Invalid data")
      return;
    }
  
    //Generate a file name
    var fileName = "export_"
    //this will remove the blank-spaces from the title and replace it with an underscore
    //fileName += ReportTitle.replace(/ /g, "_");
    //_${moment().format('YYYYMMDD_HHMMSS')}
  
    let fileSuffix = moment().format('YYYYMMDD')
    if (store.session.get('RecordsViewer') && store.session.get('RecordsViewer').filters.range) {
      const dt1 = moment(store.session.get('RecordsViewer').filters.range[0]).format('YYYYMMDD')
      const dt2 = moment(store.session.get('RecordsViewer').filters.range[1]).format('YYYYMMDD')
      fileSuffix = `${dt1}_${dt2}`
  
    }
  
    fileName += `${ReportTitle}_${fileSuffix}`.replace(/ /g, "_")
  
    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV)
  
    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension
  
    
    //this trick will generate a temp <a /> tag
    var link = document.createElement("a")
    link.href = uri;
  
    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden"
    link.download = fileName + ".csv"
  
    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link)
    link.click();
    document.body.removeChild(link)
      
  }

  const getStats = async () => {
    setLoading(true)

    const timeEntryPayTypes = []
    const timeEntryPayCategory = []
    let _totalCode = 0
    let _totalCat = 0
    const url = `${store.get("api")}/api/time_entries/stats`
    const body = {
      start: filters.range[0].utc().format('YYYY-MM-DDTHH:mm:ss'),
      end: filters.range[1].utc().format('YYYY-MM-DDTHH:mm:ss'),
    }
    const timeEntryStats = await fetch(url, {method: 'POST', body: JSON.stringify(body)})
    const _payTypeCategories = _.sortBy(timeEntryStats.payTypeCategories, ['category']) 
    _totalCat = 0
    for (let category of _payTypeCategories) {
      timeEntryPayCategory.push( { category: category.category, duration: category['duration'] } )
      const _catDuration=parseFloat(category['duration'])
      _totalCat += _catDuration
    }

    const _payTypeCodes = _.sortBy(timeEntryStats.payTypeCodes, ['payTypeCode']) 
    _totalCode = 0
    for (let code of _payTypeCodes) {
      timeEntryPayTypes.push( { category: code.category, payTypeCode: code.payTypeCode, duration: code['duration'] } )
      const _codeDuration = parseFloat(code['duration'])
      _totalCode += _codeDuration
    }

    setTotalCat( _totalCat.toFixed(2))
    setTotalCode(_totalCode.toFixed(2))
    _totalCode = 0
    _totalCode = 0
    
    
    setTotalHours(timeEntryStats.total)
    setTotalProcessed(timeEntryStats.processed)
    setPayCodes(timeEntryPayTypes)
    setTypes(timeEntryPayCategory)

    

    setLoading(false)
  }
  

  //const timeEntryPayTypes = {}
  const PayTypeCodeRows = () => {
    //console.log("PayTypeRows", payTypeKeys)
    return payCodes.map(key => (
      <tr key={key.category}>
        <td>{key.category}</td>
        <td className='code'>{key.payTypeCode}</td>
        <td className='duration'>{formatNumber(key.duration)}</td>
      </tr>
    ))
  }

  const PayTypeCatRows = () => {
    //console.log("PayTypeRows", payTypeKeys)
    return types.map(key => (
      <tr key={key.category}>
        <td>{key.category}</td>
        <td className='duration'>{formatNumber(key.duration)}</td>
      </tr>
    ))
  }

  useEffect(() => {
    if (statsOpen) getStats()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statsOpen])

  return (
    <Modal
      title={`Time Entry Stats`}
      visible={statsOpen}
      okText={okText}
      onOk={() => {
          exporter(JSON.stringify(payCodes), `CatByPayCode`, true)
          exporter(JSON.stringify(types), `Categories`, true) 
      }}
      cancelText={cancelText}
      onCancel={() => setStatsOpen(false)}
    >
      {loading && <h3>Loading Details ...</h3>}

      {!loading && (
        <div className="statsTable">
          <ul>
            <li>
              <strong>Pay Period</strong> : {filters.range[0].utc().format('MM/DD/YYYY')} - {filters.range[1].utc().format('MM/DD/YYYY')}
            </li>
            <li>
              <strong>Total Processed</strong> : {totalProcessed}
            </li>
            <li>
              <strong>Total Time Entries</strong> : {totalHours}
            </li>

            <li>
              <table className='statsTable'>
                <thead>
                  <tr>
                    <th>Pay Type Category</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <PayTypeCatRows />
                </tbody>
                <tfoot>
                  <tr>
                    <th id="total" colspan="1">Total</th>
                    <td className='duration'><strong>{formatNumber(totalCode)}</strong></td>
                  </tr>
                </tfoot>
              </table>
            </li>
            <li>
              <table className='statsTable'>
                <thead>
                  <tr>
                    <th>Pay Type Category</th>
                    <th>Pay Type Code</th>
                    <th>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  <PayTypeCodeRows />
                </tbody>
                <tfoot>
                  <tr>
                    <th id="total" colspan="2">Total :</th>
                    <td className='duration'><strong>{formatNumber(totalCat)}</strong></td>
                  </tr>
                </tfoot>
              </table>
            </li>
          </ul>
        </div>
      )}
    </Modal>
  )
}
