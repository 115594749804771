import React, { useState, useEffect } from "react"
import { _, session, store } from "../store/psrs"
import useMyContext from "../store/useMyContext"
import queryString from "query-string"
import "dhtmlx"
import "dhtmlx/ext/dhtmlxscheduler_active_links"
import "dhtmlx/ext/dhtmlxscheduler_agenda_view"
import "dhtmlx/ext/dhtmlxscheduler_grid_view"
import "dhtmlx/ext/dhtmlxscheduler_timeline"
import "dhtmlx/ext/dhtmlxscheduler_treetimeline"
import "dhtmlx/ext/dhtmlxscheduler_daytimeline"
import PayPeriodNav from "./PowerView/PayPeriodNav"
import moment from "moment"
import ShiftDrawer from "./ShiftDrawer"
import { Row, Col, Button } from "antd"
import { TagSelect, SupervisorSelect } from "./Forms"
import { REQ } from "../store/psrs"

const scheduler = window.scheduler

export const ScheduleTest = props => {
  const context = useMyContext()
  const currentUser = session.get("CurrentUser")
  const employeeNumber = currentUser.number

  const [params, setParams] = useState({
    tagIds: [],
    supervisorNumber: currentUser.role === "supervisor" ? employeeNumber : currentUser.supervisorNumber,
    payPeriodId: store.get("PayPeriod").id,
  })

  const [state, setState] = useState({
    active: null,
    days: 1,
    event: false,
    loading: false,
    mounted: false,
    scale: 50,
    payPeriod: store.get("PayPeriod"),
    timeline: scheduler.matrix.timeline,
    supervisor: _.find(store.get("Supervisors"), { number: params.supervisorNumber }),
  })

  const [shifts, setShifts] = useState([])

  const setTemplates = () => {
    //console.log("setTemplates")

    scheduler.templates.event_class = function (start, end, evt) {
      let classes = [`scale_${state.scale}`]
      if (evt.approved) classes.push("approved")
      if (evt.worked) classes.push("worked")
      if (evt.isSick) classes.push("sick")
      if (evt.id === state.shiftId) classes.push("lift")
      return classes.join(" ")
    }

    scheduler.templates.event_bar_text = function (start, end, evnt) {
      const shift = evnt
      const { employee } = shift
      const Supervisor = shift.supervisor
      const assignment = shift.assignmentId ? shift.assignment : false
      if (employee && shift) {
        const lastName = employee.lastName
        const firstInitial = `${employee.firstName[1]}.`
        return `
              <div class="shift_bar">
                <div class="left_meta">
                  <span class="bu_code">${shift.businessUnitCode || employee.businessUnitCode}</span>
                  ${assignment ? `<span class="assignment">${assignment.name}</span>` : ``}
                </div>
                <span class="name">
                  <span class="first">${firstInitial} </span>
                  <span class="last">${lastName}</span>
                </span>
                <span class="job_title">${employee.jobTitle}</span>
                ${shift.name ? `<br/><div class="shift_name">${shift.name}</div>` : ``}
                <div class="right_meta">
                  <span class="duration">${shift.duration} <b>hrs</b></span>
                  <span>${Supervisor.lastName}</span>
                </div>
              </div>
            `
      } else {
        return "AHOY ... this is a new event"
      }
    }
    scheduler.templates.timeline_scale_date = function (date) {
      let theDate = moment(date).format("HH")
      return theDate
    }
    scheduler.locale.labels.timeline_scale_header = ""
    // setTimeout(() => scheduler.updateView(), 1000)
  }

  const toggleLoading = (loading, period) => {
    setState({ ...state, loading: loading ? loading : !state.loading })
    if (period) setParams({ ...params, payPeriod: period })
  }

  const selectSupervisor = ({ value }) => {
    //console.log("selectSupervisor", value)
    const supervisorNumber = value ? value : state.supervisor.number
    setState({ ...state, supervisor: _.find(store.get("Supervisors"), { number: supervisorNumber }) })
    setParams({ ...params, supervisorNumber })
  }

  const selectGroup = args => {
    const tagIds = args.value
    //console.log("selectGroup", tagIds)
    setParams({ ...params, tagIds })
  }

  const fetchData = async () => {
    const query = {}
    if (params.tagIds.length) query.tagIds = params.tagIds
    if (params.supervisorNumber) query.supervisorNumber = params.supervisorNumber
    if (params.payPeriodId) query.payPeriodId = params.payPeriodId
    const q = queryString.stringify(query)
    const fetched = await REQ(`/api/shifts/schedule?${q}`)
    if (fetched && !!fetched.length) {
      setState({ ...state, loading: false, mounted: true })
      //console.log("fetchData", fetched)
      const _shifts = fetched.filter(shift => !!shift && !!shift.employee && !!shift.supervisor1 && shift.end && shift.start)
      const Shifts = _shifts.map(shift => {
        return {
          businessUnitCode: shift.businessUnitCode,
          costCenterChargeOutCode: shift.costCenterChargeOutCode,
          duration: shift.duration,
          text: shift.employee.fullName,
          id: shift.i,
          shiftId: shift.id,
          employeeNumber: shift.employeeNumber,
          employee: shift.employee,
          supervisor: shift.supervisor1,
          shifts: 1,
          start_date: `${moment(shift.start).format("MM-DD-YYYY HH:mm")}`,
          end_date: `${moment(shift.end).format("MM-DD-YYYY HH:mm")}`,
          approved: shift.approved,
          worked: shift.worked,
          isSick: shift.isSick,
          fetched: false,
        }
      })
      setShifts(Shifts)
    }
  }

  const handleClick = id => {
    //console.log("handleClick id", id)
    context.setShiftDrawerId(id)
  }

  const attachEvents = () => {
    //console.log("attachEvents")
    for (let id of context.dhtmlxAttachedEventIds) {
      scheduler.detachEvent(id)
    }
    let onClearAll = scheduler.attachEvent("onClearAll", async shifts => {})
    let onBeforeDrag = scheduler.attachEvent("onBeforeDrag", (id, mode, e) => false)
    let onEventDrag = scheduler.attachEvent("onEventDrag", (id, mode, e) => false)
    let onEmptyClick = scheduler.attachEvent("onEmptyClick", (date, e) => false)
    let onDblClick = scheduler.attachEvent("onDblClick", (id, e) => false)
    let onCellDblClick = scheduler.attachEvent("onCellDblClick", (x_ind, y_ind, x_val, y_val, e) => false)
    let onXScaleClick = scheduler.attachEvent("onXScaleClick", () => false)
    let onYScaleClick = scheduler.attachEvent("onYScaleClick", () => false)
    let onSchedulerResize = scheduler.attachEvent("onSchedulerResize", () => false)
    let onClick = scheduler.attachEvent("onClick", handleClick)
    const attachedEvents = [
      onClearAll,
      onBeforeDrag,
      onEventDrag,
      onEmptyClick,
      onDblClick,
      onCellDblClick,
      onXScaleClick,
      onYScaleClick,
      onSchedulerResize,
      onClick,
    ]
    context.dhtmlxSetAttachedEventIds(attachedEvents)
  }

  const changeScale = value => {
    let newScale = state.scale
    if (value === "in" && state.scale <= 90) newScale = state.scale + 10
    if (value === "out" && state.scale >= 40) newScale = state.scale - 10
    setState({ ...state, scale: newScale, update: false })
  }

  useEffect(() => {
    state.mounted && fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  useEffect(() => {
    if (state.mounted) {
      //console.log("Changed Scale")
      if (state.scale > 30) {
        scheduler.matrix.timeline.x_step = 1
        scheduler.matrix.timeline.column_width = state.scale * 0.4
        // scheduler.matrix.timeline.x_size = 24
      }
      if (state.scale <= 30) {
        scheduler.matrix.timeline.x_step = 2
        scheduler.matrix.timeline.column_width = state.scale * 0.8
        // scheduler.matrix.timeline.x_size = 12
      }
      scheduler.setCurrentView()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.scale])

  useEffect(() => {
    scheduler.parse(shifts, "json")
  }, [shifts])

  useEffect(() => {
    setState({ ...state, mounted: true })
    const mode = scheduler.getState().mode
    scheduler.config.start_on_monday = false
    // scheduler.config.api_date = "%m-%d-%Y %H:%i"
    scheduler.config.date_format = "%m-%d-%Y %H:%i"
    if (mode === undefined) {
      // THIS WAS CAUSING THE GROWING HEADER HEIGHTS
      scheduler.createTimelineView({
        column_width: state.scale * 0.4,
        container_autoresize: true,
        event_min_dy: 60,
        folder_dy: 40,
        name: "timeline",
        scrollable: true,
        section_autoheight: false,
        y_unit: [{ key: 1, label: "Shifts" }],
        y_property: "shifts",
        render: "bar",
        days: 14,
        dx: 0,
        x_unit: "hour", // measuring unit of the X-Axis.
        x_date: "%G", // date format of the X-Axis
        x_step: 1,
        x_length: 24,
        second_scale: {
          x_unit: "day",
          x_date: "%D %F %j",
        },
        // event_dy: c.shifts.height,
        // x_start: 0,
        // x_size: 24,
        // scroll_position: new Date(),
        //   // x_unit: "week", // the measuring unit of the axis (by default, 'minute')
        //   // x_date: "Week %W" //the date format of the axis ("July 01")
        // }
      })
    }
    scheduler.attachEvent("onTemplatesReady", setTemplates)
    scheduler.attachEvent("onTemplatesReady", attachEvents)
    scheduler.init("scheduler_target", new Date(), "timeline")
    setState({ ...state, mounted: true })
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="ScheduleTimeline">
      <div className="view_title">
        <Row style={{ width: "100%" }}>
          <Col span={5} className="PageTitle">
            <h1 data-cy="page-title">Schedule</h1>
          </Col>
          <Col span={6} className="GroupSelector">
            <TagSelect handleChange={selectGroup} />
          </Col>
          <Col span={4} className="SupervisorSelector">
            <SupervisorSelect supervisor={state.supervisor} handleChange={selectSupervisor} />
          </Col>
          <Col span={4} />
        </Row>
      </div>
      {!!context.shiftDrawerId && <ShiftDrawer />}
      <div id="scheduler_target" className="dhx_cal_container" style={{ width: "100%", height: "100%" }}>
        <div className="dhx_cal_navline">
          <Row>
            <Col span={4}>
              <div className="payPeriodSelector">
                <h5>Pay Period</h5>
                <PayPeriodNav payPeriod={state.payPeriod} toggleLoading={toggleLoading} />
              </div>
              <div className="dhx_cal_tab" name="day_tab" style={{ right: "204px" }} />
              <div className="dhx_cal_tab" name="week_tab" style={{ right: "140px" }} />
              <div className="dhx_cal_tab" name="timeline_tab" style={{ right: "280px" }} />
              <div className="dhx_cal_tab" name="month_tab" style={{ right: "76px" }} />
            </Col>
            <Col span={14}>
              <div className="dhx_cal_date timelineDate" />
            </Col>
            <Col span={2} className="timelineZoom">
              <Button.Group>
                <Button data-cy="dhtmlx-zoom-in" icon="zoom-in" onClick={() => changeScale("in")} />
                <Button data-cy="dhtmlx-zoom-out" icon="zoom-out" onClick={() => changeScale("out")} />
              </Button.Group>
              {/* <Slider className="time_scale" min={20} max={100} onChange={changeScale} value={state.scale} step={10} /> */}
            </Col>
            <Col span={4}>
              <div data-cy="dhtmlx-prev-button" className="dhx_cal_prev_button">
                &nbsp;
              </div>
              <div data-cy="dhtmlx-next-button" className="dhx_cal_next_button">
                &nbsp;
              </div>
              <div data-cy="dhtmlx-today-button" className="dhx_cal_today_button" />
            </Col>
          </Row>
        </div>
        <div className="dhx_cal_header" />
        <div className="dhx_cal_data" />
      </div>
    </div>
  )
}

export default ScheduleTest
