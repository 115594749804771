import React, { useState } from "react"
import { store } from "../../store/psrs"
import Select from "react-select"
import useMyContext from "../../store/useMyContext"
// import { SupervisorSelectOptions } from "./_SelectOptions"
import _ from "lodash"

export const SupervisorSelect = props => {
  const context = useMyContext()

  const Supervisors = store.get("Supervisors") || []

  // sort by label (fullName)
  let SupervisorSelectOptions = Supervisors.map(r => ({ value: r.number, label: r.fullName, role: r.role }))
  SupervisorSelectOptions = _.sortBy(SupervisorSelectOptions, ['label'])

   // remove any employee with role of disabled
   SupervisorSelectOptions = _.filter(SupervisorSelectOptions, e => e.role !== "disabled")

  const { record, value, supervisor } = props
  const field = props.field || "superNumber1"
  const id = record ? record.id : props.id
  const Supervisor = supervisor
    ? supervisor
    : record
    ? record[field]
    : value
    ? context.getRecord("Supervisors", value, "number")
    : null

  const [state, setState] = useState({
    value: Supervisor ? Supervisor.number : null,
    selected: Supervisor ? _.find(SupervisorSelectOptions, { value: Supervisor.number }) : null,
    sql: null,
  })

  const selectProps = {
    id: "cySupervisorSelect",
    className: "psrs-select",
    classNamePrefix: "psrs",
    dropdownClassName: "cySupervisorSelectDropdown SupervisorSelectDropdown",
    placeholder: props.placeholder || "Search for an Supervisor",
    isDisabled: props.disabled ? true : false,
    isClearable: props.isClearable === false  ?  false : true,
    isMulti: props.isMulti,
    defaultValue: state.selected,
    options: SupervisorSelectOptions,
    menuPlacement: "auto",
    value: Supervisor && Supervisor.number ? _.find(SupervisorSelectOptions, {value: Supervisor.number}) : Supervisor ? Supervisor : null,
    onChange: select => {
      const val = select && select.value ? select.value : null
      const sql = val ? `("superNumber1"='${val}' OR "superNumber2"='${val}' OR "superNumber3"='${val}')` : null
      setState({ ...state, selected: select, sql })
      props.handleChange({ ...props, value: val, field, id, sql })
    },
  }

  return <Select {...selectProps} cacheOptions />
}

export default SupervisorSelect
