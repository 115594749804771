import React from "react"
import { Icon, Tag, Tooltip, Checkbox } from "antd"

import uid from "uniqid"
import moment from "moment-timezone"
// import _ from "lodash"
import { CheckBox } from "grommet"
//import StateManager from "react-select"
//import { session } from "store2"
import { PayPeriodEndSelector } from "../../Forms"

const TimeEntryColumns = props => {
  // original // const { excluded, setExcluded, selectAll, setSelectAll, indeterminate, setIndeterminate, records } = props
  const { excluded, setExcluded, setSelectAll, setIndeterminate, records, paranoid } = props
  const { excludedTimeEntries, setExcludedTimeEntries } = props
  const { selectAllTimeEntry, setSelectAllTimeEntry } = props
  const { indeterminateTimeEntry, setIndeterminateTimeEntry } = props

  const SHOW_ALL = props.showAllColumns || false

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()

  const onSelectAll = (e) => {

   let ids = records.map(r => r.id)   
   // remove unselected before sending to backend
   //-//const excludedIds = Array.from(excluded.values())
   const excludedIds = Array.from(excludedTimeEntries.values())

   ids = ids.filter( function( el ) {
     return !excludedIds.includes( el );
   } );
  
    if (!e.target.checked) {
      for (let id of ids) {
        excluded.add(id)
        excludedTimeEntries.add(id)
      }
      setExcluded(new Set(Array.from(excluded.values())))
      setExcludedTimeEntries(new Set(Array.from(excluded.values())))
      //setIndeterminate(false)
    }
      

    setSelectAll(e.target.checked)
    setSelectAllTimeEntry(e.target.checked)
    //setIndeterminate(e.target.checked)
    if(!e.target.checked && excluded.size > 0 ) {
      setIndeterminate(false)
      setIndeterminateTimeEntry(false)
    } 


    
    // empty out excluded if select All so all are processed
    if (e.target.checked) {
      setExcluded(new Set(Array.from([])))
      setExcludedTimeEntries(new Set(Array.from([])))
      setIndeterminate(false)
      setIndeterminateTimeEntry(false)
    }
  }
    
  const renderStatus = entry => {
    const { approved, rejected, submit, process, status, isOT, assignmentId, paidLunch, locked} = entry
    const syncd = { className: `stat jde ${status}`, type: "cloud" }
    const paid = { className: `stat ${process ? "on processed" : ""}`, type: "dollar" }
    const submitted = { className: `stat ${submit ? "on submitted" : ""}`, type: "check-circle" }
    const ot = { className: `stat ${isOT ? "on has overtime" : ""}`, type: "clock-circle" }
    const acting = { className: `stat ${assignmentId ? "on acting" : ""}`, type: "safety-certificate" }
    const good = { className: `stat ${approved ? "on approved" : ""}`, type: "like" }
    const bad = { className: `stat ${rejected ? "on rejected" : ""}`, type: "dislike" }
    const paidlunch = { className: `stat ${paidLunch ? "on paidLunch": "" }`, type: "rest" }
    const isPaidLunch = paidLunch
    const isLocked = { className: `stat ${locked ? "on locked": "on unlocked" }`, type: "lock" }


    return (
      <div className="StatusIcons">
        <Tooltip title="Syncd to JDE">
          <span className="statusIcon">
            <Icon {...syncd} />
          </span>
        </Tooltip>
        <Tooltip mouseEnterDelay={isOT ? 0 : 99} title="Unscheduled Overtime">
          <span className="statusIcon">
            <Icon {...ot} />
          </span>
        </Tooltip>
        <Tooltip mouseEnterDelay={assignmentId ? 0 : 99} title="Acting Role">
        <span className="statusIcon">
          <Icon {...acting} />
        </span>
      </Tooltip>
        <Tooltip title="Submitted">
          <span className="statusIcon">
            <Icon {...submitted} />
          </span>
        </Tooltip>
        <Tooltip mouseEnterDelay={rejected ? 0 : 99} title="Rejected">
          <span className="statusIcon">
            <Icon {...bad} />
          </span>
        </Tooltip>
        <Tooltip mouseEnterDelay={approved ? 0 : 99} title="Approved">
          <span className="statusIcon">
            <Icon {...good} />
          </span>
        </Tooltip>
        <Tooltip mouseEnterDelay={process ? 0 : 99} title="Processed">
          <span className="statusIcon">
            <Icon {...paid} />
          </span>
        </Tooltip>
       <Tooltip mouseEnterDelay={paidlunch ? 0 : 99} title={ isPaidLunch ? "Paid Lunch" : "Un Paid Lunch" }>
          <span className="statusIcon">
            <Icon {...paidlunch} />
          </span>
       </Tooltip>
       <Tooltip mouseEnterDelay={isLocked ? 0 : 99} title={ locked ? "Locked" : "Not Locked" }>
          <span className="statusIcon">
            <Icon {...isLocked} />
          </span>
       </Tooltip>
      </div>
    )
  }

  return [
    // { Header: "shiftCode", accessor: "shiftCode" },
    // { Header: "needsApproved", accessor: "needsApproved" },
    // { Header: "approved", accessor: "approved" },
    // { Header: "rejected", accessor: "rejected" },
    // { Header: "confirmed", accessor: "confirmed" },
    // { Header: "submit", accessor: "submit" },
    // { Header: "process", accessor: "process" },
    {
      Header:  <Tooltip title={ `Select ${selectAllTimeEntry ? 'None':'All'}`}><Checkbox { ...{ indeterminate: indeterminateTimeEntry, checked: selectAllTimeEntry, onChange: onSelectAll }}> </Checkbox></Tooltip>,
      accessor: "id",
      Cell: row => {
        const isExcluded = excludedTimeEntries.has(row.value)
        const excludedProps = {
          checked: !isExcluded,
          //checked: selectAll && excluded.size === 0 ? true : isExcluded, 
          onChange: evt => {
              if (selectAllTimeEntry && excludedTimeEntries.size === 0 && evt.target.checked) {
                excludedProps.checked = true
                excluded.add(row.value)
                excludedTimeEntries.add(row.value)
                setIndeterminate(evt.target.checked)
                setIndeterminateTimeEntry(evt.target.checked)
              } else if (selectAllTimeEntry && !evt.target.checked) {
                //setSelectAll(false)
                setIndeterminate(true)
                setIndeterminateTimeEntry(true)
                excludedProps.clecked = false
                excluded.add(row.value)
                excludedTimeEntries.add(row.value)
              } else {
                if (isExcluded) {
                  excluded.delete(row.value)
                  excludedTimeEntries.delete(row.value)
                  excludedProps.checked = false
                  if(excluded.size > 0) setIndeterminate(true)
                  else setIndeterminate(false)

                  if(excludedTimeEntries.size > 0) setIndeterminateTimeEntry(true)
                  else setIndeterminateTimeEntry(false)

                } else {
                  excluded.add(row.value)
                  excludedTimeEntries.add(row.value)
                  excludedProps.checked = true
                  setIndeterminate(false)
                } 
              }
              setExcluded(new Set(Array.from(excluded.values())))
              setExcludedTimeEntries(new Set(Array.from(excludedTimeEntries.values())))
          },
        }
        return <Checkbox {...excludedProps}> </Checkbox>
      },
    },
    {
      Header: "Status",
      //width: 180,
      width: 280,
      accessor: "id",
      sortable: false,
      Cell: row => renderStatus(row.original),
    },
    {
      Header: "Deleted On",
      width: 260,
      accessor: "deletedAt",
      sortable: true,
      show: !paranoid,
      Cell: row => (
         moment(row.value).format('MM/DD/YYYY HH:mm')
      ),
    },
    {
      Header: "Supervisor",
      accessor: "supervisor1.fullName",
      width: 200,
      sortable: true,
      Cell: row => (row.value ? row.original.supervisor1.fullName : ""),
    },
    {
      Header: "Employee",
      accessor: "employee.fullName",
      width: 200,
      sortable: true,
      Cell: row => (row.value ? row.original.employee.fullName : "Unassigned"),
    },
    {
      Header: "date",
      width: 140,
      accessor: "start",
      sortable: true,
      Cell: row => moment(row.value).format("ddd MMM Do"),
    },
    {
      Header: "Start Time",
      accessor: "start",
      sortable: false,
      Cell: row => moment(row.value).format("HH:mm"),
    },
    {
      Header: "End Time",
      accessor: "end",
      sortable: true,
      Cell: row => moment(row.value).format("HH:mm"),
    },
    {
      Header: "hours",
      accessor: "duration",
      sortable: true,
      Cell: row => parseFloat(row.value), //.toPrecision(4),
    },
    {
      Header: "Pay Type",
      accessor: "payType.code",
      sortable: true,
      Cell: row => (row.value ? row.value : ""),
    },
    {
      Header: "Unpaid Lunch",
      accessor: "paidLunch",
      className: "justify-around",
      width: 120,
      show: true,
      sortable: true,
      Cell: row => <> <CheckBox disabled={true} checked={!row.value}></CheckBox></>,
    },
    {
      Header: "Approved By",
      accessor: "approvedBy.fullName",
      width: 200,
      sortable: true,
      Cell: row => (row.value ? row.original.approvedBy.fullName : ""),
    },
    {
      Header: "Pay Period End Override",
      accessor: "payPeriodEndOverride",
      width: 300,
      sortable: true,
      Cell: row => <> {row.value !== null && <PayPeriodEndSelector
         defaultValue={'WTF'}
         placeholder="optional"
         disabled={true}
         value={{ payPeriodStartId: row.original.payPeriodEndOverride, payPeriodEndId: row.original.payPeriodEndOverride }}
         //record={'timeEntries}
         //handleChange={e => {
           //updateEntry({ field: "payPeriodEndOverride", value: e.value, id: record.id })}
         //}}
       />}</ >
      //Cell: row => (row.value ? row.original.payPeriodEndOverride : ""),
    },
    {
      Header: "Groups",
      width: 300,
      accessor: "tags",
      sortable: false,
      Cell: row => (row.value ? row.value.map(tag => <Tag key={uid()}>{tag.name}</Tag>) : <span />),
    },
    {
      Header: "Acting Role",
      width: 240,
      accessor: "assignment.name",
      sortable: true,
      Cell: row => (row.value ? row.value : ""),
    },
    {
      Header: "Bus Unit",
      accessor: "businessUnit.code",
      sortable: true,
      Cell: row => (row.value ? row.value : ""),
    },
    {
      Header: "Charge Out",
      accessor: "costCenterChargeOut.code",
      sortable: true,
      Cell: row => (row.value ? row.value : ""),
    },
    {
      Header: "subledger Code",
      width: 140,
      accessor: "subLedger.code",
      sortable: true,
      Cell: row => (row.value ? row.value : ""),
    },
    {
      Header: "subledger",
      accessor: "subledger.description",
      show: SHOW_ALL,
      sortable: true,
      Cell: row => (row.value ? row.value : ""),
    },
    {
      Header: "PRTR",
      width: 170,
      accessor: "payrollTransNum",
      sortable: true,
    },
    {
      Header: "Batch #",
      width: 120,
      accessor: "ediBatchNumber",
      sortable: true,
    },
    {
      Header: "notes",
      width: 240,
      accessor: "notes",
      sortable: true,
    },
    {
      Header: "Trans #",
      width: 120,
      accessor: "ediTransactionNumber",
      sortable: true,
    },
    {
      Header: "Success Processed",
      width: 170,
      accessor: "ediSuccessfullyProcessed",
      sortable: true,
    },
    {
      Header: "Line #",
      width: 120,
      accessor: "ediLineNumber",
      sortable: true,
    },
    {
      Header: "Uniq Key",
      width: 120,
      accessor: "taskUniqueKey",
      sortable: true,
    },
    {
      Header: "jobCode",
      accessor: "jobCode",
      sortable: true,
    },
    {
      Header: "job Step",
      accessor: "jobStepCode",
      sortable: true,
    },
    {
      Header: "job Step Override",
      width: 170,
      accessor: "jobStepTimecardOverride",
      sortable: true,
    },
    {
      Header: "job Type Override",
      width: 170,
      accessor: "jobTypeTimecardOverride",
      sortable: true,
    },
    {
      Header: "Trans Action",
      width: 120,
      accessor: "ediTransactionAction",
      sortable: true,
    },
    {
      Header: "Trans Type",
      width: 120,
      accessor: "ediTransactionType",
      sortable: true,
    },
    {
      Header: "isCanine",
      accessor: "isCanine",
      show: SHOW_ALL,
      sortable: true,
    },
    {
      Header: "isDive",
      accessor: "isDive",
      show: SHOW_ALL,
      sortable: true,
    },
    {
      Header: "isFTO",
      accessor: "isFieldTraining",
      show: SHOW_ALL,
      sortable: true,
    },
    {
      Header: "isMoto",
      accessor: "isMoto",
      show: SHOW_ALL,
      sortable: true,
    },
    {
      Header: "isSwat",
      accessor: "isSwat",
      show: SHOW_ALL,
      sortable: true,
    },
    {
      Header: "isOT",
      accessor: "isOT",
      show: SHOW_ALL,
      sortable: true,
    },
    {
      Header: "paid Lunch",
      width: 140,
      accessor: "paidLunch",
      show: SHOW_ALL,
      sortable: true,
    },
    {
      Header: "name",
      width: 170,
      accessor: "name",
      show: SHOW_ALL,
      sortable: true,
    },
    {
      Header: "Date Created",
      width: 150,
      accessor: "createdAt",
      show: true,
      sortable: true,
      Cell: cell => {
        return moment(cell.value).tz(userTimeZone).format('MM-DD-YYYY HH:mm') // ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },
    {
      Header: "Date Updated",
      width: 150,
      accessor: "updatedAt",
      show: true,
      sortable: true,
      Cell: cell => {
        return moment(cell.value).tz(userTimeZone).format('MM-DD-YYYY HH:mm') // ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },
    {
      Header: "Last Update User",
      width: 250,
      accessor: 'userId',
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => {
        return cell.value // ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },
    /*{
      Header: "Last Sync User",
      width: 250,
      accessor: 'syncUserId',
      className: "justify-around",
      show: SHOW_ALL,
      sortable: true,
      Cell: cell => {
        return cell.value // ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },
    {
      Header: "Last Sync Date",
      width: 150,
      accessor: 'lastSyncTime',
      className: "justify-around",
      show: SHOW_ALL,
      sortable: true,
      Cell: cell => {
        return moment(cell.value).tz(userTimeZone).format('MM-DD-YYYY HH:mm') // ? <><Tag>{cell.value.code}</Tag> {cell.value.description} </>: ""
      }
    },*/
    {
      sortable: false
    }
  ]
}

export default TimeEntryColumns
