import React from "react"
import Select from "react-select"
import _ from "lodash"

export const DurationSelect = props => {
  const { field, handleChange, id, disabled } = props
  const value = props.value
  const hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14]
  const selectOptions = hours.map(h => ({ value: h, label: `${h} Hrs`}))

  const options = {
    className: "psrs-select",
    classNamePrefix: "psrs",
    disabled: disabled || false,
    isClearable: true,
    isMulti: false,
    options: selectOptions,
    onChange: val => {
      //console.log(field, val);
      handleChange({ ...props, field, value: val.value, id })
      return val
    },
    defaultValue: _.find(selectOptions, {value: value}),
    placeholder: "Select Hrs"
  }
  return <Select {...options} />
}

export default DurationSelect
