import React, {useState, useEffect} from "react"
import useMyContext from "../store/useMyContext"
import {Icon, Popconfirm, Tooltip, notification} from "antd"
import { session, REQ } from "../store/psrs"
//import { close } from "@sentry/browser"
//import { mutate } from "swr"
//import useSWR, { mutate } from "swr"

export const Drawer = (props) => {
  const context = useMyContext()
  const [state, setState] = useState({
    loading: props.loading ? true : false,
    open: context.shiftDrawerId  || context.templateDrawerId? true : false,
    title: props.title || "Drawer Title",
    width: props.width || 690,
    placement: props.placement || "right"
  })


  //const drawerClass = props.className ? props.className : null
  /*
  const v1closeDrawer = () => {
    if (!!context.shiftDrawerId) context.setShiftDrawerId(0)
    if (!!context.newShiftDrawer) context.setNewShiftDrawerPayTypeCode(false)
  }
  */

  const closeDrawer = async () => {
    if (!!context.shiftDrawerId) {
        // we can clean up now.  
        context.setShiftDrawerId(0)
        session.remove("originalShift")
        session.remove("originalTimeEntries")
        session.remove("changeDetected")
    } 
    if (!!context.templateDrawerId) {
      // we can clean up now.  
      context.setTemplateDrawerId(0)
  } 

    if (!!context.newShiftDrawer) context.setNewShiftDrawerPayTypeCode(false)

  }

  // undo Changes in Shift Drawer 
  
  const undoAllChanges = async() => {

    const originalShift = session.get("originalShift")      
    const undoChanges =  await REQ(`/api/utils/undoshiftchange`, 'POST', originalShift  )

    console.log('Drawer.undoChanges -> ',undoChanges)

    session.set("changeDetected", false)
    notification.open({
      message: "Changes rolled back",
      description: `Shift changes rolled back.`,
      placement: "topRight",
      duration: 3,
    })

    //if (updated.isOT || updated.isSick || updated.requestedOff) {
    //  requestApproval = true
    //} else {
    //  requestApproval = false
    // }
    //mutate('/shifts/supervised', null)
    //setChangeDetected2( false) 
    closeDrawer()
  }
  

  // Confirm changes in the shift drawer 
  const confirmChanges = async () => {
    const updateNotification = await REQ(`/api/notifications/${context.shiftDrawerId}`, "POST")
    console.log('updateNotification -> ', updateNotification)
    notification.open({
      message: "Changes Saved",
      description: `Shift changes have been saved.`,
      placement: "topRight",
      duration: 3,
    })

    closeDrawer()
  }
/*
const v1undoAllChanges = async () => {
  const originalShift = session.get('originalShift')
  const originalTimeEntries = session.get('originalTimeEntries')

  // if any time entries were added remove them and add/update originals
  const ids = [originalShift.id]
  const currentTimeEntries = await REQ(`/api/timeEntriesByShiftIds`, 'POST', { ids: ids } )
  
  if (currentTimeEntries) {
    for (let currentTE of currentTimeEntries) {
      // preserve the original line and update later if needed.  
      if (currentTE.ediLineNumber !==  '1.000' && !currentTE.isOT && !currentTE.unscheduledOT ) {
        const deleteTE = await REQ(`/api/time_entries/${currentTE.id}`, "DELETE")
      }
      
    }
  } 

  // fix time entries add originals back  
  for (let originalTE of originalTimeEntries) {

    const timeEntry = await REQ(`/api/time_entries/${originalTE.id}`, 'GET')
    
    if (timeEntry) {
      const updateTE = await REQ(`/api/time_entries/${originalTE.id}`, "PATCH", originalTE)
      console.log('updateTE -> ', updateTE)
    } else {
      // Not found, but in cached records so lets add it back in?
      const updatedShift = await REQ(`/api/time_entries/create`, "POST", originalShift).catch(err =>
        //Emitter.emit("TimeEntriesTable.js", { fn: "addEntry", error: err }),
        console.log('update shift error ')
      )
    }
  }

  //fix shifts
  const updated = await REQ(`/api/shifts/${originalShift.id}`, "PATCH", originalShift)
  session.set("changeDetected", false)
  await closeDrawer()
}
*/

  useEffect(() => {
    const open = !!context.newShiftDrawer || !!context.shiftDrawerId || !!context.templateDrawerId? true : false
    setState({...state, open})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.shiftDrawerId, context.newShiftDrawer, context.templateDrawerId])

  
  let DrawerClassName = ''
  if (props.className === 'NewShiftDrawer') {
    DrawerClassName = `${props.className} ${state.open ? "open" : "closed"} ${state.placement}`
  } else {
    DrawerClassName = `Drawer ${props.className} ${state.open ? "open" : "closed"} ${state.placement}`

  }


  return (
    <div className={DrawerClassName}>
      <div className="DrawerContent">
        <Tooltip title='Close'>
          {session.get("changeDetected") ?  
            <Popconfirm
              title='Changes Found' 
              okText='Save All Changes?'
              onConfirm={confirmChanges}
              onCancel={undoAllChanges}
              cancelText="Undo All Changes?"
              icon={<Icon type="question-circle"  />}
              placement="rightBottom"
            >
                <Icon type="close" theme="outlined"  className="DrawerClose" />
            </Popconfirm>
          :  <Icon type="close" theme="outlined"  onClick={closeDrawer} className="DrawerClose" />}
        </Tooltip>
        <div className={`DrawerLoading ${state.loading ? "loading" : "hidden"}`} />
        <div className="DrawerTitle">{props.title}</div>
        {state.open && <div className="DrawerRender">{props.children}</div>}
      </div>
      <div className="DrawerMask" onClick={closeDrawer} />
    </div>
  )
}

export default Drawer
