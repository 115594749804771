import React from "react"
import {TimePicker} from "antd"
import moment from "moment"

export const TimeField = (props) => {
  const {field, value, handleChange, disabled} = props
  const options = {
    disabled: disabled ? true : false,
    minuteStep: 5,
    onChange: (val) => {
      handleChange({ ...props, field, value: val})
    },
    value: value ? moment(value) : undefined,
    format: "HH:mm",
    className: "EndTimeField",
    allowClear: true
  }
  return <TimePicker data-cy="time-picker" {...options} />
}

export default TimeField
