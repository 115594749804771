import React, { useState, useEffect } from "react"
import { store, Can } from "../../../store/psrs"
import _ from "lodash"
import * as F from "../../Forms"
import { Spin, Row, Col, Button, Switch} from "antd"
import cleanDeep from "clean-deep"
import moment from "moment-timezone"

const DEBUG = false

const { TagSelect, TriToggle, BusinessUnitSelect, SubLedgerSelect } = F
const { AssignmentSelect, StringFilter } = F
const { SupervisorSelect, EmployeeSelect } = F
const { DateRangeFilter } = F

const Filters = props => {
  const { query, setQuery, filters, setFilters, loading, setLoading, setParanoid } = props
  const { sup1, setSup1, sup2, setSup2, sup3, setSup3 } = props

  const [state, setState] = useState({
    currentPayPeriod: false,
    allYear: false,
  })

  const [clearFilters, setClearFilters] = useState(false)
  const handleFilterChange = async props => {
		//console.log(props)
    setLoading(true)
    const { value, field } = props
    const updatedFilters = { ...filters }
    updatedFilters[field] = value

		//console.log("handleFilterChange: " + field + " " + value);
		//console.log(value);
		var sql = ""
    //let { sql } = props
    if (field === "tagIds" ) {
			if( value && value.length > 0 ) {
      	sql = ' id IN (SELECT "shiftId" FROM "tagShifts" WHERE "tagShifts"."tagId" = ANY(\'{' + value + '}\'::text[]))'; 
			}
    }
    else if (field === "unedited") {
      query.unedited = `"createdAt"="updatedAt"`
    }
    else if (field === "date") {
      query.range = null
      updatedFilters.range = null
    }
    else if (field === "range") {
      query.date = null
      updatedFilters.date = null;
			sql = props.sql
    }
    else if(field === "unassignedEmployee") {
      //console.log('field -> ', field, value)

      if (value !== null) {
        if (value) {
          sql += `"employeeNumber" is null`
        } else {
          sql += `"employeeNumber" is not null`
        }
      }
    }
    else if ( field === 'deletedAt') {
      setParanoid(true)
      if (value) {
        query.deletedAt = 'is not null'
        sql=`"deletedAt" is not null`
        setParanoid(false)
      } 
    }
		else if( value !== null ) {
			if( field === "superNumber" ) {

        let overrideSup1 = false
        if (!props.sup1 && !props.sup2 && !props.sup3) {
          setSup1(true)
          overrideSup1 = true
        }

        //sql = `("superNumber1" = '${value}' OR `
        //sql += `"superNumber2" = '${value}' OR `
        //sql += `"superNumber3" = '${value}' )`
        sql = ''
        if (props.sup1 || overrideSup1) sql = `("superNumber1" = '${value}' )`
        if (props.sup2) sql = `("superNumber2" = '${value}' )`
        if (props.sup3) sql = `("superNumber3" = '${value}' )`

        if (props.sup1 && props.sup2 && !props.sup3) {
           sql = `("superNumber1" = '${value}' OR `
           sql += `"superNumber2" = '${value}' )`
           //sql += `"superNumber3" = '${value}' )`
        }

        if (props.sup1 && !props.sup2 && props.sup3) {
          sql = `("superNumber1" = '${value}' OR `
          //sql += `"superNumber2" = '${value}' OR `
          sql += `"superNumber3" = '${value}' )`
       }

        if (props.sup1 && props.sup2 && props.sup3) {
           sql = `("superNumber1" = '${value}' OR `
           sql += `"superNumber2" = '${value}' OR `
           sql += `"superNumber3" = '${value}' )`
        }
        
				//sql = ' "superNumber1" = ' + "'" + value + "' OR"
				//sql += ' "superNumber2" = ' + "'" + value + "' OR"
				//sql += ' "superNumber3" = ' + "'" + value + "'"
			}
			else {
        sql = `"${field}" = '${value}'`
				//sql = ' "' + field + '" = ' + "'" + value + "'"
			}
		} else if (value === null && field === 'superNumber') {
      setSup1(false)
      setSup2(false)
      setSup3(false)
    }
    
    setFilters(updatedFilters)
    const updatedQuery = { ...query }
    updatedQuery[field] = sql
    const Query = { ...query, ...updatedQuery }
    setQuery(cleanDeep(Query))
  }

  
  const setDateSelector = async (props) => {
    setState({ currentPayPeriod: false, allYear: false })
    handleFilterChange( props )
  }
  
  const setCurrentPayPeriod = async () => {
    setState({ currentPayPeriod: true, allYear: false })
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()

    const range = store.get("PayPeriodToday").range
    //const current = range.map(d => moment(d.value))
    const current = range.map(d => moment(d.value).utc())
    //const YYYMMDD = current.map(c => c.utc().format("YYYY-MM-DD"))
    const YYYMMDD = current.map(c => c.tz(userTimeZone).format("YYYY-MM-DD"))
    //const sql = `"start" BETWEEN '${YYYMMDD[0]}' AND '${YYYMMDD[1]}'`



    // new utc
    const utcStartQ = moment(YYYMMDD[0] + ' 00:00:00').utc().format();
    const utcEndQ = moment(YYYMMDD[1] + ' 23:59:59').utc().format();

    const sql = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`

    filters.range[0] = moment(range[0].value)
    filters.range[1] = moment(range[1].value)

    handleFilterChange({ sql, field: "range", value: current })
  }

  /*
  const allYear = async () => {
    setState({ currentPayPeriod: false, allYear: true })
    const start = moment().startOf("year")
    const end = moment().endOf("year")
    const range = [start, end]
    const YYYMMDD = range.map(c => c.format("YYYY-MM-DD"))
    const sql = `"start" BETWEEN '${YYYMMDD[0]}' AND '${YYYMMDD[1]}'`
    handleFilterChange({ sql, field: "range", value: range })
  }
  */

  const opts = {
    filter: true,
    unstyled: "yup",
    sup1: sup1,
    sup2: sup2,
    sup3: sup3,
    handleChange: handleFilterChange,
  }

  
  const dateOpts = {
    model: 'shift',
    filter: true,
    unstyled: "yup",
    handleChange: setDateSelector,
  }

  const resetFilters = async () => {
    //console.log('reset filters')
    const ss = store.session.get("RecordsViewer")

    if (ss && ss.filters.range) {
      
      const range = ss.filters.range.map(a => moment(a))
       // new utc
       //const YYYMMDD = range.map(c => c.format("YYYY-MM-DD"))
       //const utcStartQ = moment(YYYMMDD[0] + ' 00:00:00').format()
       //const utcEndQ = moment(YYYMMDD[1] + ' 24:00:00').format()
       //const updatedRange = [ moment(YYYMMDD[0] + ' 00:00:00').utc(), moment(YYYMMDD[1] + ' 23:59:59').utc() ]
       const utcStartQ = moment(range[0].format("YYYY-MM-DD") + ' 00:00:00').utc().format()
       const utcEndQ = moment(range[1].format("YYYY-MM-DD") + ' 24:00:00').utc().format()
       //query.range = `"start" BETWEEN '${ss.filters.range[0]}' AND '${ss.filters.range[1]}'`
       query.range = `"start" BETWEEN '${utcStartQ}' AND '${utcEndQ}'`

      const allFilters = {
        payTypeCode: null,
        submit: null,
        process: null,
        unassignedEmployee: null ,
        isSick: null,
        requestedOff: null,
        imbalanced: null,
        isCollision: null,
        available:  null,
        caseNumber: null,
        employeeNumber: null,
        superNumber: null,
        assignmentId: null,
        businessUnitCode: null,
        costCenterChargeOutCode: null,
        subLedgerCode: null,
        needsApproved: null,
        approved: null,
        rejected: null,
        unedited: null,
        isFieldTraining: null,
        isDive: null,
        paidLunch: null,
        isOT: null,
        notes: null,
        tagIds: null,
      }

      const filters = {range: range, ...allFilters}
      const viewerState = {
        state: ss.state,
        update: ss.update,
        filters: cleanDeep(filters),
        query: query,
        date: Date.now(),
      }
      //store.session.remove("RecordsViewer")
      store.session.set("RecordsViewer", cleanDeep(viewerState))

      setSup1(false)
      setSup2(false)
      setSup3(false)

      setFilters(cleanDeep(filters))
      const Query = { ...query, ...allFilters }
      setQuery(cleanDeep(Query))
      setClearFilters(true)
    }
  }
  
  useEffect(() => {
    DEBUG && console.log("TimeEntry Query", query)
  }, [query])

  useEffect(() => {
    console.log("filters userEffect() -> ", filters.range)
    setState({...state })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sup1, sup2, sup3])

  return (
    <section className="recordsFilter">
      <Spin spinning={loading}>
        <ul className="unstyledList">
          <div id="my-tab-content" className="tab-content">
            <li>
              <div className="inputs">
                <div className="input">
                  <DateRangeFilter {...dateOpts} field="range" value={filters.range} />
                  <Button
                    className="ml-4"
                    size="small"
                    type={!!state.currentPayPeriod ? "primary" : "default"}
                    onClick={setCurrentPayPeriod}
                  >
                    Current PP
                  </Button>
                  {/* DATE */}
                  {/* <DateFilter handleChange={ handleFilterChange } /> */}
                </div>
              </div>
            </li>
            <li>
              <div className="inputs">
              <div align = "right">
                  <Button
                    className="ml-4"
                    size="small"
                    type="dashed"
                    onClick={resetFilters}
                  >
                    Clear All Filters</Button>
                </div>
                <div className="input">
                  <label>Employee</label>
                  <EmployeeSelect
                    {...opts}
                    field="employeeNumber"
                    employee={filters.employeeNumber ? _.find(store.get("Employees"), { number: filters.employeeNumber }) : null}
                  />
                </div>
                <div className="input">
                  <label>Supervisor</label>
                  <SupervisorSelect
                    {...opts}
                    field="superNumber"
                    supervisor={filters.superNumber ? _.find(store.get("Supervisors"), { number: filters.superNumber }) : null}
                  />
                </div>
                <div className="input">
                  <label>Supervisor: </label>
                    <div style={{textAlign: "center", margin: "0px 20px 0px 3px"}}>
                      <Switch 
                        disabled={filters.superNumber ? false : true}
                        size="small" 
                        checkedChildren="One " 
                        unCheckedChildren="One "
                        checked = {sup1}
                        defaultChecked = {true}
                        onChange = {(checked)=> {
                          setSup1(checked)
                          let superNumber = filters.superNumber
                          if (!sup2 && !sup3 && !checked) {
                            superNumber = null
                            setFilters({...filters, superNumber: null})
                          } 
                          handleFilterChange({field: 'superNumber', value: superNumber ? superNumber: null, sup2, sup3, sup1: checked})
                        }}
                      />
                    </div>
                    <div style={{textAlign: "center", margin: "0px 20px 0px 3px"}}>
                      <Switch 
                        disabled={filters.superNumber ? false : true} 
                        size="small" 
                        checkedChildren="Two " 
                        unCheckedChildren="Two "
                        checked = {sup2}
                        defaultChecked = {true}
                        onChange = {(checked)=> {
                          setSup2(checked)
                          if (!sup1 && !sup3 && !checked) {
                            setSup1(true)
                          } 
                          handleFilterChange({field: 'superNumber', value: filters.superNumber ? filters.superNumber: null, sup1, sup3, sup2: checked})
                        }}
                      />
                    </div>
                    <div style={{textAlign: "center", margin: "0px 20px 0px 3px"}}>
                      <Switch 
                        disabled={filters.superNumber ? false : true} 
                        size="small" 
                        checkedChildren="Three" 
                        unCheckedChildren="Three"
                        checked = {sup3}
                        defaultChecked = {true}
                        onChange = {(checked)=> {
                          setSup3(checked)
                          if (!sup1 && !sup2 && !checked) {
                            setSup1(true)
                          } 
                          handleFilterChange({field: 'superNumber', value: filters.superNumber ? filters.superNumber: null, sup1, sup2, sup3: checked})
                        }}
                      />
                    </div>
                </div>
              </div>
            </li>
            <li>
              <div className="inputs">
                <div className="input">
                  <label>Group</label>
                  <TagSelect {...opts} field="tagIds" value={filters.tagIds} multi={false} />
                </div>
              </div>
            </li>
            <li>
              <div className="inputs">
                <div className="input">
                  <label>Assignment</label>
                  <AssignmentSelect {...opts} field="assignmentId" value={filters.assignmentId} />
                </div>
                <div className="input">
                  <label>Business Unit</label>
                  <BusinessUnitSelect {...opts} field="businessUnitCode" value={filters.businessUnitCode} />
                </div>
                <div className="input">
                  <label>Cost Ctr ChargeOut</label>
                  <BusinessUnitSelect {...opts} field="costCenterChargeOutCode" value={filters.costCenterChargeOutCode} />
                </div>
                <div className="input">
                  <label>Sub Ledger</label>
                  <SubLedgerSelect {...opts} field="subLedgerCode" value={filters.subLedgerCode} />
                </div>
              </div>
            </li>
            <li>
              <div className="inputs">
                <Row>
                  <Col span={12}>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="needsApproved"
                        value={filters.needsApproved}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["needs approved", "dsnt need aprvd"]}
                      />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="approved" 
                        value={filters.approved} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["approved", "not approved"]} />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} field="rejected" 
                        value={filters.rejected} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["rejected", "not rejected"]} />
                    </div>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="unassignedEmployee"
                        value={filters.unassignedEmployee}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["Unassigned", "Assigned"]}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="available" 
                        value={filters.available} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["available", "not available"]} />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="isSick" 
                        value={filters.isSick} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["sick", "not sick"]} />
                    </div>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="requestedOff"
                        value={filters.requestedOff}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["requested off", "not requested off"]}
                      />
                      </div>
                      <div className="input">
                      <TriToggle
                        {...opts}
                        field="paidLunch"
                        value={filters.paidLunch}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["Paid Lunch", "not Paid Lunch"]}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            </li>
            <li>
              <div className="inputs">
                <Row>
                  <Col span={12}>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="isFieldTraining"
                        value={filters.isFieldTraining}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        dataType="number"
                        labels={["has fto hrs", "no fto hrs"]}
                      />
                    </div>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="isDive"
                        value={filters.isDive}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        dataType="number"
                        labels={["has dive hrs", "no dive hrs"]}
                      />
                    </div>
                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="imbalanced" 
                        value={filters.imbalanced} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["imbalanced", "balanced"]} />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="isOT"
                        value={filters.isOT}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        dataType="number"
                        labels={["is OT", "not OT"]}
                      />
                    </div>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="isCollision"
                        value={filters.isCollision}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["collision", "not collision"]}
                      />
                    </div>

                    <div className="input">
                      <TriToggle 
                        {...opts} 
                        field="unedited" 
                        value={filters.unedited} 
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        labels={["un edited", "edited"]} />
                    </div>
                  </Col>
                </Row>
              </div>
            </li>
            <li>
              <Row>
                <Col span={12}>
                    <div className="input">
                      <TriToggle
                        {...opts}
                        field="locked"
                        value={filters.locked}
                        clearFilters={clearFilters}
                        setClearFilters={setClearFilters}
                        dataType="number"
                        labels={["Is Locked", "Not Locked"]}
                      />
                      </div>
                  </Col>
                  <Col span={12}>
                    <Can I="restore:Shift">
                      <div className="input">  
                        <label>Restore Deleted ?</label>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <Switch 
                          style={{color: 'green'}}
                          //checkedChildren={<CheckCircleOutlined />} 
                          //unCheckedChildren={<CloseOutlined />}
                          size='small'
                          loading={loading}
                          checked={filters.deletedAt}
                          defaultChecked={false}
                          onChange={e => {
                            handleFilterChange({field: 'deletedAt', value: e})
                          }}
                        />  
                        <span>&nbsp;&nbsp;&nbsp;</span>
                      </div>
                    </Can>
                  </Col>
              </Row>
            </li>
            <li>
              <div className="inputs">
                <div className="input">
                  <label>Search Notes</label>
                  <StringFilter {...opts} field="notes" value={filters.notes} />
                </div>
                <div className="input">
                  <label>Search Case Number</label>
                  <StringFilter {...opts} field="caseNumber" value={filters.caseNumber} />
                </div>
              </div>
            </li>
            <li>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</small></b></p>
              <p style={{color:'#cee7f2'}}><b><small>.</ small></b></p>
            </li>
          </div>
        </ul>
      </Spin>
    </section>
  )
}

export default Filters
