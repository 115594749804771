import React, { useState, useEffect, useMemo } from "react"
import Select, { createFilter} from "react-select"
import useMyContext from "../../store/useMyContext"
import { store, session } from "../../store/psrs"
import _ from "lodash"

export const LeaveBalanceSelect = props => {
  const context = useMyContext()
  const {leaveBalances, disabled} = props
  const employeeNumber = session.get("CurrentUser").number
  const FormattedPayTypes = useMemo(() => {
    const leavePayTypes = {
      "470": "compTime",
      "440": "executiveAccruals",
      "51": "floatingHolidayAccruals",
      "484": "fireLeaveBank",
      "20": "sickAccruals",
      "31": "vacationAccruals",
      "450": "holidayAccruals",
      "21": "famMedLeaveAccruals",
      "89": "otherLeave",
    }
    const AllPayTypes = store.get("PayTypes")
    const PayTypeCodes = ["470", "440", "51","484", "20", "31", "450","21", "89"]
    let FilteredPayTypes = AllPayTypes.filter(pt => PayTypeCodes.includes(pt.code))

    const paidLunch = AllPayTypes.find( payCode => payCode.code === '380')

    if (props.selectType && props.selectType === 'sick') {
      //const sickCodes = ['20','21','24','25']

      const allSickCodes = _.filter(AllPayTypes, pt => pt.category.toUpperCase() === 'SICK')

      

      const sickCodes = allSickCodes.map(c => c.code);
      FilteredPayTypes = _.sortBy(_.filter(AllPayTypes, pt => sickCodes.includes(pt.code)),'code')
      FilteredPayTypes.push(paidLunch)
      //PayTypeSelectOptions = PayTypeSelectOptions.map(pt => ({ value: pt.code, label: <OptionLabel leaveType={pt} /> }))
     // <span className="pill swat"> swat </span>}
    }

    if (props.selectType && props.selectType === 'pto') {
      //const sickCodes = ['20','21','24','25']

      const allSickCodes = _.filter(AllPayTypes, pt => pt.category.toUpperCase() === 'LEAVE')
      
      const sickCodes = allSickCodes.map(c => c.code);
      FilteredPayTypes = _.sortBy(_.filter(AllPayTypes, pt => sickCodes.includes(pt.code)),'code')

      FilteredPayTypes.push(paidLunch)

      //PayTypeSelectOptions = PayTypeSelectOptions.map(pt => ({ value: pt.code, label: <OptionLabel leaveType={pt} /> }))
     // <span className="pill swat"> swat </span>}
    }

    

    return FilteredPayTypes.map(pt => {
      const { code, category, description, rename } = pt
      const leaveLabel = leavePayTypes[code]
      const leaveBalance = leaveBalances ? leaveBalances[leaveLabel] : undefined
      return { rename, code, category, description, leaveLabel, balance: leaveBalance }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  /*
if (props.selectType && props.selectType === 'sick') {
  const sickCodes = ['20','21','24','25']
  PayTypeSelectOptions = _.filter(PayTypeSelectOptions, pt => sickCodes.includes(pt.value))

}

  */

  /*
  const OptionLabel = ({ leaveType }) => {
    const { code, description, leaveLabel, balance, rename } = leaveType
    return (
      <div className="LeaveBalanceSelectOption">
        <span className="leaveBalance">{balance} hrs</span>
        <span className="leaveLabel">{ rename? rename : leaveLabel}</span>
        <span className="payTypeDescription"><b>Pay Type: </b>{ description }</span>
        <span className="payTypeCode">{code}</span>
      </div>
    )
  }
*/


  const PadPayTypeCode = (pad, str, padLeft) => {
    if (typeof str === 'undefined') 
      return pad;
    if (padLeft) {
      return (pad + str).slice(-pad.length);
    } else {
      return (str + pad).substring(0, pad.length);
    }
  }

  const OptionLabel = ({ leaveType }) => {
    const { code, description, rename } = leaveType
    const padding = Array(4).join(' ') // make a string of 4 spaces
    //const formattedCode = PadPayTypeCode( padding,code,true );
    return (
      <div className="LeaveBalanceSelectOption">
        <span className="payTypeCode">{ PadPayTypeCode( padding,code,true ) }</span>
        <span className="leaveLabel">{ rename? rename : description}</span>
      </div>
    )
  }

  

  const options = FormattedPayTypes.map(pt => ({ value: pt.code, label: <OptionLabel leaveType={pt} /> }))

  const [state, setState] = useState({
    value: [],
    inputValue: "",
    menuIsOpen: false,
  })

  // const codes = [79, 49, 59, 484, 29, 39, 206, 28]
  // IM GOING TO NEED TO MAKE A UNIQUE LIST OF LEAVE BALANCES CODE + NAME
  useEffect(() => {
    const employee = context.getRecord("Employees", employeeNumber, "number")
    setState({ ...state, value: employee ? employee.leave : null })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: 'any',
    stringify: option => `${option.label.props.leaveType.name} ${option.label.props.leaveType.description} ${option.label.props.leaveType.rename} ${option.value}`
  };

  const selectProps = {
    id: "cyLeaveBalanceSelect",
    className: "psrs-select",
    classNamePrefix: "psrs",
    isMulti: false,
    isClearable: true,
    isSearchable: true,
    options: options,
    isDisabled: disabled,
    defaultValue:  _.find(options, { value: props.value }),
   // inputValue: state.inputValue,
    onInputChange: inputValue => setState({ ...state, inputValue }),
    menuIsOpen: state.menuIsOpen,
    onMenuOpen: () => setState({ ...state, menuIsOpen: true }),
    onMenuClose: () => setState({ ...state, menuIsOpen: false }),
    filterOption: createFilter(filterConfig),
    onChange: (select, action) => {
      // setState({ ...state, value: select })
      const value = select && select.value ? select.value : null
      props.handleChange({ ...props, field: "payTypeCode", value })
    },
  }

  return <Select {...selectProps} />
}

export default LeaveBalanceSelect
