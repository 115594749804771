import React, {useState, useEffect} from "react"
import {Input} from "antd"

export const StringFilter = (props) => {
  const {disabled, field, value, handleChange} = props

  const [state, setState] = useState({
    value: value
  })

  const inputProps = {
    disabled: disabled ? true : false,
    className: "InlineEdit",
    placeholder: props.placeholder || "Enter text",
    value: state.value,
    onChange: (e) => {
      setState({...state, value: e.target.value})
    },
    onBlur: (e) => {
      const val = e.target.value
      const sql = val ? `"${field}" IS NOT NULL AND "${field}" ILIKE '%${state.value}%'` : undefined
      handleChange({...props, field, value: val, sql})
    }
  }

  useEffect(() => {
    setState({...state, value})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return <Input data-cy="string-filter" {...inputProps} />
}

export default StringFilter
