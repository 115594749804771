import React, { useState } from "react"
import { store } from "../../store/psrs"
import Select from "react-select"
// import {BusinessUnitSelectOptions} from "./_SelectOptions"
import _ from "lodash"

export const BusinessUnitSelect = props => {
  const field = props.field || "businessUnitCode"
  const { record } = props
  const id = record ? record.id : props.id
  const value = props.value ? props.value : record ? record[field] : undefined

  const BusinessUnits = store.get("BusinessUnits") || []
  let BusinessUnitSelectOptions = BusinessUnits.map(r => ({ value: r.code, label: r.name }))
  BusinessUnitSelectOptions = _.sortBy(BusinessUnitSelectOptions, ['label']) 


  const [state, setState] = useState({
    options: BusinessUnitSelectOptions,
    value: value ? _.find(BusinessUnitSelectOptions, {value: value}) : null,
    sql: null,
  })

  const selected = _.filter(BusinessUnitSelectOptions, b => {
    return value ? value === b.value : null
  })

  let _selected = state.value
  if (state.value !== selected) _selected = selected

  const selectProps = {
    isDisabled: props.disabled,
    isMulti: props.isMulti,
    id: "BusinessUnitSelect",
    className: "psrs-select",
    classNamePrefix: "psrs",
    placeholder: "Select a Business Unit",
    //isMulti: false,
    isClearable: true,
    options: BusinessUnitSelectOptions,
    defaultValue: _selected, //state.value,
    value: _selected,
    onChange: select => {
      const val = select && select.value ? select.value : null
      const sql = val ? `"${field}" IS NOT NULL AND "${field}" = '${val}'` : null
      setState({ ...state, sql })
      props.handleChange({ ...props, value: val, field, id, sql })
      return
    },
  }

  return <Select {...selectProps} />
}

export default BusinessUnitSelect
