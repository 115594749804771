import React from "react"
import _ from "lodash"

export function renderSelectDropdown(menu, componentName) {
  return (
    <div data-cy="SelectDropdown">
      <div data-cy={componentName}>{menu}</div>
    </div>
  )
}

export function filterOption(inputValue, option) {
  const { label, value, title } = option.props
  const val = inputValue.toLowerCase()
  const _label = _.isString(label) ? label.toLowerCase() : `${label}`
  const _value = _.isString(value) ? value.toLowerCase() : `${value}`
  const _title = _.isString(title) ? title.toLowerCase() : `${title}`
  if (label && _label.includes(val)) return true
  if (value && _value.includes(val)) return true
  if (title && _title.includes(val)) return true
  else return false
}
