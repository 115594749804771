import React, { useState, useEffect } from "react"
import { store, Can, Emitter } from "../store/psrs"
import useMyContext from "../store/useMyContext"
import moment from "moment-timezone"
import { Button, /*Menu,*/ Icon, Tooltip } from "antd"
import { useStateIfMounted } from "use-state-if-mounted"
import PayPeriodNav from "./PowerView/PayPeriodNav"
import {ShiftDrawer} from "./ShiftDrawer"
import {NewShiftDrawer} from "./NewShiftDrawer"
// import uniqid from "uniqid"
// import {MyShifts, SupervisedShifts, AvailableShifts} from "../Shifts"
// import {MyTimeEntries, SupervisedTimeEntries} from "../TimeEntries"
import Shifts from "./Shifts"
import TimeEntries from "./TimeEntries"
import TimeEntrySubNavButtons from "./TimeEntries/SubNavButtons"
import ShiftSubNavButtons from "./Shifts/SubNavButtons"

import Approvals from "./Approvals"
import ApprovalsV2 from "./ApprovalsV2"

import ApprovalSubNavButtons from "./Approvals/SubNavButtonsV2"
import ApprovalSubNavButtonsV2 from "./ApprovalsV2/SubNavButtonsV2"

import PayPeriodStats from "./PayPeriodStats"

//import { Search } from "./Admin/fields"
//import useSWR, { mutate } from "swr"

// { props.page === "MyShifts" && <MyShifts /> }
// { props.page === "SupervisedShifts" && <SupervisedShifts /> }
// { props.page === "AvailableShifts" && <AvailableShifts /> }
// { props.page === "MyTimeEntries" && <MyTimeEntries /> }
// { props.page === "SupervisedTimeEntries" && <SupervisedTimeEntries /> }

export const PowerView = props => {
  const context = useMyContext()
  const {shiftDrawerId, newShiftDrawer} = context
  const { model, history, page } = props
  const PayPeriod = store.get('PayPeriod')

  //const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone.toLocaleLowerCase()
  //const userTimeZone = 'America/Los_Angeles'
  
  const startOf = moment().startOf("week").subtract(1, "week")
  const ppStart = PayPeriod ? moment(PayPeriod.start) : startOf
  const ppEnd = PayPeriod ? moment(PayPeriod.end): startOf.add(2, "weeks")
  //const ppEndAlt = startOf.add(2, "weeks")
  const ppLocked = store.get('PayPeriod').locked || false
  const [mounted, setMounted] = useState(false)
  
  const [isPPLocked, setIsPPLocked] = useStateIfMounted(ppLocked)
  //const [isLocked, setIsLocked] = useState({ className: `stat ${isPPLocked ? "on locked": "on unlocked" }`, type: isPPLocked ? "lock" :"unlock" , style: { color: isPPLocked ? 'red' : 'green'}})
  
  // this was off by a day locally...  but is fine on the server.  UTC ?
  //const payPeriodTitle = `${ppStart.format("MMM Do")} - ${ppEnd.subtract(1,"day").format("MMM Do")} ${ppStart.format("YYYY")}`
  const payPeriodTitle = `${ppStart.format("MMM Do")} - ${ppEnd.format("MMM Do")} ${ppEnd.format("YYYY")}`

  //const locked = store.get('PayPeriod').locked || false
  let isLocked = { className: `stat ${isPPLocked ? "on locked": "on unlocked" }`, type: isPPLocked ? "lock" :"unlock" , style: { color: isPPLocked ? 'red' : 'green'}}


  const [state, setState] = useState({
    mounted: false,
    filters: {},
    leftDrawerOpen: false,
    rightDrawerOpen: false,
    shiftDrawer: id => setState({ ...state, shiftId: id }),
    newShiftDrawer: false,
    active: null,
    model,
   // pk: "id",
   // model: "shift",
   // ids: [],
   // initialRecords: undefined, 
  })

  //const [records, setRecords] = useState(state.initialRecords || [])

  //const key = `${store.get("api")}/api/employees`

  /*
  const createShift = selection => {
    const value = selection.item.props.value
    let payTypeCode
    if (value === "overtime") payTypeCode = '200'
    if (value === "dive") payTypeCode = '217'
    if (value === "jury") payTypeCode = '17'
    if (value === "court") payTypeCode = '261'
    if (value === "leave") payTypeCode = '30'
    if (value === "callout") payTypeCode = '210'
    if (value === "evidence2hr") payTypeCode = '250'
    if (value === "evidence4hr") payTypeCode = '251'
    if (value === "blank") payTypeCode = '1'
    context.setNewShiftDrawerPayTypeCode(payTypeCode)
  }
 */

  // <Menu.Item key={uniqid()} value="jury"> Jury Duty </Menu.Item>
  // <Menu.Item key={uniqid()} value="leave"> Leave </Menu.Item>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  /*
  const createShiftMenu = useMemo(() => (
    <Menu onClick={createShift}>
      <Menu.Item key={uniqid()} value="overtime"> Overtime </Menu.Item>
      <Menu.Item key={uniqid()} value="dive"> Dive </Menu.Item>
      <Menu.Item key={uniqid()} value="court"> Court </Menu.Item>
      <Menu.Item key={uniqid()} value="callout"> Call Out/Back </Menu.Item>
      <Menu.Item key={uniqid()} value="evidence2hr"> Evidence Pay (2hr) </Menu.Item>
      <Menu.Item key={uniqid()} value="evidence4hr"> Evidence Pay (4hr) </Menu.Item>
      <Menu.Item key={uniqid()} value="blank"> Blank </Menu.Item>
    </Menu>
  ))
    */

  const toggleLoading = () => setState({ ...state, loading: !state.loading })

  const getAvailableShifts = props.page === "AvailableShifts"


  useEffect(() => {
    if (!!shiftDrawerId) setState({...state, })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftDrawerId])

  useEffect(() => {
    //state.mounted && setIsPPLocked(!!isPPLocked) //_setApprovals()
    if (mounted) {
      Emitter.on("ChangedLockStatus", (lockStatus) => {
        //console.log("Emitter.on('ChangedPayPeriod')")
        //if (state.mounted) {
        if (mounted) {
          setIsPPLocked(lockStatus.locked)  /// <--- this is my unmounted error
           // eslint-disable-next-line react-hooks/exhaustive-deps
          isLocked = { className: `stat ${lockStatus.locked ? "on locked": "on unlocked" }`, type: lockStatus.locked ? "lock" :"unlock" , style: { color: lockStatus.locked ? 'red' : 'green'}}
          //setIsLocked({ className: `stat ${lockStatus.locked ? "on locked": "on unlocked" }`, type: lockStatus.locked ? "lock" :"unlock" , style: { color: lockStatus.locked ? 'red' : 'green'}})
        } else {
       ////   setState({ ...state, mounted:true })
          //setIsPPLocked(lockStatus.locked)
          //setIsLocked({ className: `stat ${isPPLocked ? "on locked": "on unlocked" }`, type: isPPLocked ? "lock" :"unlock" , style: { color: isPPLocked ? 'red' : 'green'}})
  
        }
      })
      
    } else {
      setMounted(true)
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, state.mounted])

  useEffect(() => {
    if (!state.mounted) {
      setState({ ...state, mounted:true })
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => { state.mounted = true }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const subNavButtonProps = { history, page, role: context.role }

 // const searchProps = { key: "search", state, setState, mutate, url: key }

  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">
          {props.title}
          {!getAvailableShifts && <small>{payPeriodTitle}</small>}
          <Tooltip mouseEnterDelay={isLocked ? 0 : 99} title={ isPPLocked? "Locked" : "Not Locked" }>
            <span className="statusIcon">
              <small><Icon {...isLocked}/></small>
            </span>
          </Tooltip>
          {/*
          <span>
            &nbsp;&nbsp;<a style={{ fontSize: '12px'}} href="https://submission.bromb.co/bwright/PSRS">Give Feedback</a>
          </span>
          */}
        </h1>
        
        {/*
        <div>
            <Search {...searchProps} />
        </div>
        */}
        <div className="actions">
          {props.page !== "AvailableShifts" && <PayPeriodNav toggleLoading={toggleLoading} payPeriod={context.payPeriod} />}
          {props.page.includes("Shifts") && <ShiftSubNavButtons {...subNavButtonProps} />}
          {props.page.includes("TimeEntries") && <TimeEntrySubNavButtons {...subNavButtonProps} />}
          {props.page.includes("Approvals") && !props.page.includes("V2") && <ApprovalSubNavButtons {...subNavButtonProps} />}
          {props.page.includes("ApprovalsV2") && <ApprovalSubNavButtonsV2 {...subNavButtonProps} />} 

          <Can I="create" a="Shift">
           {/* <Dropdown overlay={createShiftMenu}>
              <Button type="dashed" icon="plus-square" onClick={e => context.setShiftDrawerId(0)}>
                Create Shift
              </Button>
            </Dropdown>
          */}
              <Button type="dashed" icon="plus-square" onClick={e => {
                  
                  context.setShiftDrawerId(0)
                  context.setNewShiftDrawerPayTypeCode('1')
                } }   
              >
                Create Shift
              </Button>
          </Can>
        </div>
      </div>
      <div className="view_content">
        {props.page.includes("Shifts") && <Shifts {...props} />}
        {props.page.includes("TimeEntries") && <TimeEntries {...props} />}

        {props.page.includes("ApprovalsV2") && <ApprovalsV2 {...props} />}
        {props.page.includes("Approvals") && !props.page.includes("V2") && <Approvals {...props} />}
        
        {props.page.includes("PayPeriodStats") && <PayPeriodStats {...props} />}
      </div>
      {!!shiftDrawerId && <ShiftDrawer />}
      {!!newShiftDrawer && <NewShiftDrawer payTypeCode={newShiftDrawer} />}
    </React.Fragment>
  )
}

export default PowerView
