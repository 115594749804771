// This file should match in both CLIENT & MOBILE
import { store } from "./psrs"
import { detectSubjectType, AbilityBuilder, Ability } from "@casl/ability"
import moment from "moment"
// import { subject as a } from "@casl/ability"

export const ability = user => {
  const User = user || store.session.get("CurrentUser")

  const subjectName = subject => {
    if (subject && typeof subject === "object" && subject.modelName) {
      return subject.modelName
    }
    return detectSubjectType(subject)
  }

  const { can, cannot, build } = new AbilityBuilder(Ability)

  if (!!User) {
    const employeeNumber = User.number.toString()
    // cannot("submit", "Shift", { end: { $gt: Date.now() - 30 * 60 * 1000 } })
    // can("submit", "Shift", { end: { $gt: Date.now() - 30 * 60 * 1000 } })

    //can("submit", "Shift", { end: { $lt: moment().subtract(30, "minutes").toISOString() } })
    can("submit", "Shift", { end: { $lte: moment().toISOString() } })
    cannot("submit", "Shift", { end: { $gte: moment().subtract(30, "minutes").toISOString() } })

    // Evidence disabled
    can("edit:evidence:shift", "Shifts.timeEntries", { payTypeCode: { $ne: ["250","251"]}})
    
    /*
    if (User.number === '264609') {
      cannot("edit:evidence:shift")
      cannot("edit:Shift:Start")
      cannot("edit:Shift:End")
      cannot("edit:Shift.paidLunch")
      cannot("edit", "TimeEntry", { employeeNumber: employeeNumber })
    }
    */

    // VIEWS
    can("visit:Approvals")
    can("visit:Employee")
    can("visit:Employees")
    can("visit:Icons")
    can("visit:Logout")
    //can("visit:PrintSchedule")
    can("visit:Settings")
    can("visit:Shift")
    can("visit:Shifts")
    can("visit:TimeEntries")
    //can("visit:TimelineSchedule")

    // SHIFT DRAWER
    can("see:shift:timeEntries", "Shift", { employeeNumber: employeeNumber, isSick: false, requestedOff: false })
    can("see:shift:sickEntries", "Shift", { employeeNumber: employeeNumber, isSick: true })
    can("see:shift:leaveEntries", "Shift", { employeeNumber: employeeNumber, requestedOff: true })
    can("see:shift:reasons", "Shift", { durationOt: { $gt: 0 } })
    can("see:row:icon:available", "Shift", { available: true })
    can("pto", "Shift", { isOT: { $ne: true }, isSick: { $ne: true } })
    can("sick", "Shift", { isOT: { $ne: true }, requestedOff: { $ne: true } })

    can("approve", "TimeEntry", { superNumber1: employeeNumber })
    can("approve", "TimeEntry", { superNumber2: employeeNumber })
    can("approve", "TimeEntry", { superNumber3: employeeNumber })
    cannot("approve","TimeEntry", {employeeNumber: employeeNumber})

    can("approve", "Shift", { superNumber1: employeeNumber })
    can("approve", "Shift", { superNumber2: employeeNumber })
    can("approve", "Shift", { superNumber3: employeeNumber })    
    cannot("approve","Shift", {employeeNumber: employeeNumber})


    can("create", "Shift")

    // allow all to edit groups
    can("edit:shift:Groups")

    // allow all to edit Assignment
    can("edit:shift:Assignment")

    // don't allow edit in Shift Drawer once processed
    //can("edit", "TimeEntry", { process: true } )
    //cannot("edit","Shift", { process: true })

    if (User.role === "employee") {
      // SHIFTS
      can("bidOn", "Shift", { available: true })
      cannot("manageBids")
      can("edit", "Shift", { employeeNumber: employeeNumber,  process: false  })
      //can("edit", "TimeEntry", { employeeNumber: employeeNumber})
      cannot("edit:Shift:Employee")
      cannot("edit:Shift:Supervisor")
      cannot("edit:Shift:Groups")
      cannot("edit:Shift:Assignment")
      cannot("edit:Shift:BusinessUnit")
      cannot("edit:Shift:SubLedger")
      // can("submit", "Shift", { end: { $lt: Date.now() - 30 * 60 * 1000 } })
      // can("submit", "Shift", { end: { $lt: moment().add(30, "minutes").toDate() } })
      // can("submit", "Shift", { employeeNumber: employeeNumber })

      // TIME ENTRIES
      can("edit", "TimeEntry", { employeeNumber: employeeNumber, process: false })
      // can("submit", "TimeEntry", { end: { $lt: moment().subtract(30, "minutes").toDate() } })
      can('see:Role')
      can("see:employee:Role")
      can('see:psrsIcon')
      cannot("visit:ApprovalsV2")
    }

    /*  removing users ability to edit this field.
        driving from TimeEntry grid now.
    if (User.isDive) {
      can("add:DiveTime")
    }

    if (User.isFieldTraining) {
      can("add:FTOtime")
    }
    */

    if (User.role === "supervisor") {
      can("delete", "Shift", { superNumber1: employeeNumber })
      can("delete", "Shift", { superNumber2: employeeNumber })
      can("delete", "Shift", { superNumber3: employeeNumber })
      can("delete", "TimeEntry", { superNumber1: employeeNumber })
      can("delete", "TimeEntry", { superNumber2: employeeNumber })
      can("delete", "TimeEntry", { superNumber3: employeeNumber })
      can("delete", "Team", { superNumber1: employeeNumber })
      // can("delete", "Team", { superNumber2: employeeNumber })

      can("manageBids", "Shift", { superNumber1: employeeNumber })
      can("manageBids", "Shift", { superNumber2: employeeNumber })
      can("manageBids", "Shift", { superNumber3: employeeNumber })




      // Approvals
      can("visit:ApprovalsV2")
      can("approve", "Shift")
      can("approve", "TimeEntry")
      cannot("approve","TimeEntry", {employeeNumber: employeeNumber})
      cannot("approve","Shift", {employeeNumber: employeeNumber})

      // access Admin Forms
      //can("view:assignments")
      //can("view:businessunits")
      can("view:employees")
      //can("view:equipment")
      //can("view:groups")
      //can("view:paytypes")
      //can("view:reasons")
      //can("view:specialties")
      //can("view:subledgers")
      //can("view:shifttemplates")
      //can("view:sessions")
      //can("view:monitor")

    }

    if (User.role === "admin") {
      can("edit:TimeEntry:process")
      can("edit:TimeEntry:lock")
      can("delete", "Shift")
      can("delete", "TimeEntry")
      can("delete", "Team")
      can("edit", "Role")
      can("edit:employee:Role")
      can("delete:Sessions")
      can("manageBids")

      // Approvals
      can("visit:ApprovalsV2")
      can("approve", "Shift")
      can("approve", "TimeEntry")
      can ("approve", "All")
      cannot("approve","TimeEntry", {employeeNumber: employeeNumber})
      cannot("approve","Shift", {employeeNumber: employeeNumber})
      //cannot("approve","All", {employeeNumber: employeeNumber})

      // access Admin Forms
      can("view:assignments")
      can("view:businessunits")
      can("view:employees")
      can("view:equipment")
      can("view:groups")
      can("view:paytypes")
      can("view:reasons")
      can("view:specialties")
      can("view:subledgers")
      can("view:shifttemplates")
      can("view:sessions")
      can("view:monitor")
      can("viewAll:Teams")
      can("visit:Teams")

      // RESTORE
      can("restore:Shift")
      can("restore:TimeEntry")

    }

    if ( User.businessUnitCode === '500506101') {
      can("edit:shift:paidLunch")
    }

    if (User.role === "supervisor" || User.role === "admin") {
      // SHIFTS
      // moved to everyone can("edit:shift:Groups")

      can("bidOn", "Shift", { available: true })
      //can("manageBids")

      can("edit:shift:Employee")
      can("edit:shift:Supervisor")
      // moved to everyone can("edit:shift:Assignment")
      can("edit:shift:BusinessUnit")
      can("edit:shift:SubLedger")
      can("edit:shift:paidLunch")
      can("edit", "Shift")
      can("see:shift:sickEntries", "Shift", { isSick: true })
      can("see:shift:leaveEntries", "Shift", { requestedOff: true })
      can("see:shift:timeEntries", "Shift", { isSick: false, requestedOff: false })
      can("see:shift:bids", "Shift", { available: true })
      can("approve:shift")
      can("approve", "timeEntry")

      // VIEWS
      //can("viewAll:Teams")
      can("visit:AdminNav")
      cannot('see:psrsIcon')
      can("visit:Assignments")
      can("visit:BusinessUnits")
      can("visit:Equipment")
      can("visit:PayTypes")
      can("visit:RecordsViewer")
      can("visit:Specialties")
      can("visit:SubLedgers")
      //can("visit:Teams")
      can("visit:Template")
      can("visit:PrintSchedule")
      can("visit:TimelineSchedule")
      can("visit:PayPeriodStats")

      // CREATE
      can("create:Assignments")
      can("create:Equipment")
      can("create:Groups")
      can("create:Reasons")
      can("create:Shifts")
      can("create:Specialties")
      can("create:ShiftTemplates")
      can("create:SubLedgers")
      can("create:Teams")
      can("create:Templates")
      can("create", "TimeEntry")
      can("schedule:Shifts")

      // DELETE
      can("delete:ShiftTemplates")

      // COPY
      can("copy:ShiftTemplates")


      // EDIT
      can("edit:ShiftTemplates")

      // RESTORE
      //can("restore:shifts")
      //can("restore:TimeEntry")


      //AUTH0 
      can("see:SilentInvite")

      // Breadcrumbs
      can("see:Breadcrumb")
      can("visit:Breadcrumb")
      can("view:Breadcrumb")

      // Approvals
      //can("approve", "Shift")
      //can("approve", "TimeEntry")

    }
  }

  return build({ subjectName })
}

export default ability

// can("see:shift:action:submit", "Shift")
// can("see:shift:action:sick", "Shift")
// can("see:shift:action:pto", "Shift")
// can("see:shift:action:approve", "Shift")
// can("see:shift:action:reject", "Shift")
// can("see:shift:action:process", "Shift")
// can("see:shift:action:delete", "Shift")
// can("see:shift:action:otherShift", "Shift")
// can("see:shift:action:sickShift", "Shift")
// can("see:row:icon:approve", "Record", { approved: true })
// can("see:row:icon:reject", "Record", { rejected: true })
// can("see:row:icon:sick", "Record", { isSick: true })
// can("see:row:icon:pto", "Record", { requestedOff: true })
// can("see:row:icon:submit", "Record", { submit: true })
// can("see:row:icon:ot", "Record", { isOT: true })
// can("see:row:icon:process", "Record", { process: true })
