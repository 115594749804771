import React, { useEffect, useState } from "react"
import { reqOptions, Emitter, store } from "../store/psrs"
//import { useHistory } from "react-router-dom"
import { useMyContext, useAbilityContext } from "../store/useMyContext"
import useSWR from "swr"
import ReactTable from "react-table"
import { shiftColumns } from "./Approvals/shiftColumns"
import { timeEntryColumns } from "./Approvals/timeEntryColumns"
//import { Button } from "antd"
import {Layout} from "antd"
//import { ShiftDrawer } from "./ShiftDrawer"
//import { NewShiftDrawer } from "./NewShiftDrawer"
import _ from "lodash"

const { Content } = Layout

export const Approvals = props => {
  // console.log("Approvals", props)
  const { /*url,*/ page } = props
  //const history = useHistory()
  const context = useMyContext()
  const ability = useAbilityContext()

  const payPeriod = store.get("PayPeriod")
  const payPeriodId = payPeriod.id
  const query = props.query(payPeriodId)
  const [loading, setLoading] = useState(false)

  //const { shiftDrawerId, newShiftDrawer } = context

  /*
  const state = {
    loading: false,
    view: page === "ShiftApprovals" ? "shifts" : "timeEntries",
    filter: false,
    columns: {
      shifts: shiftColumns,
      timeEntries: timeEntryColumns,
    },
  }
  */

  //"https://localhost:5001/api/approvals/timeEntry"
  //"https://localhost:5001/api/raw_query/timeEntries"
  /*
  const xfetcher = url => {
    const options = { ...reqOptions }
    return fetch(url, options).then(res => res.json())
  }
  */

  const fetcher = key => {
    const options = {
      ...reqOptions,
      method: "POST",
      body: JSON.stringify({ query }),
    }
    setLoading(true)
    return fetch(props.url, options)
      .then(async res => {
        setLoading(false)
        const records = await res.json()
        const filteredRecs = _.filter(records, t => t.name !== "Unpaid Lunch")
        return filteredRecs 
        //return records
      })
      .catch(err => Emitter.emit("Approvals/index.js", { fn: "fetcher", error: err }))
  }

  const swrOpts = { revalidateOnFocus: false }
  //const { data } = useSWR(props.url, fetcher, swrOpts)
  const { data, mutate } = useSWR(query, fetcher, swrOpts)

  useEffect(() => {
    Emitter.on("ShiftDrawerUpdate", () => {
      //const pageUrl = `${store.get("api")}${props.path}`
      //mutate(url)
      mutate()
    })
    Emitter.on("ShiftDrawerClose", () => {
      //const pageUrl = `${store.get("api")}${props.path}`
      //mutate(url)
      mutate()
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refresh = () => {
    //const pageUrl = `${store.get("api")}${props.path}`
    mutate() // mutate(props.url)

  }

  const columnProps = { ...props, context, ability, refresh }

  const tableOptions = {
    noDataText: "",
    filterable: true,
    loading: loading, //state.loading,
    data: data,
    defaultPageSize: 100,
    columns: page === "ShiftApprovals" ? shiftColumns(columnProps) : timeEntryColumns(columnProps),
    multiSort: true,
    defaultSorted: [
      { id: "employee.fullName", asc: true },
      { id: "start", asc: true },
    ],
  }

  //console.log(data)

  // added layout/content for scrolling on small screens
  return (
    <React.Fragment>
      {/*<div className="view_title">
        <h1 data-cy="page-title">{props.title}</h1>
        <div className="actions">
          <Button.Group>
            <Button type={page === "ShiftApprovals" ? "primary" : "default"} onClick={() => history.push("/approvals/shifts")}>
              Shifts
            </Button>
            <Button
              type={page === "TimeEntryApprovals" ? "primary" : "default"}
              onClick={() => history.push("/approvals/timeEntries")}
            >
              Time Entries
            </Button>
          </Button.Group>
        </div>
      </div>
  <div className="view_content"> */}
      <Layout className="RecordsViewer">
        <Content className="Content">
          <ReactTable {...tableOptions} />
          {/*</div> */}
          {/*{!!shiftDrawerId && <ShiftDrawer />}*/}
          {/*{!!newShiftDrawer &&  <NewShiftDrawer payTypeCode={newShiftDrawer} />}*/}
        </Content>
      </Layout>
      
   </React.Fragment>
  )
}

export default Approvals
