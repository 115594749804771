import React from "react"
import {Icon, Tooltip, /*Popconfirm*/ } from "antd"

//import { FinePrint } from "../FinePrint"

export const ShiftGridStatus = props => {


        //const newCanSubmit = true
        // const valid = true

        const renderTimeStatus = entry => {
            //const { approved, rejected, submit, process, status, isOT, assignmentId, paidLunch, locked} = entry
            const { submit, process, status, isOT, assignmentId, paidLunch, locked} = entry

            const syncd = { className: `stat jde ${status}`, type: "cloud" }
            const paid = { className: `stat ${process ? "on processed" : ""}`, type: "dollar" }
            const submitted = { className: `stat ${submit ? "on submitted" : ""}`, type: "check-circle" }
            const ot = { className: `stat ${isOT ? "on has overtime" : ""}`, type: "clock-circle" }
            const acting = { className: `stat ${assignmentId ? "on acting" : ""}`, type: "safety-certificate" }
            //const good = { className: `stat ${approved ? "on approved" : ""}`, type: "like" }
            //const bad = { className: `stat ${rejected ? "on rejected" : ""}`, type: "dislike" }
            const paidlunch = { className: `stat ${paidLunch ? "on paidLunch": "" }`, type: "rest" }
            const isPaidLunch = paidLunch
            const isLocked = { className: `stat ${locked ? "on locked": "on unlocked" }`, type: "lock" }


            return (
                <div className="StatusIcons">
                <Tooltip title="Syncd to JDE">
                    <span className="statusIcon">
                    <Icon {...syncd} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={isOT ? 0 : 99} title="Unscheduled Overtime">
                    <span className="statusIcon">
                    <Icon {...ot} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={assignmentId ? 0 : 99} title="Acting Role">
                <span className="statusIcon">
                    <Icon {...acting} />
                </span>
                </Tooltip>
                <Tooltip title="Submitted">
                    <span className="statusIcon">
                    <Icon {...submitted} />
                    </span>
                </Tooltip>
                {/* <Tooltip mouseEnterDelay={rejected ? 0 : 99} title="Rejected">
                    <span className="statusIcon">
                    <Icon {...bad} />
                    </span>
                </Tooltip> */}
                {/* <Tooltip mouseEnterDelay={approved ? 0 : 99} title="Approved">
                    <span className="statusIcon">
                    <Icon {...good} />
                    </span>
                </Tooltip> */}
                <Tooltip mouseEnterDelay={process ? 0 : 99} title="Processed">
                    <span className="statusIcon">
                    <Icon {...paid} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={paidlunch ? 0 : 99} title={ isPaidLunch ? "Paid Lunch" : "Un Paid Lunch" }>
                    <span className="statusIcon">
                    <Icon {...paidlunch} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={isLocked ? 0 : 99} title={ locked ? "Locked" : "Not Locked" }>
                    <span className="statusIcon">
                    <Icon {...isLocked} />
                    </span>
                </Tooltip>
                </div>
            )
        }
        
        const renderShiftStatus = shift => {
            //const { available, requestedOff, approved, imbalanced, rejected, isSick, isOT, isCollision, submit, assignmentId, assignment,paidLunch,locked} = shift
            const { available, requestedOff, imbalanced, isSick, isOT, isCollision, submit, assignmentId, assignment,paidLunch,locked} = shift
            const submitted = { className: `stat ${submit ? "on submitted" : ""}`, type: "check-circle" }
            const ot = { className: `stat ${isOT ? "on has overtime" : ""}`, type: "clock-circle" }
            const bid = { className: `stat ${available ? "on bid" : ""}`, type: "user-add" }
            const pto = { className: `stat ${requestedOff ? "on pto" : ""}`, type: "message" }
            const sick = { className: `stat ${isSick ? "on sick" : ""}`, type: "medicine-box" }
            //const good = { className: `stat ${approved ? "on approved" : ""}`, type: "like" }
            //const bad = { className: `stat ${rejected ? "on rejected" : ""}`, type: "dislike" }
            const wrongTime = { className: `stat ${imbalanced ? "on imbalanced" : ""}`, type: "stop" }
            const collision = { className: `stat ${isCollision ? "on collision" : ""}`, type: "issues-close" }
            const acting = { className: `stat ${assignmentId ? "on acting" : ""}`, type: "safety-certificate" }
            const paidlunch = { className: `stat ${paidLunch ? "on paidLunch": "" }`, type: "rest" }
            const isPaidLunch = paidLunch
            const isLocked = { className: `stat ${locked ? "on locked": "on unlocked" }`, type: "lock" }
      
            return (
            <div className="StatusIcons">
                <Tooltip mouseEnterDelay={isSick ? 0 : 99} title="Sick">
                    <span className="statusIcon">
                        <Icon {...sick} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={isCollision ? 0 : 99} title="Collision">
                    <span className="statusIcon">
                        <Icon {...collision} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={requestedOff ? 0 : 99} title="Time Off">
                    <span className="statusIcon">
                        <Icon {...pto} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={available ? 0 : 99} title="Available">
                    <span className="statusIcon">
                        <Icon {...bid} />
                    </span>
                </Tooltip>
                <Tooltip title="Submitted">
                    <span className="statusIcon">
                        <Icon {...submitted} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={isOT ? 0 : 99} title="Has Overtime">
                    <span className="statusIcon">
                        <Icon {...ot} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={imbalanced ? 0 : 99} title="Imbalanced Time Entries">
                    <span className="statusIcon">
                        <Icon {...wrongTime} />
                    </span>
                </Tooltip>
                <Tooltip mouseEnterDelay={assignmentId ? 0 : 99} title={assignment ? assignment.name : "Acting Role"}>
                    <span className="statusIcon">
                        <Icon {...acting} />
                    </span>
                </Tooltip>
                {/* <Tooltip mouseEnterDelay={rejected ? 0 : 99} title="Rejected">
                    <span className="statusIcon">
                        <Icon {...bad} />
                    </span>
                </Tooltip> */}
                {/* <Tooltip mouseEnterDelay={approved ? 0 : 99} title="Approved">
                    <span className="statusIcon">
                        <Icon {...good} />
                    </span>
                </Tooltip> */}
                {/* here */}
                {/* SUBMIT */}
                {/* <Tooltip mouseEnterDelay={newCanSubmit ? 0 : 99}  title={shift.submit ? 'Un Submit': 'Submit'}>
                    <Popconfirm
                        disabled = {newCanSubmit ? false : true }
                        title={shift.submit ? "Unsubmit Shift" : <FinePrint />}
                        onConfirm={ async () => {
                            //const valid = await (validateFields(shift))       
                            if (newCanSubmit && valid) {
                                // handle isOT notificaiton on submit
                                if (shift.isOT && !shift.submit) {
                                    //const updateNotification = await REQ(`/api/notifications/${shift.id}`, "POST")
                                    const updateNotification = 'Yup...'
                                    console.log('Submit updateNotification -> ', updateNotification)
                                }

                                //updateShift({ field: "submit", value: !shift.submit, shiftId: shift.id })

                            } 
                        }}
                        okText={shift.submit ? "Un Submit" : "Submit"}
                        cancelText="Cancel"
                    >
                        <span className={`statusIcon ${newCanSubmit && "hoverPointer"}`}>
                            <Icon {...submitted} />
                        </span>
                    </Popconfirm>
                </Tooltip> */}
 

                {/* end here*/}
                {<Tooltip mouseEnterDelay={paidlunch ? 0 : 99} title={ isPaidLunch ? "Paid Lunch" : "Un Paid Lunch" }>
                    <span className="statusIcon">
                    <Icon {...paidlunch} />
                    </span>
                </Tooltip>}
                <Tooltip mouseEnterDelay={isLocked ? 0 : 99} title={ locked? "Locked" : "Not Locked" }>
                <span className="statusIcon">
                    <Icon {...isLocked} />
                </span>
                </Tooltip>
            </div>
            )

        }

        if (props.model.toLowerCase() === 'shift') {
            return renderShiftStatus(props.record)
        }

        if (props.model.toLowerCase() === 'entry') {
            return renderTimeStatus(props.record)
        }

        
  }
  
  export default ShiftGridStatus