import React from "react"
import { Button, Popconfirm, Tooltip } from "antd"
import moment from "moment"
import {REQ, a} from "../../store/psrs"
import { CheckBox } from "grommet"

export const shiftColumns = (props) => {
  //console.log('shiftColumns props', props);

  const {ability, context, refresh} = props

  //const canApprove = ability.can("approve", a("Shift", shift))


  const approveEntry = async (model, id, value) => {
    await REQ(`/api/resources/shift/${id}`, "PATCH", { approved: value, rejected: false })
    refresh()
  }

  const rejectEntry = async (model, id, value) => {
    await REQ(`/api/resources/shift/${id}`, "PATCH", { approved: false, rejected: value })
    refresh()
  }

  return [
    {
      Header: "Employee",
      headerClassName: "alignLeft",
      accessor: "employee.fullName",
      sortable: true,
      Cell: row => (row.value ? row.value : "UNASSIGNED"),
    },
    {
      Header: "Date Of",
      headerClassName: "alignLeft",
      accessor: "start",
      sortable: true,
      Cell: row => moment(row.value).format("ddd MMM Do"),
    },
    {
      Header: "Hours",
      accessor: "duration",
      sortable: true,
      Cell: row => `${parseFloat(row.value).toPrecision(3)} Hrs`,
    },
    {
      Header: "Supervisor",
      headerClassName: "alignLeft",
      accessor: "supervisor1.fullName",
      sortable: true,
      Cell: row => row.value,
    },
    {
      Header: "Type",
      accessor: "id",
      sortable: false,
      Cell: row => {
        const rec = row.original
        return rec.isSick ? "SICK" : rec.requestedOff ? "LEAVE" : rec.isOT ? "OVERTIME" : "REGULAR"
      }
    },
    {
      Header: "Unpaid Lunch",
      accessor: "paidLunch",
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => <> <CheckBox disabled={true} checked={!cell.value}></CheckBox></>,
    },
    {
      Header: "In Collision",
      accessor: "isCollision",
      className: "justify-around",
      show: true,
      sortable: true,
      Cell: cell => <> <CheckBox disabled={true} checked={cell.value}></CheckBox></>,
    },
    {
      Header: "",
      className: "justify-around",
      accessor: "id",
      sortable: false,
      Cell: row => {
        const rec = row.original
        const disabled = ability.cannot("approve", a("Shift", rec))
        return (
          <>
            <Button.Group>
              {/* IF SUPERVISOR OR ADMIN 
              <Button
                disabled={disabled}
                value={rec.id}
                className={!!row.original.approved ? "approved" : ""}
                icon="like"
                type={rec.approved ? "primary" : "default"}
                onClick={e => approveEntry("shift", rec.id)}
              /> */}
						

              {/* APPROVE */}  
              <Tooltip title={`${rec.isCollision && rec.approved ? "Un Approve Shift in Collision" : rec.approved ? "Un Approve" : !rec.isCollision ? "Approve" : "Approve Shift in Collision" }`}>
              	<Popconfirm
                	title={rec.isCollsion && rec.approved ? "Un Approve Shift in Collision" : rec.approved ? "Un Approve Shift" : "Approve Shift Fine Print"}
                	onConfirm={ e => 
                    //approveEntry("shift", rec.id)}
                   (!rec.rejected) && approveEntry("shift", rec.id, !rec.approved )}
                	okText={rec.approved ? "Un Approve" : "Approve"}
                	cancelText="Cancel"
              	>
                	<Button
                  	icon="like"
                  	disabled={disabled}
                  	type={rec.isCollision && rec.approved ? "danger" : rec.approved ? "primary" : "default"}
                	/>
              	</Popconfirm>
            	</Tooltip>

              {/* REJECT */}
              <Tooltip title={`${rec.isCollision && rec.rejected ? "Un Reject Shift in Collision" : rec.isCollision && !rec.rejected ? "Reject Shift in Collision" : rec.rejected ?"Un Reject" : "Reject"}`}>
              	<Popconfirm
                	title={rec.isCollision && rec.rejected ? "Un Reject Shift in Collision" : rec.rejected ? "Un Reject Shift" : "Reject Shift Fine Print"}
                	onConfirm={ e => 
                    //approveEntry("shift", rec.id)}
                   (!rec.approved) && rejectEntry("shift", rec.id, !rec.rejected )}
                	okText={rec.rejected ? "Un Rject" : "Reject"}
                	cancelText="Cancel"
              	>
              <Button
                className={!!row.original.rejected ? "rejected" : ""}
                icon="dislike"
                disabled={disabled}
                type={ rec.isCollision && rec.rejected ? "danger" : rec.rejected ? "primary" : "default"}
              />
              	</Popconfirm>
            	</Tooltip>

              {/*
              <Button
                disabled={disabled}
                value={rec.id}
                className={!!row.original.rejected ? "rejected" : ""}
                icon="dislike"
                type={rec.rejected ? "primary" : "default"}
                onClick={e => rejectEntry("shift", rec.id)}
              />
              */}

              <Button
                value={row.value}
                icon="eye"
                onClick={e => {
                  context.setShiftDrawerId(row.value)
                }}
              >
                View
              </Button>
            </Button.Group>
          </>
        )
      },
    },
  ]
}

export default shiftColumns
