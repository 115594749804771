import "react-app-polyfill/ie11"
import "nodelist-foreach-polyfill"
import dotenv from "dotenv"
import React from "react"
import ReactDOM from "react-dom"
import moment from "moment"
// import * as serviceWorker from "./serviceWorker"
import * as Cookies from "es-cookie"
import _ from "lodash"

import { App, Version } from "./components"
import { store, CheckMem } from "./store/psrs"

import "antd/dist/antd.css"
import "react-table/react-table.css"
import "./styles/app.scss"
import "./styles/working.sass"

// import * as Sentry from "@sentry/browser"
// Sentry.init({ dsn: "https://01d9ee26a0c147beb81e57f009876b5a@sentry.io/1388791" })
dotenv.config()

if (window.Cypress) {
  window.env = "test"
}

window.globalTime = moment()
window.Cookies = Cookies
window.checkMem = CheckMem
window.store = store
window._ = _

class Psrs extends React.Component {
  /*//crisp
  componentDidMount () {
    // Include the Crisp code here, without the <script></script> tags
    
    
    window.$crisp = [];
    window.$crisp.push(["safe", true])
    window.CRISP_WEBSITE_ID = "886971fa-2b9e-4c05-84d2-fe79b7650f88";

    (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
      
    })();
    window.$crisp.push(['do', 'chat:hide']);
    
   };
   */
  // bomb feedback  
  render() {

    return (
      <>
        <App />
        <Version />
      </>
    )
  }
}

const { protocol, host, pathname } = window.location
if (protocol === "http:") {
  const correctURL = `https://${host}${pathname}`
  console.log(`NOT HTTP ... should be ... ${correctURL}`)
  window.location.replace(correctURL)
}

ReactDOM.render(<Psrs />, document.getElementById("root"))

// Learn more about service workers: https://create-react-app.dev/docs/making-a-progressive-web-app/
// serviceWorker.register()
if ("serviceWorker" in navigator) {
  // Use the window load event to keep the page load performant
  window.addEventListener("load", () => {
    // if (!!window.location.port) {  // loading custom service worker from local relative path to serviceWorker.js from localhost
    //   console.log('LOADING FROM ./sww.js');
    //   navigator.serviceWorker.register("/sww.js");
    // } else {
    //   navigator.serviceWorker.register("/sww.js");
    // }
  })
}
