import React, { useState, useEffect } from "react"
import { reqOptions, store } from "../store/psrs"
import ReactTable from "react-table"
import useSWR, { mutate } from "swr"
import Select from "react-select"
import { Button } from "antd"
import { Search, InPut } from "./Admin/fields"
import { deleteResource, createResource, updateResource } from "./Admin/fields"
import { PayTypeSelect } from "./Forms/PayTypeSelect"
import _ from "lodash"

export const Reasons = props => {
  const pageTitle = "Reasons"
  const createTitle = "Create New Reason"

  const [state, setState] = useState({
    pk: "id",
    model: "reason",
    ids: [],
    initialRecords: store.get("_Reasons") || undefined,
		sort: {
			category:false
		}
  })

  const [records, setRecords] = useState(state.initialRecords || [])

  const categoryOptions = [
    { value: "SICK", label: "SICK" },
    { value: "OVERTIME", label: "OVERTIME" },
    { value: "REJECTED", label: "REJECTED" },
    { value: "OTHER", label: "OTHER" },
  ]

  const fetcher = async url => {
    const options = { ...reqOptions }
    const data = await fetch(url, options).then(res => res.json())
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    return data
  }
  const key = `${store.get("api")}/api/resources/reason`
  const { data } = useSWR(key, fetcher, { initialData: state.initialRecords })

  const _props = { state, setState, mutate, url: key }

  var sort = {}
	const changeSort = function (key) {
		if( Object.keys(state.sort).indexOf(key) === -1 ) {
			sort[key] = false;
			setState({ ...state, sort:sort });
		}
		else {
			sort[key] = !state.sort[key];
			setState({ ...state, sort:sort });
		}
	}

  const columns = [
    { Header: "Updated", accessor: "updatedAt", show: false, sortable: false },
    { Header: "ID", accessor: "id", className: "id_column", show: false, sortable: false },
    {
      Header: "",
      accessor: "i",
      sortable: false,
      show: true,
      width: 50,
      style: {
        color: 'white',
      },
    },
    {
			sortable: false,
      Header: () => (
				<span
            onClick={ () => changeSort("category") }
				>
				Category
				</span>
			),
      accessor: "category",
      className: "justify-around",
      Cell: cell => {
        const selectOptions = {
          className: "psrs-select",
          classNamePrefix: "psrs",
          defaultValue: cell.value ? _.find(categoryOptions, { value: cell.value.toUpperCase() }) : null,
          isClearable: true,
          options: categoryOptions,
          placeholder: "Select a Category",
          onChange: select => {
            const val = !select ? null : select.value.toUpperCase()
            updateResource({ ..._props, value: val, field: "category", id: cell.original.id })
          },
        }
        return <Select {...selectOptions} />
      },
    },
    {
			sortable: false,
      Header: () => (
				<span
            onClick={ () => changeSort("description") }
				>
				Description
				</span>
			),
      accessor: "description",
      Cell: cell => {
        return <InPut field="description"  allowClear={true} value={cell.value} id={cell.original.id} {..._props} />
      },
    },
    {
      Header: "Pay Type",
      accessor: "payTypeCode",
      sortable: false,
      Cell: cell => (
        <PayTypeSelect
          optional={true}
          record={cell.original}
          handleChange={val => updateResource({ ..._props, id: cell.original.id, value: val, field: "payTypeCode" })}
        />
      ),
    },
    {
      Header: "Actions",
      className: "justify-around",
      width: 100,
      sortable: false,
      Cell: row => (
        <div className="row_actions">
          <Button
            data-cy="DeleteRecordAction"
            icon="delete"
            shape="circle"
            type="danger"
            onClick={() => deleteResource({ ..._props, id: row.original.id })}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    if (data && data.length) store.set("_Reasons", data)
    if (data && data.length) store.set("Reasons", data)

    return() => { 
      store.remove("_Reasons")
    }

  }, [data])

  useEffect(() => {
    setRecords(state.ids.length ? _.filter(data, r => state.ids.includes(r.id)) : data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ids])

	var sorted = {};
	for( var f in state.sort ) {
		sorted.id = f;
		sorted.desc = state.sort[f];
		break;
	}
	//console.log(sorted);
	
  return (
    <React.Fragment>
      <div className="view_title">
        <h1 data-cy="page-title">{pageTitle}</h1>
        <div className="actions">
          <Search {..._props} />
          <Button data-cy="CreateResourceButton" type="dashed" icon="plus-square" onClick={() => createResource(_props)}>
            {createTitle}
          </Button>
        </div>
      </div>
      <div data-cy="RecordsTable" className="view_content">
        <ReactTable
          columns={columns}
          loading={false}
          data={records}
          filterable={true}
          multiSort={false}
          defaultPageSize={100}
          className="-striped -highlight flex-1"
          sorted={[sorted]}
        />
      </div>
    </React.Fragment>
  )
}

export default Reasons
