import React from "react"
import {session} from "../../store/psrs"
import {Button} from "antd"

const ApprovalSubNavButtonsV2 = ({history, page}) => {
  const nav = (e) => history.push(e.target.value, {basePath: "/approvalsV2"})
  //const role = store.session.get('role')
  const shifts = page === "ShiftApprovals"
  //const timeEntries = page === "TimeEntryApprovals"
  //const myShifts = page === "MyShiftApprovals"
  //const myTimeEntries = page === "MyTimeEntryApprovals"

  const userRole = session.get("CurrentUser").role 



  return (
    <Button.Group className="mx-2">
      { userRole !=='employee' && <Button type={shifts ? "primary" : "default"} onClick={nav} value="/approvals/shifts">My Approvals</Button> }
      {/* userRole !=='employee' && <Button type={timeEntries ? "primary" : "default"} onClick={nav} value="/approvals/timeEntries">TimeEntries</Button> */}
      {/* <Button type={myShifts ? "primary" : "default"} onClick={nav} value="/approvals/myShifts">My Shifts</Button> */ }
      {/* <Button type={myTimeEntries ? "primary" : "default"} onClick={nav} value="/approvals/myTimeEntries">My TimeEntries</Button> */ }

    </Button.Group>
  )


}

export default ApprovalSubNavButtonsV2