import React, { useState, useEffect, useMemo } from "react"
import { Input } from "antd"

export const InlineEdit = props => {
  const { allowClear, disabled, id, field, value, handleChange, yo } = props

  const [state, setState] = useState({
    allowClear: allowClear,
    value: value,
    disabled: disabled ? true : false,
    className: "InlineEdit",
    placeholder: props.placeholder || "Enter text",
    onChange: e => {
      setState({ ...state, value: e.target.value })
    },
    onBlur: e => {
      const val = e.target.value || ""
      setState({ ...state, value: val })
      handleChange({ ...props, id, field, value: val, sql })
    },
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sql = useMemo(() => `"${field}" IS NOT NULL AND "${field}" LIKE '%${state.value}%'`, [state.value])

  useEffect(() => {
    if (yo) {
      //console.log("InlineEdit value changed", value)
    }
    setState({ ...state, value })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return <Input data-cy="InlineEdit" {...state} />
}

export default InlineEdit
