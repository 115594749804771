import React from "react"

export const Monitor = props => {

    const monitoringURL = props.url

   return (
        <React.Fragment>
            <div className="rightColumn" id="reportPlaceholder" style={{ display:"table", width:"100%", height:"100%" }}>
                <iframe title="Monitor" src={monitoringURL} style={ {width: "100%", height: "100%", border: "1px solid black"}}  />                                
            </div>
        </React.Fragment>
    ) 

}

export default Monitor