import React, { useEffect, useState } from 'react';
import { store, reqOptions } from  "../../store/psrs"
import { Icon } from "antd"


import "./ShiftAuditModal.css"

const formatFieldName = (fieldName) => {
  if (fieldName === '_created') {
    return "Created"
  } else {
    return fieldName
    .replace(/([a-z])([A-Z])/g, '$1 $2')  // Add space before capital letters
    .replace(/([0-9])/g, ' $1')           // Add space before numbers
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  }
  
};

const JsonTable = ({id, modelName}) => {
  const [sortedChanges, setSortedChanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //const {id, context} = props
  //const shiftId = context.shiftId

  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Replace with your actual API endpoint
        //const uri = `https://localhost:5001/api/reports/audit/${id}`

        const uri = modelName === 'Shift' ? `${store.get("api")}/api/reports/audit/shift/${id}` : `${store.get("api")}/api/reports/audit/time_entry/${id}`
        //const uri = `${store.get("api")}/api/reports/audit/shift/${id}`

        const params = { ...reqOptions, method: "GET" }
        const response = await fetch( uri, params ) ;
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const jsonData = await response.json();

        // Function to flatten and sort changes
        const flattenAndSortChanges = (reportDataArray) => {
          const flattenedChanges = [];

          reportDataArray.forEach(report => {
            Object.keys(report.reportData.changes).forEach(changeKey => {
              const change = report.reportData.changes[changeKey];
              flattenedChanges.push({
                id: report.reportData.id,
                employeeNumber: report.reportData.employeeNumber,
                fullName: report.reportData.employeeName,
                dateCreated: report.reportData.date_created,
                dateUpdated: report.reportData.date_updated,
                field: changeKey,
                modelName: change.modelName,
                action: change.action,
                valueBefore: change.before,
                valueAfter: change.after,
                changedBy: change.userId,
                createdAt: change.dateCreated,
                updatedAt: change.dateUpdated
              });
            });
          });

          // Sort by action and then by dateUpdated
          flattenedChanges.sort((a, b) => {
            if (a.action < b.action) return -1;
            if (a.action > b.action) return 1;
            return new Date(a.updatedAt) - new Date(b.updatedAt);
          });

          return flattenedChanges;
        };

        // Set the sorted changes in state
        setSortedChanges(flattenAndSortChanges(jsonData));
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchJsonData();
  }, [id, modelName]);

  if (loading) {
    return <div><Icon style={{color: "DodgerBlue"}}type="loading" /><p>Loading audit data this may take some time...</p></div>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <table id="audit">
        <thead>
          <tr>
            {/* <th>ID</th>
            <th>Employee Number</th>
            <th>Full Name</th>
            <th>Date Created</th>
            <th>Date Updated</th> */}
            <th>Table Name</th>
            <th>Action</th>
            <th>Field</th>
            <th>Value Before</th>
            <th>Value After</th>
            <th>Changed By</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {sortedChanges.map((change, index) => (
            <tr key={index}>
             {/*  <td>{change.id}</td>
              <td>{change.employeeNumber}</td>
              <td>{change.fullName}</td>
              <td>{change.dateCreated}</td>
              <td>{change.dateUpdated}</td> */}
              <td>{change.modelName}</td>
              <td>{change.action}</td>
              <td>{formatFieldName(change.field)}</td> 
              <td>{change.valueBefore}</td>
              <td>{change.valueAfter}</td>
              <td>{change.changedBy}</td>
              <td>{change.createdAt}</td>
              <td>{change.updatedAt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JsonTable;
