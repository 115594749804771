import { useEffect } from 'react';

const useScript = (url,async=false, defer=false, type=false, dataTheme='light') => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url
    if (type) script.type = 'script/javascript'
    //script.type= type ? ''
    script.async = async
    script.defer = defer
    //script['data-theme'] = dataTheme
    //<script src='/ext/dhtmlxscheduler_minical.js' type="text/javascript"></script>
    //<script src="../../codebase/ext/dhtmlxscheduler_minical.js?v=5.3.12" type="text/javascript" charset="utf-8"></script>

    if (dataTheme) script.setAttribute("data-theme", "dark")

    //document.getElementsByTagName("head")[0].appendChild(script);
    //document.body.appendChild(script);
    document.head.appendChild(script)

    return () => {
      //document.body.removeChild(script);
      document.head.removeChild(script)
    }
  }, [url,async,defer,type,dataTheme],);
};

export default useScript;